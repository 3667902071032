import {Component, Input, OnInit} from "@angular/core";
import {IBindingTitleBrowseListDto} from "../../browse/browse-titles-types";
import {resolveContext} from "./types";

@Component({
    selector: "app-title-details",
    template: `
        <span class="title-details">
            <span class="content-amount-indicators" tooltip-append-to-body="true"
                  ngbTooltip="{{'title.page-count-indicator.description' | translate}} {{title.bindingCount}} {{'title.page-count-indicator.bindings' | translate}}, {{title.pageCount}} {{'title.page-count-indicator.pages' | translate}}">
                <app-page-count-indicator [pageCount]="title.pageCount"></app-page-count-indicator>
                <span>{{title.pageCount}} {{'title.page-count-indicator.pages' | translate}}</span>
                <span>{{title.bindingCount}} {{'title.page-count-indicator.bindings' | translate}}</span>
            </span>
            
            <span class="content-amount-indicators" ngbTooltip="{{'title.digitization-indicator.description' | translate}} {{digitizationPercentage}}">
                <app-progress-bar-indicator [fullness]="title.digitizationCoverage"></app-progress-bar-indicator>
                <span>{{gaplessPublishedYears}}</span>
            </span>

            <span class="identification">
                <span class="text-muted mr-1" style="vertical-align: middle">{{title.identification}}&nbsp;</span> 
                <app-title-info [context]="context" [issn]="title.identification"></app-title-info>
            </span>
        </span>
    `
})
export class TitleDetailsComponent implements OnInit {

    @Input() title: IBindingTitleBrowseListDto;
    @Input() digitizationPercentage: string;
    @Input() gaplessPublishedYears: string;

    public context: string;

    ngOnInit(): void {
        if (this.context == null)
            this.context = resolveContext(this.title.generalType);
    }
}

