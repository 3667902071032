import {Component, Inject, Input} from "@angular/core";
import {DisplayService} from "../../display.service";
import {BINDING_VIEW, ICurrentBindingView} from "../../../binding/types";

@Component({
    selector: "app-newspaper-info",
    template: `
        <app-sidebar>
            <app-sidebar-header titleKey="sanomalehti.title.data" (closeSidebar)="cbv.toggleMarc()"></app-sidebar-header>
            <app-sidebar-content class="kk-bg-light">
                <div class="marc-text">
                    <app-newspaper-metadata-details [issn]="issn"></app-newspaper-metadata-details>
                </div>    
            </app-sidebar-content>
        </app-sidebar>
    `,
    styleUrls: [
        "./binding-marc-text.scss"
    ]
})
export class NewspaperMetadataOverlayComponent {

    @Input() issn: string;

    constructor(public readonly displayService: DisplayService,
                @Inject(BINDING_VIEW) public readonly cbv: ICurrentBindingView) {
    }
}