import {Injectable} from "@angular/core";
import {LoggingService} from "../logging.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {LoginDialogComponent} from "./login-dialog.component";
import {SettingsService} from "../settings.service";
import {AccountRestEndpoint, PreferencesDto, UserEndpoint, UserProperties} from "../../apina-digiweb";
import {SessionExpiredComponent} from "./session-expired.component";
import {ActivatedRoute} from "@angular/router";
import {ConfirmationResult, TermsConfirmationV2ModalComponent} from "./terms-confirmation-v2-modal.component";
import {MatDialog} from "@angular/material/dialog";
import {CookieService} from "ngx-cookie-service";
import {BroadcastChannelService, BroadcastMessageType} from "../broadcast-channel.service";
import {TermsConfirmationModalComponent} from "./terms-confirmation-modal.component";
import {BehaviorSubject, Observable} from "rxjs";
import {distinctUntilChanged, filter, map, shareReplay, switchMapTo, take} from "rxjs/operators";
import {filterNonNull} from "../../utils/observable-utils";

const DONT_ASK_CONFIRMATION_KEY = 'dont_ask_terms_confirmation';
const ARBITRARY_NON_EMPTY_VALUE = '1';

@Injectable()
export class AccountService {

    private readonly refresh$ = new BehaviorSubject<void>(undefined);

    private readonly _userProperties = new BehaviorSubject<UserProperties>(null);

    public readonly userProperties$: Observable<UserProperties> = this._userProperties.pipe(filterNonNull);

    public readonly currentUserInfo$ = this.userProperties$.pipe(map(a => a.currentUserInfo));

    public readonly loggedIn$ = this.currentUserInfo$.pipe(map(a => a.loggedIn));

    public readonly preferences$: Observable<PreferencesDto> = this.refresh$.pipe(
        switchMapTo(this.accountRestEndpoint.getPreferences()),
        distinctUntilChanged(_.isEqual),
        shareReplay(1)
    );

    /**
     * Returns a snapshot of the current state. If data is still loading, returns false.
     */
    get loggedInSnapshot(): boolean {
        const props = this._userProperties.value;
        return props && props.currentUserInfo.loggedIn;
    }

    private sessionExpiredModal: NgbModalRef;

    constructor(readonly log: LoggingService,
                private readonly settingsService: SettingsService,
                private readonly userEndpoint: UserEndpoint,
                private readonly accountRestEndpoint: AccountRestEndpoint,
                private readonly ngbModal: NgbModal,
                private readonly matDialog: MatDialog,
                private readonly activatedRoute: ActivatedRoute,
                private readonly cookieService: CookieService,
                broadcastChannelService: BroadcastChannelService) {

        this.refresh$.pipe(
            switchMapTo(userEndpoint.getCurrentUserProperties())
        ).subscribe((props) => {
            this._userProperties.next(props);
        });

        broadcastChannelService.messages$.pipe(
            filter(a => a.type === BroadcastMessageType.SERVER_LOGIN_STATUS)
        ).subscribe((msg) => {
            if (typeof msg.data === "boolean")
                this.updateLoginStatus(msg.data);
            else
                console.error("Invalid login status data from broadcast event", msg.data);
        });

        log.debug("Initialized AccountService", this);
    }

    /**
     * Should be called from application root component, so it will be loaded on every page.
     */
    public checkConfirmations() {
        this.userProperties$.pipe(take(1)).subscribe(props => {
            if (props.isTermsConfirmationRequired && !this.isConfirmationDeferred()) {
                this.openTermsConfirmationDialog();
            }
        });
    }

    public openTermsConfirmationDialog() {
        if (this.isDialogV2()) {
            this.openTermsDialogV2();
        } else {
            this.openTermsDialogV1();
        }
    }

    private openTermsDialogV1() {
        const ref = this.ngbModal.open(TermsConfirmationModalComponent, {
            size: 'lg'
        });

        ref.result.then((result?: ConfirmationResult) => {
            if (result === ConfirmationResult.SKIPPED)
                this.deferConfirmation();
        }, reason => () => {
            // XXX If we leave this out dismissing the dialog (e.g. clicking outside it) results in error.
            // This is not actually called even in that case.
        });
    }

    private openTermsDialogV2() {
        const matDialogRef = this.matDialog.open(TermsConfirmationV2ModalComponent, {
            minWidth: "50vw",
            width: "95vw",
            maxWidth: "800px", // 800px is pretty ok
            minHeight: "50vh",
            maxHeight: "95vh"
        });
        matDialogRef.afterClosed().subscribe((result: ConfirmationResult) => {
            if (result === ConfirmationResult.SKIPPED)
                this.deferConfirmation();
            else if (result === ConfirmationResult.CONFIRMED) {
                this.refresh$.next();
            }
        });
    }

    private isDialogV2() {
        return new Date().getFullYear() > 2019;
    }

    private isConfirmationDeferred(): boolean {
        return this.cookieService.check(DONT_ASK_CONFIRMATION_KEY);
    }

    login($event?: Event) {
        if ($event)
            $event.preventDefault();

        this.ngbModal.open(LoginDialogComponent);
    }

    deferConfirmation() {
        this.cookieService.set(DONT_ASK_CONFIRMATION_KEY, ARBITRARY_NON_EMPTY_VALUE, 90, '/', null, true, "Strict");
    }

    /**
     * Update login status from login status returned from server.
     */
    updateLoginStatus(serverStatus: boolean) {
        const loggedIn = this.loggedInSnapshot;
        if (loggedIn === true && serverStatus === false) {
            // session is expired

            setTimeout(() => {
                // show only one instance, even if login status updates are fired rapidly
                if (this.sessionExpiredModal == null) {
                    this.sessionExpiredModal = this.ngbModal.open(SessionExpiredComponent);
                    this.sessionExpiredModal.result.then(relog => {
                        if (relog)
                            this.login();
                        else
                            this.reloadUI();
                        this.sessionExpiredModal = null;
                    }, ignored => {
                        this.sessionExpiredModal = null;
                        this.reloadUI();
                    });
                }
            }, 1000);

        } else if (loggedIn === false && serverStatus === true) {
            // user logged-in in another tab

            // reload page, there's probably no harm in it.
            this.reloadUI();
        }
    }

    private reloadUI() {
        // hack:
        // If a url mapping is not found on server, we get a fast 404 response which always says the user is not logged
        // in. If the user is logged in according to client state and we try to reload, we get a reload-loop.
        // So check for a flag set by the not-found route and don't reload on those urls.
        const snapshots = this.activatedRoute.snapshot.children;

        for (const snapshot of snapshots) {
            if (snapshot.data.noReload) {
                console.log("reload disabled for current route");
                return;
            }
        }

        window.location.reload();
    }

    /**
     * Refreshes account state from server.
     */
    refresh() {
        this.refresh$.next();
    }
}
