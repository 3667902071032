import {ApinaConfig} from "./apina-digiweb";
import {formatISODate, parseISODate} from "./utils/date";
import {formatISOInstant, parseISOInstant} from "./utils/time";

export type UriComponents = string;
export type URI = string;
export type URL = string;
export type AsteriId = string;
export type PublicationId = string;
export type ISO639LanguageCode = string;
export type MarcCompatibleDate = string;
export class MaterialTag { // XXX also defined in user-management

    constructor(public readonly type: string,
                public readonly value: string) {
    }

    public static parse(serialized: string): MaterialTag | null {
        if (serialized == null)
            return null;
        const delim = serialized.indexOf(':');

        if (delim === -1) {
            throw Error("Invalid tag: " + serialized);
        }

        return new MaterialTag(serialized.substring(0, delim), serialized.substring(delim + 1));
    }

    public static serializeStatic(tag: MaterialTag) {
        return tag != null ? tag.serialize() : null;
    }

    serialize() {
        return this.type + ':' + this.value;
    }
}

export function configureApinaBindings(apinaConfig: ApinaConfig) {
    apinaConfig.registerIdentitySerializer('UriComponents');
    apinaConfig.registerIdentitySerializer('URI');
    apinaConfig.registerIdentitySerializer('URL');
    apinaConfig.registerIdentitySerializer('AsteriId');
    apinaConfig.registerIdentitySerializer('PublicationId');
    apinaConfig.registerIdentitySerializer('ISO639LanguageCode');
    apinaConfig.registerIdentitySerializer('Dictionary<any>');
    apinaConfig.registerIdentitySerializer('Dictionary<string>');
    apinaConfig.registerIdentitySerializer('Dictionary<string[]>');
    apinaConfig.registerIdentitySerializer('MarcCompatibleDate');
}

export function configureApinaDateBindings(apinaConfig: ApinaConfig) {
    apinaConfig.registerSerializer('LocalDate', {
        serialize: formatISODate,
        deserialize: parseISODate
    });

    apinaConfig.registerSerializer('Instant', {
        serialize: formatISOInstant,
        deserialize: parseISOInstant
    });

    apinaConfig.registerSerializer('MaterialTag', {
        serialize: MaterialTag.serializeStatic,
        deserialize: MaterialTag.parse
    });
}