import {ChangeDetectorRef, Directive} from "@angular/core";
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from "@angular/forms";
import {Observable, of} from "rxjs";
import {BindingSearchRestEndpoint} from "../../apina-digiweb";
import {map, tap} from "rxjs/operators";

/**
 * Validates whether text is valid Lucene syntax.
 */
@Directive({
    selector: '[appValidQuerySyntax]',
    providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: QuerySyntaxValidator, multi: true}]
})
export class QuerySyntaxValidator implements AsyncValidator {

    constructor(private ref: ChangeDetectorRef, private bindingSearchApi: BindingSearchRestEndpoint) {}

    validate(ctrl: AbstractControl): Observable<ValidationErrors | null> {
        /**
         * TODO Observe if async validator mechanics change regarding ChangeDetectionStrategy.OnPush in future:
         * currently this only works when tapping to mark for checks, see: https://github.com/angular/angular/issues/12378 and
         * https://github.com/angular/angular/issues/10816
         */
        return (!ctrl.value) ? of(null) : this.bindingSearchApi.validateSearchQuerySyntax(ctrl.value).pipe(
            map(isValid => (isValid) ? null : {'isInvalidSyntax': false}),
            tap(() => { this.ref.markForCheck(); })
        );
    }
}
