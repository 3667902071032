import {Component, Input} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {SerialPublicationDateAccuracy} from "../../../apina-digiweb";
import {variableAccuracyDate} from "../../../utils/date";
import {LocalDate} from "@js-joda/core";

@Component({
    template: `
        <div appModalDraggable [appModalDraggableHandle]="draggableHandle">
            <div class="modal-header" #draggableHandle>
                <h4 class="modal-title" translate>year-binding-list.date-picker.title</h4>
                <button type="button" class="close" (click)="activeModal.dismiss()" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <form #form="ngForm" autocomplete="off">
                    <div class="form-row">
                        <div class="form-group col-sm-3">
                            <label translate>binding-problem.date-accuracy</label>
                            <select [(ngModel)]="dateAccuracy" name="date-accuracy" class="form-control" (change)="accuracyUpdated()">
                                <option *ngFor="let da of dateAccuracies" [value]="da">{{'year-binding-list.date-accuracies.' + da | translate}}</option>
                            </select>
                        </div>
                        <div class="form-group col-sm-7 offset-sm-2">
                            <label translate>binding-problem.date</label>
                            <div>
                                <input type="text" [(ngModel)]="date"
                                       name="publishing-date"
                                       [required]="dayRequired"
                                       [disabled]="!dayRequired"
                                       class="form-control"
                                       placeholder="pp"
                                       pattern="\\d{1,2}"
                                       style="width: 3em; display: inline-block;"
                                />
                                .
                                <input type="text" [(ngModel)]="month"
                                       name="publishing-month"
                                       [required]="monthRequired"
                                       [disabled]="!monthRequired"
                                       class="form-control"
                                       placeholder="kk"
                                       pattern="\\d{1,2}"
                                       style="width: 3em; display: inline-block;"
                                />
                                .
                                <span class="form-control-static">{{year}}</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <div class="alert alert-warning" *ngIf="invalidDate" translate>year-binding-list.date-picker.invalid</div>
                
                <button class="btn btn-kk-blue" (click)="submit()" [disabled]="form.invalid" translate>year-binding-list.date-picker.submit</button>
            </div>
        </div>
    `
})
export class VariableAccuracyDateSelectionModalComponent {

    public dateAccuracies: SerialPublicationDateAccuracy[] = Object.keys(SerialPublicationDateAccuracy) as any[];

    @Input() year: number;

    dateAccuracy = SerialPublicationDateAccuracy.DATE;
    date: string;
    month: string;

    invalidDate = false;

    constructor(public readonly activeModal: NgbActiveModal) {
    }

    get dayRequired() {
        return this.dateAccuracy === SerialPublicationDateAccuracy.DATE;
    }

    get monthRequired() {
        return this.dateAccuracy === SerialPublicationDateAccuracy.DATE ||
            this.dateAccuracy === SerialPublicationDateAccuracy.MONTH;
    }

    submit() {
        const resultValue = this.getResultValue();

        if (!resultValue) {
            this.invalidDate = true;
        } else {
            this.activeModal.close(resultValue);
        }
    }

    private getResultValue() {
        const m = parseNumber(this.month);
        const d = parseNumber(this.date);

        if (m != null && (m < 1 || m > 12))
            return null;

        if (d != null && (d < 1 || d > 31))
            return null;

        if (d != null) {
            try {
                LocalDate.of(this.year, m, d);
            } catch (e) {
                return null;
            }
        }

        switch (this.dateAccuracy) {
            case SerialPublicationDateAccuracy.DATE:
                return variableAccuracyDate(this.year, m, d);
            case SerialPublicationDateAccuracy.MONTH:
                return variableAccuracyDate(this.year, m, null);
            case SerialPublicationDateAccuracy.YEAR:
                return variableAccuracyDate(this.year, null, null);
        }
    }

    accuracyUpdated() {
        switch (this.dateAccuracy) {
            case SerialPublicationDateAccuracy.YEAR:
                this.month = "";
                this.date = "";
                break;
            case SerialPublicationDateAccuracy.MONTH:
                this.date = "";
                break;
            case SerialPublicationDateAccuracy.DATE:
                break;
        }
    }
}

function parseNumber(value: string): number|null {
    const number = parseInt(value, 10);
    if (isNaN(number))
        return null;
    else
        return number;
}
