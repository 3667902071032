import {Component, Input, OnInit} from "@angular/core";
import {IBindingTitleBrowseListDto} from "../../browse/browse-titles-types";
import {resolveContext} from "./types";

@Component({
    selector: "app-title-basic-info",
    template: `
        <span class="title-basic-info">
            <i *ngIf="title.hasNewBindingsWithinWeek" class="new-bindings-indicator fa fa-star" ngbTooltip="{{'title.new-bindings-within-week' | translate}}"></i>
        
            <span class="name" [ngSwitch]="isAccessible">
                <a *ngSwitchCase="true" routerLink="/{{context}}/titles/{{title.identification}}" [queryParams]="{year: curyear}" class="visitable">{{title.title}}</a>
                <span *ngSwitchCase="false">{{title.title}} </span>
            </span>
        
            <span class="text-muted digitization-years-row">
                <app-digitization-years [list]="title.digitizationTimes"></app-digitization-years>
            </span>
        </span>
    `
})
export class TitleBasicInfoComponent implements OnInit {
    @Input() isAccessible: boolean;
    @Input() title: IBindingTitleBrowseListDto;
    @Input() curyear: number;

    public context: string;

    ngOnInit(): void {
        if (this.context == null)
            this.context = resolveContext(this.title.generalType);
    }
}
