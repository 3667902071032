import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ProcessedUserArticleRow, ResultMode} from "./result/result-row";

@Component({
    selector: 'app-article-search-results',
    template: `
        <div class="row result result-article">
            <app-progress-spinner *ngIf="items == null"></app-progress-spinner>
            <div *ngFor="let row of items; let idx = index" [ngClass]="resultMode == 'THUMB' ? 'col-6 col-md-4 col-lg-3' : 'col-md-12'">
                <ng-container [ngSwitch]="resultMode">
                    <app-search-result-text-thumb-article *ngSwitchCase="'TEXT'" [showThumb]="false" [row]="row" [allowDelete]="allowDelete" (deleteRow)="deleteRow.emit($event)"></app-search-result-text-thumb-article>
                    <app-search-result-text-thumb-article *ngSwitchCase="'TEXT_WITH_THUMB'" [showThumb]="true" [row]="row" [allowDelete]="allowDelete" (deleteRow)="deleteRow.emit($event)"></app-search-result-text-thumb-article>
                    <app-search-result-thumb-article *ngSwitchCase="'THUMB'" [row]="row" [highlightPlacement]="(idx % 4) < 2 ? 'right' : 'left'"></app-search-result-thumb-article>
                </ng-container>
            </div>
        </div>
    `
})
export class ArticleSearchResultsComponent {
    @Input() resultMode = ResultMode;

    @Input() items: ProcessedUserArticleRow[] | null;

    @Input() allowDelete: boolean;

    @Output() deleteRow = new EventEmitter<ProcessedUserArticleRow>();
}