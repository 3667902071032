import {AfterViewInit, Component, ElementRef, Input, OnDestroy} from '@angular/core';
import {FacebookService} from "./facebook.service";
import {Subscription} from "rxjs";

/**
 * Stolen from http://jasonwatmore.com/post/2018/06/01/angular-2-social-sharing-buttons-for-facebook-google-plus-twitter-linkedin-and-pinterest
 */
@Component({
    selector: 'app-facebook-like',
    template: `<div class="fb-share-button" [attr.data-href]="url" data-layout="button_count" data-action="like" data-show-faces="true" data-share="true"></div>`
})
export class FacebookLikeComponent implements AfterViewInit, OnDestroy {
    @Input() url: string;
    private subscription: Subscription;

    constructor(private elem: ElementRef,
                private facebookService: FacebookService) {

    }

    ngAfterViewInit(): void {
        this.subscription = this.facebookService.xfbml.subscribe(t => {
            t.parse(this.elem.nativeElement);
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
