import {Pipe, PipeTransform} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

@Pipe({ name: 'translateMultiNamed' })
export class TranslateMultiNamedPipe implements PipeTransform {

    constructor(private translate: TranslateService) {}

    transform(obj: any): string {
        switch (this.translate.currentLang) {
            case 'en':
                return obj.nameEn;
            case 'sv':
                return obj.nameSv;
            case 'fi':
                return obj.nameFi;
            default:
                return obj.nameFi;
        }
    }
}

@Pipe({ name: 'translateBindingProblemType' })
export class TranslateBindingProblemType implements PipeTransform {

    constructor(private translate: TranslateService) {}

    transform(value: string): string {
        return value && this.translate.instant(createTranslationKey('binding-problem.type', value));
    }
}

@Pipe({ name: 'translateMissingBindingInfoType' })
export class TranslateMissingBindingInfoType implements PipeTransform {

    constructor(private translate: TranslateService) {}

    transform(value: string): string {
        return value && this.translate.instant('missing-binding.' + value);
    }
}

function createTranslationKey(prefix: string, type: string): string {
    return prefix + '.' + type.replace(/_/g, '-').toLowerCase();
}

const DIGITS_PER_MAGNITUDE = 3;
const units = ['tavua', 'kB', 'MB', 'GB', 'TB'];

@Pipe({ name: 'fileSize' })
export class FileSizePipe implements PipeTransform {
    transform(value: any, ...args: any[]): any {
        const bytes = value;
        const magnitude = Math.floor(log10(bytes) / DIGITS_PER_MAGNITUDE);
        const unit = units[magnitude];
        const displayValue = magnitude > 0 ? bytes / Math.pow(10, magnitude * DIGITS_PER_MAGNITUDE) : bytes;

        return `${displayValue.toFixed(1)} ${unit}`;
    }
}

function log10(x: number): number {
    return Math.log(x) / Math.LN10;
}
