/// <reference path="./digiweb.d.ts" />

import * as jQuery from 'jquery';
import "./polyfills";
import "./utils/feature-detects";
import {AppModule} from "./app.module";
import {platformBrowserDynamic} from "@angular/platform-browser-dynamic";

window.jQuery = jQuery;
window._ = require('lodash');

// noinspection JSIgnoredPromiseFromCall
platformBrowserDynamic().bootstrapModule(AppModule);
