import {HammerGestureConfig} from "@angular/platform-browser";
import {Injectable} from "@angular/core";

@Injectable()
export class HammerConfig extends HammerGestureConfig {
    overrides = <any>{
        // override hammerjs default configuration
        'swipe': { enable: false },
        'pan': { enable: false },
        'pinch': { enable: true }
    };
}
