import {GeneralType} from "../apina-digiweb";

export function isSerialPublication(generalType: GeneralType): boolean {
    switch (generalType) {
        case GeneralType.NEWSPAPER:
        case GeneralType.JOURNAL:
            return true;
        default:
            return false;
    }
}

export function isOpus(generalType: GeneralType): boolean {
    switch (generalType) {
        case GeneralType.BOOK:
        case GeneralType.CARD_INDEX:
        case GeneralType.MANUSCRIPT:
        case GeneralType.MAP:
        case GeneralType.MUSIC_NOTATION:
        case GeneralType.PICTURE:
            return true;
        default:
            return false;
    }
}
