import {Component, Input} from "@angular/core";
import {ProcessedBindingSearchResultRow} from "./result-row";
import {PlacementArray} from "@ng-bootstrap/ng-bootstrap/util/positioning";

@Component({
    selector: "app-search-result-thumb",
    template: `
        <div class="result-row">
            <a [href]="row.url" target="_blank" appStoreSession>
                <div class="result thumb-result">
                    <ng-template #thumbHtml>
                        <div class="search-highlight-fragment" *ngFor="let hl of row.highLights" [innerHTML]="hl"></div>
                    </ng-template>
                    <div class="thumb-frame" [ngbPopover]="thumbHtml" [triggers]="hasHighlights(row) ? 'mouseenter:mouseleave' : ''" [container]="'body'" [placement]="highlightPlacement">
                        <div class="search-thumb-wrapper">
                            <img [src]="row.thumbnailUrl" alt="{{row.captionTitle}}" appErrorThumb />
                            <div class="pagenum2 text-center"><span *ngIf="row.pageNumber != 1" class="badge badge-secondary">{{row.pageNumber}}</span></div>
                        </div>
                    </div>
                    <div class="thumb-caption" [ngbTooltip]="row.captionTitle">
                        <div class="cropped-row link-colors">{{row.mainLinkTitle}}</div>
                        <div class="cropped-row small"><span *ngIf="row.authors?.length === 0">&nbsp;</span>{{row.authorList}}</div>
                        <div class="small"><span *ngIf="row.issue != null"> <span translate>query.result.nro</span> {{row.issue}},</span>&nbsp;<span *ngIf="row.date">{{row.date | dateWithAccuracy:row.dateAccuracy}}</span></div>
                        <div class="cropped-row small"><span *ngIf="row.placeOfPublication != null"> {{row.placeOfPublication}}, </span><span *ngIf="row.publisher != null">{{row.publisher}}</span></div>
                    </div>
                </div>
            </a>
        </div>
    `,
    styleUrls: [
        "./result-common.scss",
        "./thumb-result.scss"
    ]
})
export class ThumbResultComponent {
    @Input() row: ProcessedBindingSearchResultRow;
    @Input() highlightPlacement: string | PlacementArray;

    public hasHighlights(row: ProcessedBindingSearchResultRow) {
        return row.highLights.length > 0;
    }
}
