import {Directive, ElementRef, EventEmitter, HostListener, Inject, Input, OnDestroy, Output} from "@angular/core";
import {HammerConfig} from "../../hammer-config";
import {HAMMER_GESTURE_CONFIG} from "@angular/platform-browser";
import {avg} from "../../utils/number-utils";

// copied from platform-browser.d.ts
declare interface HammerInstance {
    on(eventName: string, callback?: Function): void;
    off(eventName: string, callback?: Function): void;
    destroy?(): void;
}

export interface IFocalZoomEvent {
    level: number;

    /**
     * Ratio 0...1 indicating where the focal point of zoom should be. 0 means left border, 0.5 means the middle
     * horizontally, 1 means right border.
     */
    eventX: number;

    /**
     * Ratio 0...1 indicating where the focal point of zoom should be. 0 means top border, 0.5 means the middle
     * vertically, 1 means bottom border.
     */
    eventY: number;
}

@Directive({
    selector: "[appPinchable]"
})
export class PinchableDirective implements OnDestroy {

    constructor(element: ElementRef, @Inject(HAMMER_GESTURE_CONFIG) hammerConfig: HammerConfig) {
        this.el = element.nativeElement;
        this.hammerTime = hammerConfig.buildHammer(this.el);

        this.hammerTime.on('pinch', (event: HammerInput) => {
            const level = event.scale * this.startLevel;
            this.pinchZoom.emit({
                level,
                eventX: this.startX,
                eventY: this.startY
            });
        });
    }

    @Input() zoomLevel: number;
    @Output() pinchZoom = new EventEmitter<IFocalZoomEvent>();

    private readonly el: HTMLElement;
    private hammerTime: HammerInstance;

    private startLevel = 0;
    // point where zoom started
    private startX: number;
    private startY: number;

    // If the argument type is just TouchEvent, Firefox doesn't load the app + error:
    // ReferenceError: TouchEvent is not defined[Learn More]
    @HostListener("touchstart", ["$event"]) onTouchStart(event: TouchEvent | any) {
        this.startLevel = this.zoomLevel;

        const {centerX, centerY} = this.getEventCenter(event);

        const bounds = this.el.getBoundingClientRect();

        const offsetX = centerX - bounds.left;
        const offsetY = centerY - bounds.top;
        this.startX = offsetX / bounds.width;
        this.startY = offsetY / bounds.height;
    }

    @HostListener("touchend") onTouchEnd() {
        this.startX = null;
        this.startY = null;
    }

    private getEventCenter(event: TouchEvent) {
        let centerX: number;
        let centerY: number;

        if (event.touches.length === 1) {
            const touch = event.touches[0];
            centerX = touch.pageX;
            centerY = touch.pageY;
        } else if (event.touches.length === 2) {
            const touch1 = event.touches[0];
            const touch2 = event.touches[1];

            centerX = avg(touch1.pageX, touch2.pageX);
            centerY = avg(touch1.pageY, touch2.pageY);
        }
        return {centerX, centerY};
    }

    ngOnDestroy(): void {
        this.hammerTime.off("pinch");
    }
}
