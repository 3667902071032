<section class="container" *ngIf="consentForm" [formGroup]="consentForm">
    <h2 translate>cookies.title</h2>
    <p [innerHTML]="'cookies.page.description' | translate"></p>
    <br>
    <h3>{{ 'cookies.page.consentTitle' | translate }}</h3>
    <p>{{ 'cookies.page.consentDescription' | translate }}</p>
    <br>
    <div class="consent-sub-title">
        <mat-checkbox formControlName="essential" color="primary">
            <h4 translate>cookies.page.essential.title</h4>
        </mat-checkbox>
    </div>
    <p>{{ 'cookies.page.essential.description' | translate }}</p>
    <table class="table table-responsive">
        <thead>
            <tr>
                <td><b>{{ 'cookies.page.tableHeader.name' | translate }}</b></td>
                <td><b>{{ 'cookies.page.tableHeader.provider' | translate }}</b></td>
                <td><b>{{ 'cookies.page.tableHeader.description' | translate }}</b></td>
                <td><b>{{ 'cookies.page.tableHeader.validity' | translate }}</b></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>agreed-categories</td>
                <td>digi.kansalliskirjasto.fi</td>
                <td>{{ 'cookies.page.essential.table.categories' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.1year' | translate }}</td>
            </tr>
            <tr>
                <td>kkArticleMetadata</td>
                <td>digi.kansalliskirjasto.fi</td>
                <td>{{ 'cookies.page.essential.table.article' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.1week' | translate }}</td>
            </tr>
            <tr>
                <td>org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE</td>
                <td>digi.kansalliskirjasto.fi</td>
                <td>{{ 'cookies.page.essential.table.lang' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.session' | translate }}</td>
            </tr>
        </tbody>
    </table>

    <div class="consent-sub-title">
        <mat-checkbox formControlName="preference" color="primary">
            <h4 translate>cookies.page.preference.title</h4>
        </mat-checkbox>
    </div>
    <p>{{ 'cookies.page.preference.description' | translate }}</p>
    <table class="table table-responsive">
        <thead>
            <tr>
                <td><b>{{ 'cookies.page.tableHeader.name' | translate }}</b></td>
                <td><b>{{ 'cookies.page.tableHeader.provider' | translate }}</b></td>
                <td><b>{{ 'cookies.page.tableHeader.description' | translate }}</b></td>
                <td><b>{{ 'cookies.page.tableHeader.validity' | translate }}</b></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>csrftoken</td>
                <td>5261.clients.giosgusercontent.com</td>
                <td>{{ 'cookies.page.preference.table.giosg.csrf' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.2years' | translate }}</td>
            </tr>
            <tr>
                <td>sgid</td>
                <td>service.giosg.com</td>
                <td>{{ 'cookies.page.preference.table.giosg.optimize' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.2years' | translate }}</td>
            </tr>
            <tr>
                <td>gid</td>
                <td>service.giosg.com</td>
                <td>{{ 'cookies.page.preference.table.giosg.generic' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.2years' | translate }}</td>
            </tr>
            <tr>
                <td>giosg_chat_id_#</td>
                <td>digi.kansalliskirjasto.fi</td>
                <td>{{ 'cookies.page.preference.table.giosg.generic' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.2years' | translate }}</td>
            </tr>
            <tr>
                <td>giosg_gid_#</td>
                <td>digi.kansalliskirjasto.fi</td>
                <td>{{ 'cookies.page.preference.table.giosg.generic' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.2years' | translate }}</td>
            </tr>
            <tr>
                <td>giosg_reporting_pageview_track_seq_num</td>
                <td>digi.kansalliskirjasto.fi</td>
                <td>{{ 'cookies.page.preference.table.giosg.generic' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.1hour' | translate }}</td>
            </tr>
            <tr>
                <td>giosg_gsessid_#</td>
                <td>digi.kansalliskirjasto.fi</td>
                <td>{{ 'cookies.page.preference.table.giosg.generic' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.2years' | translate }}</td>
            </tr>
        </tbody>
    </table>
    <div class="consent-sub-title">
        <mat-checkbox formControlName="statistic" color="primary">
            <h4 translate>cookies.page.statistic.title</h4>
        </mat-checkbox>
    </div>
    <p>{{ 'cookies.page.statistic.description' | translate }}</p>
    <p></p>
    <table class="table table-responsive">
        <thead>
            <tr>
                <td><b>{{ 'cookies.page.tableHeader.name' | translate }}</b></td>
                <td><b>{{ 'cookies.page.tableHeader.provider' | translate }}</b></td>
                <td><b>{{ 'cookies.page.tableHeader.description' | translate }}</b></td>
                <td><b>{{ 'cookies.page.tableHeader.validity' | translate }}</b></td>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>_pk_id</td>
                <td>digi.kansalliskirjasto.fi</td>
                <td>{{ 'cookies.page.statistics.table.matomo.description' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.13months' | translate }}</td>
            </tr>
            <tr>
                <td>_pk_ses</td>
                <td>digi.kansalliskirjasto.fi</td>
                <td>{{ 'cookies.page.statistics.table.matomo2.description' | translate }}</td>
                <td>{{ 'cookies.page.statistics.table.validity.30minutes' | translate }}</td>
            </tr>
        </tbody>
    </table>
    <button type="submit" class="btn btn-kk-blue" (click)="onSubmit()">{{ 'cookies.page.submit' | translate }}</button>
</section>
<br>