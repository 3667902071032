import {Component, Inject, Input, OnInit} from "@angular/core";
import {
    BindingProblemDto,
    BindingProblemType,
    BindingProblemUpdatedMetadata,
    BindingRestEndpoint,
    ReferenceDataRestEndpoint,
    SerialPublicationDateAccuracy,
    SerialTitleValidationDto
} from "../../apina-digiweb";
import {MarcCompatibleDate} from "../../apina-types";
import {BINDING_VIEW, ICurrentBindingView} from "../../binding/types";

interface MetadataViewDto {
    issn: string;
    issue: string;
    date: string;
    dateAccuracy: SerialPublicationDateAccuracy;
}

@Component({
    selector: "app-binding-problems",
    template: `
        <app-sidebar>
            <app-sidebar-header titleKey="binding-problem.title" (closeSidebar)="cbv.toggleBindingProblemDialog()"></app-sidebar-header>
            <app-sidebar-content>
                <app-progress-spinner *ngIf="loading"></app-progress-spinner>
                <form class="form-horizontal" *ngIf="!loading">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label translate>binding-problem.problem</label>
                            <select [(ngModel)]="model.type" name="type" class="form-control">
                                <option *ngFor="let type of bindingProblemTypes" [value]="type">{{type | translateBindingProblemType}}</option>
                            </select>
                        </div>
                    </div>

                    <div *ngIf="showMetadata">
                        <div class="form-row">
                            <div class="form-group col-12">
                                <label>ISSN:</label>
                                <input type="text"
                                       [ngModel]="meta.issn" (ngModelChange)="updateISSN($event)"
                                       name="issn"
                                       class="form-control"
                                />
                            </div>
                            <div class="form-group col-12">
                                <label></label>
                                <div *ngIf="issnValidation as v">
                                    <div class="alert alert-danger" *ngIf="!v.valid" translate>binding-problem.issn-not-found</div>
                                    <div class="alert alert-success" *ngIf="v.valid">{{v.title}}</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-6">
                                <label translate>binding-problem.date-accuracy</label>
                                <select [(ngModel)]="meta.dateAccuracy" name="date-accuracy" class="form-control">
                                    <option *ngFor="let da of dateAccuracies" [value]="da">{{'binding-problem.date-accuracy.' + da | translate}}</option>
                                </select>
                            </div>
                            <div class="form-group col-6">
                                <label translate>binding-problem.date</label>
                                <input type="text" [(ngModel)]="meta.date"
                                       name="publishing-date"
                                       class="form-control"
                                       [placeholder]="datePlaceHolder"
                                       [pattern]="datePattern"
                                />
                            </div>
                            <div class="form-group col-12">
                                <label translate>binding-problem.issue</label>
                                <input type="text" [(ngModel)]="meta.issue"
                                       name="issue"
                                       maxlength="30"
                                       class="form-control"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="form-row" *ngIf="!showMetadata">
                        <div class="form-group col-12">
                            <label>{{'binding-problem.description' | translate}}:</label>
                            <textarea class="form-control" rows="5" [(ngModel)]="model.description" name="description" placeholder="{{'binding-problem.description' | translate}}"></textarea>
                        </div>
                    </div>
                </form>
                
                <div>
                    <button class="btn btn-kk-blue" (click)="reportProblem()" [disabled]="!submitAllowed">{{submitText | translate}}</button>
                </div>
            </app-sidebar-content>
        </app-sidebar>
    `
})
export class BindingProblemsComponent implements OnInit {

    constructor(@Inject(BINDING_VIEW) public cbv: ICurrentBindingView,
                private referenceDataRest: ReferenceDataRestEndpoint,
                private bindingRest: BindingRestEndpoint) {
    }

    @Input() public bindingId: number;

    public bindingProblemTypes: BindingProblemType[] = Object.keys(BindingProblemType) as any[];
    public dateAccuracies: SerialPublicationDateAccuracy[] = Object.keys(SerialPublicationDateAccuracy) as any[];
    public loading = true;
    public validating = false;
    public model: BindingProblemDto;
    public meta: MetadataViewDto;

    issnValidation: SerialTitleValidationDto | null;

    ngOnInit(): void {
        this.model = {
            bindingId: this.bindingId,
            description: '',
            type: BindingProblemType.INVALID_METADATA,
            updatedMetadata: null
        };

        this.bindingRest.getCurrentMetadataForProblems(this.bindingId).subscribe(data => {
            if (data) {
                this.meta = {
                    issn: data.issn,
                    issue: data.issue,
                    dateAccuracy: data.dateAccuracy,
                    date: localizeMCD(data.date)
                };
            }
            this.loading = false;
        });
    }

    get showMetadata() {
        return this.model.type === BindingProblemType.INVALID_METADATA && this.meta != null;
    }

    get datePlaceHolder() {
        switch (this.meta.dateAccuracy) {
            case SerialPublicationDateAccuracy.YEAR:
                return 'yyyy';
            case SerialPublicationDateAccuracy.MONTH:
                return 'kk.yyyy';
            case SerialPublicationDateAccuracy.DATE:
                return 'pp.kk.yyyy';
        }
    }

    get datePattern() {
        switch (this.meta.dateAccuracy) {
            case SerialPublicationDateAccuracy.YEAR:
                return '\\d{4}';
            case SerialPublicationDateAccuracy.MONTH:
                return '\\d{1,2}.\\d{4}';
            case SerialPublicationDateAccuracy.DATE:
                return '\\d{1,2}.\\d{1,2}.\\d{4}';
        }
    }

    updateISSN(issn: string) {
        this.meta.issn = issn;
        this.validating = true;
        if (issn && issn.length > 1) {
            this.bindingRest.validateSerialTitle(issn).subscribe((result) => {
                this.issnValidation = result;
                this.validating = false;
            });
        }
    }

    get submitAllowed(): boolean {
        return !this.loading && !this.validating && (this.issnValidation == null || this.issnValidation.valid);
    }

    get submitText(): string {
        if (this.showMetadata)
            return 'binding-problem.update-data';
        else
            return 'binding-problem.report';
    }

    reportProblem() {
        if (this.meta != null)
            this.model.updatedMetadata = serializeMetadata(this.meta);

        this.bindingRest.saveBindingProblem(this.model).subscribe(() => {
            this.cbv.toggleBindingProblemDialog();
        });
    }
}

function localizeMCD(date: MarcCompatibleDate): string {
    const parts = date.split("-");
    return parts.reverse().join(".");
}

function formatMCD(localizedDate: string): MarcCompatibleDate {
    const parts = localizedDate.split(".");
    return parts.reverse().join("-");
}

function serializeMetadata(meta: MetadataViewDto): BindingProblemUpdatedMetadata {
    return {
        issn: meta.issn,
        issue: meta.issue,
        publishingDate: formatMCD(meta.date),
        dateAccuracy: meta.dateAccuracy
    };
}
