import {BehaviorSubject, merge, MonoTypeOperatorFunction, Observable, of, Unsubscribable} from "rxjs";
import {filter, map} from "rxjs/operators";

export type Type<T> = new (...args: any[]) => T;

// when modifying also update mapResponse(..) and mapError(..)
export enum ResourceLoadingException {
    NOT_FOUND = "NOT_FOUND",
    SERVER_ERROR = "SERVER_ERROR",
    UNKNOWN = "UNKNOWN",
}

export function mapResponse<T, R>(value: T | ResourceLoadingException, mapper: (value: T) => R): R | null {
    if (value === ResourceLoadingException.NOT_FOUND ||
        value === ResourceLoadingException.SERVER_ERROR ||
        value === ResourceLoadingException.UNKNOWN) {
        return null;
    } else {
        return mapper(value);
    }
}

export function mapError<R>(value: any | ResourceLoadingException, mapper: (value: ResourceLoadingException) => R, onResponse: R): R {
    if (value === ResourceLoadingException.NOT_FOUND ||
        value === ResourceLoadingException.SERVER_ERROR ||
        value === ResourceLoadingException.UNKNOWN) {
        return mapper(value);
    } else {
        return onResponse;
    }
}

export function isNonError<T>(value: T | ResourceLoadingException): value is T {
    return value !== ResourceLoadingException.NOT_FOUND
        && value !== ResourceLoadingException.SERVER_ERROR
        && value !== ResourceLoadingException.UNKNOWN;
}

export function filterNonNull<T>(source: Observable<T|null|undefined>): Observable<T> {
    // @ts-ignore
    return source.pipe(filter(t => !!t));
}

export function filterNonErrors<T>(source: Observable<T | ResourceLoadingException>): Observable<T> {
    return source.pipe(filter(a => isNonError(a))) as Observable<T>;
}

export function filterNonNullArrays<T, U>(source: Observable<[T|null|undefined, U|null|undefined]>): Observable<[T, U]> {
    // @ts-ignore
    return source.pipe(filter(([t, u]) => !!t && !!u));
}

export function filterInstanceOf<T>(type: Type<T>): MonoTypeOperatorFunction<T> {
    return filter(a => a instanceof type);
}

export function updateIfChanged<T>(subject: BehaviorSubject<T>, next: T) {
    const value = subject.value;
    if (value !== next)
        subject.next(next);
}

export function addContext<A, B>(observable: Observable<A>, context: B): Observable<WithContext<A, B>> {
    return observable.pipe(map((payload) => {
        return new WithContext<A, B>(payload, context);
    }));
}

export function withInitialValue<T>(value: T, observable: Observable<T>): Observable<T> {
    return merge(of(value), observable);
}

export function toggleBooleanSubject(subject: BehaviorSubject<boolean>) {
    subject.next(!subject.value);
}

/**
 * Useful for wrapping a simple boolean in an Observable when used with *ngIf, which would in the case of value 'false'
 * not render.
 */
export class BoolWrapper {
    constructor(public readonly value: boolean) {
    }

    static coerceToBool(value: boolean | null | undefined): BoolWrapper {
        return new BoolWrapper(!!value);
    }
}

export class Subscriptions {
    private subscriptions: Unsubscribable[] = [];
    private switchables = new Map<string, Unsubscribable>();
    private closed = false;

    add(unsubscribable: Unsubscribable) {
        this.checkState();
        this.subscriptions.push(unsubscribable);
    }

    switchable(id: string, subscription: Unsubscribable) {
        this.checkState();
        const previous = this.switchables.get(id);
        if (previous !== undefined) {
            previous.unsubscribe();
        }
        this.switchables.set(id, subscription);
    }

    unsubscribeAll() {
        this.checkState();
        for (const subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
        this.switchables.forEach(sub => {
            sub.unsubscribe();
        });
        delete this.subscriptions;
        delete this.switchables;
        this.closed = true;
    }

    private checkState() {
        if (this.closed)
            throw Error("unsubscribeAll() already called once");
    }
}

export class WithContext<A, B> {
    constructor(public readonly payload: A, 
                public readonly context: B) {
    }
}