import {Directive} from "@angular/core";
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
    selector: "[ngModel][appValidateYear]",
    providers: [{provide: NG_VALIDATORS, useExisting: YearValidatorDirective, multi: true}]
})
export class YearValidatorDirective implements Validator {

    validate(c: AbstractControl): ValidationErrors | null {
        if (c.value === null || c.value === "") {
            return null;
        }
        const yearValue = Number(c.value);
        return isNaN(yearValue) || yearValue < 0 ? {"invalidYear": true} : null;
    }

}
