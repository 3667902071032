import {Injectable} from "@angular/core";
import {DateAdapter} from "@angular/material/core";
import {currentDate, formatEuropeanDate, parseEuropeanDate} from "../../../utils/date";
import {DateTimeFormatter, LocalDate} from "@js-joda/core";
import {TimeTranslationService} from "../../../translations/time-translations";

// XXX this is just copy-paste from moment formats in the material date-picker documentation
export const MY_DATE_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'LL',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

function generateDayNames(): string[] {
    const nums = [];
    for (let i = 0; i < 35; i++)
        nums.push(String(i + 1));
    return nums;
}

export const DAY_NAMES = generateDayNames();

const INVALID_DATE = LocalDate.of(0, 1, 1);
const CURRENT_YEAR = LocalDate.now().year();

@Injectable()
export class LocalDateAdapter extends DateAdapter<LocalDate> {

    private readonly weekdays: string[];
    private readonly monthsShort: string[];

    constructor(timeTranslations: TimeTranslationService) {
        super();

        const weekdays = timeTranslations.weekDays;
        // noinspection UnnecessaryLocalVariableJS
        const sundayFirst = [weekdays[6]].concat(weekdays.slice(0, 6));
        this.weekdays = sundayFirst;
        this.monthsShort = timeTranslations.monthNamesShort;
    }

    addCalendarDays(date: LocalDate, days: number): LocalDate {
        return date.plusDays(days);
    }

    addCalendarMonths(date: LocalDate, months: number): LocalDate {
        return date.plusMonths(months);
    }

    addCalendarYears(date: LocalDate, years: number): LocalDate {
        return date.plusYears(years);
    }

    clone(date: LocalDate): LocalDate {
        return LocalDate.of(date.year(), date.month(), date.dayOfMonth());
    }

    createDate(year: number, month: number, date: number): LocalDate {
        return LocalDate.of(year, month + 1, date);
    }

    format(date: LocalDate, displayFormat: any): string {
        return this._format(displayFormat, date);
    }

    private _format(displayFormat: any, date: LocalDate) {
        if (displayFormat === MY_DATE_FORMATS.display.monthYearLabel)
            return this.monthsShort[date.month().ordinal()] + " " + date.year();

        return formatEuropeanDate(date);
    }

    getDate(date: LocalDate): number {
        return date.dayOfMonth();
    }

    getDateNames(): string[] {
        return DAY_NAMES;
    }

    getDayOfWeek(date: LocalDate): number {
        // js-joda starts weekdays from monday, material expects week starting from sunday
        return (date.dayOfWeek().ordinal() + 1) % 7;
    }

    getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
        return this.weekdays;
    }

    getFirstDayOfWeek(): number {
        return 1; // sunday is 0
    }

    getMonth(date: LocalDate): number {
        return date.month().ordinal();
    }

    getMonthNames(style: "long" | "short" | "narrow"): string[] {
        return this.monthsShort;
    }

    getNumDaysInMonth(date: LocalDate): number {
        return date.lengthOfMonth();
    }

    getYear(date: LocalDate): number {
        return date.year();
    }

    getYearName(date: LocalDate): string {
        return String(date.year());
    }

    invalid(): LocalDate {
        return INVALID_DATE;
    }

    isDateInstance(obj: any): boolean {
        if (obj == null)
            return false;

        return obj instanceof LocalDate;
    }

    isValid(date: LocalDate): boolean {
        return date != null && date.year() > 999 && date.year() <= CURRENT_YEAR + 1;
    }

    parse(value: any, parseFormat: any): LocalDate | null {
        if (!value)
            return undefined;

        if (parseFormat === MY_DATE_FORMATS.parse.dateInput)
            return parseEuropeanDate(value) || INVALID_DATE;
        else
            return undefined;
    }

    toIso8601(date: LocalDate): string {
        return date.format(DateTimeFormatter.ISO_LOCAL_DATE);
    }

    today(): LocalDate {
        return currentDate();
    }
}
