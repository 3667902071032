import {Injectable} from "@angular/core";
import {FrontPageContent, FrontpageRestEndpoint, GeneralType, InfoMessage, RootRestEndpoint} from "../apina-digiweb";
import {Observable, timer} from "rxjs";
import {repeatWhen, shareReplay} from "rxjs/operators";
import {extractFromXml, FeedData} from '@extractus/feed-extractor'
import {TranslateService} from "@ngx-translate/core";

const infoMessageRefreshPeriodMs = 10 * 60 * 1000; // 10 min

@Injectable()
export class BasicInfoService {

    public frontPageContent$: Observable<FrontPageContent>;

    public rssDigiNews$: Observable<any>;

    /**
     * Sorted list of generalTypes that have actual bindings.
     */
    public sortedGeneralTypes$: Observable<GeneralType[]>;

    public infoMessages$: Observable<InfoMessage[]>;

    constructor(frontpageEndpoint: FrontpageRestEndpoint, rootRestEndpoint: RootRestEndpoint,
                private readonly translate: TranslateService) {
        const refreshTimer$ = timer(infoMessageRefreshPeriodMs, infoMessageRefreshPeriodMs);

        const digiRssFeedUrl = this.translate.instant('frontapage.digi-rss-feed-url');

        // reload only on app boot
        this.sortedGeneralTypes$ = rootRestEndpoint.getGeneralTypes().pipe(shareReplay(1));

        // reload every 10 minutes
        this.infoMessages$ = rootRestEndpoint.getInfoMessages().pipe(
            repeatWhen(() => refreshTimer$),
            shareReplay(1)
        );

        this.rssDigiNews$ = new Observable<any>(subscriber => {
            const result = this.extractRssFeed(digiRssFeedUrl).then((value: FeedData) => {subscriber.next(value)});
        });

        // reload every time
        this.frontPageContent$ = frontpageEndpoint.getContent();
    }

    async fetchWithTimeout(resource: string, options = {}, timeout = 90000) {

        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);

        const response = await fetch(resource, {
            ...options,
            signal: controller.signal
        });
        clearTimeout(id);

        return response;
    }

    async fixXml(xml: string) {
        // replace regex removes lang attributes from xml's span elements. Needed, because otherwise xml would not validate.
        // Maybe can be removed in the future, if Drupal does not add these attributes when example word document is pasted to news feed.
        xml = xml.replace(/(| )lang=\"[A-Z-]{1,6}\"(\s|)/g, "");

        return xml;
    }
    async extractRssFeed(url: string) {
        try {
            const res = await this.fetchWithTimeout(url,{cache: "no-store"}, 90000);
            let xml = await res.text();
            xml = await this.fixXml(xml);

            if (xml != null) {
                return extractFromXml(xml, {normalization: false});
            }
            else {
                return null;
            }
        } catch (e) {
            console.log(e);
            return null;
        }
    }
}