import {TranslateService} from "@ngx-translate/core";
import {Injectable} from "@angular/core";

const monthNamesFull = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december"
];

const monthNamesShort = [
    "january.short",
    "february.short",
    "march.short",
    "april.short",
    "may.short",
    "june.short",
    "july.short",
    "august.short",
    "september.short",
    "october.short",
    "november.short",
    "december.short"
];

const weekDays = [
    "day.mo",
    "day.tu",
    "day.we",
    "day.th",
    "day.fr",
    "day.sa",
    "day.su"
];


@Injectable()
export class TimeTranslationService {

    constructor(private translateService: TranslateService) {
    }

    // TODO should we cache these?

    get monthNamesFull() {
        return this.translateList(monthNamesFull);
    }

    get monthNamesShort() {
        return this.translateList(monthNamesShort);
    }

    get weekDays() {
        return this.translateList(weekDays);
    }

    translateList(items: string[]): string[] {
        return items.map(i => this.translateService.instant(i));
    }
}
