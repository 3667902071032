import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { FeedbackEndpoint, SendFeedbackForm } from "../../apina-digiweb";

@Injectable()
export class FeedbackService {

    displayFeedbackForm: BehaviorSubject<boolean> = new BehaviorSubject(false);
    feedbackMessage: BehaviorSubject<string> = new BehaviorSubject("");

    constructor(
        private feedbackEndpoint: FeedbackEndpoint
    ) {}

    public async sendFeedback(form: SendFeedbackForm) {
        return await this.feedbackEndpoint.sendFeedback(form).toPromise();
    }

    public toggleFeedbackForm() {
        this.displayFeedbackForm.next(!this.displayFeedbackForm.value)
    }

    public async getFeedbackMessage() {
        const message = await this.feedbackEndpoint.getMessage().toPromise();
        this.feedbackMessage.next(message?.message);
    }
}