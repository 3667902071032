import {Component, Input} from "@angular/core";

@Component({
    selector: "app-sidebar-content",
    template: `
        <div class="sidebar-scrollable-content" [ngClass]="{'p-3': padding}">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: [
        "./sidebar-content.scss"
    ]
})
export class SidebarContentComponent {
    @Input() padding = true;
}