import {distinctUntilChanged, map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {LoggingService} from "./logging.service";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {toggleBooleanSubject} from "../utils/observable-utils";

const MOBILE_SCREEN_BREAKPOINT = 575;

@Injectable()
export class DisplayService {

    private _showTopNavigation$ = new BehaviorSubject<boolean>(true);
    private _articleMode$ = new BehaviorSubject<boolean>(false);
    private _maximized$ = new BehaviorSubject<boolean>(false);
    private _clipView$ = new BehaviorSubject<boolean>(false);
    private _headerMinimized$ = new BehaviorSubject<boolean>(false);
    private _sidebarWidth$ = new BehaviorSubject<number>(350);
    
    /** If either dimension is below breakpoint, we assume mobile. */
    isMobile: boolean;
    mobileMenuOpen = false;

    private _headerHeight$ = new BehaviorSubject<number>(0);
    private _headerVisibleHeight$: Observable<number> = combineLatest([this._maximized$, this._headerHeight$, this._headerMinimized$]).pipe(
        map(([maximized, height, headerMinimized]) => {
            return maximized ? 0 : height;
        })
    );

    public showTopNavigation$: Observable<boolean> = this._showTopNavigation$;
    public articleMode$: Observable<boolean> = this._articleMode$;
    public maximized$: Observable<boolean> = this._maximized$;
    public clipView$: Observable<boolean> = this._clipView$;
    public headerMinimized$: Observable<boolean> = this._headerMinimized$;
    public sidebarWidth$: Observable<number> = this._sidebarWidth$;

    constructor(private log: LoggingService) {
        this.isMobile = this.detectMobile();

        log.debug("Initialized DisplayService", this);
    }

    get showTopNavigation() {
        return this._showTopNavigation$.value;
    }

    set showTopNavigation(value: boolean) {
        this._showTopNavigation$.next(value);
    }

    get articleMode() {
        return this._articleMode$.value;
    }

    set articleMode(value: boolean) {
        this._articleMode$.next(value);
    }

    get maximized() {
        return this._maximized$.value;
    }

    set maximized(value: boolean) {
        this._maximized$.next(value);
    }

    get clipView() {
        return this._clipView$.value;
    }

    set clipView(value: boolean) {
        this._clipView$.next(value);
    }

    get headerMinimized() {
        return this._headerMinimized$.value;
    }

    set headerMinimized(value: boolean) {
        this._headerMinimized$.next(value);
    }

    get headerHeight(): Observable<number> {
        return this._headerHeight$;
    }

    get headerVisibleHeight(): Observable<number> {
        return this._headerVisibleHeight$;
    }

    get sidebarWidth(): Observable<number> {
        return this.sidebarWidth$.pipe(distinctUntilChanged());
    }
    
    private detectMobile() {
        const width = window.screen.availWidth;
        const height = window.screen.availHeight;
        return Math.min(width, height) < MOBILE_SCREEN_BREAKPOINT;
    }

    toggleMaximized() {
        toggleBooleanSubject(this._maximized$);
    }

    toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
    }

    updateSidebarWidth(pixels: number) {
        this._sidebarWidth$.next(pixels);
    }

    updateHeaderHeight(height: number) {
        this._headerHeight$.next(height);
    }
}
