import {PageEvent} from "@angular/material/paginator";

/**
 * Utility to use with https://material.angular.io/components/paginator/overview
 */
export class Paging {
    constructor(public page: number, public pageSize: number) {
    }

    public static from($event: PageEvent): Paging {
        return new Paging($event.pageIndex + 1, $event.pageSize);
    }
    
    get offset(): number {
        return (this.page - 1) * this.pageSize;
    }
}

