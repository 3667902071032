<div id="feedback-form-container" (click)="closeForm()">
    <div id="feedback-form-wrapper">
        <form *ngIf="feedbackForm" [formGroup]="feedbackForm" id="feedback-form" (click)="$event.stopPropagation()">
            <i class="fa-solid fa-xmark" id="close-icon" (click)="closeForm()"></i>
            <h4>{{ 'feedback.header' | translate }}</h4>
            <p [innerHTML]="'feedback.description' | translate"></p>
            <div *ngIf="(feedbackService.feedbackMessage | async)?.length" id="feedback-message">
                <i class="fa-solid fa-circle-info"></i>
                <p>{{feedbackService.feedbackMessage | async}}</p>
            </div>
            <mat-form-field>
                <input matInput [placeholder]="'feedback.email' | translate" formControlName="customerEmail">
                <mat-error *ngIf="feedbackForm.get('customerEmail').touched && feedbackForm.get('customerEmail').hasError('email')">
                    {{ 'feedback.emailFormat' | translate }}
                </mat-error>
                <mat-error *ngIf="feedbackForm.get('customerEmail').touched && feedbackForm.get('customerEmail').hasError('maxlength')">
                    {{ 'feedback.emailLength' | translate }}
                </mat-error>
            </mat-form-field>
            <br>
            <mat-form-field>
                <input matInput [placeholder]="'feedback.subject' | translate" formControlName="subject" required>
                <mat-error *ngIf="feedbackForm.get('subject').touched && feedbackForm.get('subject').hasError('required')">
                    {{ 'feedback.subjectRequired' | translate }}
                </mat-error>
                <mat-error *ngIf="feedbackForm.get('subject').touched && feedbackForm.get('subject').hasError('maxlength')">
                    {{ 'feedback.subjectLength' | translate }}
                </mat-error>
            </mat-form-field>
            <br>
            <mat-form-field>
                <textarea matInput [placeholder]="'feedback.feedback' | translate" formControlName="description" required></textarea>
                <mat-error *ngIf="feedbackForm.get('description').touched && feedbackForm.get('description').hasError('required')">
                    {{ 'feedback.descriptionRequired' | translate }}
                </mat-error>
                <mat-error *ngIf="feedbackForm.get('description').touched && feedbackForm.get('description').hasError('maxlength')">
                    {{ 'feedback.descriptionLength' | translate }}
                </mat-error>
            </mat-form-field>
            <br>
            <button [disabled]="sendStatus === 'sending' || feedbackForm.invalid" class="btn btn-kk-blue d-flex" style="justify-content: center;" (click)="sendFeedback()">
                <span>{{ 'feedback.button' | translate }}</span>
                <app-progress-spinner *ngIf="sendStatus === 'sending'" [size]="'XX-SMALL'" style="margin-left:10px"></app-progress-spinner>
            </button>
            <p *ngIf="sendStatus === 'failed'">{{ 'feedback.failed' | translate }}</p>
        </form>
    </div>
</div>