import {Component, Input} from "@angular/core";
import {SiteSection} from "../navigation.service";
import {Observable} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";

// TODO unused component, do we need any of this in the future?
@Component({
    selector: 'app-search-tabs',
    template: `
        <ul class="horizontal-list search-methods" [ngSwitch]="activeSection | async">
            <div *ngSwitchCase="'AIKAKAUSI'" [@enterTrigger]="'fadeIn'">
                <li [ngClass]="{'active': currentSectionActive && tab == 'search'}"><a href="/aikakausi/search" ngbTooltip="{{'main.links.search.tooltip' | translate}}" placement="bottom">{{'main.links.search' | translate}}</a></li>
                <li [ngClass]="{'active': currentSectionActive && tab == 'user-articles'}"><a href="/aikakausi/articles" ngbTooltip="{{'main.links.user.articles.tooltip' | translate}}" placement="bottom">{{'main.links.user.articles' | translate}}</a></li>
                <li [ngClass]="{'active': currentSectionActive && tab == 'titles'}"><a href="/aikakausi/titles" ngbTooltip="{{'aikakausi.main.links.title.alphabet.tooltip' | translate}}" placement="bottom">{{'aikakausi.main.links.title.alphabet' | translate}}</a></li>
            </div>
            <div *ngSwitchCase="'SANOMALEHTI'" [@enterTrigger]="'fadeIn'">
                <li [ngClass]="{'active': currentSectionActive && tab == 'search'}"><a href="/sanomalehti/search" ngbTooltip="{{'main.links.search.tooltip' | translate}}"  placement="bottom">{{'main.links.search' | translate}}</a></li>
                <li [ngClass]="{'active': currentSectionActive && tab == 'user-articles'}"><a href="/sanomalehti/articles" ngbTooltip="{{'main.links.user.articles.tooltip' | translate}}" placement="bottom">{{'main.links.user.articles' | translate}}</a></li>
                <li [ngClass]="{'active': currentSectionActive && tab == 'titles'}"><a href="/sanomalehti/titles" ngbTooltip="{{'sanomalehti.main.links.browsing.tooltip' | translate}}" placement="bottom">{{'sanomalehti.main.links.browsing' | translate}}</a></li>
                <li [ngClass]="{'active': currentSectionActive && tab == 'papers-for-day'}"><a href="/sanomalehti/papers-for-day" ngbTooltip="{{'sanomalehti.main.links.papers-for-day.tooltip' | translate}}" placement="bottom">{{'sanomalehti.main.links.papers-for-day' | translate}}</a></li>
                <li [ngClass]="{'active': currentSectionActive && tab == 'directory'}"><a href="/sanomalehti/directory" ngbTooltip="{{'sanomalehti.main.links.articles.tooltip' | translate}}" placement="bottom">{{'sanomalehti.main.links.articles' | translate}}</a></li>
            </div>
            <div *ngSwitchCase="'PIENPAINATE'" [@enterTrigger]="'fadeIn'">
                <li [ngClass]="{'active': currentSectionActive && tab == 'search'}"><a href="/pienpainate/search" ngbTooltip="{{'main.links.search.tooltip' | translate}}"  placement="bottom">{{'main.links.search' | translate}}</a></li>
                <li [ngClass]="{'active': currentSectionActive && tab == 'user-articles'}"><a href="/pienpainate/articles" ngbTooltip="{{'main.links.user.articles.tooltip' | translate}}" placement="bottom">{{'main.links.user.articles' | translate}}</a></li>
                <li [ngClass]="{'active': currentSectionActive && tab == 'publishers'}"><a href="/pienpainate/publishers" ngbTooltip="{{'pienpainate.main.links.publisher.tooltip' | translate}}" placement="bottom">{{'pienpainate.main.links.publisher' | translate}}</a></li>
                <li [ngClass]="{'active': currentSectionActive && tab == 'years'}"><a href="/pienpainate/centuries" ngbTooltip="{{'pienpainate.main.links.years.tooltip' | translate}}" placement="bottom">{{'pienpainate.main.links.years' | translate}}</a></li>
                <li [ngClass]="{'active': currentSectionActive && tab == 'directory'}"><a href="/pienpainate/directory" ngbTooltip="{{'pienpainate.main.links.signum.tooltip' | translate}}" placement="bottom">{{'pienpainate.main.links.signum' | translate}}</a></li>
            </div>
            <div *ngSwitchCase="'TEOS'" [@enterTrigger]="'fadeIn'">
                <li [ngClass]="{'active': currentSectionActive && tab == 'search'}"><a href="/teos/search" ngbTooltip="{{'main.links.search.tooltip' | translate}}"  placement="bottom">{{'main.links.search' | translate}}</a></li>
                <li [ngClass]="{'active': currentSectionActive && tab == 'titles'}"><a href="/teos/titles" ngbTooltip="{{'kirja.main.links.titles.tooltip' | translate}}"  placement="bottom">{{'kirja.main.links.titles' | translate}}</a></li>
            </div>
            <div *ngSwitchDefault>
                <!-- just a placeholder so that the row height remains the same always -->
                <span style="visibility: hidden">X</span>
            </div>
        </ul>
    `,
    styleUrls: [
        "./search-tabs.scss"
    ],
    animations: [
        trigger('enterTrigger', [
            state('fadeIn', style({
                opacity: '1'
            })),
            transition('void => *', [
                style({
                    opacity: '0',
                    'transform': 'translateY(-50%)'
                }),
                animate('350ms')
            ])
        ])
    ]
})
export class SearchTabsComponent {
    @Input() tab?: string;

    currentSection: SiteSection;

    activeSection: Observable<SiteSection>;

    currentSectionActive: boolean;

    /*
    constructor(private navigationService: NavigationService) {
        this.currentSection = this.navigationService.section;
        this.activeSection = this.navigationService.navigationTarget.pipe(map(section => {
           return section || this.currentSection;
        }));

        this.activeSection.subscribe(section => {
            this.currentSectionActive = section === this.currentSection;
        });
    }
    */
}
