// references:
// http://www.bibtex.org/SpecialSymbols/
// https://en.wikibooks.org/wiki/LaTeX/Special_Characters
// ftp://sunsite.icm.edu.pl/pub/CTAN/info/symbols/comprehensive/symbols-a4.pdf

const invalidCharMap: { [ch: string]: string } = {
    '{': '\\{',
    '}': '\\}',
    '"': '\\"',
    '$': '\\$',

    // very common characters
    'ä': '\\"{a}',
    'Ä': '\\"{A}',
    'ö': '\\"{o}',
    'Ö': '\\"{O}',

    'å': '\\aa',
    'Å': '\\AA',

    // only these unusual characters are found in publicly available material

    'â': '\\^{a}',
    'Â': '\\^{A}',
    'ê': '\\^{e}',
    'Ê': '\\^{E}',

    'ì': '\\`{i}',
    'Ì': '\\`{I}',

    // these are found in non-public material

    'á': '\\’{a}',
    'Á': '\\’{A}',
    'é': '\\’{e}',
    'É': '\\’{E}',

    'ø': '\\o',
    'Ø': '\\O',

    'æ': '\\ae',
    'Æ': '\\AE',

    'ü': '\\"{u}',
    'Ü': '\\"{U}',

    // not found in titles

    'ß': '\\ss'
};

function sanitize(character: string): string {
    return invalidCharMap[character] || character;
}

export function bibtexEscape(value: string) {
    if (value === undefined || value === null)
        return value;

    const buf: string[] = [];

    for (let i = 0; i < value.length; i++)
        buf.push(sanitize(value.charAt(i)));

    return buf.join('');
}
