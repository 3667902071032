import {map} from 'rxjs/operators';
import {Injectable} from "@angular/core";
import {ITag, PersistedTag} from "./common/tag-select.component";
import {AutoCompleteWordsEndpoint} from "../apina-digiweb";
import {Observable} from "rxjs";
import {LoggingService} from "./logging.service";

@Injectable()
export class TagService {

    constructor(log: LoggingService, private autoCompleteEndpoint: AutoCompleteWordsEndpoint) {
        log.debug("Initialized TagService", this);
    }

    // TODO load on init?
    findTags(): Observable<ITag[]> {
        return this.autoCompleteEndpoint.findAutoCompleteWords().pipe(map(data => {
            const result: ITag[] = [];

            for (const keyword of data.keywords) {
                if (!keyword.toLowerCase().includes("translocalis")) {
                    result.push(new PersistedTag('KEYWORD', keyword));
                }
            }

            result.sort(PersistedTag.compare);

            return result;
        }));
    }
}
