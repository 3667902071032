import {Component, Input, OnInit} from "@angular/core";
import {Context} from "./types";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-title-info',
    template: `
        <span class="title-info">
            <a *ngIf="context === 'sanomalehti'" [routerLink]="['/sanomalehti/titles', issn, 'data']" ngbTooltip="{{'title.info.link-description' | translate}}">
                
                <i class="fa fa-info-circle" [ngClass]="{'fa-2x': large}">
                    <span class="sr-only">{{'papers.infoguide'|translate}}</span>
                </i>
                <span class="sr-only">{{'title.info.link-description'|translate}}</span>
            </a>
            <a *ngIf="context === 'aikakausi'" href="{{metalink}}{{issn}}" target="_blank" ngbTooltip="{{'title.info.link-description' | translate}}">
                <i class="fa fa-info-circle" [ngClass]="{'fa-2x': large}"><span class="sr-only">{{'papers.infoguide'|translate}}</span></i>
            </a>
        </span>
    `
})
export class TitleInfoComponent implements OnInit {
    @Input() context: Context;
    @Input() issn: string;
    @Input() large = false;
    public metalink: string;

    constructor(private readonly translate: TranslateService) {

    }

    ngOnInit(): void {
        this.metalink = this.translate.instant('natlibfi.metadatasrc') ;

    }


}
