import {ErrorHandler, NgModule} from "@angular/core";
import {BrowserModule, HAMMER_GESTURE_CONFIG, Title} from "@angular/platform-browser";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BrowseCollectionsComponent} from "./angular4/collections/browse-collections.component";
import {ApinaConfig, ApinaModule} from "./apina-digiweb";
import {configureApinaBindings, configureApinaDateBindings} from "./apina-types";
import {LoginDialogComponent} from "./angular4/account/login-dialog.component";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {configureTranslateService} from "./translations/translation-conf";
import {PaginationComponent} from "./angular4/common/pagination.component";
import {SettingsService} from "./angular4/settings.service";
import {DatePickerComponent} from "./angular4/common/date-picker.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PopoverPickerComponent} from "./angular4/popover-picker/popover-picker.component";
import {AccountDropdownComponent} from "./angular4/account/account-dropdown.component";
import {AccountService} from "./angular4/account/account.service";
import {ErrorPopupComponent} from "./angular4/error-popup.component";
import {ErrorService} from "./angular4/error.service";
import {OverlayService} from "./angular4/overlay/overlay.service";
import {ClippingFormComponent} from "./angular4/binding/clipping-form.component";
import {PrintClippingFormComponent} from "./angular4/binding/print-clipping-form.component";
import {CookieService} from "ngx-cookie-service";
import {LoggingService} from "./angular4/logging.service";
import {ProgressSpinnerComponent} from "./angular4/common/progress-spinner.component";
import {FileSizePipe, TranslateBindingProblemType, TranslateMissingBindingInfoType, TranslateMultiNamedPipe} from "./angular4/pipe/pipes";
import {MessagesService} from "./translations/client-translations";
import {ClippingHelpComponent} from "./angular4/binding/clipping-help.component";
import {BindingProblemsComponent} from "./angular4/binding/binding-problems.component";
import {HideBindingComponent} from "./angular4/binding/hide-binding.component";
import {RedactionFormComponent} from "./angular4/binding/redaction-form.component";
import {DigitizationYearsComponent} from "./angular4/browse/digitization-years.component";
import {NavigationService} from "./angular4/navigation.service";
import {SearchTabsComponent} from "./angular4/tags/search-tabs.component";
import {TitleDetailsComponent} from "./angular4/browse/title-details.component";
import {ProgressBarIndicatorComponent} from "./angular4/browse/progress-bar-indicator.component";
import {PageCountIndicatorComponent} from "./angular4/browse/page-count-indicator.component";
import {TitleInfoComponent} from "./angular4/browse/title-info.component";
import {TitleBasicInfoComponent} from "./angular4/browse/title-basic-info.component";
import {BrowseTitlesComponent} from "./angular4/browse/browse-titles.component";
import {DisplayService} from "./angular4/display.service";
import {TabbedOverlayComponent} from "./angular4/article/tabbed-overlay.component";
import {TabbedOverlayPaneComponent} from "./angular4/article/tabbed-overlay-pane.component";
import {UserArticleTabsComponent} from "./angular4/article/user-article-tabs.component";
import {ArticleNotesComponent} from "./angular4/article/article-notes.component";
import {ArticleCommentsComponent} from "./angular4/article/article-comments.component";
import {GlobalNaviComponent} from "./angular4/nav/global-navi.component";
import {MainTitleComponent} from "./angular4/nav/main-title.component";
import {InfoMessagesComponent} from "./angular4/common/info-messages.component";
import {SearchDrawerComponent, SearchDrawerServiceDelegateComponent} from "./angular4/tags/search-drawer.component";
import {SearchResultCountBadgeComponent} from "./angular4/common/search-result-count-badge.component";
import {AccountTabsComponent} from "./angular4/account/account-tabs.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {BrowseIssuesByYearComponent} from "./angular4/browse/browse-issues-by-year.component";
import {MissingInfoModalComponent} from "./angular4/browse/title/missing-info-modal.component";
import {ThumbsByMonthComponent} from "./angular4/browse/title/thumbs-by-month.component";
import {IssueCalendarComponent} from "./angular4/browse/title/issue-calendar.component";
import {IssueCalendarMarkComponent} from "./angular4/browse/title/issue-calendar-mark.component";
import {YearValidatorDirective} from "./angular4/browse/validate-year.directive";
import {StickyHeaderComponent} from "./angular4/common/sticky-header.component";
import {BindingOcrInfoModalComponent} from "./angular4/binding/binding-ocr-info-modal.component";
import {TermsConfirmationModalComponent} from "./angular4/account/terms-confirmation-modal.component";
import {DigiwebAppComponent} from "./angular4/digiweb-app.component";
import {TimeTranslationService} from "./translations/time-translations";
import {CalendarMonthComponent} from "./angular4/browse/title/calendar-month.component";
import {CalendarMonthMissingComponent} from "./angular4/browse/title/calendar-month-missing.component";
import {TagSelectComponent} from "./angular4/common/tag-select.component";
import {TagService} from "./angular4/tag.service";
import {BreadcrumbsService} from "./angular4/breadcrumbs/breadcrumbs.service";
import {BreadcrumbsComponent} from "./angular4/breadcrumbs/breadcrumbs.component";
import {ClosePopoverOnClickOutsideDirective} from "./angular4/hacks/close-popover-on-click-outside.directive";
import {BindingSearchComponent} from "./angular4/search/binding-search.component";
import {BindingSearchResultsComponent} from "./angular4/search/binding-search-results.component";
import {TrustAsHtmlPipe} from "./angular4/pipe/trust-as-html.pipe";
import {DateWithAccuracyPipe} from "./angular4/pipe/date-with-accuracy.pipe";
import {DatePipe, registerLocaleData} from "@angular/common";
import {SearchResultChartComponent} from "./angular4/search/result/search-result-chart.component";
import {ViewCollectionComponent} from "./angular4/collections/view-collection.component";
import {PageChangeComponent} from "./angular4/binding/page-change.component";
import {BindingPageViewComponent} from "./angular4/binding/binding-page-view.component";
import {MousedownOrTouchstartDirective} from "./angular4/binding/mousedown-or-touchstart.directive";
import {ScaledPositionDirective} from "./angular4/binding/scaled-position.directive";
import {DraggableDirective} from "./angular4/binding/draggable.directive";
import {HammerConfig} from "./hammer-config";
import {PinchableDirective} from "./angular4/binding/pinchable.directive";
import {HeaderComponent} from "./angular4/nav/header.component";
import {SearchDrawerService} from "./angular4/nav/search-drawer.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {BrandAreaComponent} from "./angular4/nav/brand-area.component";
import {BindingOcrTextComponent} from "./angular4/binding/binding-ocr-text.component";
import {UserArticleComponent} from "./angular4/article/user-article.component";
import {MobileNaviComponent} from "./angular4/nav/mobile-navi.component";
import {BindingMarcTextComponent} from "./angular4/binding/metadata/binding-marc-text.component";
import {BindingRestrictedWarningComponent} from "./angular4/binding/binding-restricted-warning.component";
import {CitationInfoComponent} from "./angular4/binding/metadata/citation-info.component";
import {ErrorThumbnailDirective} from "./angular4/common/error-thumbnail.directive";
import {PapersForDayComponent} from "./angular4/search/papers-for-day.component";
import {SearchService} from "./angular4/search/search.service";
import {CloseTagSelectOnClickOutsideDirective} from "./angular4/hacks/close-tag-select-on-click-outside.directive";
import {BindingViewComponent} from "./angular4/binding/binding-view.component";
import {UserArticleSearchComponent} from "./angular4/search/user-article-search.component";
import {SavedContentComponent} from "./angular4/search/saved-content.component";
import {CheckboxListComponent} from "./angular4/common/checkbox-list.component";
import {FrontpageComponent} from "./angular4/frontpage/frontpage.component";
import {FooterComponent} from "./angular4/frontpage/footer.component";
import {OpendataListComponent} from "./angular4/opendata/opendata-list.component";
import {OpendataSubmitComponent} from "./angular4/opendata/opendata-submit.component";
import {OpendataRequestedPackagesComponent} from "./angular4/opendata/opendata-requested-packages.component";
import {NgxCaptchaModule} from "ngx-captcha";
import {LoginPageComponent} from "./angular4/account/login-page.component";
import {CurrentUrlComponent} from "./misc/current-url.component";
import {FacebookLikeComponent} from "./angular4/social/facebook-like.component";
import {TweetComponent} from "./angular4/social/tweet.component";
import {AppRoutingModule} from "./app-routing.module";
import {FallbackComponent} from "./errors/fallback.component";
import {OtherComponent} from "./angular4/misc/other.component";
import {HumansitemapComponent} from "./angular4/misc/humansitemap.component";
import {TutkainComponent} from "./angular4/misc/tutkain.component";
import {ErrorMessageOnceComponent} from "./errors/error-message-once.component";
import {BasicInfoService} from "./angular4/basic-info.service";
import {NewspaperMetadataOverlayComponent} from "./angular4/binding/metadata/newspaper-metadata-overlay.component";
import {TechnicalMarcRecordComponent} from "./angular4/binding/metadata/technical-marc-record.component";
import {UserFriendlyMarcRecordComponent} from "./angular4/binding/metadata/user-friendly-marc.record.component";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {FacebookService} from "./angular4/social/facebook.service";
import {FacebookContentComponent} from "./angular4/social/facebook-content.component";
import {BindingViewCitationInfoComponent} from "./angular4/binding/metadata/binding-view-citation-info.component";
import {BindingAgreementInfoComponent} from "./angular4/binding/metadata/binding-agreement-info.component";
import {InterceptorModule} from "./angular4/interceptors/interceptor.module";
import {StatsComponent} from "./angular4/common/stats.component";
import {InfiniteScrollPrefillDirective} from "./angular4/hacks/infinite-scroll-prefill.directive";
import {AutofocusDirective} from "./angular4/common/autofocus.directive";
import {DocumentService} from "./angular4/document.service";
import {SessionExpiredComponent} from "./angular4/account/session-expired.component";
import {UserArticleOcrComponent} from "./angular4/article/user-article-ocr.component";
import {NerPersonSearchComponent} from "./angular4/ner/admin/ner-person-search.component";
import {BindingInaccessibleComponent} from './angular4/binding/binding-inaccessible.component';
import {QuerySyntaxValidator} from "./angular4/search/querysyntaxvalidator.directive";
import localeFi from '@angular/common/locales/fi';
import {NerLazyTextComponent} from "./angular4/ner/admin/ner-lazy-text.component";
import {NerNameSearchComponent} from "./angular4/ner/ner-name-search.component";
import {NotFoundComponent} from "./errors/not-found.component";
import {DigiErrorHandler} from "./errors/error-handler";
import {SlickCarouselModule} from "ngx-slick-carousel";
import {OverlayModule} from "@angular/cdk/overlay";
import {SocialPrivacyService} from "./angular4/social/social-privacy.service";
import {TermsConfirmationV2ModalComponent} from "./angular4/account/terms-confirmation-v2-modal.component";
import {MatStepperModule} from "@angular/material/stepper";
import {MatSelectModule} from "@angular/material/select";
import {MatDialogModule} from "@angular/material/dialog";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatListModule} from "@angular/material/list";
import {MatRadioModule} from "@angular/material/radio";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {AccountSettingsComponent} from "./angular4/account/account-settings.component";
import {MatBadgeModule} from "@angular/material/badge";
import {ModalDraggableDirective} from "./angular4/hacks/modal-draggable.directive";
import {YearBindingListComponent} from "./angular4/browse/title/year-binding-list.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {VariableAccuracyDateSelectionModalComponent} from "./angular4/browse/title/variable-accuracy-date-selection-modal.component";
import {HackathonSearchComponent} from "./angular4/search/hackathon/hackathon-search.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {BroadcastChannelService} from "./angular4/broadcast-channel.service";
import {AccountSearchHistoryComponent} from "./angular4/account/account-search-history.component";
import {MatPaginatorModule} from "@angular/material/paginator";
import {ConfirmClearSearchHistoryModalComponent} from "./angular4/account/confirm-clear-search-history-modal.component";
import {SessionStorageService} from "./angular4/hacks/session-storage.service";
import {StoreSessionDirective} from "./angular4/hacks/store-session.directive";
import {LocalizedDatePipe} from "./angular4/pipe/localized-date.pipe";
import {GiosgService} from "./angular4/connectors/giosg.service";
import {MatTreeModule} from "@angular/material/tree";
import {NewspaperMetadataComponent} from "./angular4/browse/title/newspaper-metadata.component";
import {NewspaperMetadataDetailsComponent} from "./angular4/common/newspaper-metadata-details.component";
import {AfterLogoutComponent} from "./angular4/account/after-logout.component";
import {CollectionTreeComponent} from "./angular4/collections/collection-tree.component";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions} from "@angular/material/core";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {ProgressBarComponent} from "./angular4/common/progress-bar.component";
import {ComponentPartListComponent} from "./angular4/binding/metadata/parts/component-part-list.component";
import {TextThumbResultComponent} from "./angular4/search/result/text-thumb-result.component";
import {ThumbResultComponent} from "./angular4/search/result/thumb-result.component";
import {ThumbResultArticleComponent} from "./angular4/search/result/thumb-result-article.component";
import {TextThumbResultArticleComponent} from "./angular4/search/result/text-thumb-result-article.component";
import {SearchInsideBindingComponent} from "./angular4/binding/search-inside-binding.component";
import {BindingSearchFormComponent} from "./angular4/search/binding-search-form.component";
import {ResultNavigationComponent} from "./angular4/search/result/result-navigation.component";
import {PopoverPickerAsyncComponent} from "./angular4/popover-picker/popover-picker-async.component";
import {ScrollControlDirective} from "./angular4/common/scroll-control.directive";
import {_MatMenuDirectivesModule, MatMenuModule} from "@angular/material/menu";
import {MatomoAnalyticsService} from "./angular4/analytics/matomo-analytics.service";
import {ArticleSearchResultsComponent} from "./angular4/search/article-search-results.component";
import {ComponentTreeComponent} from "./angular4/binding/metadata/component-tree.component";
import {ComponentTreeNodeComponent} from "./angular4/binding/metadata/component-tree-node.component";
import { UserconsentComponent } from "./angular4/userconsent/userconsent.component";
import { UserconsentBannerComponent } from "./angular4/userconsent/banner/userconsent-banner.component";
import { UserconsentService } from "./angular4/userconsent/userconsent.service";
import {BindingToolbarComponent} from "./angular4/binding/binding-toolbar.component";
import {SidebarHeaderComponent} from "./angular4/binding/sidebar/sidebar-header.component";
import {SidebarComponent} from "./angular4/binding/sidebar/sidebar.component";
import {SidebarContentComponent} from "./angular4/binding/sidebar/sidebar-content.component";
import {SidebarHandleComponent} from "./angular4/binding/sidebar/sidebar-handle.component";
import {PlatformService} from "./angular4/platform.service";
import { FeedbackComponent } from "./angular4/feedback/feedback.component";
import { FeedbackService } from "./angular4/feedback/feedback.service";
import {UserArticlesImportComponent} from "./angular4/account/user-articles-import.component";
import {MediapageComponent} from "./angular4/misc/mediapage.component";

const globalRippleConfig: RippleGlobalOptions = {
    disabled: true
};

@NgModule({
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        ApinaModule,
        InterceptorModule,
        TranslateModule.forRoot(),
        InfiniteScrollModule,
        SlickCarouselModule,
        NgxCaptchaModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDialogModule,
        MatIconModule,
        MatListModule,
        MatPaginatorModule,
        MatRadioModule,
        MatSelectModule,
        MatSnackBarModule,
        MatStepperModule,
        OverlayModule,
        MatGridListModule,
        MatInputModule,
        MatBadgeModule,
        MatDatepickerModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatTreeModule,
        MatButtonToggleModule,
        _MatMenuDirectivesModule,
        MatMenuModule,
    ],
    declarations: [
        DigiwebAppComponent,
        FrontpageComponent,
        FooterComponent,
        LoginPageComponent,
        TagSelectComponent,
        HeaderComponent,
        BrandAreaComponent,
        GlobalNaviComponent,
        MobileNaviComponent,
        MainTitleComponent,
        InfoMessagesComponent,
        BrowseCollectionsComponent,
        CollectionTreeComponent,
        ViewCollectionComponent,
        LoginDialogComponent,
        SessionExpiredComponent,
        TermsConfirmationModalComponent,
        TermsConfirmationV2ModalComponent,
        PaginationComponent,
        DatePickerComponent,
        PopoverPickerComponent,
        PopoverPickerAsyncComponent,
        AccountDropdownComponent,
        AccountSearchHistoryComponent,
        ErrorPopupComponent,
        ClippingFormComponent,
        PrintClippingFormComponent,
        RedactionFormComponent,
        ClippingHelpComponent,
        ProgressSpinnerComponent,
        ProgressBarComponent,
        TranslateMultiNamedPipe,
        TranslateBindingProblemType,
        TranslateMissingBindingInfoType,
        BindingPageViewComponent,
        MousedownOrTouchstartDirective,
        ScaledPositionDirective,
        DraggableDirective,
        PinchableDirective,
        BindingViewComponent,
        SearchInsideBindingComponent,
        PageChangeComponent,
        BindingProblemsComponent,
        BindingOcrTextComponent,
        BindingOcrInfoModalComponent,
        BindingMarcTextComponent,
        ComponentPartListComponent,
        NewspaperMetadataOverlayComponent,
        TechnicalMarcRecordComponent,
        UserFriendlyMarcRecordComponent,
        BindingRestrictedWarningComponent,
        CitationInfoComponent,
        HideBindingComponent,
        DigitizationYearsComponent,
        SearchDrawerComponent,
        SearchDrawerServiceDelegateComponent,
        SearchTabsComponent,
        SearchResultCountBadgeComponent,
        TitleDetailsComponent,
        ProgressBarIndicatorComponent,
        PageCountIndicatorComponent,
        TitleInfoComponent,
        TitleBasicInfoComponent,
        BrowseTitlesComponent,
        TabbedOverlayComponent,
        TabbedOverlayPaneComponent,
        UserArticleComponent,
        UserArticleTabsComponent,
        UserArticleOcrComponent,
        AccountTabsComponent,
        ArticleNotesComponent,
        ArticleCommentsComponent,
        FacebookLikeComponent,
        TweetComponent,
        BrowseIssuesByYearComponent,
        IssueCalendarComponent,
        CalendarMonthComponent,
        CalendarMonthMissingComponent,
        IssueCalendarMarkComponent,
        ThumbsByMonthComponent,
        YearBindingListComponent,
        MissingInfoModalComponent,
        VariableAccuracyDateSelectionModalComponent,
        StickyHeaderComponent,
        YearValidatorDirective,
        BreadcrumbsComponent,
        ClosePopoverOnClickOutsideDirective,
        CloseTagSelectOnClickOutsideDirective,
        AutofocusDirective,
        BindingSearchComponent,
        BindingSearchFormComponent,
        BindingSearchResultsComponent,
        TextThumbResultComponent,
        TextThumbResultArticleComponent,
        ThumbResultComponent,
        ThumbResultArticleComponent,
        UserArticleSearchComponent,
        SavedContentComponent,
        PapersForDayComponent,
        TrustAsHtmlPipe,
        LocalizedDatePipe,
        DateWithAccuracyPipe,
        ResultNavigationComponent,
        SearchResultChartComponent,
        BrowseCollectionsComponent,
        ErrorThumbnailDirective,
        CheckboxListComponent,
        OpendataListComponent,
        OpendataSubmitComponent,
        OpendataRequestedPackagesComponent,
        FileSizePipe,
        CurrentUrlComponent,
        FallbackComponent,
        NotFoundComponent,
        OtherComponent,
        HumansitemapComponent,
        TutkainComponent,
        MediapageComponent,
        AfterLogoutComponent,
        ErrorMessageOnceComponent,
        FacebookContentComponent,
        BindingViewCitationInfoComponent,
        BindingAgreementInfoComponent,
        ErrorThumbnailDirective,
        StatsComponent,
        InfiniteScrollPrefillDirective,
        NerPersonSearchComponent,
        NerLazyTextComponent,
        NerNameSearchComponent,
        BindingInaccessibleComponent,
        QuerySyntaxValidator,
        AccountSettingsComponent,
        ModalDraggableDirective,
        HackathonSearchComponent,
        ConfirmClearSearchHistoryModalComponent,
        StoreSessionDirective,
        NewspaperMetadataComponent,
        NewspaperMetadataDetailsComponent,
        ScrollControlDirective,
        ArticleSearchResultsComponent,
        ComponentTreeComponent,
        ComponentTreeNodeComponent,
        UserconsentComponent,
        UserconsentBannerComponent,
        BindingToolbarComponent,
        SidebarHeaderComponent,
        SidebarComponent,
        SidebarContentComponent,
        SidebarHandleComponent,
        FeedbackComponent,
        UserArticlesImportComponent
    ],
    providers: [
        [{provide: ErrorHandler, useClass: DigiErrorHandler}],
        Title,
        LoggingService,
        BroadcastChannelService,
        CookieService,
        SettingsService,
        ErrorService,
        AccountService,
        OverlayService,
        MessagesService,
        TimeTranslationService,
        NavigationService,
        DisplayService,
        TagService,
        SearchDrawerService,
        BreadcrumbsService,
        DatePipe,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig
        },
        SearchService,
        BasicInfoService,
        FacebookService,
        MatomoAnalyticsService,
        GiosgService,
        DocumentService,
        SocialPrivacyService,
        SessionStorageService,
        {
            provide: MAT_RIPPLE_GLOBAL_OPTIONS, 
            useValue: globalRippleConfig
        },
        UserconsentService,
        PlatformService,
        FeedbackService
    ],
    bootstrap: [
        DigiwebAppComponent
    ]
})
export class AppModule {
    constructor(apinaConfig: ApinaConfig, translate: TranslateService, settingsService: SettingsService) {
        configureApinaDateBindings(apinaConfig);
        configureApinaBindings(apinaConfig);
        apinaConfig.registerIdentitySerializer('IDictionary<any>');

        configureTranslateService(translate, settingsService.language);

        registerLocaleData(localeFi, 'fi');
    }
}
