import {GeneralType} from "../../apina-digiweb";

export type Context = 'sanomalehti' | 'aikakausi';

export function resolveContext(generalType: GeneralType): Context {
    switch (generalType) {
        case GeneralType.JOURNAL:
            return "aikakausi";
        case GeneralType.NEWSPAPER:
            return "sanomalehti";
        default:
            throw Error("unsupported generalType: " + generalType);
    }
}
