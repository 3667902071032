import {animate, state, style, transition, trigger} from "@angular/animations";

/**
 * Static = no animation while in this state.
 *
 * We apparently don't need to define this state. "*" doesn't seem to match unknown states.
 */
export const ANIM_STATIC = 'static';
export const ANIM_OPEN_CLOSE_OPEN = 'open';
export const ANIM_ADD_REMOVE_IN = 'in';

export const animOpenClose = trigger('openClose', [
    state(ANIM_OPEN_CLOSE_OPEN, style({
        opacity: 1
    })),
    transition('void => *', [
        style({
            opacity: 0,
            height: 0
        }),
        animate('0.4s ease-out')
    ]),
    transition('* => void', [
        animate('0.2s ease-in', style({
            opacity: 0,
            height: 0
        }))
    ])
]);

export const animAddRemoveSelection = trigger('addRemoveSelection', [
    state(ANIM_ADD_REMOVE_IN,
        style({
            transform: 'translateX(0)',
            opacity: 1
        })
    ),
    transition('void => *', [
        style({
            opacity: 0,
            transform: 'translateX(-100%)',
        }),
        animate('300ms ease-out')
    ]),
    transition('* => void', [
        animate('150ms ease-in', style({
            transform: 'translateX(100%)',
            opacity: 0
        }))
    ])
]);
