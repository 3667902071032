import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Pipe({name: "trustAsHtml"})
export class TrustAsHtmlPipe implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {}

    transform(value: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }

}
