import {Injectable, OnDestroy} from "@angular/core";
import {LoggingService} from "../logging.service";

const SESSION_STORAGE_VAR_PREFIX = "s94381s-"; // just something random

/**
 * Contents of sessionStorage *should* carry over to a new tab when it is first created, but Firefox doesn't support
 * this: https://bugzilla.mozilla.org/show_bug.cgi?id=818389
 *
 * As a workaround, we copy sessionStorage contents to localStorage just before links are clicked, and restore the
 * session from localStorage in the new tab, if the keys in sessionStorage are missing.
 *
 * In order not to mess up any other stuff possibly being done in sessionStorage and localStorage, we apply this stuff
 * only to keys prefixed with our own identifier.
 */
@Injectable()
export class SessionStorageService implements OnDestroy {

    constructor(private readonly loggingService: LoggingService) {
        try {
            assertAvailable(localStorage, "localStorage");
            assertAvailable(sessionStorage, "sessionStorage");

            if (this.referredFromOwnSite())
                this.populateSessionFromLocalStorage();
        } catch (e) {
            loggingService.error(e);
        }
    }

    private populateSessionFromLocalStorage() {
        // copy contents from variables with our prefix
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key && key.startsWith(SESSION_STORAGE_VAR_PREFIX)) {
                if (sessionStorage.getItem(key) !== null) {
                    this.loggingService.debug("skipping already found entry " + key);
                } else {
                    const item = localStorage.getItem(key);
                    sessionStorage.setItem(key, item);
                    this.loggingService.debug("SessionStorageService: initialized entry " + key);

                    // If we remove the item from localStorage, it's possible that pages that are currently loading
                    // in other tabs might not receive localStorage information that they expect.
                    // However, if we don't remove this, links that are opened with "open link in new tab" will remember
                    // the (possibly) incorrect localStorage data, since our click-interceptor doesn't work with this
                    // (see StoreSessionDirective).
                    //
                    // Prefer missing data to incorrect data.
                    localStorage.removeItem(key);
                }
            }
        }
    }

    /**
     * If we follow a link from e.g. Facebook, we don't want to populate session from some old stuff in localStorage.
     */
    private referredFromOwnSite(): boolean {
        try {
            const selfHost = window.location.host;
            const url = new URL(document.referrer);
            const referrerHost = url.host;

            return selfHost === referrerHost;
        } catch (e) {
            return false;
        }
    }

    public setItem(key: string, value: string): void {
        try {
            const modifiedKey = SESSION_STORAGE_VAR_PREFIX + key;
            sessionStorage.setItem(modifiedKey, value);
        } catch (e) {
            this.loggingService.error(e);
        }
    }

    public getItem(key: string): string {
        try {
            const modifiedKey = SESSION_STORAGE_VAR_PREFIX + key;
            return sessionStorage.getItem(modifiedKey);
        } catch (e) {
            this.loggingService.error(e);
            return null;
        }
    }

    public copySessionToLocalStorage() {
        try {
            for (let i = 0; i < sessionStorage.length; i++) {
                const key = sessionStorage.key(i);
                if (key && key.startsWith(SESSION_STORAGE_VAR_PREFIX)) {
                    const item = sessionStorage.getItem(key);
                    localStorage.setItem(key, item);
                }
            }
        } catch (e) {
            this.loggingService.error(e);
        }
    }

    /**
     * Should be called before opening a link in new tab (with <a target="_blank">, etc.), so that the breadcrumbs in
     * sessionStorage get copied to localStorage. In the new tab the (possibly) empty sessionStorage is populated from
     * localStorage, so we need to make sure localStorage has the right content.
     *
     * If we did not call this just before the link is clicked, localStorage could have been overwritten by "wrong"
     * breadcrumbs content from another tab.
     */
    public beforeNewTabOpened() {
        this.copySessionToLocalStorage();
    }

    ngOnDestroy(): void {
        // TODO clear localStorage from our entries when last tab is closed
    }
}

function assertAvailable(value: any, name: string) {
    if (!value) {
        throw Error("Could not initialize SessionStorageService, missing " + name + ": " + value);
    }
}
