import {Directive, ElementRef, HostListener} from "@angular/core";
import {LoggingService} from "../logging.service";

@Directive({
    selector: "img[appErrorThumb]"
})
export class ErrorThumbnailDirective {
    private el: HTMLImageElement;

    private loadedOnce = false;

    @HostListener("error") onError() {
        if (!this.loadedOnce) {
            this.log.debug("image failed to load, replacing with placeholder image");
            this.loadedOnce = true;

            this.el.src = "/images/404/230x300.png";
        }
    }

    constructor(elem: ElementRef, private log: LoggingService) {
        this.el = elem.nativeElement as HTMLImageElement;
    }
}
