import {ErrorHandler, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import * as StackTrace from "stacktrace-js";

@Injectable()
export class DigiErrorHandler extends ErrorHandler {

    constructor(private httpClient: HttpClient) {
        super();
    }

    handleError(error: any): void {
        super.handleError(error);

        const url: string = window.errorHandlerUrl;
        const commitId: string = window.commonOptions && window.commonOptions.buildNumber;

        if (url != null) {
            const message = error.message ? error.message : error.toString();

            StackTrace.fromError(error, {offline: true}).then(frames => {
                const stacktrace = frames
                    .splice(0, 20)
                    .map(sf => sf.toString())
                    .join('\n');

                this.httpClient.post(url, {
                    message,
                    details: stacktrace,
                    commitId: commitId
                }).subscribe(() => {
                }, (e) => {
                }); // ignore http errors
            }).catch(() => {
                // ignore stack parsing errors
            });
        }
    }
}
