import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {BehaviorSubject, Observable} from "rxjs";
import {LoggingService} from "../logging.service";
import {BindingInformation, Dictionary, GeneralType, QueryOrder} from "../../apina-digiweb";
import {isOpus, isSerialPublication} from "../../utils/domain-utils";
import {NavigationService} from "../navigation.service";
import {SessionStorageService} from "../hacks/session-storage.service";
import {crop} from "../../utils/string-utils";
import {firstDateOfYear, formatEuropeanDate, lastDateOfYear} from "../../utils/date";

// Notice filterJsonProperties(..) below!
export interface BreadcrumbLink {

    /**
     * @deprecated use router link commands array if possible.
     */
    link?: string;
    /**
     * If both translationKey and localizedText are defined, translationKey is used.
     */
    translationKey?: string;
    localizedText?: string;
    commands?: string[];

    /**
     * Translation key for tooltip.
     *
     * If both tooltip and tooltipKey are defined, tooltipKey is used.
     */
    tooltipKey?: string;
    tooltip?: string;

    queryParams?: Dictionary<any>;
}

function filterJsonProperties(value: BreadcrumbLink[]): BreadcrumbLink[] | null {
    if (_.isArray(value)) {
        return value.map(i => {
            return {
                queryParams: i.queryParams,
                localizedText: i.localizedText,
                commands: i.commands,
                link: i.link,
                translationKey: i.translationKey,
                tooltip: i.tooltip,
                tooltipKey: i.tooltipKey
            };
        });
    }
    return null;
}

function capitalize(text: string): string {
    if (text && text.length > 1) {
        return text[0].toUpperCase() + text.substr(1);
    } else {
        return text;
    }
}

@Injectable()
export class BreadcrumbsService {

    private changes: BehaviorSubject<BreadcrumbLink[]>;

    constructor(private translateService: TranslateService,
                private log: LoggingService,
                private readonly navigationService: NavigationService,
                private readonly sessionStorageService: SessionStorageService) {
        const initialValue: BreadcrumbLink[] = window?.breadcrumbs?.links || [];
        this.changes = new BehaviorSubject<BreadcrumbLink[]>(initialValue);

        log.debug("Initialized BreadcrumbsService with links", initialValue);
    }

    setLocalizedText(localizationKey: string) {
        this.setBreadcrumbLinks([
            this.createLocalizedBreadcrumbLink(localizationKey, [])
        ]);
    }

    setLocalizedLink(localizationKey: string, commands: string[]) {
        this.setBreadcrumbLinks([
            this.createLocalizedBreadcrumbLink(localizationKey, commands)
        ]);
    }

    getBreadcrumbLinks(): Observable<BreadcrumbLink[]> {
        return this.changes.asObservable();
    }

    setBreadcrumbLinks(breadcrumbs: BreadcrumbLink[]): void {
        this.log.debug("setBreadcrumbs", breadcrumbs);

        this.changes.next(breadcrumbs);

        // FIXME fancy breadcrumbs logic is not working right now, see how it's supposed to work in breadcrumbs.md
    }

    createBreadcrumbLink(title: string, commands: string[]): BreadcrumbLink {
        return {localizedText: title, link: null, commands};
    }

    createLocalizedBreadcrumbLink(titleKey: string, commands: string[]): BreadcrumbLink {
        const localizedTitle = this.translateService.instant(titleKey);

        return {localizedText: localizedTitle, link: null, commands};
    }

    // ---- //

    public setForBindingView(data: BindingInformation): void {
        const breadcrumbs = this.getLatestLocation() || this.createDefaultRoot(data);

        if (isOpus(data.generalType)) {
            breadcrumbs.push(this.createCurrentBindingSearchLink(data));
            breadcrumbs.push({
                localizedText: data.title
            });
        } else if (isSerialPublication(data.generalType)) {
            breadcrumbs.push(this.createSerialTitleLink(data));
            breadcrumbs.push(this.createVolumeLink(data));
            breadcrumbs.push({
                localizedText: this.formatIssue(data)
            });
        } else if (data.generalType === GeneralType.PRINTING) {
            breadcrumbs.push(this.createPrintingPublisherLink(data));
            const yearLink = this.createPrintingYearLink(data);
            if (yearLink)
                breadcrumbs.push(yearLink);
            breadcrumbs.push({
                localizedText: data.title
            });
        }

        this.setBreadcrumbLinks(breadcrumbs);
    }

    private formatIssue(data: BindingInformation): string {
        const date = data.citationInfo.publishingDate;

        const formattedDate = date ? formatEuropeanDate(date) : this.translate("breadcrumbs.no-date");

        return data.issue ?
            formattedDate + " no " + data.issue :
            formattedDate;
    }

    private createCurrentBindingSearchLink(data: BindingInformation): BreadcrumbLink {
        return {
            localizedText: this.translate("title-type.OPUS"),
            commands: [this.navigationService.basePaths.search],
            queryParams: {
                title: data.citationInfo.issn,
                pages: "1-" + data.pages[data.pages.length - 1].number
            },
            tooltip: this.translate("breadcrumbs.tooltip.search-this-binding")
        };
    }

    private createPrintingPublisherLink(data: BindingInformation): BreadcrumbLink {
        const publisher = data.citationInfo.publisher;
        return {
            localizedText: crop(publisher, 15, true),
            commands: [this.navigationService.basePaths.search],
            queryParams: {
                formats: data.generalType,
                publisher,
                orderBy: QueryOrder.AUTHOR_ASC
            },
            tooltip: this.translate("breadcrumbs.tooltip.search-by-publisher")
        };
    }

    private createPrintingYearLink(data: BindingInformation): BreadcrumbLink | null {
        const year = data.citationInfo.publishingYear;

        if (!!year) {
            return {
                localizedText: "" + year,
                commands: [this.navigationService.basePaths.search],
                queryParams: {
                    formats: data.generalType,
                    startDate: firstDateOfYear(year),
                    endDate: lastDateOfYear(year),
                    orderBy: QueryOrder.DATE
                },
                tooltip: this.translate("breadcrumbs.tooltip.search-by-publication-year")
            };
        } else {
            return null;
        }
    }

    private createDefaultRoot(data: BindingInformation): BreadcrumbLink[] {
        const searchPath = this.navigationService.basePaths.search;
        const generalType = data.generalType;
        const localizedGeneralType = capitalize(this.translateService.instant("general-type.plural." + generalType));

        if (isSerialPublication(generalType)) {
            return [{
                commands: [this.navigationService.basePaths.serialPublications],
                queryParams: {generalTypes: generalType},
                localizedText: localizedGeneralType
            }];
        } else if (isOpus(generalType)) {
            return [{
                commands: [searchPath],
                queryParams: {formats: generalType},
                localizedText: localizedGeneralType
            }];
        } else if (generalType === GeneralType.PRINTING) {
            return [{
                commands: [searchPath],
                queryParams: {formats: generalType},
                localizedText: localizedGeneralType
            }];
        } else {
            return [];
        }
    }

    private createSerialTitleLink(data: BindingInformation): BreadcrumbLink {
        return {
            commands: [this.navigationService.getSection(data.generalType), "titles", data.citationInfo.issn],
            localizedText: data.publicationTitle,
            tooltip: this.translate("breadcrumbs.tooltip.show-serial-publication")
        };
    }

    private createVolumeLink(data: BindingInformation): BreadcrumbLink {
        const year = data.citationInfo.publishingYear;
        return {
            commands: [this.navigationService.getSection(data.generalType), "titles", data.citationInfo.issn],
            queryParams: {year},
            localizedText: "" + year,
            tooltip: this.translate("breadcrumbs.tooltip.show-volume")
        };
    }

    setLatestLocation(path: BreadcrumbLink[]): void {
        this.sessionStorageService.setItem("kk-bc-latest-location", JSON.stringify(path));
    }

    getLatestLocation(): BreadcrumbLink[] | null {
        try {
            const serialized = this.sessionStorageService.getItem("kk-bc-latest-location");

            if (serialized) {
                const parsed = filterJsonProperties(JSON.parse(serialized) as BreadcrumbLink[]);

                if (parsed) {
                    return parsed;
                }
            }
        } catch (e) {}

        return null;
    }

    private translate(key: string) {
        return this.translateService.instant(key);
    }
}
