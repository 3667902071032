import {TranslateService} from "@ngx-translate/core";

/**
 * Translation service returns the key, if translation is not found. This convenience function allows
 * to define a default value that is returned instead of the key.
 */
export function translationOrDefault(translateService: TranslateService, 
                                     key: string, 
                                     defaultValue: string): string {
    const result = translateService.instant(key);

    return result === key ? defaultValue : result;
}