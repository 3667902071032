import {debounceTime, map} from 'rxjs/operators';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {BehaviorSubject, merge, Subscription} from "rxjs";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import {escapeRegExp} from "../../utils/escape";
import {TranslateService} from "@ngx-translate/core";
import {ANIM_ADD_REMOVE_IN, ANIM_OPEN_CLOSE_OPEN, ANIM_STATIC, animAddRemoveSelection, animOpenClose} from "../animations/animations";
import {levenshteinWordAverage} from "../../utils/string-utils";

const MAX_DISPLAYED_ITEMS = 250;

const FORMATTER_DEFAULTS: PopoverFormatter = {
    selectedFormat: {
        showInfo1: true,
        showInfo2: true,
        trimTitle: false
    },
    unselectedFormat: {
        showInfo1: true,
        showInfo2: true,
        trimTitle: false
    }
};

export interface PopoverItemFormat {
    trimTitle?: boolean;
    showInfo1?: boolean;
    showInfo2?: boolean;
}

export interface PopoverFormatter {
    selectedFormat?: PopoverItemFormat;
    unselectedFormat?: PopoverItemFormat;
}

export interface IPopoverItem {
    id: any;
    title: string;
    /**
     * Optional info-row shown below the title.
     */
    info?: string;
    /**
     * Optional second info-row.
     */
    info2?: string;
    /**
     * Used in prefixing the title when a single item is selected.
     */
    type?: string;

    /**
     * Used in sorting filtered values.
     */
    score?: number;
}

@Component({
    selector: "app-popover-picker",
    styleUrls: ["./popover-picker.component.scss"],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => PopoverPickerComponent),
        multi: true
    }],
    animations: [
        animOpenClose,
        animAddRemoveSelection
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <ng-template #picker>
            <div class="app-popover popover-picker-popup-content" [ngClass]="{large: large}">
                <!-- FIXME aria label -->
                <button type="button" class="close" (click)="popover.close()" attr.aria-label="{{'list.close.tooltip' | translate}}">&times;</button>
                <app-progress-spinner *ngIf="!items"></app-progress-spinner>
                <div *ngIf="items">
                    <div [@openClose]="openCloseAnim" *ngIf="hasSelections()">
                        <h4 class="my-2">{{'popover-picker.filters' | translate}}</h4>
    
                        <ul class="items">
                            <li *ngFor="let item of selectedItems; trackBy: popoverItemTrack"
                                [ngClass]="{conflict: isConflictingItem(item)}"
                                [@addRemoveSelection]="addRemoveAnim">
                                <a href="" (click)="deselectItem($event, item)">
                                    <i class="fa fa-minus float-right"></i>
                                    <div class="title">{{formatTitle(item, true)}}</div>
                                    <div class="info" *ngIf="showInfo1(item, true)">{{item.info}}</div>
                                    <div class="info" *ngIf="showInfo2(item, true)">{{item.info2}}</div>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <h4 class="my-2">{{'popover-picker.add-filter' | translate}} ({{('popover-picker.single.' + type) | translate}})</h4>

                    <div class="input-group item-filter">
                        <input type="text" class="form-control" placeholder="{{'popover-picker.search' | translate}}" 
                               [(ngModel)]="filter" aria-label="Select" appAutofocus autofocusDelay="50">
                    </div>
                    
                    <ul class="items filters" [ngClass]="{large: large}">
                        <li *ngFor="let item of visibleItems; trackBy: popoverItemTrack" (click)="selectItem($event, item)">
                            <a href="">
                                <i class="fa fa-plus"></i>
                                <div class="title">{{formatTitle(item, false)}}</div>
                                <div class="info" *ngIf="showInfo1(item, false)">{{item.info}}</div>
                                <div class="info" *ngIf="showInfo2(item, false)">{{item.info2}}</div>
                            </a>
                        </li>
                        <li *ngIf="visibleItems.length < matchingItems.length" class="text-center">
                            <button type="button" class="btn btn-kk-blue" (click)="displayMoreOptions($event)" translate>popover-picker.display-more</button>
                        </li>
                    </ul>
                    
                    <div class="d-flex justify-content-between flex-row-reverse">
                        <span class="text-center">{{'popover-picker.possible-filters' | translate}} {{matchingItems.length}} / {{items.length}}</span>

                        <span *ngIf="numberOfVisibleItems > 0 && numberOfVisibleItems <= selectAllVisibilityLimit">
                            <a href="" (click)="selectAllItems($event)" [translate]="'popover-picker.select-all'" [translateParams]="{count: numberOfVisibleItems}"></a>
                        </span>
                    </div>
                </div>
            </div>
        </ng-template>

        <div class="input-group popover-picker" [ngClass]="{conflict: hasConflictingItems()}">
            <input type="text" class="form-control" readonly [value]="inputText" attr.aria-label="{{'list.choices.tooltip' | translate}}" [disabled]="_isDisabled" 
                   #input (focus)="popover.open()" [ngClass]="{'has-selections': hasSelections()}">
            <span class="input-group-append">
                <button type="button" class="btn btn-kk" [ngbPopover]="picker" placement="{{placement}}" [autoClose]="false"
                        #popover="ngbPopover" attr.aria-label="{{'list.descr.tooltip'|translate}}" [disabled]="_isDisabled" appClosePopoverOnClickOutside [parentInput]="input">
                    <i class="fa fa-caret-down"></i></button>
            </span>
        </div>
    `
})
export class PopoverPickerComponent implements ControlValueAccessor, AfterViewInit, OnChanges, DoCheck, OnDestroy {

    readonly selectAllVisibilityLimit = 25;

    @Input() type: string;
    @Input() extraFilter: (item: IPopoverItem) => boolean;
    @Input() placement = "bottom-right";
    @Input() _isDisabled = false;
    @Input() large = true;
    @Input() formatter: PopoverFormatter;

    /**
     * AngularJS doesn't like the "_" in "_isDisabled", so call this from legacy code instead.
     */
    @Input() set setDisabled(value: boolean) {
        if (value !== undefined)
            this._isDisabled = value;
    }

    @Input() set items(value: IPopoverItem[]) {
        this._items = value || [];
        this.refreshSelectedItems();
    }

    get items(): IPopoverItem[] {
        return this._items;
    }

    @ViewChild("popover", {static: true}) pickerPopover: NgbPopover;

    openCloseAnim = ANIM_STATIC;
    addRemoveAnim = ANIM_STATIC;
    filter$ = new BehaviorSubject("");

    selectedIds: string[] = [];
    private selectionLength = 0; // for custom change detection, see ngDoCheck method
    
    selectedItems: IPopoverItem[] = [];
    matchingItems: IPopoverItem[] = []; // subset of items
    visibleItems: IPopoverItem[] = []; // subset of matchingItems
    displayItemsUpTo = MAX_DISPLAYED_ITEMS;

    inputText = "";
    _items: IPopoverItem[];
    _formatter: PopoverFormatter;

    private readonly subs = new Subscription();

    static makeFilterPredicate(filter: string): (item: IPopoverItem) => boolean {
        if (filter === '')
            return item => true;

        const regex = new RegExp(escapeRegExp(filter), 'i');
        return item => regex.test(item.title) || (item.info && regex.test(item.info))  || (item.info2 && regex.test(item.info2));
    }

    propagateChange = (_: any) => {};

    get numberOfVisibleItems() {
        return this.visibleItems.length;
    }

    constructor(private translateService: TranslateService,
                private readonly cd: ChangeDetectorRef) {
        this.filter$.pipe(debounceTime(200)).subscribe(value => {
            this.updateFilterList();
            cd.markForCheck();
        });
    }
    
    ngDoCheck(): void {
        // We know that items in the selection are not modified, they can only be added or removed, one at a time. 
        // Thus, we can implement change detection simply by keeping track of changes to the array length.
        const prevLength = this.selectionLength;
        this.selectionLength = this.selectedIds.length;
        if (prevLength !== this.selectionLength) {
            this.refreshSelectedItems();
            this.cd.markForCheck(); // we need to tell Angular that the component needs re-rendering
        }
    }
    
    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.refreshSelectedItems();

        this.subs.add(merge(
            this.pickerPopover.shown.pipe(map(() => true), debounceTime(1)),
            this.pickerPopover.hidden.pipe(map(() => false))
        ).subscribe((shown) => {
            this.openCloseAnim = shown ? ANIM_OPEN_CLOSE_OPEN : ANIM_STATIC;
            this.addRemoveAnim = shown ? ANIM_ADD_REMOVE_IN : ANIM_STATIC;
        }));

        this.subs.add(this.pickerPopover.shown.subscribe(() => {
            this.updateFilterList();
            this.refreshSelectedItems();
        }));
    }

    get filter(): string {
        return this.filter$.getValue();
    }
    set filter(value: string) {
        this.filter$.next(value);
    }

    writeValue(obj: any): void {
        this.selectedIds = obj || [];
        if (this.selectedIds)
            this.refreshSelectedItems(); // TODO: should we optimize and refresh selected list only when the model has really been changed
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    // Note: ControlValueAccessor's disable state setting is not working from angularJS
    setDisabledState(isDisabled: boolean): void {
        this._isDisabled = isDisabled;
        if (this._isDisabled && this.pickerPopover.isOpen()) {
            this.pickerPopover.close();
        }
    }

    hasSelections(): boolean {
        return this.selectedItems.length > 0;
    }

    isConflictingItem(item: IPopoverItem): boolean {
        return this.extraFilter != null && !this.extraFilter(item);
    }

    hasConflictingItems(): boolean {
        const extraFilter = this.extraFilter;
        if (!extraFilter) return false;

        return _.some(this.selectedItems, (item: any) => !extraFilter(item));
    }

    updateFilterList() {
        const predicate = this.makeFilterMatchingPredicate();
        const goodMatchThreshold = 2;
        const badMatches: IPopoverItem[] = [];
        const goodMatches: IPopoverItem[] = [];
        const filterValue = this.filter;

        for (const item of this.items) {
            const matching = predicate(item) && this.selectedIds.indexOf(item.id) === -1;

            if (matching) {
                if (filterValue && filterValue.length >= 3) {
                    item.score = levenshteinWordAverage(filterValue, item.title);

                    if (item.score <= goodMatchThreshold) {
                        goodMatches.push(item);
                    } else {
                        badMatches.push(item);
                    }
                } else {
                    badMatches.push(item);
                }
            }
        }

        goodMatches.sort(this.compareLevenshtein);
        this.matchingItems = goodMatches.concat(badMatches);

        this.visibleItems = this.matchingItems.slice(0, this.displayItemsUpTo);
    }

    compareLevenshtein(a: IPopoverItem, b: IPopoverItem): number {
        if (a.score < b.score)
            return -1;
        else if (a.score === b.score)
            return 0;
        else
            return 1;
    }

    removeItemFromFilterList(item: IPopoverItem) {
        const visibleItemIndex = this.visibleItems.indexOf(item);
        if (visibleItemIndex !== -1)
            this.visibleItems.splice(visibleItemIndex, 1);
    }

    displayMoreOptions(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        this.displayItemsUpTo = this.visibleItems.length + MAX_DISPLAYED_ITEMS;
        this.visibleItems = this.matchingItems.slice(0, this.displayItemsUpTo);
    }

    /**
     * refresh the selected items list based on model i.e. selectedIds
     */
    refreshSelectedItems() {
        const selectedIds = this.selectedIds;
        if (selectedIds == null || selectedIds.length === 0 || this.items == null) {
            this.selectedItems = [];
        } else {
            this.selectedItems = this.items.filter(item => this.selectedIds.indexOf(item.id) !== -1);
        }
        this.refreshInputText();
    }

    refreshInputText(): void {
        if (!this.selectedIds || !this.items) return;

        const selected = this.selectedItems;

        if (selected.length === 0) {
            this.inputText = this.translateService.instant('popover-picker.all') + ' ' + this.translateService.instant(`popover-picker.all.${this.type}`);
        } else if (selected.length === 1) {
            const s = selected[0];
            const prefix = s.type ? s.type : this.translateService.instant(`popover-picker.single.${this.type}`);
            this.inputText = `${prefix}: ${s.title?.trim()}`; // collections have padding in title due to tree structure
        } else {
            this.inputText = this.translateService.instant(`popover-picker.counted.${this.type}`, { count: selected.length });
        }
    }

    selectItem(event: Event, item: IPopoverItem) {
        event.preventDefault();
        event.stopPropagation();
        this.selectedIds.push(item.id);
        this.removeItemFromFilterList(item);

        this.updateFilterList();
        this.refreshSelectedItems();

        // notify change
        this.propagateChange(this.selectedIds);
    }

    selectAllItems(event: Event) {
        event.preventDefault();
        event.stopPropagation();

        for (const item of this.visibleItems) {
            this.selectedIds.push(item.id);
        }
        this.updateFilterList();
        this.refreshSelectedItems();

        // notify change
        this.propagateChange(this.selectedIds);
    }

    deselectItem(event: Event, item: IPopoverItem) {
        event.preventDefault();
        event.stopPropagation();

        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
        this.selectedIds.splice(this.selectedIds.indexOf(item.id), 1);

        this.updateFilterList();
        this.refreshInputText();

        // notify change
        this.propagateChange(this.selectedIds);
    }

    // Function to track IPopoverItems in *ngFor
    popoverItemTrack(index: number, item: IPopoverItem) {
        return item.id;
    }

    makeFilterMatchingPredicate(): (item: IPopoverItem) => boolean {
        const filterPredicate = PopoverPickerComponent.makeFilterPredicate(this.filter);
        const extraFilter = this.extraFilter;
        return extraFilter ? (item: IPopoverItem) => filterPredicate(item) && extraFilter(item) : filterPredicate;
    }

    formatTitle(item: IPopoverItem, selected: boolean): string {
        const value = item.title;
        return this.getFormat(selected).trimTitle ? value?.trim(): value;
    }

    showInfo1(item: IPopoverItem, selected: boolean): boolean {
        return !!item.info && this.getFormat(selected).showInfo1;
    }

    showInfo2(item: IPopoverItem, selected: boolean): boolean {
        return !!item.info2 && this.getFormat(selected).showInfo2;
    }

    private getFormat(selected: boolean): PopoverItemFormat {
        return selected? this._formatter.selectedFormat : this._formatter.unselectedFormat;
    }

    ngOnChanges(changes: SimpleChanges): void {
        // This is usually called only once.
        // Angular documentation discourages using DoCheck and OnChanges together, but I don't see the harm.
        this._formatter = _.defaultsDeep({}, this.formatter, FORMATTER_DEFAULTS);
    }
}
