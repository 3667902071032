import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable, throwError} from "rxjs";
import {ErrorService} from "../error.service";
import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";

@Injectable()
export class HttpExceptionHandler implements HttpInterceptor {

    constructor(private errorService: ErrorService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const observable = next.handle(req);

        if (observable != null) {
            return observable.pipe(catchError((error: HttpErrorResponse) => {
                if (this.shouldReportToUser(error)) {
                    setTimeout(() => {
                        this.errorService.showHttpError(error);
                    }, 2000);
                }

                // We can't return the original Observable, as it has "errored out".
                // Create a new Observable instead.
                return throwError(error);
            }));
        } else
            return observable;
    }

    private shouldReportToUser(error: HttpErrorResponse) {
        const statusCode = "" + error.status;
        return statusCode.startsWith("5"); // show only 5XX errors
    }
}
