import {ChangeDetectionStrategy, Component, HostListener, Input} from "@angular/core";
import {DisplayService} from "../../display.service";
import {take} from "rxjs/operators";

export enum ExpandDirection { LEFT = "LEFT", RIGHT = "RIGHT"}

@Component({
    selector: "app-sidebar-handle",
    template: `
        <div class="sidebar-handle" (mousedown)="startResize($event)" (touchstart)="startResize($event)" [ngClass]="{dragging: listening}"></div>
    `,
    styleUrls: [
        "./sidebar-handle.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarHandleComponent {
    
    @Input() expandDirection: ExpandDirection = ExpandDirection.LEFT;
    
    listening = false;
    private startX: number;
    private startWidth: number;

    constructor(readonly displayService: DisplayService) {
    }

    startResize($event: MouseEvent|TouchEvent) {
        $event.stopPropagation();
        $event.preventDefault();

        this.displayService.sidebarWidth.pipe(take(1)).subscribe((w) => {
            this.startWidth = w;
            this.listening = true;
            this.startX = getX($event);
        });
    }
    
    @HostListener("body:mousemove", ["$event"])
    @HostListener("body:touchmove", ["$event"])
    mouseMove($event: MouseEvent | TouchEvent) {
        if (this.listening) {
            const diff = getX($event) - this.startX;
            
            const newWidth = this.expandDirection === ExpandDirection.LEFT ?
                this.startWidth - diff :
                this.startWidth + diff;
            
            this.displayService.updateSidebarWidth(newWidth);
        }
    }

    @HostListener("body:mouseup", ["$event"])
    @HostListener("body:touchend", ["$event"])
    mouseUp($event: MouseEvent | TouchEvent) {
        this.listening = false;
        this.startWidth = null;
    }
}

function getX($event: MouseEvent | TouchEvent): number {
    if ($event instanceof MouseEvent) {
        return $event.clientX;
    } else if ($event instanceof TouchEvent) {
        return $event.touches[0].clientX;
    } else {
        throw Error("unsupported event type " + $event);
    }
}