import {Component, Input} from "@angular/core";
import {MarcInfo} from "../../../apina-digiweb";

@Component({
    selector: "app-technical-marc-record",
    template: `
        <ng-container *ngIf="record != null">
            <table>
                <tr>
                    <td><strong>LEADER</strong></td>
                    <td>{{record.leader}}</td>
                </tr>
                
                <tr *ngFor="let cf of record.controlFieldInfos">
                    <td class="text-nowrap">
                        <strong>{{cf.tag}}<span *ngIf="cf.indexRange">/{{cf.indexRange}}</span></strong>
                    </td>
                    <td class="cf-value">{{cf.value}}</td>
                </tr>
                <tr *ngFor="let df of record.dataFieldInfos">
                    <td class="text-nowrap">
                        <strong>{{df.tag}}</strong> {{df.ind1}} {{df.ind2}}
                    </td>
                    <td>
                        <span *ngFor="let sf of df.subFields" class="df-value">
                            <strong>|{{sf.code}}</strong>&nbsp;{{sf.value}} 
                        </span>
                    </td>
                </tr>
                
            </table>
        </ng-container>
    `,
    styleUrls: [
        "./technical-marc-record.scss"
    ]
})
export class TechnicalMarcRecordComponent {

    @Input() record: MarcInfo;
}
