import {Component, Input, OnInit} from "@angular/core";
import {UserArticleService} from "./user-article.service";

@Component({
    selector: 'app-user-article-ocr',
    template: `
        <app-progress-spinner *ngIf="loading" color="accent"></app-progress-spinner>

        <div class="copyable-ocr-text">
            <div *ngFor="let line of ocrText" class="citable-content kk-tab-content text-justify kk-bg-lightgray" style="white-space: pre-line;">
                {{line}}
            </div>
        </div>
    `
})
export class UserArticleOcrComponent implements OnInit {

    @Input() articleId: number;

    loading = true;
    ocrText: string[];

    constructor(private articleService: UserArticleService) {
    }

    ngOnInit(): void {
        this.articleService.getArticleOCR(this.articleId).subscribe(results => {
            this.ocrText = results;
            this.loading = false;
        });
    }
}
