import {Component, EventEmitter, Input, Output} from "@angular/core";
import {CitationInfo} from "../../../apina-digiweb";

@Component({
    selector: "app-binding-view-citation-info",
    template: `
        <app-sidebar>
            <app-sidebar-header titleKey="citation.title" (closeSidebar)="closeDialog.emit()"></app-sidebar-header>
            
            <app-sidebar-content>
                <app-citation-info [citationInfo]="data" [currentPageNumber]="currentPageNumber"></app-citation-info>
                
                <button mat-stroked-button (click)="selectCitableContent()" class="mt-2">
                    <span translate>citation.select-text</span></button>
            </app-sidebar-content>
        </app-sidebar>
    `
})
export class BindingViewCitationInfoComponent {
    @Output() closeDialog = new EventEmitter<void>();

    @Input() data: CitationInfo;
    @Input() currentPageNumber: number;

    // TODO: this shouldn't be here and is also copy-pasted to article-controller
    selectCitableContent() {
        // TODO: fetching element globally like this is evil
        const content = jQuery('textarea.citable-content:visible');
        content.focus();
        content.select();
    }
}
