import {AfterViewInit, Directive, ElementRef, Input, OnDestroy} from "@angular/core";
import {Observable, Subscription} from "rxjs";

@Directive({
    selector: "[appScrollControl]"
})
export class ScrollControlDirective implements AfterViewInit, OnDestroy {
    
    @Input() set resetScroll(value: Observable<void>) {
        this.reset$ = value;
    }
    
    reset$: Observable<void>;
    
    private readonly sub = new Subscription();
    
    constructor(private readonly elementRef: ElementRef) {
    }

    ngAfterViewInit(): void {
        this.sub.add(this.reset$.subscribe(() => {
            const el = this.elementRef.nativeElement as HTMLElement;
            el.scrollTop = 0;
        }));
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}