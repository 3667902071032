import {Component, OnInit} from "@angular/core";
import {NavigationService} from "../navigation.service";
import {SettingsService} from "../settings.service";

@Component({
    template: `
        <section class="container">
            
            <div class="alert alert-danger" *ngIf="loginFailed" translate>account.login.failed</div>
        
            <div class="row">
                
                <div class="offset-md-1 col-md-5" *ngIf="localLoginEnabled">
    
                    <form action="/j_spring_security_check" method="post" class="form-horizontal" ngNoForm>
                        <input type="hidden" name="returnTo" [value]="returnTo" />
    
                        <h4></h4>
    
                        <div class="form-group row">
                            <label for="j_username" class="col-form-label col-md-3" translate>account.login</label>
    
                            <div class="col-md-8">
                                <input type="text" id="j_username" name="j_username" autofocus class="form-control" autocorrect="off" autocapitalize="off">
                            </div>
                        </div>
    
                        <div class="form-group row">
                            <label for="j_password" class="col-form-label col-md-3" translate>account.password</label>
    
                            <div class="col-md-8">
                                <input type="password" name="j_password" id="j_password" class="form-control">
                            </div>
                        </div>
    
                        <div class="form-group row">
                            <div class="offset-md-3 col-md-10">
                                <button type="submit" class="btn btn-kk-blue" disabled="disabled" [disabled]="isLoginDisabled()" translate>account.login.action</button>
                                <span class="login-countdown" *ngIf="isLoginDisabled()">{{loginAllowedCountdown}}</span>
                                
                                <a *ngIf="localRegistrationEnabled" [href]="paths.register" class="btn btn-outline-secondary" translate>account.create-account</a>
                            </div>
                        </div>
                    </form>
                </div>
            
                <div class="col-md-6" *ngIf="otherLogins && includeExternalResources">
                    <h4 translate>account.login.other</h4>
    
                    <form *ngIf="isGoogle" action="/signin/google" method="POST" style="display: inline-block" ngNoForm>
                        <input type="hidden" name="returnTo" [value]="returnTo" />
                        <button class="btn btn-kk-blue" type="submit">
                            <img src="/images/logos/google.png" height="14px" style="top: -0.1rem; position: relative;" alt="Google"/> Google
                        </button>
                    </form>
    
                    <form *ngIf="isFacebook" action="/signin/facebook" method="POST" style="display: inline-block" ngNoForm>
                        <input type="hidden" name="returnTo" [value]="returnTo" />
                        <button class="btn btn-kk-blue ml-2" type="submit"><i class="fa fa-facebook"></i> Facebook</button>
                    </form>

                    <form *ngIf="isTwitter" action="/signin/twitter" method="POST" style="display: inline-block" ngNoForm>
                        <input type="hidden" name="returnTo" [value]="returnTo" />
                        <button class="btn btn-kk-blue ml-2" type="submit"><i class="fa fa-twitter"></i> Twitter</button>
                    </form>

                </div>
            </div>
        </section>
    `
})
export class LoginPageComponent implements OnInit {

    error: boolean;
    nextAllowedLoginAttempt: number;
    returnTo: string;

    loginAllowedCountdown: number = null;

    isGoogle = false;
    isFacebook = false;
    isTwitter = false;

    constructor(private navigationService: NavigationService,
                private settingsService: SettingsService) {

        // TODO check from server
        // const providers = (window as any).registeredSocialProviders;

        this.isGoogle = true; // providers.includes("google");
        this.isFacebook = true; // providers.includes("facebook");
        this.isTwitter = true; // providers.includes("twitter");

        const params = this.navigationService.search;
        this.error = !!params.error;
        this.nextAllowedLoginAttempt = +params.nextAllowedLoginAttempt;
        this.returnTo = decodeURIComponent(params.returnTo || "/");
    }

    ngOnInit(): void {

        // epoch millis
        const now = new Date().getTime();
        const delay = this.nextAllowedLoginAttempt - now;

        if (delay > 0) {
            const delaySeconds = Math.ceil(delay / 1000);
            this.loginAllowedCountdown = delaySeconds;

            setTimeout(() => {
                this.loginAllowedCountdown -= 1;
            }, 1000, delaySeconds);

        } else {
            this.loginAllowedCountdown = 0;
        }
    }

    isLoginDisabled() {
        return this.loginAllowedCountdown > 0;
    }

    get otherLogins() {
        return this.isGoogle || this.isFacebook || this.isTwitter;
    }

    get loginFailed() {
        return true;
    }

    get localLoginEnabled() {
        return this.settingsService.commonOptions.localLoginEnabled;
    }

    get localRegistrationEnabled(): boolean {
        return this.settingsService.commonOptions.localRegistrationEnabled;
    }

    get includeExternalResources() {
        return this.settingsService.commonOptions.includeExternalResources;
    }

    get paths() {
        return this.navigationService.basePaths;
    }
}
