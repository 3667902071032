import {Component, EventEmitter, forwardRef, Injectable, Input, OnInit, Output} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {formatEuropeanDate} from "../../utils/date";
import {LocalDate} from "@js-joda/core";
import {DateAdapter, MAT_DATE_FORMATS} from "@angular/material/core";
import {LocalDateAdapter, MY_DATE_FORMATS} from "./date/local-date-adapter";

type NORMALIZE_TYPE = 'start-of-year' | 'end-of-year';
const YEAR_PATTERN = /^\d{4}$/;

@Injectable()
export class DateNormalizerAdapter extends LocalDateAdapter {

    normalizer: NORMALIZE_TYPE;

    parse(value: any, parseFormat: any): LocalDate | null {
        if (value) {
            if (this.normalizer && value.match(YEAR_PATTERN)) {
                const year = parseInt(value, 10);

                switch (this.normalizer) {
                    case  "start-of-year":
                        return super.parse('01.01.' + value, parseFormat);
                    case "end-of-year":
                        return super.parse('31.12.' + value, parseFormat);
                    default:
                        throw Error("unknown normalizer: " + this.normalizer);
                }
            }
        }

        return super.parse(value, parseFormat);
    }

    registerNormalize(normalize: NORMALIZE_TYPE) {
        this.normalizer = normalize;
    }
}

@Component({
    selector: "app-datepicker",
    template: `
        <div class="input-group">
            <input class="form-control" [ngClass]="{'has-input': !!model}"
                   [placeholder]="_placeholder"
                   [matDatepicker]="picker"
                   [(ngModel)]="model"
                   aria-label="Valitse päivä"/>
            <span class="input-group-append">
                <button type="button" class="btn btn-kk" (click)="picker.open()"><i class="fa fa-calendar"></i><span class="sr-only">Valitse päivä</span></button>
            </span>
        </div>
        <mat-datepicker #picker [startAt]="model || initDate"></mat-datepicker>
    `,
    styleUrls: ['./date-picker.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true
        },
        {
            provide: DateAdapter,
            useExisting: forwardRef(() => DateNormalizerAdapter)
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
        },
        DateNormalizerAdapter
    ]
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {
    _model: LocalDate;
    propagateChange: (_: any) => void;

    @Input() placeholder: LocalDate;
    @Input() initDate: LocalDate;
    @Input() normalize?: NORMALIZE_TYPE;

    @Output() onChange = new EventEmitter<void>();

    constructor(private dateAdapter: DateNormalizerAdapter) {
    }

    ngOnInit(): void {
        if (this.normalize)
            this.dateAdapter.registerNormalize(this.normalize);
    }

    get _placeholder(): string {
        return this.placeholder && formatEuropeanDate(this.placeholder) || "pp.kk.vvvv"; // TODO i18n
    }

    get model(): LocalDate {
        return this._model;
    }

    set model(val: LocalDate) {
        if (val instanceof LocalDate || val == null) {
            this._model = val;
            this.propagateChange(val);
            this.onChange.emit();
        }
    }

    get startDate(): LocalDate { // TODO unused
        return this.model || this.initDate;
    }

    writeValue(obj: LocalDate): void {
        // model -> view
        this._model = obj;
    }

    registerOnChange(fn: (_: any) => void): void {
        // view -> model
        this.propagateChange = fn;
    }

    registerOnTouched(fn: any): void {
        // TODO implement
    }

    setDisabledState?(isDisabled: boolean): void {
        // TODO implement
    }
}
