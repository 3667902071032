import {Component, Input} from "@angular/core";
import {ProcessedUserArticleRow} from "./result-row";
import {PlacementArray} from "@ng-bootstrap/ng-bootstrap/util/positioning";

@Component({
    selector: "app-search-result-thumb-article",
    template: `
        <div class="result-row">
            <a [href]="row.url" target="_blank" appStoreSession>
                <div class="thumb-result" style="height: 260px">
                    <ng-template #thumbHtml>
                        <div class="search-highlight-fragment" *ngFor="let hl of row.highLights" [innerHTML]="hl"></div>
                    </ng-template>
                    <div class="thumb-frame" style="height:200px;" [ngbPopover]="thumbHtml" [triggers]="hasHighlights(row) ? 'mouseenter:mouseleave' : ''" [container]="'body'" [placement]="highlightPlacement">
                        <div class="search-thumb-wrapper">
                            <div style="display:inline-block; position:relative; max-width:98%;">
                                <img [src]="row.previewImageUrl" alt="{{row.title}}" />
                            </div>
                        </div>
                    </div>
                    <div class="thumb-caption" [ngbTooltip]="row.title">
                        <div class="cropped-row link-colors">{{row.title | slice:0:25 }}{{row.title.length > 25 ? '...' : ''}}</div>
                        <div class="cropped-row small">{{row.bindingTitle | slice:0:25 }}{{row.bindingTitle.length > 25 ? '...' : ''}}</div>
                        <div class="cropped-row small">
                            <span *ngIf="row.bindingDate">{{row.bindingDate}}</span>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    `,
    styleUrls: [
        "./result-common.scss",
        "./thumb-result.scss"
    ]
})
export class ThumbResultArticleComponent {
    @Input() row: ProcessedUserArticleRow;
    @Input() highlightPlacement: string | PlacementArray;


    public hasHighlights(row: ProcessedUserArticleRow) {
        return row.highLights.length > 0;
    }
}
