import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ProcessedUserArticleRow} from "./result-row";

@Component({
    selector: "app-search-result-text-thumb-article",
    template: `
        <div class="result-row">
            <a href="{{row.url}}" target="_blank" appStoreSession>
                <div class="media">
                    <div class="media-left text-with-thumb-image-container" *ngIf="showThumb">
                        <div style="display:inline-block; position:relative;">
                            <img class="media-object text-with-thumb-image" [src]="row.previewImageUrl" alt="">
                        </div>
                    </div>
                    <div class="media-body">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="meta-data">
                                    <div class="link-colors">
                                        <span style="font-weight:bold;font-size:120%">{{row.title}}</span> -
                                        <span style="font-weight:bold">{{row.fullBindingTitle}} <span *ngIf="allowDelete" class="float-right" (click)="emitDelete($event, row)"><i class="fa fa-trash-o"></i></span></span>
                                    </div>

                                    <div class="row" *ngIf="row.notes?.length">
                                        <div class="col-md-2"><span>Muistiinpanot:</span></div><div class="col-md-10" style="color:black; font-weight: bold;">{{row.notes}}</div>
                                    </div>

                                    <div class="row" *ngIf="row.description?.length">
                                        <div class="col-md-2"><span>Tiivistelmä:</span></div><div class="col-md-10">{{row.description}}</div>
                                    </div>

                                    <div class="row" *ngIf="row.allKeywords?.length">
                                        <div class="col-md-2"><span translate>user-article.keywords</span></div><div class="col-md-10"> {{row.allKeywords.join(', ')}}</div>
                                    </div>

                                    <div class="row" *ngIf="row.categoryName?.length">
                                        <div class="col-md-2"><span translate>user-article.category</span></div><div class="col-md-10">{{row.categoryName}}</div>
                                    </div>

                                    <div class="row" *ngIf="row.subjectName?.length">
                                        <div class="col-md-2"><span translate>user-article.subject</span></div><div class="col-md-10">{{row.subjectName}}</div>
                                    </div>

                                    <div class="row" *ngIf="row.text.length > 0">
                                        <div class="col-md-2"><span>OCR</span></div>
                                        <div class="col-md-10" *ngIf="row.displayOCR">
                                            <p class="ocr-text" *ngFor="let block of row.text">{{block}}</p>
                                        </div>
                                        <div class="col-md-10" *ngIf="!row.displayOCR">
                                            <button class="btn btn-sm btn-kk m-0" (click)="displayOCR($event, row)" translate>user-article.display-ocr</button>
                                        </div>
                                    </div>
                                </div>

                                <div class="preview" *ngIf="hasHighlights(row)">
                                    <div class="search-highlight-fragment" *ngFor="let hl of row.highLights" [innerHTML]="hl"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </div>
    `,
    styleUrls: [
        "./result-common.scss"
    ]
})
export class TextThumbResultArticleComponent {
    @Input() showThumb: boolean;
    @Input() row: ProcessedUserArticleRow;
    @Input() allowDelete: boolean;
    @Output() deleteRow = new EventEmitter<ProcessedUserArticleRow>();
    
    public hasHighlights(row: ProcessedUserArticleRow) {
        return row.highLights.length > 0;
    }

    public displayOCR($event: Event, row: ProcessedUserArticleRow) {
        $event.stopPropagation();
        $event.preventDefault();

        row.displayOCR = true;
    }
    
    public emitDelete($event: Event, row: ProcessedUserArticleRow) {
        $event.preventDefault();
        this.deleteRow.emit(row);
    }
}
