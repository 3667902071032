import {Injectable, Injector} from "@angular/core";
import {ComponentPortal, ComponentType, PortalInjector} from "@angular/cdk/portal";
import {Overlay} from "@angular/cdk/overlay";
import {Observable, Subject} from "rxjs";

export class ActiveOverlay {
    close(result?: any): void {}
    dismiss(reason?: any): void {}
}

export class OverlayData {
    [name: string]: any;
}

export interface CdkOverlayRef {
    dismiss(reason?: any): void;
    result$: Observable<any>;
}

class CdkOverlayRefImpl implements CdkOverlayRef {
    constructor(private subject: Subject<any>,
                private activeOverlay: ActiveOverlay) {
    }

    result$ = this.subject.asObservable();

    dismiss(reason?: any): void {
        this.activeOverlay.dismiss(reason);
    }
}

@Injectable()
export class OverlayService {

    constructor(private injector: Injector,
                private overlay: Overlay) {
    }

    openCdk<T>(component: ComponentType<T>, parentInjector?: Injector, data?: OverlayData): CdkOverlayRef {
        const overlayRef = this.overlay.create();
        const weakMap = new WeakMap<any, any>();
        if (data)
            weakMap.set(OverlayData, data);

        const resultSubject = new Subject<any>();

        const activeOverlay: ActiveOverlay = {
            close(result?: any): void {
                overlayRef.dispose();
                resultSubject.next(result);
                resultSubject.complete();
            },

            dismiss(reason?: any): void {
                overlayRef.dispose();
                resultSubject.error(reason);
                resultSubject.complete();
            }
        };
        weakMap.set(ActiveOverlay, activeOverlay);

        const portalInjector = new PortalInjector(parentInjector || this.injector, weakMap);
        const componentPortal = new ComponentPortal(component, null, portalInjector);
        const componentRef = overlayRef.attach(componentPortal);

        return new CdkOverlayRefImpl(resultSubject, activeOverlay);
    }
}
