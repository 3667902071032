import {Component, Input} from "@angular/core";

@Component({
    selector: 'app-page-count-indicator',
    template: `
        <span class="page-count-indicator class-{{magnitude}}" [innerHTML]="indicator"></span>
    `
})
export class PageCountIndicatorComponent {

    magnitude: number;
    indicator: string;

    @Input()
    set pageCount(count: number) {
        if (count === undefined) {
            throw new Error("Attribute page-count must be defined");
        }

        this.magnitude = this.getMagnitude(count);

        const icon = '<i class="fa fa-circle"></i>';

        this.indicator = _.repeat(icon, this.magnitude);
    }

    private getMagnitude(pageCount: number): number {
        if (pageCount > 100000) {
            return 5;
        } else if (pageCount > 20000) {
            return 4;
        } else if (pageCount > 5000) {
            return 3;
        } else if (pageCount > 500) {
            return 2;
        }
        return 1;
    }
}
