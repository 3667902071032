import {Component} from "@angular/core";
import {SettingsService} from "../settings.service";

@Component({
    selector: "app-other",
    template: `
        <div class="container-fluid other-section">
            <h2 align="center" class="mt-5 mb-5">{{'other.title' | translate}}</h2>
            
            <div class="row">
                <span *ngIf="!externalResourcesEnabled">
                <p align="center" [innerHTML]="'other.limiteduselink.warning' | translate"></p>
                </span>
            </div>
        
            <div class="row">
                <div class="col-6 col-md-4 col-lg-3">
                    <div class="thumbnail" style="height:100px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a target="_new" href="https://data.nationallibrary.fi/">
                                <img alt="Data catalog logo" height="100" width="200" src="/images/datasets.jpg">
                                {{'other.datacatalogue.title' | translate}}</a></h3>
                            <p align="center">{{'other.datacatalogue.desc' | translate}}</p>
                        </div>
                    </div>
                </div>
                

                <div class="col-6 col-md-4 col-lg-3">
                    <div class="thumbnail" style="height:100px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a target="_new" href="https://www.newseye.eu/">  
                                <img alt="NewsEye logo" height="100" width="200" src="/images/NewsEyeLogos_MP_3-01.jpg">
                                {{'other.newseye.title' | translate}}</a></h3>
                            <p align="center">{{'other.newseye.desc' | translate}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-md-4 col-lg-3">
                    <div class="thumbnail" style="height:100px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a target="_new" href="https://finna.fi/Content/lehtien-kertomaa-savosta">  
                                <img alt="Finna Luokkahuone logo" height="100" width="200" src="/images/finna/finna_luokkahuone.jpg">
                                {{'other.luokkahuone.title' | translate}}</a></h3>
                            <p align="center">{{'other.luokkahuone.desc' | translate}}</p>
                        </div>
                    </div>
                </div>
                
                <!--
                <div class="col-6 col-md-4 col-lg-3">
                    <div class="thumbnail" style="height:300px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="http://www.doria.fi/handle/10024/78800">
                                <img alt="" src="/images/skartat_thumb.jpg">
                                {{'other.karttoja.title' | translate}}</a></h3>
                            <p align="center">{{'other.karttoja.desc' | translate}}</p>
                        </div>
                    </div>
                </div>-->

                <div class="col-6 col-md-4 col-lg-3" >
                    <div class="thumbnail" style="height:300px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="https://e-kirjasto.fi/">
                                <img alt="" src="/images/logos/e-kirjasto-logo.png">
                                {{'other.ekirjasto.title' | translate}}</a></h3>
                            <p align="center">{{'other.ekirjasto.desc' | translate}}</p>
                        </div>
                    </div>
                </div>
                
                <div class="col-6 col-md-4 col-lg-3" >
                    <div class="thumbnail" style="height:300px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="http://www.doria.fi/handle/10024/85119">
                                <img alt="" src="/images/pie_thumb.jpg">
                                {{'other.pien.title' | translate}}</a> </h3>
                            <p align="center">{{'other.pien.desc' | translate}}</p>
                        </div>
                    </div>
                </div>
        
                <div class="col-6 col-md-4 col-lg-3" >
                    <div class="thumbnail" style="height:300px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="http://www.doria.fi/handle/10024/7353">
                                <img alt="" src="/images/ukk_thumb.jpg">
                                {{'other.kekkonen.title' | translate}}</a></h3>
                            <p align="center">{{'other.kekkonen.desc' | translate}}</p>
                        </div>
                    </div>
                </div>
        
                <div class="col-6 col-md-4 col-lg-3" >
                    <div class="thumbnail" style="height:300px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="http://www.doria.fi/handle/10024/66373">
                                <img alt="" src="/images/raita_thumb.jpg">
                                {{'other.raita.title' | translate}}</a></h3>
                            <p align="center">{{'other.raita.desc' | translate}}</p>
                        </div>
                    </div>
                </div>
        
                <div class="col-6 col-md-4 col-lg-3" >
                    <div class="thumbnail" style="height:300px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="http://fragmenta.kansalliskirjasto.fi/">
                                <img alt="" src="/images/frag_thumb.jpg">
                                {{'other.fragmenta.title' | translate}}</a></h3>
                            <p align="center">{{'other.fragmenta.desc' | translate}}</p>
                        </div>
                    </div>
                </div>
        
                <div class="col-6 col-md-4 col-lg-3" >
                    <div class="thumbnail" style="height:300px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="https://fennougrica.kansalliskirjasto.fi/">
                                <img alt="" src="/images/fennougrica_thumb.jpg">
                                {{'other.fennougrica.title' | translate}}</a></h3>
                            <p align="center">{{'other.fennougrica.desc' | translate}}</p>
                        </div>
                    </div>
                </div>
        
                <div class="col-6 col-md-4 col-lg-3" >
                    <div class="thumbnail" style="height:300px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="https://kansalliskirjasto.finna.fi/">
                                <img alt="" src="/images/FNA_tunnus_vaaka_WEB_turk.png" style="padding-top: 12px">
                                {{'other.finna.title' | translate}}</a></h3>
                            <p align="center">{{'other.finna.desc' | translate}}</p>
                        </div>
                    </div>
                </div>
        
                <div class="col-6 col-md-4 col-lg-3">
                    <div class="thumbnail" style="height:300px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="https://www.kansalliskirjasto.fi/fi/lahjoita">
                                <img alt="" src="/images/lahj_thumb.png">
                                {{'other.kerays.title' | translate}}</a></h3>
                            <div align="center" [innerHTML]="'other.kerays.desc' | translate"></div>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-md-4 col-lg-3" >
                    <div class="thumbnail" style="height:300px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="https://www.wdl.org/en/search/?institution=national-library-of-finland#view=gallery">
                                <img alt="" src="/images/wdl_thumb.PNG">
                                {{'other.wdl.title' | translate}}</a></h3>
                            <p align="center">{{'other.wdl.desc' | translate}}</p>
                        </div>
                    </div>
                </div>

                <div class="col-6 col-md-4 col-lg-3" >
                    <div class="thumbnail" style="height:200px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="https://www.europeana.eu/portal/en">
                                <img style="height:120px" alt="" src="/images/logos/EU_basic_logo_portrait_black.png">
                                {{'other.europeana.title' | translate}}</a></h3>
                            <p align="center">{{'other.europeana.desc' | translate}}</p>
                        </div>
                    </div>
                </div>


                <div class="col-6 col-md-4 col-lg-3" >
                    <div class="thumbnail" style="height:300px; background-color: transparent;border:none;">
                        <div class="caption">
                            <h3 align="center"><a href="http://www.doria.fi/handle/10024/4194">
                                <img alt="" src="/images/muita_thumb.jpg">
                                {{'other.lisaa.title' | translate}}</a></h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class OtherComponent {

    constructor(private settingsService: SettingsService) {

    }

    get externalResourcesEnabled() {
        return this.settingsService.commonOptions.includeExternalResources;
    }


}
