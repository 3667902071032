import {AfterViewInit, Directive, ElementRef, HostListener, Input} from "@angular/core";

/**
 * This is a copy of https://www.npmjs.com/package/ngb-modal-draggable, as the original
 * doesn't compile nicely with us. the "ngb"-prefix has just been changed to "app", and a few
 * code-style errors fixed.
 */
@Directive({
    selector: '[appModalDraggable]'
})
export class ModalDraggableDirective implements AfterViewInit {
    private modalElement: HTMLElement;
    private topStart: number;
    private leftStart: number;
    private md: boolean;
    private handleElement: HTMLElement;
    private rootLevel = 2;

    constructor(public element: ElementRef) {
    }

    @Input()
    set appModalDraggableHandle(handle: HTMLElement) {
        this.handleElement = handle;
    }

    @Input()
    set appModalRootLevel(level: number) {
        this.rootLevel = level;
    }

    ngAfterViewInit() {
        let element = this.element.nativeElement;
        for (let level = this.rootLevel; level > 0; level--) {
            element = element.parentNode;
        }

        this.modalElement = element;

        this.modalElement.style.position = 'relative';
        this.modalElement.className += ' cursor-draggable';
    }

    @HostListener('mousedown', ['$event'])
    onMouseDown(event: MouseEvent) {
        if (event.button === 2 || (this.handleElement && event.target !== this.handleElement))
            return; // prevents right click drag, remove this if you don't want it
        this.md = true;
        this.topStart = event.clientY - Number(this.modalElement.style.top.replace('px', ''));
        this.leftStart = event.clientX - Number(this.modalElement.style.left.replace('px', ''));
        event.preventDefault();
    }

    @HostListener('document:mouseup', ['$event'])
    onMouseUp(event: MouseEvent) {
        this.md = false;
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
        if (this.md) {
            this.modalElement.style.top = (event.clientY - this.topStart) + 'px';
            this.modalElement.style.left = (event.clientX - this.leftStart) + 'px';
        }
    }

    @HostListener('document:mouseleave', ['$event'])
    onMouseLeave(event: MouseEvent) {
        this.md = false;
    }
}
