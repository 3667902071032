import {Component, EventEmitter, Input, Output} from "@angular/core";

/**
 * This component is a wrapper for NgbPagination, because the Ngb version uses ChangeDetectionStrategy.OnPush, which
 * is buggy with downgradeComponent(...).
 *
 * See https://github.com/angular/angular/issues/14286
 */
@Component({
    template: `
        <ngb-pagination [page]="page" [collectionSize]="collectionSize" [pageSize]="pageSize" [maxSize]="7"
                [ellipses]="false" (pageChange)="onPageChanged($event)"></ngb-pagination>
    `
})
export class PaginationComponent {
    @Input() page: number;
    @Input() collectionSize: number;
    @Input() pageSize: number;
    @Output() pageChange = new EventEmitter<number>();

    onPageChanged(page: number) {
        // The NgbComponent emits NaN values on init. We don't care for those.
        if (!isNaN(page))
            this.pageChange.emit(page);
    }
}
