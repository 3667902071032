import {Component} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-session-expired",
    template: `
        <div class="modal-header">
            <h4 class="modal-title" translate>session.expired.title</h4>
            <button type="button" class="close" (click)="activeModal.dismiss('cross-click')" aria-hidden="true">&times;</button>
        </div>
        <div class="modal-body">
            <p>
                {{'session.expired.content' | translate}} <a href="" (click)="relog($event)" translate>session.expired.link</a>.
            </p>
        </div>
    `
})
export class SessionExpiredComponent {

    constructor(public activeModal: NgbActiveModal) {
    }

    relog($event: Event) {
        $event.preventDefault();
        this.activeModal.close(true);
    }
}
