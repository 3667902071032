import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import {BindingService} from "./binding.service";

import {DocumentService} from "../document.service";
import {SearchDrawerService} from "../nav/search-drawer.service";
import {ActivatedRoute} from "@angular/router";
import {asArray} from "../../search/request-param-utils";
import {NavigationService} from "../navigation.service";
import {parseQueryString} from "../../utils/url";
import {BINDING_VIEW, BindingViewParams, BindingViewState, ClippingMode, ICurrentBindingView, NonUrlState, PageRequest} from "../../binding/types";
import {CurrentBindingView} from "./current-binding-view";
import {filterNonNull, Subscriptions} from "../../utils/observable-utils";
import {BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";
import {BindingAndAvailability, BindingSearchRestEndpoint, DebugHighlighterType, GeneralType, PageRequestSource} from "../../apina-digiweb";
import {BehaviorSubject, combineLatest, Observable} from "rxjs";
import {map, take} from "rxjs/operators";
import {DisplayService} from "../display.service";
import {ExpandDirection} from "./sidebar/sidebar-handle.component";

const PARAM_PAGE = 'page';
const PARAM_OCR = 'ocr';
const PARAM_OLD_OCR = 'oldOcr';
const PARAM_OCR_BORDERS = 'ocrBorders';
const PARAM_MARC = 'marc';
const PARAM_NER_LOCATIONS = 'ner-loc';
const PARAM_NER_PERSONS = 'ner-per';
const PARAM_TERM = 'term';
export const ONETIME_PARAM_SEEK_WITHIN = 'seekTermsWithin';


// What are these? Search this file for 'something completely different' to get an answer.
const originalPath = location.pathname;
const originalParams = parseQueryString(location.search);

enum SidebarType {
    CLIPPING_FORM,
    REDACTION_FORM,
    PRINT_FORM,
    AGREEMENT_INFO,
    MARC_INFO,
    NEWSPAPER_INFO,
    OCR,
    COMPONENT_TREE,
    COMPONENT_PARTS,
    SEARCH_INSIDE,
    CITATION_INFO,
    BINDING_PROBLEMS,
    HIDE_BINDING,
}

interface ViewData {
    bi: BindingAndAvailability;
    bvs: BindingViewState;
    state2: NonUrlState;
    clipMode: ClippingMode;

    enabledSidebars: Map<SidebarType, boolean>;
    sidebarVisible: boolean;
}

@Component({
    selector: "app-binding-view",
    template: `
        <div class="binding-view" *ngIf="vd$ | async as vd" [style.top.px]="displayService.headerVisibleHeight | async">

            <div [style.grid-area]="'main'" [style.position]="'relative'">
                <app-page-change></app-page-change>
                <app-binding-page-view *ngIf="vd.bi.isAvailable" ></app-binding-page-view>

                <app-binding-inaccessible *ngIf="!vd.bi.isAvailable"
                                          [citationInfo]="vd.bi.bindingInformation.citationInfo"></app-binding-inaccessible>

                <app-binding-restricted-warning [enabled]="vd.bi.isAvailable && (view.isRestrictedMaterialWarningVisible$ | async)"></app-binding-restricted-warning>
            </div>
            
            <div [style.grid-area]="'sidebar-handle'" *ngIf="vd.sidebarVisible">
                <app-sidebar-handle [expandDirection]="ExpandDirection.LEFT"></app-sidebar-handle>
            </div>
            
            <div *ngIf="vd.bvs as state" class="sidebar" [style.grid-area]="'sidebar'">
                
                <app-clipping-form *ngIf="vd.enabledSidebars.get(SidebarType.CLIPPING_FORM)"></app-clipping-form>
                
                <app-redaction-form *ngIf="vd.enabledSidebars.get(SidebarType.REDACTION_FORM)"></app-redaction-form>
                
                <app-print-clipping-form *ngIf="vd.enabledSidebars.get(SidebarType.PRINT_FORM)"></app-print-clipping-form>
                
                <app-binding-agreement-info *ngIf="vd.enabledSidebars.get(SidebarType.AGREEMENT_INFO)" 
                                            [bindingId]="vd.bi.bindingInformation.id" 
                                            (closeDialog)="view.toggleAgreements()"></app-binding-agreement-info>
                
                <app-binding-marc-text *ngIf="vd.enabledSidebars.get(SidebarType.MARC_INFO)" 
                                       [componentPart]="vd.state2.componentPart"></app-binding-marc-text>

                <app-newspaper-info *ngIf="vd.enabledSidebars.get(SidebarType.NEWSPAPER_INFO)"
                                    [issn]="vd.bi.bindingInformation.citationInfo.issn"></app-newspaper-info>

                <app-component-tree *ngIf="vd.enabledSidebars.get(SidebarType.COMPONENT_TREE)" 
                                    [bindingId]="vd.bi.bindingInformation.id"
                                    (closeDialog)="view.toggleComponentTree()"></app-component-tree>

                <app-component-part-list *ngIf="vd.enabledSidebars.get(SidebarType.COMPONENT_PARTS)" 
                                         [bindingId]="vd.bi.bindingInformation.id"
                                         (closeDialog)="view.toggleToc(); view.toggleParts()"></app-component-part-list>

                <app-binding-ocr-text *ngIf="vd.enabledSidebars.get(SidebarType.OCR)"></app-binding-ocr-text>

                <app-search-inside-binding *ngIf="vd.enabledSidebars.get(SidebarType.SEARCH_INSIDE)" 
                                           [bindingId]="vd.bi.bindingInformation.id"></app-search-inside-binding>
                
                <app-binding-view-citation-info *ngIf="vd.enabledSidebars.get(SidebarType.CITATION_INFO)" 
                                                (closeDialog)="view.toggleCitation()"
                                                [data]="vd.bi.bindingInformation.citationInfo"
                                                [currentPageNumber]="view.loadedPageNumber$ | async"></app-binding-view-citation-info>
                
                <app-binding-problems *ngIf="vd.enabledSidebars.get(SidebarType.BINDING_PROBLEMS)" 
                                      [bindingId]="vd.bi.bindingInformation.id"></app-binding-problems>
                
                <app-hide-binding *ngIf="vd.enabledSidebars.get(SidebarType.HIDE_BINDING)" 
                                  [bindingId]="vd.bi.bindingInformation.id"></app-hide-binding>
            </div>
            
            <app-binding-toolbar [style.grid-area]="'toolbar'"></app-binding-toolbar>
        </div>
    `,
    providers: [
        BindingService,
        {provide: BINDING_VIEW, useClass: CurrentBindingView}
    ],
    styleUrls: [
        "./binding-view.scss"
    ]
})
export class BindingViewComponent implements OnInit, OnDestroy {
    
    SidebarType = SidebarType;
    ExpandDirection = ExpandDirection;
    
    constructor(public bindingService: BindingService,
                @Inject(BINDING_VIEW) public view: ICurrentBindingView,
                private navigationService: NavigationService,
                documentService: DocumentService,
                public readonly displayService: DisplayService,
                searchDrawerService: SearchDrawerService,
                route: ActivatedRoute,
                breadcrumbsService: BreadcrumbsService,
                bindingSearchRestEndpoint: BindingSearchRestEndpoint) {

        const bindingId = +route.snapshot.params.bindingId;
        const articleIdStr = route.snapshot.params.articleId;
        const articleId = articleIdStr != null ? +articleIdStr : null;
        const locationParams = this.navigationService.search;

        this.params = {
            id: bindingId,
            articleId
        };

        const seekWithin = locationParams[ONETIME_PARAM_SEEK_WITHIN];

        const pageFromUrl = parseInt(locationParams[PARAM_PAGE], 10) || null;
        
        const pageRequestSource = !!seekWithin ? PageRequestSource.COMPONENT_PART__SEARCH_RESULT : null;
        
        this.initialState = {
            page: null, /* page is not loaded before first pageRequest */ 
            oldOcr: /true/i.test(locationParams[PARAM_OLD_OCR]),
            ocrBorders: /true/i.test(locationParams[PARAM_OCR_BORDERS]),
            displayOcr: /true/i.test(locationParams[PARAM_OCR]),
            displayMarc: /true/i.test(locationParams[PARAM_MARC]),
            displayNerLocations: /true/i.test(locationParams[PARAM_NER_LOCATIONS]),
            displayNerPersons: /true/i.test(locationParams[PARAM_NER_PERSONS]),
            searchTerms: asArray(locationParams[PARAM_TERM]),
        };

        view.setSearchTerms(this.initialState.searchTerms);
        
        searchDrawerService.setParameters({
            tab: "titles"
        });

        this.subs.add(this.bindingService.bindingInformation$.subscribe(data => {
            documentService.setContentTitle(data.bindingInformation.title);
            breadcrumbsService.setForBindingView(data.bindingInformation);
        }));

        let initialState = true;
        
        const hasSearchTerms = this.initialState.searchTerms.length > 0;
        if (seekWithin && hasSearchTerms) {
            // XXX performing a new elastic index search at this point creates some lag with component part pages
            console.log("Found 'seekTermsWithin' parameter, searching for terms in " + seekWithin, seekWithin);
            bindingSearchRestEndpoint.searchInsideBinding({
                exactWords: false,
                currentPage: 1, // TODO irrelevant?
                bindingId,
                query: this.initialState.searchTerms.join(" "),
                pages: seekWithin
            }, 0, 1, DebugHighlighterType.AUTO).subscribe((result) => {
                const firstResult = result.pageRows[0];
                if (firstResult) {
                    console.log(`Found first term in page ${firstResult.pageNumber}, loading page...`);
                    this.initialPageRequest$.next(new PageRequest(firstResult.pageNumber, pageRequestSource));
                }
            });
        } else {
            this.initialPageRequest$.next(new PageRequest(pageFromUrl, pageRequestSource));
        }

        this.subs.add(this.view.bindingViewState$.subscribe(state => {
            if (state != null) {
                this.updateLocation(state, initialState);
                initialState = false;
            }
        }));
        
        this.vd$ = combineLatest([this.bindingService.bindingInformation$, this.view.bindingViewState$, this.view.nonUrlState$, this.view.clippingMode$]).pipe(
            map(([bi, state, state2, clipMode]) => {
                const ss = new Map<SidebarType, boolean>();
                ss.set(SidebarType.CLIPPING_FORM, clipMode === ClippingMode.ARTICLE);
                ss.set(SidebarType.REDACTION_FORM, clipMode === ClippingMode.REDACTION);
                ss.set(SidebarType.PRINT_FORM, clipMode === ClippingMode.PRINT);
                ss.set(SidebarType.AGREEMENT_INFO, state2.displayAgreements);
                ss.set(SidebarType.MARC_INFO, state.displayMarc && bi.bindingInformation.generalType !== GeneralType.NEWSPAPER);
                ss.set(SidebarType.NEWSPAPER_INFO, state.displayMarc && bi.bindingInformation.generalType === GeneralType.NEWSPAPER);
                ss.set(SidebarType.OCR, state.displayOcr);
                ss.set(SidebarType.COMPONENT_TREE, state2.displayComponentTree);
                ss.set(SidebarType.COMPONENT_PARTS, state2.displayParts);
                ss.set(SidebarType.SEARCH_INSIDE, state2.displaySearch);
                ss.set(SidebarType.CITATION_INFO, state2.displayCitation);
                ss.set(SidebarType.BINDING_PROBLEMS, state2.displayBindingProblemDialog);
                ss.set(SidebarType.HIDE_BINDING, state2.displayHideBindingDialog);
                
                let sidebarVisible = false;
                for (const enabled of ss.values()) {
                    if (enabled) {
                        sidebarVisible = true;
                        break;
                    }
                }

                return {
                    bi,
                    bvs: state,
                    state2,
                    clipMode,
                    enabledSidebars: ss,
                    sidebarVisible
                }
            })
        );
    }

    private subs = new Subscriptions();

    private readonly params: BindingViewParams;
    private readonly initialState: BindingViewState;
    private readonly initialPageRequest$ = new BehaviorSubject<PageRequest>(null);
    
    readonly vd$: Observable<ViewData>;
    
    updateLocation(state: BindingViewState, replaceState: boolean) {
        const params = {
            [PARAM_TERM]: state.searchTerms,
            [PARAM_PAGE]: state.page,
            [PARAM_OCR]: state.displayOcr ? "true" : null,
            [PARAM_MARC]: state.displayMarc ? "true" : null,
            [PARAM_NER_LOCATIONS]: state.displayNerLocations ? "true" : null,
            [PARAM_NER_PERSONS]: state.displayNerPersons ? "true" : null,
            [PARAM_OLD_OCR]: state.oldOcr ? "true" : null,
            [PARAM_OCR_BORDERS]: state.ocrBorders ? "true" : null
        };
        this.navigationService.setSearch(params, replaceState);
    }

    ngOnInit() {
        const viewInit$ = this.view.initialize(this.params, this.initialState);
        
        combineLatest([viewInit$, this.initialPageRequest$.pipe(filterNonNull, take(1))]).subscribe(([a, b]) => {
            this.view.goToPage(b);
        });

        //
        // And now something completely different...
        //
        // Our legacy URLs had both actual URL params (such as search terms) and hash-params such as
        // the current page. So URL could have the form (/sanomalehti/binding/558384?term=a#?page=2).
        // We changed the URLs to use HTML5 history to always use actual params, because Google would
        // not consider hash-parameters for indexing.
        //
        // Now this causes some problems, because Angular tries really hard to automatically convert
        // MSIE-compatible fallback URLs to canonical HTML5 URLs without hash-parameters. Since we
        // have some content after hash, Angular will ignore anything before it and destroy stuff
        // that we actually care about. (Note we don't produce URLs like this anymore, but users have
        // bookmarked these funky URLs from olden days.)
        //
        // See http://jira.kansalliskirjasto.fi/browse/DIGI-1935 for more details.
        //
        // Anyhow, what are we to do? We'll try to recover some sanity by restoring stuff selectively
        // from original location that we safely tucked away before Angular managed to get its dirty
        // fingers into our location. Note that just restoring arbitrary parameters won't work because
        // it would break Angular's current functionality. We need to be selected and hard-code only
        // for some specific parameter combinations that are seem in the wild.
        //
        /*
        const bindingIdStr = this.bindingId.toString();
        if (originalPath.lastIndexOf(bindingIdStr) === originalPath.length - bindingIdStr.length) {
            // It seems that there's some rewriting going on. To the rescue!
            // TODO is this necessary anymore? Does Angular rewrite something?
            // this.navigationService.path = bindingIdStr;

            // Ok, now let's restore selected parameters. If the original URL had terms,
            // but our current version does not have terms, restore the terms. However,
            // if the current version has any terms, we'll take that as an authoritative answer.
            const originalTerms = asArray(originalParams[PARAM_TERM]);
            if (originalTerms.length && !this.terms.length) {
                const locationParams = this.navigationService.search;
                locationParams[PARAM_TERM] = this.terms = originalTerms;
                this.navigationService.search(locationParams);
            }
        }*/
    }

    ngOnDestroy(): void {
        this.subs.unsubscribeAll();
    }
}
