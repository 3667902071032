import {Component, OnDestroy, ViewChild} from "@angular/core";
import {BindingSearchRestEndpoint, CollectionDefaultPageDto, CollectionRestEndpoint, CollectionTreeNodeDTO} from "../../apina-digiweb";
import {SettingsService} from "../settings.service";
import {NavigationService} from "../navigation.service";
import {BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";
import {DisplayService} from "../display.service";
import {MatSidenav} from "@angular/material/sidenav";
import {MatTreeNestedDataSource} from "@angular/material/tree";
import {BehaviorSubject, Subscription} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {filter, switchMapTo} from "rxjs/operators";

const URL_PARAM_ID = "id";

@Component({
    template: `
        <div *ngIf="initialized$ | async else loading">
            <div *ngIf="selectedId == null">
                <div class="container mt-4 mb-5">
                    <h2 translate>collections.page-title</h2>
                    <app-collection-tree [dataSource]="dataSource" [selectedId]="selectedId"
                                         (selectNode)="onSelectCollection($event)"></app-collection-tree>
                </div>
    
                <div *ngIf="defaultPage" class="banner kk-bg-dark" [ngStyle]="{'background-image': 'url(' + defaultPage.bannerUrl + ')'}">
                    <div class="gradient-teal">
                        <div class="container">
                            <h1>{{defaultPage.name}}</h1>
                            <div class="collection-description" [innerHTML]="defaultPage.description"></div>
                        </div>
                    </div>
                </div>
            </div>
    
            <div *ngIf="selectedId != null">
                <app-view-collection [collectionId]="selectedId"></app-view-collection>
            </div>
        </div>
        
        <ng-template #loading>
            <app-progress-bar></app-progress-bar>
        </ng-template>
    `,
    styleUrls: [
        "./banner.scss"
    ]
})
export class BrowseCollectionsComponent implements OnDestroy {

    @ViewChild("sidenav", {static: true}) sidenav: MatSidenav;

    sidenavInitiallyOpen = false;

    defaultPage: CollectionDefaultPageDto;

    initialized$ = new BehaviorSubject<boolean>(false);

    dataSource = new MatTreeNestedDataSource<CollectionTreeNodeDTO>();
    selectedId: number;

    private sub = new Subscription();

    constructor(private readonly collectionsApi: CollectionRestEndpoint,
                private readonly settingsService: SettingsService,
                private readonly bindingSearchApi: BindingSearchRestEndpoint,
                private readonly navigationService: NavigationService,
                public readonly displayService: DisplayService,
                readonly breadcrumbs: BreadcrumbsService,
                readonly activatedRoute: ActivatedRoute) {

        breadcrumbs.setLocalizedText('kirja.main.links.collection');

        this.sub.add(this.initialized$.pipe(
            filter(a => a),
            switchMapTo(activatedRoute.queryParams)
        ).subscribe((params) => {
            this.selectedId = +params[URL_PARAM_ID] || undefined;
        }));

        collectionsApi.getData().subscribe(response => {
            this.defaultPage = response.defaultPage;
            this.dataSource.data = response.tree;

            this.initialized$.next(true);
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    onSelectCollection($event: CollectionTreeNodeDTO) {
        this.selectedId = $event.id;
        this.navigationService.setSearchParam(URL_PARAM_ID, $event.id);
    }
}
