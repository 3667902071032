import {Component, EventEmitter, Inject, OnDestroy, OnInit, Output} from "@angular/core";
import {
    ArticleInfo,
    ArticleRestEndpoint,
    EditUserArticleDto,
    ReferenceDataRestEndpoint,
} from "../../apina-digiweb";
import {
    BINDING_VIEW,
    IClipping,
    ICurrentBindingView,
    IPageInformation,
    RegionTransformHandle
} from "../../binding/types";
import {CookieService} from "ngx-cookie-service";
import {LoggingService} from "../logging.service";
import {TagService} from "../tag.service";
import {ITag, PersistedTag} from "../common/tag-select.component";
import {Observable, of, Subscription} from "rxjs";
import {distinctUntilChanged, map, shareReplay, switchMap, take, tap} from "rxjs/operators";
import {FormControl, FormGroup} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";

const COOKIE_NAME = 'kkArticleMetadata';

// TODO port null-as-empty

@Component({
    selector: "app-clipping-form",
    template: `
        <app-sidebar>
            <app-sidebar-header titleKey="clipping.mark-clipping" (closeSidebar)="cancel()"></app-sidebar-header>
            <app-sidebar-content>
                <div class="overlay-form">
                    <form name="metadataForm" role="form" #f="ngForm">
                        <label for="metadataForm">
                            <div style="color:yellow" [innerHTML]="'clipping.special-info'|translate"></div>
                        </label>
                        <div class="form-group">
                            <label for="title"><span translate>user-article.title</span> *</label>

                            <button type="button" class="clear-field btn btn-kk-blue btn-sm float-right" (click)="clearTitle()"
                                    [disabled]="!metadata.title"
                                    title="{{'user-article.clear-field' | translate}}"><i class="fa fa-times fa-md"></i>
                            </button>

                            <input type="text" class="form-control" id="title"
                                   placeholder="{{'user-article.title' | translate}}"
                                   [(ngModel)]="metadata.title" name="title" required>
                        </div>

                        <div class="form-group">
                            <label><span translate>user-article.subject</span></label>

                            <!-- TODO tooltips -->
                            <select name="subject" [(ngModel)]="metadata.subject" null-as-empty class="form-control">
                                <option value="">-</option>
                                <option *ngFor="let subject of clippingSubjects"
                                        [value]="subject.id">{{subject | translateMultiNamed}}</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="genre"><span translate>user-article.category</span> *</label>

                            <!-- TODO tooltips -->
                            <select id="genre" name="category" [(ngModel)]="metadata.category" class="form-control">
                                <option value="">-</option>
                                <option *ngFor="let category of clippingCategories"
                                        [value]="category.id">{{category | translateMultiNamed}}</option>
                            </select>
                        </div>

                        <div class="form-group come-closer">
                            <label for="inputKeywords" translate>user-article.keywords</label>
                            
                            <button type="button" class="btn btn-sm btn-kk-light float-right" (click)="showAnnifForm = !showAnnifForm"
                                    [disabled]="this.cbv.clippingsSnapshot.length == 0"
                                    translate>annif-keywords.download-keywords
                            </button>
                            <form [formGroup]="annifForm" *ngIf="showAnnifForm  && this.cbv.clippingsSnapshot.length > 0" class="border border-white my-2">
                                <div class="form-group" class="m-2">
                                    <h5 class="tip" translate>annif-keywords.title</h5>
                                    <label for="selectedProject"><span translate>annif-keywords.vocabulary</span></label>

                                    <!-- TODO tooltips -->
                                    <select formControlName="selectProject" id="selectedProject" (change)="onFintoProjectChange()" class="form-control">
                                        <option *ngFor="let project of fintoProjects"
                                                [value]="project.project_id">{{project.name}}</option>
                                    </select>
                                </div>
                                <div class="form-group" class="m-2">
                                    <button class="btn btn-sm btn-kk-light btn-block d-flex justify-content-center" [disabled]="!selectedProject || downloadingAnnifKeywords" (click)="getAnnifKeywords(selectedProject)" [value]="downloadText()">
                                        <span translate>annif-keywords.download-keywords</span>
                                        <app-progress-spinner *ngIf="downloadingAnnifKeywords" [size]="'XX-SMALL'"></app-progress-spinner>
                                    </button>
                                </div>
                            </form>
                            <app-tag-select [referenceData]="keywordOptions" [ngModel]="keywords" (ngModelChange)="updateKeywords($event)" name="keywords"
                                            [placeholder]="'user-article.keywords.placeholder' | translate"></app-tag-select>
                            <label *ngIf="restrictedKeywords"><span translate>user-article.keywords.translocalis-restricted</span></label>
                        </div>
                        
                        <div class="d-flex justify-content-between align-items-center">
                            <button type="button" class="btn btn-kk-light fa fa-copy fa-lg mr-1" 
                                    (click)="copyMetadata()" ngbTooltip="{{'clipping.copy.tooltip' | translate}}"></button>

                            <button type="button" class="btn btn-kk-light fa fa-paste fa-lg mr-auto" 
                                    (click)="pasteMetadata()" ngbTooltip="{{'clipping.paste.tooltip' | translate}}"
                                    [disabled]="cbv.saving || !metadataAvailable()">
                            </button>
                            
                            <button type="button" class="btn btn-kk-blue mr-1" (click)="cancel()"
                                    [disabled]="cbv.saving" translate>common.action.cancel
                            </button>
                            <button class="btn btn-kk-light d-flex align-items-center" (click)="save()"
                                    [disabled]="restrictedKeywords || cbv.saving || metadata.category == null || (hasActiveClippings$ | async) === false">
                                <span translate>common.action.save</span>
                                <app-progress-spinner *ngIf="cbv.saving" [size]="'XX-SMALL'"></app-progress-spinner>
                            </button>
                        </div>
                    </form>
                </div>

            </app-sidebar-content>
        </app-sidebar>
    `
})
export class ClippingFormComponent implements OnInit, OnDestroy {

    @Output() saved = new EventEmitter<ArticleInfo>();
    
    constructor(@Inject(BINDING_VIEW) public cbv: ICurrentBindingView,
                private articleRest: ArticleRestEndpoint,
                private referenceDataRest: ReferenceDataRestEndpoint,
                private cookieService: CookieService,
                private log: LoggingService,
                private tagService: TagService,
                private translateService: TranslateService) {

        this.annifForm = new FormGroup({
            selectProject: new FormControl('selectProject')
        });

        this.getFintoAiProjects(this.fintoAiProjectsUrl).then((result: boolean) => {
            if (result) {
                this.selectedProject = this.fintoProjectsByLang[0].project_id;
                this.annifForm.get("selectProject").setValue(this.selectedProject, {onlySelf: true});
            }
        });
    }

    private sub = new Subscription();

    articleId: number = null;
    collapsed = false;
    restrictedKeywords = false;
    metadata: EditUserArticleDto = {
        category: undefined,
        clippings: [],
        description: "",
        language: undefined,
        rawKeywords: [],
        subject: undefined,
        title: ""
    };
    clippingSubjects: any[];
    clippingCategories: any[];
    currentRegionsOCR: string[];

    annifForm: FormGroup;
    showAnnifForm: boolean;
    downloadingAnnifKeywords:boolean;
    fintoDigiLangMappings:{[key: string]: string}  = {fi: "fi", sv: "sv", en: "en"};
    RISDigiLangMappings:{[key: string]: string}  = {fin: "fi", swe: "sv", eng: "en"};
    fintoProjects: { project_id: string, name: string , language: string}[] = [];
    fintoProjectsByLang: { project_id: string, name: string , language: string}[] = [];
    selectedProject:string;
    selectedProjectForLanguage:boolean;
    language:string;
    fintoAiProjectsUrl = "https://ai.finto.fi/v1/projects";

    // caches metadata.rawKeywords in Set for tag-select component
    keywords = new Set<ITag>();

    hasActiveClippings$ = this.cbv.clippings$.pipe(map(a => a.length > 0), distinctUntilChanged());

    keywordOptions = this.tagService.findTags();

    ngOnInit(): void {
        this.sub.add(this.referenceDataRest.getClippingReferenceData()
            .subscribe((result) => {
                this.clippingSubjects = result.subjects;
                this.clippingCategories = result.categories;
            }));

        this.sub.add(this.cbv.bindingInfo$.subscribe(data => {
            const edit = data.edit;
            const citation = data.citationInfo;

            if (edit) {
                this.articleId = edit.articleId;
                this.metadata = edit.clipping;
                this.readKeywordsFromModel();
            }

            if (citation) {
                this.language = this.RISDigiLangMappings[citation.rISLanguage];

                if (!this.language) {
                    // default to fin, if language cannot be get from RISDigiLangMappings
                    this.language = this.RISDigiLangMappings.fin;
                }
            }
            else {
                // default to fin, if language cannot be get from citationInfo for some reason
                this.language = this.RISDigiLangMappings.fin;
            }
        }));
    }

    writeKeywordsToModel() {
        const result: string[] = [];

        this.keywords.forEach(val => {
            result.push(PersistedTag.serialize(val));
        });

        this.metadata.rawKeywords = result;
    }

    readKeywordsFromModel() {
        const result = new Set<ITag>();

        for (const kw of this.metadata.rawKeywords) {
            result.add(PersistedTag.deserialize(kw));
        }

        this.keywords = result;
    }

    cancel() {
        this.cbv.cancelClipping();
    }

    metadataAvailable(): boolean {
        return this.cookieService.check(COOKIE_NAME);
    }

    copyMetadata() {
        const metadata = this.metadata;
        this.writeKeywordsToModel();

        const value = JSON.stringify({
            title: metadata.title,
            category: metadata.category,
            subject: metadata.subject,
            keywords: metadata.rawKeywords
        });

        this.cookieService.set(COOKIE_NAME, value, 7, '/', null, true, "Strict");
    }

    pasteMetadata() {
        if (this.metadataAvailable()) {
            try {
                const cookieMetadata = JSON.parse(this.cookieService.get(COOKIE_NAME));

                const metadata = this.metadata;

                metadata.title = cookieMetadata.title;
                metadata.category = cookieMetadata.category;
                metadata.subject = cookieMetadata.subject;
                metadata.rawKeywords = cookieMetadata.keywords;

                this.readKeywordsFromModel();
            } catch (e) {
                this.log.error("Error while reading article metadata from cookie", e);
            }
        }
    }

    updateKeywords(keywords: Set<PersistedTag>): void {
        this.keywords = keywords;
        
        for (const tag of keywords) {
            if (tag.value.toLowerCase().includes("translocalis")) {
                this.restrictedKeywords = true;
                return;
            }
        }
        
        this.restrictedKeywords = false;
    }
    
    clearTitle() {
        this.metadata.title = "";
    }

    fetchOcrTextForRegions(): Observable<string[]> {
        class IClippingWithId implements IClipping {

            id: number;

            constructor(public readonly info: IClipping) {
                this.height = info.height;
                this.width = info.width;
                this.x = info.x;
                this.y = info.y;
                this.pageNumber = info.pageNumber;
            }

            height: number;
            pageNumber: number;
            width: number;
            x: number;
            y: number;

            contains(px: number, py: number): boolean {
                return false;
            }

            move(type: RegionTransformHandle, dx: number, dy: number, page: IPageInformation): void {
            }

            updateTo(x: number, y: number, width: number, height: number): void {
            }
        }

        const regionsData:IClippingWithId[] = this.cbv.clippingsSnapshot.map((region) => new IClippingWithId(region));

        return this.articleRest.fetchOcrTextForRegions(this.cbv.bindingId, regionsData).pipe(
            shareReplay(1),
            tap((result) => {
                return result;
            })
        );
    }

    async getFintoAiProjects(url: string) {
        try {
            const res = await fetch(url);
            if (res.ok) {
                const resultText = await res.text();
                const resultJson = JSON.parse(resultText);

                resultJson.projects.forEach((project: { language: string, name: string, project_id: string; }, index: number) => {
                    if (project.language in this.fintoDigiLangMappings) {
                        this.fintoProjects.push({name: project.name, project_id: project.project_id, language: project.language});
                    }
                });

                // filter fintoProjects by languge that the current binding is using.
                this.fintoProjectsByLang = this.fintoProjects.filter(x => x.language === this.language);

                // If there were no projects by the current binding language use the original finto projects order
                if (this.fintoProjectsByLang.length === 0){
                    this.fintoProjectsByLang = this.fintoProjects;
                }

                return true;
            }
            else {
                // network error in the 4xx–5xx range
                alert("Odottamaton virhe Annif sanastojen tietojen latauksessa.");
                return false;
            }
        } catch (e) {
            alert("Odottamaton virhe Annif sanastojen tietojen latauksessa.");
            console.log(e);
            return false;
        }
    }

    async getAnnifKeywords(projectId: string) {
        this.downloadingAnnifKeywords = true;
        this.fetchOcrTextForRegions().subscribe(async result => {
            try {
                this.currentRegionsOCR = result;
                const keywordsUrl = this.fintoAiProjectsUrl + "/" + projectId + "/suggest";

                const rawResponse = await fetch(keywordsUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                    },
                    body:  new URLSearchParams({
                        limit: '10',
                        threshold: '0',
                        language:this.fintoDigiLangMappings[this.translateService.currentLang],
                        text: this.currentRegionsOCR.join()
                    }).toString()
                });

                if (rawResponse.ok) {
                    const resultJson = await rawResponse.json();

                    resultJson.results.forEach((keywordResult: { label: string }) => {
                        const currentKeyword = new PersistedTag('KEYWORD', keywordResult.label);
                        let newKeyword = true;

                        this.keywords.forEach((keyword) => {
                            if (keyword.value === currentKeyword.value) {
                                newKeyword = false;
                            }
                        });

                        if (newKeyword) {
                            this.keywords.add(currentKeyword);
                        }
                    });
                }
                else {
                    // network error in the 4xx–5xx range
                    alert("Odottamaton virhe leikkeen Annif-asiasanojen latauksessa.");
                }

                this.downloadingAnnifKeywords = false;

            } catch (e) {
                alert("Odottamaton virhe leikkeen Annif-asiasanojen latauksessa.");
                this.downloadingAnnifKeywords = false;
                console.log(e);
            }
        }, response => {
            this.downloadingAnnifKeywords = false;
            if (response.status === 401) {
                alert("Not authorized to download clippings text. Have you logged in?");
            }
            else {
                alert("Odottamaton virhe leikkeen tekstin latauksessa.");
                this.log.error("Unexpected error when downloading clippings text:", response);
            }
        });
    }

    onFintoProjectChange() {
        this.selectedProject = this.annifForm.get("selectProject").value;
    }

    downloadText() {
        return this.translateService.instant("annif-keywords.download-keywords");
    }

    save() {
        const clippingsSnapshot = this.cbv.clippingsSnapshot;

        this.writeKeywordsToModel();
        const metadata = this.metadata;

        const data: EditUserArticleDto = {
            clippings: clippingsSnapshot,
            category: metadata.category,
            subject: metadata.subject,
            language: metadata.language,
            title: metadata.title,
            description: metadata.description,
            rawKeywords: metadata.rawKeywords
        };

        // TODO does our view notice this?
        this.cbv.saving = true;

        of(this.cbv.editExisting).pipe(
            switchMap(editExisting => editExisting ?
                this.articleRest.updateArticle(this.articleId, data) :
                this.articleRest.createArticle(this.cbv.bindingId, data)
            ),
            take(1)
        ).subscribe((result) => {
            this.cbv.saving = false;
            this.cbv.clippingFinished(result);

        }, response => {
            this.cbv.saving = false;
            if (response.status === 401) {
                alert("Not authorized to save. Have you logged in?");
            } else if (response.status === 406) {
                alert("Not authorized to save. Used reserved keyword 'translocalis'");
            } else {
                alert("Odottamaton virhe tallennuksessa.");
                this.log.error("Unexpected error when saving:", response);
            }
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}