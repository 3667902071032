import {Component, ElementRef} from "@angular/core";

const SERVER_CONTENT_CONTAINER_ID = "kk-server-page";

@Component({
    template: `
        <div></div>
    `
})
export class FallbackComponent {

    constructor(elem: ElementRef) {
        const serverPage: HTMLElement = document.getElementById(SERVER_CONTENT_CONTAINER_ID);

        elem.nativeElement.appendChild(serverPage);
        serverPage.removeAttribute("style"); // remove "display: none;", making it visible
    }
}
