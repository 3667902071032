import {TranslateService} from "@ngx-translate/core";

export function configureTranslateService(translate: TranslateService, currentLanguage: string) {
    if (currentLanguage)
        translate.use(currentLanguage);
    translate.setDefaultLang("fi");

    translate.setTranslation("fi", require('./properties-loader!../../../../../common-services/src/main/resources/messages/digiweb_messages_fi.properties'));
    translate.setTranslation("en", require('./properties-loader!../../../../../common-services/src/main/resources/messages/digiweb_messages_en.properties'));
    translate.setTranslation("sv", require('./properties-loader!../../../../../common-services/src/main/resources/messages/digiweb_messages_sv.properties'));
}
