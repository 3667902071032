import {IClipping, IPageInformation} from './types';
import {Observable, Subject} from "rxjs";

const minimumSize = 20;

function closeTo(a: number, b: number, isInside: boolean) {
    const sensitivity = isInside ? 18 : 2;
    return Math.abs(a - b) < sensitivity;
}

export class ClippingRect implements IClipping {

    changed = new Subject<void>();

    constructor(public pageNumber: number,
                public x: number,
                public y: number,
                public width: number,
                public height: number) {
    }

    updateTo(x: number, y: number, width: number, height: number) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.changed.next();
    }

    contains(px: number, py: number) {
        return px >= this.x && px <= (this.x + this.width) && py >= this.y && py <= (this.y + this.height);
    }

    match(px: number, py: number) {
        const inside = this.contains(px, py);
        const closeToTop = closeTo(py, this.y, inside);
        const closeToBottom = closeTo(py, this.y + this.height, inside);
        const closeToLeft = closeTo(px, this.x, inside);
        const closeToRight = closeTo(px, this.x + this.width, inside);

        if (closeToTop) {
            if (closeToLeft) {
                return "corner-top-left";
            }

            if (closeToRight) {
                return "corner-top-right";
            }
        }

        if (closeToBottom) {
            if (closeToLeft) {
                return "corner-bottom-left";
            }

            if (closeToRight) {
                return "corner-bottom-right";
            }
        }

        if (px >= this.x && px <= (this.x + this.width)) {
            if (closeToTop) {
                return "border-top";
            }
            if (closeToBottom) {
                return "border-bottom";
            }
        }

        if (py >= this.y && py <= (this.y + this.height)) {
            if (closeToLeft) {
                return "border-left";
            }
            if (closeToRight) {
                return "border-right";
            }
        }

        if (inside) {
            return "inside";
        } else {
            return null;
        }
    }

    moveWhole(x: number, y: number, page: IPageInformation) {
        this.x = Math.max(0, Math.min(page.width - this.width, x));
        this.y = Math.max(0, Math.min(page.height - this.height, y));
    }

    moveTop(dy: number) {
        const oldY = this.y;
        const newY = Math.max(0, Math.min(oldY + this.height - minimumSize, oldY + dy));

        this.y = newY;
        this.height += (oldY - newY);
    }

    moveBottom(dy: number, page: IPageInformation) {
        this.height = Math.max(minimumSize, Math.min(this.height + dy, page.height - this.y));
    }

    moveLeft(dx: number) {
        const oldX = this.x;
        const newX = Math.max(0, Math.min(oldX + this.width - minimumSize, oldX + dx));

        this.x = newX;
        this.width += (oldX - newX);
    }

    moveRight(dx: number, page: IPageInformation) {
        this.width = Math.max(minimumSize, Math.min(this.width + dx, page.width - this.x));
    }

    move(type: string, dx: number, dy: number, page: IPageInformation) {
        switch (type) {
            case "border-top":
                this.moveTop(dy);
                break;
            case "border-bottom":
                this.moveBottom(dy, page);
                break;

            case "border-left":
                this.moveLeft(dx);
                break;

            case "border-right":
                this.moveRight(dx, page);
                break;

            case "corner-top-left":
                this.moveTop(dy);
                this.moveLeft(dx);
                break;
            case "corner-bottom-right":
                this.moveBottom(dy, page);
                this.moveRight(dx, page);
                break;
            case "corner-top-right":
                this.moveTop(dy);
                this.moveRight(dx, page);
                break;
            case "corner-bottom-left":
                this.moveBottom(dy, page);
                this.moveLeft(dx);
                break;

            case "inside":
                this.moveWhole(this.x + dx, this.y + dy, page);
                break;

            default:
                throw new Error("unsupported type for move " + type);
        }

        this.changed.next();
    }

    onChanges(): Observable<void> {
        return this.changed;
    }
}
