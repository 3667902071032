import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    Output,
    ViewChild
} from "@angular/core";

/**
 * Directive that fixes the position of the element after the page is scrolled beyond the top edge of that element.
 *
 * While the page is not scrolled, the element is left intact, but the width property is locked. After scrolling,
 * the "scrolling-position" class is added, along with some css properties, including "position: fixed".
 * An invisible filler element takes the place of the sticky element after scrolling, in order to retain all the other
 * elements in place.
 *
 * It is recommended that the element does not have any margin or padding while in the non-scrolled state, as those
 * are not taken into account when calculating the element's position and height.
 */
@Component({
    selector: 'app-sticky-header',
    template: `
        <div #element [class.scrolling-position]="scrollVisible" [style.width.px]="width">
            <ng-content></ng-content>
        </div>
        <div [style.width.px]="width" [style.height.px]="height" [hidden]="!scrollVisible"></div>
    `,
    styleUrls: [
        "./app-sticky-header.scss"
    ]
})
export class StickyHeaderComponent implements AfterContentInit {

    /**
     * Can be used to tweak the element offset, if the scrolling element has different styles, etc.
     */
    @Input() modifyScrollingOffset: number;
    @Output() isScrolling = new EventEmitter<boolean>();

    @ViewChild("element", {static: true}) el: ElementRef;

    scrollVisible = false;

    // lock width to #element
    // Note: resizing the window can break the width. We can't, however, simply listen to the resize event
    // and fix the width, because the auto-width only works correctly if the page is scrolled to the top.
    width: number;
    height: number;
    initialOffset: number;

    @HostListener("window:scroll", ["$event.currentTarget"])
    onScroll(window: Window) {
        this.scrollVisible = window.scrollY >= this.initialOffset;
        this.isScrolling.emit(this.scrollVisible);
    }

    get offsetModifier(): number {
        return this.modifyScrollingOffset || 0;
    }

    ngAfterContentInit(): void {
        const nativeElement = this.el.nativeElement as HTMLElement;

        const bounds = nativeElement.getBoundingClientRect();
        this.width = bounds.width;
        this.height = bounds.height;
        this.initialOffset = bounds.top + this.offsetModifier;
    }
}
