import {Component, OnInit} from "@angular/core";
import {formatEuropeanDate} from "../../utils/date";
import {AccountRestEndpoint, CurrentUserCommentDto} from "../../apina-digiweb";
import {LocalDate} from "@js-joda/core";

@Component({
    template: `
        <ngb-tabset>
            <ngb-tab [title]="'scrapbook.tab.saved-content' | translate">
                <ng-template ngbTabContent>
                    <app-saved-content></app-saved-content>
                </ng-template>
            </ngb-tab>
            <ngb-tab [title]="'scrapbook.tab.comments' | translate">
                <ng-template ngbTabContent>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card bg-light">
                                <div class="card-body">
                                    <div *ngIf="hasComments">
                                        <div class="row" *ngFor="let comment of comments">
                                            <div class="col-lg-2">{{formatDate(comment.created)}}</div>
                                            <div>
                                                "{{comment.textStart}}"
                                                <span translate>scrapbook.for-article</span>
                                                <a [href]="comment.url">{{comment.clippingTitle}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <p *ngIf="!hasComments" translate>common.no-comments</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ngb-tab>
        </ngb-tabset>
    `,
    styles: [
        `ng-template { padding-top: 2rem; }`
    ]
})
export class AccountTabsComponent implements OnInit {
    comments: CurrentUserCommentDto[];

    constructor(private accountRest: AccountRestEndpoint) {}

    ngOnInit(): void {
        this.accountRest.getCurrentUserComments().subscribe(comments => {
            this.comments = comments;
        });
    }

    get hasComments() { return this.comments && this.comments.length > 0; }

    formatDate(date: LocalDate) {
        return formatEuropeanDate(date);
    }
}
