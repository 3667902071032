import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {ThemePalette} from "@angular/material/core";

export type SpinnerSize  = "XX-SMALL" | "X-SMALL" | "SMALL" | "MEDIUM" | "LARGE";

@Component({
    selector: "app-progress-spinner",
    template: `
        <mat-progress-spinner mode="indeterminate" [color]="color" [diameter]="diameter"></mat-progress-spinner>
    `,
    styleUrls: [
        "progress.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressSpinnerComponent {
    
    @Input() color: ThemePalette = "accent";
    @Input() size: SpinnerSize = "MEDIUM";
    
    get diameter() {
        switch (this.size) {
            case "XX-SMALL":
                return 16;
            case "X-SMALL":
                return 24;
            case "SMALL":
                return 32;
            case "MEDIUM":
                return 45;
            case "LARGE":
                return 64;
        }
    }
}
