import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TabbedOverlayController} from "./tabbed-overlay.component";

// tab is a normal tab, action means that the tab cannot be opened, only clicked
export type TabType = 'tab' | 'action';

export type TitleType = 'icon' | 'text';

export interface TabPane {
    faIconClass: string;
    isSelected: boolean;
    title: string;
    titleType: TitleType;
    type: TabType;
    select(): void;
    deSelect(): void;
    tabClick(): void;
}

@Component({
    selector: 'app-tabbed-overlay-pane',
    template: `<div *ngIf="pane.isSelected"><ng-content></ng-content></div>`,
    styles: [
        '* { color: white; position: relative; }'
    ]
})
export class TabbedOverlayPaneComponent implements OnInit {

    @Input() set faIconClass(value: string) { this.pane.faIconClass = value; }

    // this sets only the initial value, user clicks don't affect this
    @Input() set selected(value: boolean) { this.pane.isSelected = value; }
    @Input() set title(value: string) { this.pane.title = value; }
    @Input() set titleType(value: TitleType) { this.pane.titleType = value; }
    @Input() set type(value: TabType) { this.pane.type = value; }

    @Output() tabClick = new EventEmitter<void>();
    @Output() onSelected = new EventEmitter<boolean>();

    pane: TabPane;

    constructor(private ctrl: TabbedOverlayController) {

        const self = this;
        this.pane = {
            faIconClass: this.faIconClass,
            isSelected: this.selected,
            title: this.title,
            titleType: this.titleType || 'icon',
            type: this.type || 'tab',
            select() {
                this.isSelected = true;
                self.onSelected.emit(true);
            },
            deSelect() {
                this.isSelected = false;
                self.onSelected.emit(false);
            },
            tabClick() {
                self.tabClick.emit();
            }
        };
    }

    ngOnInit(): void {
        this.ctrl.addPane(this.pane);
    }
}
