import {Component, Injectable, Input} from "@angular/core";
import {TabPane, TabType} from "./tabbed-overlay-pane.component";

const tabTypes: TabType[] = ['tab', 'action'];

@Injectable()
export class TabbedOverlayController {
    panes: TabPane[] = [];
    selected: TabPane = null;

    addPane(pane: TabPane) {
        if (tabTypes.indexOf(pane.type) === -1) {
            throw new Error("Invalid tab type: " + pane.type);
        }

        if (!this.selected) {
            this.select(pane);
        }
        this.panes.push(pane);
    }

    select(pane: TabPane) {
        if (pane.type === 'tab') {
            this.panes.forEach((p: TabPane) => {
                if (p.type === 'tab' && p.isSelected) {
                    p.deSelect();
                }
            });
            pane.select();
            this.selected = pane;
        }
    }
}

@Component({
    selector: 'app-tabbed-overlay',
    template: `
        <div>
            <div class="tabs-container" [ngClass]="classes">
                <ol>
                    <li *ngFor="let pane of ctrl.panes">
                        <a href="#" (click)="click($event, pane)" 
                           [ngClass]="{active: pane.isSelected}" 
                           [ngbTooltip]=" pane.titleType != 'text' ? pane.title : ''">
                            <span *ngIf="pane.titleType === 'text'">{{pane.title}}</span>
                            <span class="sr-only">{{pane.title}}</span>
                            <i *ngIf="pane.titleType === 'icon'" class="fa {{pane.faIconClass}}"></i>
                        </a>
                    </li>
                </ol>
            </div>
            <div class="tabbed-overlay">
                <ng-content></ng-content>
            </div>
        </div>
    `,
    providers: [
        TabbedOverlayController
    ],
    styles: [
        `.tabbed-overlay { min-height: 13rem; }`
    ]
})
export class TabbedOverlayComponent {

    @Input() classes: string;

    constructor(public ctrl: TabbedOverlayController) {
    }

    click($event: Event, pane: TabPane) {
        $event.preventDefault();

        this.ctrl.select(pane);

        // both normal and action-only tabs can have click-action
        if (pane.tabClick) {
            pane.tabClick();
        }
    }
}
