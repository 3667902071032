import {map} from 'rxjs/operators';
import {Component, OnInit, ViewChild} from "@angular/core";
import {ArticleDetails, ArticleRestEndpoint} from "../../apina-digiweb";
import {DisplayService} from "../display.service";
import {TranslateService} from "@ngx-translate/core";
import {SettingsService} from "../settings.service";
import {AccountService} from "../account/account.service";
import {UserArticleTabsComponent} from "./user-article-tabs.component";
import {Observable} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {UserArticleService} from "./user-article.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";
import {NavigationService} from "../navigation.service";

export interface IArticleController {
    articleId: number;
    displayOverlay: boolean;
    article: ArticleDetails;
    metadata: { key: string, value: string }[];
    canShare: boolean;
    includeSocialWidgets: boolean;

    isLoggedIn$: Observable<boolean>;
    toggleOverlay(): void;
    selectCitableContent($event: Event, selector: string): void;
    confirmDelete(): boolean;
}

@Component({
    template: `
        <div *ngIf="article && article.regions">
            <div class="clipping-view mt-3" [ngClass]="{ mobile: displayService.isMobile }">
                <a *ngFor="let region of article.regions; let i = index" [href]="region" target="_blank" class="d-block" appStoreSession>
                    <img [src]="region" alt="{{'clipping.article.region'| translate}}{{i}}" [ngClass]="{ wide: !displayOverlay }" appErrorThumb>
                </a>
            </div>
            
            <!-- TODO apply .tabbed-overlay-container.overlay with host css -->
            <app-user-article-tabs
                    [articleCtrl]="this" 
                    class="article-meta tabbed-overlay-container overlay kk-bg-dark"
                    [style.top.px]="offsetTop | async"
                    [ngClass]="{ minimized: !displayOverlay, mobile: displayService.isMobile }">
            </app-user-article-tabs>
        </div>
    `,
    styleUrls: [
        "../binding/overlay-tabs.scss",
        "./user-article.scss"
    ],
    providers: [
        UserArticleService
    ]
})
export class UserArticleComponent implements IArticleController, OnInit {

    bindingId: number;
    articleId: number;

    @ViewChild("overlay", {static: true}) overlay: UserArticleTabsComponent;

    _displayOverlay = true;
    article: ArticleDetails;
    metadata: { key: string, value: string }[] = [];
    ocrText: string[];
    canShare = false;
    includeSocialWidgets = false;
    context: string; // aikakausi/sanomalehti // todo validate, copied from browse-issues-by-year.component

    get displayOverlay() {
        return this._displayOverlay;
    }

    set displayOverlay(value: boolean) {
        this._displayOverlay = value;
    }

    offsetTop: Observable<number>;

    constructor(private articleRest: ArticleRestEndpoint,
                private accountService: AccountService,
                private $translate: TranslateService,
                private settingsService: SettingsService,
                private snackBar: MatSnackBar,
                public displayService: DisplayService,
                route: ActivatedRoute,
                private readonly breadcrumbsService: BreadcrumbsService,
                private readonly navigationService: NavigationService) {

        this.articleId = +route.snapshot.params.articleId;
        this.context = route.snapshot.params.context;
        this.bindingId = route.snapshot.params.bindingId;
        displayService.clipView = true;

        this.includeSocialWidgets = this.settingsService.commonOptions.includeExternalResources;

        this.offsetTop = this.displayService.headerVisibleHeight.pipe(map(h => h + 10));
    }

    ngOnInit(): void {
        this.articleRest.getArticleDetails(this.articleId).subscribe(article => {
            this.article = article;
            this.metadata = this.createMetadata(article);
            this.breadcrumbsService.setLatestLocation([
                // todo we can arrive to this page from multiple locations: binding-view, article search and my-clippings
                // complex, so omit for now
                {
                    translationKey: "main.links.user.articles",
                    commands: [this.navigationService.basePaths.clippings]
                },
                {
                    localizedText: '"' + article.title + '"',
                    commands: [
                        this.context,
                        "binding",
                        "" + this.bindingId,
                        "articles",
                        "" + this.articleId
                    ]
                }
            ]);
        });
    }

    confirmDelete() {
        return confirm(this.$translate.instant("user-article.delete.confirm"));
    }

    private createMetadata(article: ArticleDetails): { key: string, value: string }[] {
        const metadata: { key: string, value: string }[] = [];

        function addIfNonEmpty(key: string, value: string | string[]) {
            if (typeof value === 'string') {
                if (value)
                    metadata.push({key, value: value as string});
            } else if (value instanceof Array) {
                if (value.length)
                    metadata.push({key, value: value.join(", ")});
            }
        }

        function createLinks(value: string | string[]): string | string[] {
            if (typeof value === 'string') {
                if (value) {
                    return '<a href="clippings?query=&requireAllKeywords=true&fuzzy=false&keyword=' + encodeURIComponent(value) + '&orderBy=CREATED_DESC&page=1">' + value + '</a>';
                }
            } else if (value instanceof Array) {
                const result = [];
                if (value.length) {
                    for (const currentValue of value) {
                        result.push('<a href="clippings?query=&requireAllKeywords=true&fuzzy=false&keyword=' + encodeURIComponent(currentValue) + '&orderBy=CREATED_DESC&page=1">' + currentValue + '</a>');
                    }
                }
                return result;
            }
            return '';
        }

        addIfNonEmpty("user-article.description", article.description);
        addIfNonEmpty("user-article.category", article.category);
        addIfNonEmpty("user-article.subject", article.subject);
        addIfNonEmpty("user-article.language", article.language);
        addIfNonEmpty("user-article.keywords", createLinks(article.keywords));

        return metadata;
    }

    get isLoggedIn$() {
        return this.accountService.loggedIn$;
    }

    toggleOverlay() {
        this.displayOverlay = !this.displayOverlay;
    }

    // TODO copy-paste from BindingRootController (or was at some point)
    selectCitableContent($event: Event, selector: string) {
        $event.preventDefault();
        const content = jQuery(selector);
        const node = content[0]; // XXX assumes we get exactly one element

        if (node instanceof HTMLInputElement || node instanceof HTMLTextAreaElement) {
            content.focus();
            content.select();
        } else {
            window.getSelection().selectAllChildren(node);
        }

        // TODO execCommand("copy") is already deprecated, but https://w3c.github.io/clipboard-apis/ to replace it is not ready yet. Check compatibility regularly.
        if (typeof(document.execCommand("copy")) !== "undefined") {
            document.execCommand("copy");
            this.snackBar.open(this.$translate.instant("citation.clipboard"), "Ok", {duration: 4000});
        }
    }
}
