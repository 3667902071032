import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {SerialPublicationRestEndpoint, TitleCalendar} from "../../../apina-digiweb";

@Component({
    selector: 'app-issue-calendar',
    template: `
        <app-progress-spinner [hidden]="!loading"></app-progress-spinner>
        
        <div class="alert alert-danger" *ngIf="calendar == null && !loading">
            <p><span [innerHTML]="'error.not-authorized-or-not-found' | translate"></span></p>
        </div>
        
        <div *ngIf="calendar != null && !loading">
            <h2 class="mb-5"><span [class]="calendar.cssClasses" [title]="calendar.tooltip">{{calendar.year}}</span></h2>

            <div class="calendar-year-issues" *ngIf="calendar.bindings[0] != null">
                <p translate>browse.bindings-without-accurate-date</p>
                <ul>
                    <li *ngFor="let binding of calendar.bindings">
                        <a [href]="binding.uri" title="No {{binding.issue}}">No {{binding.issue}}</a>
                    </li>
                </ul>
            </div>

            <div class="year-calendar row justify-content-between">
                <div *ngFor="let monthCalendar of calendar.months" class="calendar-month col-12 col-md-5">
                    <app-calendar-month [monthCalendar]="monthCalendar"></app-calendar-month>
                </div>
            </div>
        </div>
    `,
    styleUrls: [
        "./issue-calendar.scss"
    ]
})
export class IssueCalendarComponent implements OnChanges {

    @Input() calendar: TitleCalendar;
    @Input() issn: string;
    @Input() year: number;

    loading = true;

    constructor(private serialPublicationEndpoint: SerialPublicationRestEndpoint) {}

    private updateCalendar() {
        this.loading = true;
        this.calendar = null;

        if (this.year === undefined || isNaN(this.year))
            this.loading = false;
        else
            this.serialPublicationEndpoint.calendarForYear(this.issn, this.year).subscribe(calendar => {
                this.calendar = calendar;
                this.sortBindings();
                this.loading = false;
            });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateCalendar();

    }

    sortBindings() {
        this.calendar.bindings.sort((a, b) => {

            if (a.publishingDate < b.publishingDate) return -1;
            if (a.publishingDate > b.publishingDate) return -1;

            // If dates are the same, compare by issue number
            return this.compareIssues(a.issue, b.issue);
        });
    }

    parseIssueNumber(issue:string) {
        const match = issue.match(/^(\d+)[ _]*([A-Za-z]*)$/);
        return match ? { number: parseInt(match[1], 10), suffix: match[2] } : null;
    }

    compareIssues(issue1:string, issue2:string) {
        const parsed1 = this.parseIssueNumber(issue1);
        const parsed2 = this.parseIssueNumber(issue2);

        if (parsed1.number < parsed2.number) return -1;
        if (parsed1.number > parsed2.number) return 1;
        if (parsed1.suffix < parsed2.suffix) return -1;
        if (parsed1.suffix > parsed2.suffix) return 1;
        return 0;
    }

}
