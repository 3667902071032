import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {map} from "rxjs/operators";
import {BroadcastChannelService, BroadcastMessageType} from "../broadcast-channel.service";

const HEADER_LOGGED_IN = "X-KK-loggedin";

@Injectable()
export class LoggedInDetector implements HttpInterceptor {

    constructor(private readonly broadcastChannel: BroadcastChannelService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const observable = next.handle(req);

        if (observable != null) {
            return observable.pipe(
                map(result => {
                    if (result instanceof HttpResponse) {
                        const headers = result.headers;
                        const value = headers.get(HEADER_LOGGED_IN);
                        if (!isPossiblyCached(headers) && value) {
                            const loggedIn = /true/.test(value);
                            this.broadcastChannel.send(BroadcastMessageType.SERVER_LOGIN_STATUS, loggedIn);
                        }
                    }

                    return result;
                })
            );
        } else
            return observable;
    }
}

function isPossiblyCached(headers: HttpHeaders): boolean {
    const cacheControl = headers.get("Cache-Control");
    return cacheControl && !cacheControl.toLocaleLowerCase().includes("no-store");
}
