import {Component} from "@angular/core";
import {AccountService} from "./account.service";
import {NavigationService} from "../navigation.service";

@Component({
    selector: 'app-account-dropdown',
    template: `
        <div ngbDropdown class="d-inline-block" *ngIf="accountService.userProperties$ | async as up">
            <button type="button" class="btn btn-kk-blue" id="accountDropdown" ngbDropdownToggle>
                <span class="d-none d-lg-inline">
                    {{up.currentUserInfo.name}}<span class="caret"></span>
                </span>
                <span class="d-inline d-lg-none">
                    <i class="fa-regular fa-user"></i><span class="caret"></span>
                </span>
                <span *ngIf="up.isTermsConfirmationRequired" class="account-indicator" matBadge="1"
                      matBadgeOverlap="true" matBadgeSize="small" matBadgePosition="above before" matBadgeColor="accent"></span>
            </button>
            <div ngbDropdownMenu aria-labelledby="accountDropdown">
                <a [routerLink]="basePaths.myClippings" rel="nofollow" class="dropdown-item account" translate>scrapbook.saved-content</a>
                <a [routerLink]="basePaths.mySearchHistory" rel="nofollow" class="dropdown-item account" translate>my-search-history.title</a>
                <a [href]="basePaths.changePassword" *ngIf="up.currentUserInfo.passwordUser" class="dropdown-item"
                   rel="nofollow" translate>account.action.change-password</a>
                <a [routerLink]="basePaths.settings" class="dropdown-item"
                   rel="nofollow">{{'account.settings' | translate}}
                    <span *ngIf="up.isTermsConfirmationRequired" matBadge="1" matBadgeOverlap="false"
                          matBadgeSize="small"></span>
                </a>
                <a [href]="basePaths.logout" class="dropdown-item" translate>account.logout</a>
            </div>
        </div>
    `,
    styleUrls: [
        './account-dropdown.scss'
    ]
})
export class AccountDropdownComponent {

    get basePaths() { return this.navigationService.basePaths; }
    constructor(public accountService: AccountService,
                private navigationService: NavigationService) {
    }
}
