import {ChangeDetectionStrategy, Component} from "@angular/core";
import {NerPersonSearchResult, NerPersonSearchResultRow, NerRestEndpoint} from "../../../apina-digiweb";
import {FormControl} from "@angular/forms";
import {concatMap, debounceTime, filter, map, switchMap} from "rxjs/operators";
import {LocalDate} from "@js-joda/core";
import {formatEuropeanDate} from "../../../utils/date";
import {concat, Observable, of} from "rxjs";
import {NavigationService} from "../../navigation.service";
import {ActivatedRoute} from "@angular/router";
import {firstNonExcludedIndex, lastNonExcludedIndex} from "../../../utils/string-utils";
import {AccountService} from "../../account/account.service";
import {BreadcrumbsService} from "../../breadcrumbs/breadcrumbs.service";

enum LoadingState { INITIAL = 1, UPDATING = null }

const QUERY_PARAM_NAME = "q";

const EXTRA_PREVIEW_WIDTH = 250;
const EXTRA_PREVIEW_HEIGHT = 75;

const FILTERED_PUNCTUATION_FOR_PAGE_HIGHLIGHT = ',.- ';

@Component({
    selector: "app-ner-person-search",
    template: `
        <section class="container" *ngIf="enabled$ | async; else unauthorized">
            <input type="text" class="form-control" placeholder="Syötä henkilön sukunimi" appAutofocus [formControl]="input"/>

            <div *ngIf="result$ | async as result; else loading">
                <p class="mt-3">Näytetään {{result.rows.length || 0}} / {{result.total || 0}}</p>

                <table class="table table-sm table-striped mt-3" *ngIf="result.total > 0">
                    <!--
                    <colgroup>
                        <col style="width: 8rem">
                        <col>
                        <col>
                        <col>
                        <col>
                    </colgroup>
                    -->
                    <thead>
                    <th>Sukunimi?</th>
                    <th>NER / Osumaympäristö</th>
                    <th>Lähde</th>
                    <th>OCR-Osat</th>
                    <th>REF</th>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of result.rows">
                        <td>{{row.primaryToken}}</td>
                        <td>
                            <span [ngbTooltip]="previewAll"
                                  placement="bottom"
                                  container="body"
                                  class="ner-text"
                            >{{row.nerText}}</span>

                            <app-ner-lazy-text [nerOccurrenceId]="row.occurrenceId"></app-ner-lazy-text>

                            <ng-template #previewAll>
                                <img [src]="createPreviewAllUrl(row)" alt="preview"/>
                            </ng-template>
                        </td>
                        <td>
                            <a [href]="createPageUrl(row)">[sivu]</a>
                        </td>
                        <td class="d-flex flex-wrap">
                            <div *ngFor="let part of row.parts"
                                 class="ocr-part">
                                {{part.ocrPart}}
                            </div>
                        </td>
                        <td>{{row.tagRef}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>

            <!-- add extra space for preview images at the bottom -->
            <div [style.height.px]="700"></div>
        </section>

        <ng-template #loading>
            <app-progress-spinner></app-progress-spinner>
        </ng-template>

        <ng-template #unauthorized>
            <section class="container">Ei oikeutta</section>
        </ng-template>
    `,
    styleUrls: [
        'ner-person-search.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NerPersonSearchComponent {

    input = new FormControl('');

    enabled$ = this.accountService.userProperties$.pipe(map(a => a.canUsePersonSearch));
    input$ = concat(of(LoadingState.INITIAL), this.input.valueChanges.pipe(debounceTime(250)));
    result$: Observable<LoadingState | NerPersonSearchResult>;

    constructor(public accountService: AccountService,
                private nerRestEndpoint: NerRestEndpoint,
                private navigationService: NavigationService,
                private activatedRoute: ActivatedRoute,
                breadcrumbs: BreadcrumbsService) {
        breadcrumbs.setLocalizedText('main.ner-search');

        const q = this.activatedRoute.snapshot.queryParamMap.get(QUERY_PARAM_NAME);

        this.result$ = this.input$.pipe(
            concatMap(val => of(LoadingState.UPDATING, val)),
            switchMap(query => {
                switch (query) {
                    case LoadingState.UPDATING:
                        return of(null);
                    case LoadingState.INITIAL:
                        return nerRestEndpoint.findNerPersons('');
                    default:
                        return nerRestEndpoint.findNerPersons(query);
                }
            })
        );

        this.input$.pipe(
            filter(val => val !== LoadingState.INITIAL && val !== LoadingState.UPDATING)
        ).subscribe(val => {
            this.navigationService.setSearchParam(QUERY_PARAM_NAME, val);
        });

        if (q) {
            setTimeout(() => {
                this.input.setValue(q);
            });
        }
    }

    formatDate(date: LocalDate) {
        return formatEuropeanDate(date);
    }

    createPageUrl(row: NerPersonSearchResultRow): string {
        const bindingId = row.bindingId;
        const pageNumber = row.pageNumber;
        return `/sanomalehti/binding/${bindingId}?page=${pageNumber}` + getHighlightTerms(row);
    }

    createPreviewAllUrl(row: NerPersonSearchResultRow): string {
        return createPreviewAreaUrl(row.bindingId, row.occurrenceId);
    }
}

class Box {
    constructor(public x: number,
                public y: number,
                public w: number,
                public h: number
                ) {}
}


function createPreviewAreaUrl(bindingId: number, occurrenceId: number): string {
    return `/sanomalehti/binding/${bindingId}/ner-occurrence-image/${occurrenceId}`;
}

function trimPunctuation(word: string): string {
    const first = firstNonExcludedIndex(word, FILTERED_PUNCTUATION_FOR_PAGE_HIGHLIGHT);
    const last = lastNonExcludedIndex(word, FILTERED_PUNCTUATION_FOR_PAGE_HIGHLIGHT);

    if (first !== -1 && last !== -1)
        return word.substr(first, last + 1);
    else
        return '';
}

function getHighlightTerms(row: NerPersonSearchResultRow) {
    return "&term=" + row.parts.map(p => trimPunctuation(p.ocrPart)).join("&term=");
}
