import {Pipe, PipeTransform} from "@angular/core";
import {LocalDate} from "@js-joda/core";
import {formatEuropeanDate} from "../../utils/date";

@Pipe({
    name: "localizedDate"
})
export class LocalizedDatePipe implements PipeTransform {
    transform(value: LocalDate): string {
        return formatEuropeanDate(value) || '';
    }
}