import {Injectable} from "@angular/core";
import {LoggingService} from "./logging.service";
import {FrontEndOptions} from "../apina-digiweb";

type UiLanguage = "fi" | "sv" | "en";

/**
 * Replacement for most stuff in RootScope.
 */
@Injectable()
export class SettingsService {

    /** AngularJS injection key */
    static KEY = 'SettingsService5';

    language: UiLanguage;
    commonOptions: FrontEndOptions;

    buildNumberAbbr: string;
    readonly reCaptchaSiteKey: string;

    constructor(log: LoggingService) {
        // TODO fetch these via json-api instead
        this.language = window.language as UiLanguage;
        this.commonOptions = window.commonOptions;
        this.buildNumberAbbr = this.getAbbreviatedBuildNumber();
        this.reCaptchaSiteKey = window.reCaptchaSiteKey;

        log.debug("Initialized SettingsService", this);
    }

    get feedbackEnabled() {
        return this.commonOptions.jiraCollectorEnabled && this.commonOptions.includeExternalResources;
    }

    get lang() {
        return this.language;
    }

    get logoPathLight() {
        return `/images/logos/logo_${this.lang}.png`;
    }

    get logoPathDark() {
        const lang2 = this.lang === 'sv' ? 'fi' : this.lang;
        return `/images/logos/logo_${lang2}_darkblue.png`;
    }

    get showExperimentalSearchFeatures() {
        return this.commonOptions.showExperimentalSearchFeatures;
    }

    get cacheBuster() {
        return this.buildNumberAbbr || 'dev';
    }

    private getAbbreviatedBuildNumber(): string {
        const buildNumber = this.commonOptions.buildNumber;
        return buildNumber && buildNumber.substring(0, 7) || '';
    }
}
