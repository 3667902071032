import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UserconsentService, ConsentCategories } from './userconsent.service';

/**
 * Allows user to manage and explore consents
 */
@Component({
    selector: "userconsent-component",
    templateUrl: "userconsent.component.html",
    styleUrls: [
        "./userconsent.component.scss"
    ]
})

export class UserconsentComponent {

    agreedCategories: string[];
    consentForm: FormGroup;

    constructor(
        private userConsentService: UserconsentService
    ) {
        this.getAgreedCategories();
        this.initForm();
    }

    getAgreedCategories() { 
        this.agreedCategories = this.userConsentService.getAgreedCategories().split(",") 
    }

    initForm() {
        this.consentForm = new FormGroup({
            essential: new FormControl({value: true, disabled: true}),
            preference: new FormControl(this.agreedCategories.includes(ConsentCategories.PREFERENCE) ? true : false),
            marketing: new FormControl(this.agreedCategories.includes(ConsentCategories.MARKETING) ? true : false),
            statistic: new FormControl(this.agreedCategories.includes(ConsentCategories.STATISTIC) ? true : false)
        })
    }

    onSubmit() {
        const formValue = this.consentForm.getRawValue();
        const cookieValue = ConsentCategories.ESSENTIAL + 
        (formValue.preference ? "," + ConsentCategories.PREFERENCE : "") + 
        (formValue.marketing ? "," + ConsentCategories.MARKETING : "") + 
        (formValue.statistic ? "," + ConsentCategories.STATISTIC : "");
        this.userConsentService.setAgreedCategories(cookieValue);
        this.userConsentService.clearCategories();
        location.reload();
    }
}