import {Injectable, Injector, OnDestroy} from "@angular/core";
import {
    ActivePageResult,
    BindingViewParams,
    BindingViewState,
    ClientPageInformation,
    ClippingMode,
    FitBy,
    IBindingPageView,
    IClipping,
    ICurrentBindingView,
    IPageInformation,
    IRegion,
    NonUrlState,
    OcrInfo,
    PageRequest,
    PercentagePoint,
    ZoomEvent,
    ZoomType
} from "../../binding/types";
import {ArticleInfo, BindingRestEndpoint, ComponentPartBlock, OcrBlock, PageRequestSource, RedactionDto, RedactionReason, RedactionRestEndpoint} from "../../apina-digiweb";
import {BehaviorSubject, combineLatest, merge, Observable, Subject} from "rxjs";
import * as _ from "lodash";
import {ClippingHelpComponent} from "./clipping-help.component";
import {ENABLE_OCR} from "../../config";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ErrorService} from "../error.service";
import {LoggingService} from "../logging.service";
import {AccountService} from "../account/account.service";
import {OverlayService} from "../overlay/overlay.service";
import {DisplayService} from "../display.service";
import {BindingService} from "./binding.service";
import {debounceTime, distinctUntilChanged, filter, map, shareReplay, switchMap, take} from "rxjs/operators";
import {ClippingRect} from "../../binding/clipping-rect";
import {Overlay} from "@angular/cdk/overlay";
import {filterNonErrors, filterNonNull, mapResponse, Subscriptions, toggleBooleanSubject} from "../../utils/observable-utils";
import {combineAreas} from "../../utils/region-utils";
import {NavigationService} from "../navigation.service";


const MIN_PAGE = 1;
const UBER_BLOCK_PADDING = 7;

/**
 * Services for communicating how binding page is displayed over multiple components.
 */
@Injectable()
export class CurrentBindingView implements ICurrentBindingView, OnDestroy {

    constructor(private ngbModal: NgbModal,
                private errorService: ErrorService,
                private $log: LoggingService,
                private bindingRest: BindingRestEndpoint,
                private redactionRest: RedactionRestEndpoint,
                private accountService: AccountService,
                private overlayService: OverlayService,
                private overlay: Overlay,
                private injector: Injector,
                private displayService: DisplayService,
                private bindingService: BindingService,
                private navigationService: NavigationService) {

        // TODO shouldn't we disable this somewhere (or are we relying on page reload?)
        this.displayService.articleMode = true;

        if (this.displayService.isMobile && !this.displayService.maximized)
            this.displayService.toggleMaximized();
    }

    //
    // state / subjects
    //
    private pageRequest$ = new Subject<PageRequest>();
    private clippingsModified = new Subject<void>();
    private editableRedaction = new BehaviorSubject<RedactionDto>(null);
    private terms: any;
    private subs = new Subscriptions();
    private pageViewController: IBindingPageView;

    // URL state
    public page$ = new BehaviorSubject<number | null>(null);
    public displayComponentTree$ = new BehaviorSubject<boolean>(false);
    public displayParts$ = new BehaviorSubject<boolean>(false);
    public displayOcr$ = new BehaviorSubject<boolean>(false);
    public displayMarc$ = new BehaviorSubject<boolean>(false);
    public oldOcr$ = new BehaviorSubject<boolean>(false);
    public ocrBorders$ = new BehaviorSubject<boolean>(false);
    public searchTerms$ = new BehaviorSubject<string[]>([]);
    
    // non-URL state
    public componentPart$ = new BehaviorSubject<string|null>(null); // finnaId
    public displayAgreements$ = new BehaviorSubject<boolean>(false);
    public displayArticles$ = new BehaviorSubject<boolean>(false);
    public displayBindingProblemDialog$ = new BehaviorSubject<boolean>(false);
    public displayCitation$ = new BehaviorSubject<boolean>(false);
    public displayHideBindingDialog$ = new BehaviorSubject<boolean>(false);
    public displayNerLocations$ = new BehaviorSubject<boolean>(false);
    public displayNerPersons$ = new BehaviorSubject<boolean>(false);
    public displaySearch$ = new BehaviorSubject<boolean>(false);
    public displayToc$ = new BehaviorSubject<boolean>(false);
    
    public bindingId: number;
    public highlightedBlock$ = new BehaviorSubject<OcrBlock | null>(null);
    public componentTreeBlocks$ = new BehaviorSubject<IRegion[] | null>(null);
    public selectedComponentPartId$ = new BehaviorSubject<number|null>(null);
    public clippings$ = new BehaviorSubject<IClipping[]>([]);
    public clippingMode$ = new BehaviorSubject<ClippingMode>(ClippingMode.OFF);

    // XXX our version of TypeScript and RxJS definitions doesn't allow more than 6 combineLatest parameters
    public bindingViewState$: Observable<BindingViewState> = combineLatest([
        combineLatest([
            this.page$,
            this.displayOcr$,
            this.displayMarc$,
            this.displayNerLocations$,
            this.displayNerPersons$,
            this.oldOcr$,
        ]),
        combineLatest([
            this.ocrBorders$,
            this.searchTerms$,
        ])
    ]).pipe(
        map(([p1, p2]) => {
            return {
                page: p1[0],
                displayOcr: p1[1],
                displayMarc: p1[2],
                displayNerLocations: p1[3],
                displayNerPersons: p1[4],
                oldOcr: p1[5],
                ocrBorders: p2[0],
                searchTerms: p2[1],
            }
        })
    );


    // XXX our version of TypeScript and RxJS definitions doesn't allow more than 6 combineLatest parameters
    public nonUrlState$: Observable<NonUrlState> = combineLatest([
        combineLatest([
            this.componentPart$,
            this.displayAgreements$,
            this.displayArticles$,
            this.displayBindingProblemDialog$,
            this.displayCitation$,
            this.displayComponentTree$,
        ]),
        combineLatest([
            this.displayHideBindingDialog$,
            this.displayParts$,
            this.displaySearch$,
            this.displayToc$,
        ]),
    ]).pipe(
        map(([p1, p2]) => {
            return {
                componentPart: p1[0],
                displayAgreements: p1[1],
                displayArticles: p1[2],
                displayBindingProblemDialog: p1[3],
                displayCitation: p1[4],
                displayComponentTree: p1[5],
                displayHideBindingDialog: p2[0],
                displayParts: p2[1],
                displaySearch: p2[2],
                displayToc: p2[3],
            }
        })
    );
    
    public enableOcr = ENABLE_OCR;
    
    /**
     * The current zoom level. This is the ratio between page width to viewport width.
     *
     * For example:
     *   - 1.0 means that width of the page matches the width of the viewport
     *   - 0.5 means that page fills only half of the viewport's width
     *   - 2.0 means that half of the page's width is visible
     */
    public zoomEvents$ = new BehaviorSubject(new ZoomEvent(1, ZoomType.NO_CHANGE));

    /** should we continue clipping after saving? */
    public continueAfterSave = false;

    /** do we allow dragging? */
    public dragMode = false;

    /** are we editing existing clipping instead of creating a new one? */
    public editExisting = false;

    /** does the user have permission to make clippings on this binding? */
    public allowClipping = false;

    /** are we currently in progress on saving? */
    public saving = false;
    
    public get clippingEditorOpenSnapshot(): boolean {
        return this.clippingMode$.getValue() !== ClippingMode.OFF;
    }

    public get clippingsSnapshot(): IClipping[] {
        return this.clippings$.getValue();
    }

    //
    // derived observables
    //
    public bindingInfo$ = this.bindingService.bindingInformation$.pipe(map(a => a.bindingInformation));
    public pages$ = this.bindingInfo$.pipe(map(a => a.pages.map(p => new ClientPageInformation(p))));
    public pagesCount$ = this.pages$.pipe(map(a => a.length));
    public activePageResult$: Observable<ActivePageResult> = combineLatest([this.pageRequest$, this.pages$]).pipe(map(([pr, pc]) => this.validateAndLoadPage(pr, pc)), shareReplay(1));
    public safeBindingViewState$: Observable<BindingViewState> = this.bindingViewState$.pipe(filterNonNull);
    public loadedPage$ = this.activePageResult$.pipe(map(a => a.page));
    public loadedPageNumber$ = this.loadedPage$.pipe(map(p => p.info.number));
    public componentTreeUberBlock$ = this.componentTreeBlocks$.pipe(map((areas) => this.combineRegions(areas, UBER_BLOCK_PADDING)));
    public isSideViewActive$ = this.safeBindingViewState$.pipe(map(state => state.displayOcr || state.displayMarc));
    public currentPageOcrData$ = combineLatest([this.safeBindingViewState$, this.loadedPageNumber$]).pipe(switchMap(([bvs, page]) => this.bindingService.getOcrData(this.bindingId, page, bvs.oldOcr)), shareReplay(1));
    public ocrInfo$ = this.currentPageOcrData$.pipe(
        filter((a) => a.hasOwnProperty("ocrVersion")),
        map((a) => (a as OcrInfo))
    );
    public ocrBlocks$ = this.currentPageOcrData$.pipe(map(d => mapResponse(d, a => a.blocks)));
    public componentPartAreas$ = this.loadedPageNumber$.pipe(distinctUntilChanged(), switchMap((page) => this.bindingService.getComponentPartAreas(this.bindingId, page)), shareReplay(1));
    public selectedComponentPartAreas$ = combineLatest([this.componentPartAreas$.pipe(filterNonErrors), this.selectedComponentPartId$]).pipe(
        map(([areas, partId]) => !!partId ?  areas.filter(x => x.partId === partId) : [])
    );
    public selectedComponentPartUberBlock$ = this.selectedComponentPartAreas$.pipe(
        map((areas) => this.combineComponentPartBlocks(areas, UBER_BLOCK_PADDING))
    );
    public userArticleClippingsOnCurrentPage$ = combineLatest([this.loadedPageNumber$, this.bindingInfo$]).pipe(
        map(([p, bi]) => bi.userArticles.filter(x => x.clippings.some(c => c.pageNumber === p))),
        map(c => c.length),
        distinctUntilChanged()
    );
    public clippingEditorOpen$ = this.clippingMode$.pipe(map(a => a !== ClippingMode.OFF), distinctUntilChanged()); // TODO should be === ClippingMode.ARTICLE?
    public redactionEditorOpen$ = this.clippingMode$.pipe(map(a => a === ClippingMode.REDACTION), distinctUntilChanged());
    public areRedactionsVisible$ = combineLatest([this.bindingInfo$, this.redactionEditorOpen$]).pipe(map(([i, editorOpen]) => !i.hideRedactions && !editorOpen));
    public editableRedaction$: Observable<RedactionDto> = this.editableRedaction;
    public clippingsOnCurrentPage$ = combineLatest([this.loadedPageNumber$, this.clippings$]).pipe(map(
            ([page, clippings]) => clippings.filter(c => c.pageNumber === page)),
        shareReplay(1)
    );
    public areArticlesVisible$ = combineLatest([this.safeBindingViewState$, this.clippingEditorOpen$]).pipe(map(
        ([state, editorOpen]) => {
            return !(state.displayOcr || state.displayNerLocations || state.displayNerPersons || editorOpen);
        }
    ));
    public isRestrictedMaterialWarningVisible$ = this.bindingInfo$.pipe(map(i => i.restrictedMaterial && !this.displayService.maximized));
    
    initialize(params: BindingViewParams, initialState: BindingViewState): Observable<void> {

        this.bindingId = params.id;
        this.terms = initialState.searchTerms;
        this.bindingService.initialize(params);
        
        const resultSubject = new Subject<void>();
        
        this.bindingService.bindingInformation$.pipe(take(1)).subscribe(result => {
            this.$log.debug("Loaded ", result);

            const bindingInfo = result.bindingInformation;
            this.allowClipping = bindingInfo.clippable;
            this.importState(initialState);
            
            const edit = bindingInfo.edit;
            if (edit)
                this.clippings$.next(edit.clipping.clippings.map(r => new ClippingRect(r.pageNumber, r.x, r.y, r.width, r.height)));

            if (edit) {
                this.startArticleClipping();
                this.editExisting = true;
            }
            
            resultSubject.next();
            resultSubject.complete();
            
        }, e => {
            this.errorService.showError("Niteen lataus epäonnistui", "Niteen lataus epäonnistui", e);
            resultSubject.error(e);
            resultSubject.complete();
        });

        const ocrDisabled$ = this.safeBindingViewState$.pipe(
            distinctUntilChanged((a, b) => a.displayOcr === b.displayOcr),
            filter(s => s.displayOcr === false),
            map(s => s.displayOcr),
        );

        this.subs.add(merge(ocrDisabled$, this.loadedPageNumber$).subscribe(() => {
            this.setHighlightedBlock(null);
        }));

        this.subs.add(this.clippingsModified.pipe(debounceTime(100)).subscribe(() => {
            this.clippings$.next(this.clippings$.getValue());
        }));

        // Whenever we switch to editing or back from editing, maximize/un-maximize automatically
        this.subs.add(this.clippingEditorOpen$.subscribe(open => {
            this.displayService.maximized = open;
        }));
        
        this.subs.add(this.activePageResult$.subscribe((apr) => {
            this.setActivePageToState(apr.redirectedPage);
        }));
        
        return resultSubject;
    }

    importState(state: BindingViewState) {
        this.page$.next(state.page);
        this.displayOcr$.next(state.displayOcr);
        this.displayMarc$.next(state.displayMarc);
        this.displayNerLocations$.next(state.displayNerLocations);
        this.displayNerPersons$.next(state.displayNerPersons);
        this.oldOcr$.next(state.oldOcr);
        this.ocrBorders$.next(state.ocrBorders);
        this.searchTerms$.next(state.searchTerms);
    }

    private validateAndLoadPage(pr: PageRequest, pages: ClientPageInformation[]): ActivePageResult {
        const normalizedPage = this.normalizePageNumber(pr.pageNumber, pages.length) || MIN_PAGE;
        
        const page = this.getPageByNumber(pages, normalizedPage);
        
        return new ActivePageResult(pr, normalizedPage, page);
    }

    setHighlightedBlock(block: OcrBlock | null): void {
        this.highlightedBlock$.next(block);
    }

    setHighlightedBlocks(blocks: IRegion[] | null): void {
        this.componentTreeBlocks$.next(blocks);
    }

    addClipping(clipping: IClipping): void {
        const curr = this.clippings$.getValue();
        curr.push(clipping);
        this.clippings$.next(curr);
    }

    removeClipping(clipping: IClipping): void {
        const curr = this.clippings$.getValue();
        const index = _.findIndex(curr, c => c === clipping);
        if (index !== -1) {
            curr.splice(index, 1);
            this.clippings$.next(curr);
        }
    }

    /**
     * We could instantly emit new value for clippings$, but we might get a lot of these from mouse events, so
     * introduce a delay to improve performance.
     */
    emitClippingsModified(): void {
        this.clippingsModified.next();
    }

    setZoom(value: number): void {
        const zoomType = this.getZoomType(value);
        if (zoomType !== ZoomType.NO_CHANGE) {
            this.zoomEvents$.next(new ZoomEvent(value, zoomType));
        }
    }

    private getZoomType(value: number) {
        const previousLevel = this.zoomEvents$.getValue().zoomLevel;
        if (value === previousLevel)
            return ZoomType.NO_CHANGE;
        else
            return value > previousLevel ? ZoomType.IN : ZoomType.OUT;
    }

    rotate90() {
        this.pageViewController.rotate90();
    }

    isRotated(): boolean {
        return this.pageViewController.isRotated();
    }
    
    fitWidth() {
        this.pageViewController.fit(FitBy.WIDTH);
    }

    fitHeight() {
        this.pageViewController.fit(FitBy.HEIGHT);
    }

    zoomIn() {
        this.setZoom(this.zoomEvents$.getValue().zoomLevel * 1.1);
    }

    zoomOut() {
        this.setZoom(this.zoomEvents$.getValue().zoomLevel * 0.9);
    }
    
    zoomToRegion(block: IRegion): void {
        this.zoomEvents$.next(new ZoomEvent(this.zoomEvents$.getValue().zoomLevel, ZoomType.NO_CHANGE, block));
    }

    panToRegion(region: IRegion): void {
        this.pageViewController.panToRegion(region);
    }

    focalZoom(level: number, point: PercentagePoint) {
        const filteredLevel = Math.max(0.01, Math.min(20, level));
        const type = this.getZoomType(filteredLevel);
        if (type !== ZoomType.NO_CHANGE)
            this.zoomEvents$.next(new ZoomEvent(level, type, point));
    }

    setPageView(view: IBindingPageView): void {
        this.pageViewController = view;
    }

    setSearchTerms(terms: string[]) {
        this.searchTerms$.next(terms);
    }

    goToPage(pageRequest: PageRequest): void {
        // XXX goToPage seems to be stupid and load stuff even if the page does not change
        this.pageRequest$.next(pageRequest);
    }

    toggleUserArticles(): void {
        toggleBooleanSubject(this.displayArticles$);
    }

    toggleComponentTree(): void {
        toggleBooleanSubject(this.displayComponentTree$);
    }

    toggleParts(): void {
        toggleBooleanSubject(this.displayParts$);
    }
    
    toggleOcr() {
        toggleBooleanSubject(this.displayOcr$);
    }
    
    toggleToc() {
        toggleBooleanSubject(this.displayToc$);
    }
    
    toggleSearch() {
        toggleBooleanSubject(this.displaySearch$);
    }
    
    toggleMarc() {
        toggleBooleanSubject(this.displayMarc$);
    }
    
    toggleOcrBorders() {
        toggleBooleanSubject(this.ocrBorders$);
    }

    toggleOldOcr() {
        toggleBooleanSubject(this.oldOcr$);
    }
    
    toggleNerLocations() {
        toggleBooleanSubject(this.displayNerLocations$);
    }

    toggleNerPersons() {
        toggleBooleanSubject(this.displayNerPersons$);
    }

    toggleAgreements() {
        toggleBooleanSubject(this.displayAgreements$);
    }

    toggleCitation() {
        toggleBooleanSubject(this.displayCitation$);
    }
    
    toggleBindingProblemDialog(): void {
        toggleBooleanSubject(this.displayBindingProblemDialog$);
    }

    toggleHideBindingDialog(): void {
        toggleBooleanSubject(this.displayHideBindingDialog$); 
    }

    showComponentPartMetadata(finnaId: string) {
        this.componentPart$.next(finnaId);
        if (finnaId != null)
            this.displayMarc$.next(true);
    }

    getPageByNumber<T extends IPageInformation>(pages: T[], pageNumber: number): T | undefined {
        return pages.find(p => p.info.number === pageNumber);
    }

    toggleArticleEdit() {
        if (!this.allowClipping || this.clippingEditorOpenSnapshot)
            return;

        this.startArticleClipping();
    }

    togglePrintEdit() {
        if (!this.allowClipping || this.clippingEditorOpenSnapshot)
            return;

        if (this.dragMode)
            this.dragMode = false;

        this.startPrintClipping();
    }

    toggleRedactEdit() {
        if (this.clippingMode$.getValue() === ClippingMode.REDACTION) {
            this.cancelClipping();
            return;
        }

        if (this.clippingEditorOpenSnapshot)
            return;

        this.editRedaction({
            id: null,
            bindingId: this.bindingId,
            title: '',
            reason: RedactionReason.COPYRIGHT,
            author: '',
            otherInformation: '',
            regions: this.clippings$.getValue() as any[] // TODO
        });
    }

    startArticleClipping() {
        // TODO we could check if user data is still loading and not prompt for login just yet
        if (!this.accountService.loggedInSnapshot) {
            this.accountService.login();
            return;
        }

        this.clippingMode$.next(ClippingMode.ARTICLE);
    }
    
    clippingFinished(result: ArticleInfo) {
        this.navigationService.goTo(result.articleUrl);
    }

    startPrintClipping() {
        this.clippingMode$.next(ClippingMode.PRINT);
    }

    editRedaction(redaction: RedactionDto) {
        this.bindingInfo$.pipe(take(1)).subscribe(bindingData => {
            this.clippings$.next(redaction.regions.map(r => new ClippingRect(r.pageNumber, r.x, r.y, r.width, r.height)));
            redaction.regions = this.clippings$.getValue() as any[]; // TODO
            
            this.clippingMode$.next(ClippingMode.REDACTION);

            this.editableRedaction.next(redaction);
        });
    }

    cancelClipping(refresh?: boolean): void {
        // TODO implement refresh clipping data
        
        combineLatest([this.bindingInfo$, this.redactionRest.findRedactionsForBinding(this.bindingId)])
            .pipe(take(1))
            .subscribe(([bi, rs]) => {
                if (bi.edit && bi.edit.cancelUri) {
                    this.navigationService.goTo(bi.edit.cancelUri);
                }

                // XXX editing observable contents
                bi.redactions = rs;
            });
                
        this.clippings$.next([]);
        this.clippingMode$.next(ClippingMode.OFF);
    }

    showHelp() {
        this.ngbModal.open(ClippingHelpComponent, {
            size: "lg"
        });
    }

    isHorizontallyScrollable(): boolean {
        return this.pageViewController.isHorizontallyScrollable();
    }

    private normalizePageNumber(page: number, pageCount: number): number {
        return Math.max(MIN_PAGE, Math.min(pageCount, page));
    }

    previousPage(): void {
        const prevPage = this.page$.value - 1;

        this.pageRequest$.next(new PageRequest(prevPage, PageRequestSource.PAGER));
    }

    nextPage(): void {
        const nextPage = this.page$.value + 1;

        this.pageRequest$.next(new PageRequest(nextPage, PageRequestSource.PAGER));
    }

    setSelectedPartId(partId: number | null): void {
        this.selectedComponentPartId$.next(partId);
    }

    private setActivePageToState(page: number) {
        this.page$.next(page);
    }
    
    ngOnDestroy(): void {
        this.subs.unsubscribeAll();
    }

    private combineComponentPartBlocks(areas: ComponentPartBlock[], padding: number): ComponentPartBlock | null {
        const uberBlock = combineAreas(areas, padding);
        
        return !!uberBlock ? Object.assign(uberBlock, {
                partId: areas[0].partId,
                partTitle: areas[0].partTitle
            }) : null;
    }

    private combineRegions(areas: IRegion[], padding: number): IRegion | null {
        return combineAreas(areas, padding);
    }
}
