import {Directive, ElementRef, HostListener, Input, Renderer2} from "@angular/core";

// TODO this should make the whole page-image draggable. Seems even the ng1 version had been broken for a while now.

enum MouseButton {MAIN}

@Directive({
    selector: "[appDraggable]"
})
export class DraggableDirective {

    @Input() appDraggable: boolean;

    get enabled(): boolean {
        return this.appDraggable;
    }

    readonly el: HTMLElement;
    private dragging: boolean;
    private lastX: number;
    private lastY: number;

    constructor(element: ElementRef, renderer: Renderer2) {
        this.el = element.nativeElement;

        renderer.addClass(this.el, "draggable-area");
    }

    @HostListener("mousedown", ["$event"])
    onMouseDown(event: MouseEvent) {
        if (event.button === MouseButton.MAIN && this.enabled) {
            this.dragging = true;
            this.lastX = event.pageX;
            this.lastY = event.pageY;
        }
    }

    @HostListener("mouseup", ["$event"])
    onMouseUp(event: MouseEvent) {
        if (event.button === MouseButton.MAIN) {
            this.dragging = false;
            this.lastX = null;
            this.lastY = null;
        }
    }

    @HostListener("mousemove", ["$event"])
    onMouseMove(event: MouseEvent) {
        if (this.dragging) {
            event.preventDefault();

            this.el.scrollLeft = this.el.scrollLeft - (event.pageX - this.lastX);
            this.el.scrollTop = this.el.scrollTop - (event.pageY - this.lastY);

            this.lastX = event.pageX;
            this.lastY = event.pageY;
        }
    }
}
