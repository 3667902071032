import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {BindingRestEndpoint, ComponentPartListDTO, ComponentPartSummaryDTO, PageRequestSource} from "../../../../apina-digiweb";
import {combineLatest, Observable, Subject, Subscription} from "rxjs";
import {BINDING_VIEW, ICurrentBindingView, PageRequest} from "../../../../binding/types";
import {debounceTime, map, take} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {withInitialValue} from "../../../../utils/observable-utils";
import {translationOrDefault} from "../../../../utils/translation-utils";

@Component({
    selector: "app-component-part-list",
    template: `
        <app-sidebar>
            <app-sidebar-header [titleKey]="'component-part.list.title'" (closeSidebar)="closeDialog.emit()"></app-sidebar-header>
            
            <app-sidebar-content>
                <div *ngIf="summaryRows$ | async as summaryRows">
                    <div class="kk-mat-dark-theme mb-2">
                        <mat-form-field [appearance]="'outline'" [style.width.%]="100">
                            <mat-label>{{'component-part.list.search' | translate}}</mat-label>
                            <input type="text" matInput [formControl]="filterCtrl"/>    
                        </mat-form-field>
                        
                        <mat-form-field [appearance]="'outline'" [style.width.%]="100">
                            <mat-label>{{'component-part.list.summary' | translate}}</mat-label>
                            <mat-select [formControl]="typesCtrl" multiple [disableOptionCentering]="true">
                                <mat-select-trigger>
                                    {{typesCtrl.value ? localize(typesCtrl.value[0]) : ''}}
                                    <span *ngIf="typesCtrl.value?.length > 1">(+{{typesCtrl.value.length - 1}})</span>
                                </mat-select-trigger>
                                <mat-option *ngFor="let row of summaryRows" [value]="row">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>{{row.localizedType}}</span><span class="badge badge-pill badge-primary">{{row.count}}</span>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
    
                <div *ngIf="filteredData$ | async as entries else loading">
                    <div class="filtered-count">
                        <span class="badge badge-pill badge-primary">{{entries.length}}</span> {{'search-result-tabs.COMPONENT_PARTS' | translate}}
                    </div>
                    <div class="scrollable-content">
                        <ul>
                            <li *ngFor="let row of entries">
                                <a class="component-part-entry" [ngClass]="{active: isVisible(row), selected: row.id === selectedComponentPartId}" (click)="goto(row)">
                                    <div>{{row.title}}</div>
                                    <div class="details-row">
                                        <a class="meta-available" (click)="toggleMeta(row)" [ngClass]="{active: isMetaDisplayed(row)}" *ngIf="row.finnaId != null"><i class="fa fa-info-circle fa-fw me-1"></i></a>
                                        <span class="cp-types">{{row.localizedType}}</span>
                                        <span class="page-number">{{getPageSpan(row)}}</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>    
                </div>
            </app-sidebar-content>
        </app-sidebar>
        <ng-template #loading>
            <div class="mb-3">
                <app-progress-spinner></app-progress-spinner>
            </div>
        </ng-template>
    `,
    styleUrls: [
        "./component-part-list.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponentPartListComponent implements OnInit, OnDestroy {

    constructor(private bindingRest: BindingRestEndpoint,
                @Inject(BINDING_VIEW) private currentBindingView: ICurrentBindingView,
                private ref: ChangeDetectorRef,
                private translate: TranslateService) {
        this.sub.add(this.currentBindingView.loadedPageNumber$.subscribe(page => {
            this.currentPage = page;
            ref.markForCheck();
        }));
        
        // initialize state from cbv
        this.currentBindingView.nonUrlState$.pipe(take(1)).subscribe(s => {
            this.selectedComponentPartFinnaId = s.componentPart;
        });
        this.sub.add(this.currentBindingView.selectedComponentPartId$.subscribe(id => {
            this.selectedComponentPartId = id;
        }));
    }

    @Input() bindingId: number;

    @Output() closeDialog = new EventEmitter<void>();

    filterCtrl = new FormControl();
    typesCtrl = new FormControl();
    
    private summaryRows = new Subject<UiSummaryRow[]>();
    summaryRows$: Observable<UiSummaryRow[]> = this.summaryRows;
    data$ = new Subject<UiRow[]>();
    filter$ = withInitialValue('', this.filterCtrl.valueChanges.pipe(debounceTime(50)));
    typeFilter$: Observable<UiSummaryRow[]> = withInitialValue([], this.typesCtrl.valueChanges);
    filteredData$: Observable<UiRow[]> = combineLatest([this.data$ as Observable<UiRow[]>, this.filter$, this.typeFilter$]).pipe(
        map(([d, f, types]) => {
            return this.filterByTypes(types, this.filterByQuery(f, d));
        }));

    currentPage: number;
    
    // This is for highlighting the areas. Which is not the same as...
    selectedComponentPartId: number = null;
    // ...this, which is for metadata overlay.
    selectedComponentPartFinnaId: string = null;
    
    sub = new Subscription();

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
    
    ngOnInit(): void {
        this.bindingRest.listComponentPartTypes(this.bindingId).subscribe((types) => {
            this.summaryRows.next(this.convertSummaryRows(types));
        });
        this.bindingRest.listComponentParts(this.bindingId).subscribe((results) => {
            this.data$.next(this.convertResultRows(results));
        });
    }

    private convertSummaryRows(types: ComponentPartSummaryDTO[]): UiSummaryRow[] {
        return types.map((r) => {
            return Object.assign({
                localizedType: this.localize(r)
            }, r);
        });
    }

    private convertResultRows(results: ComponentPartListDTO[]): UiRow[] {
        let counter = 0;
        const namelessLabel = this.translate.instant("component-part.no-title.substitute");
        return results.map((r) => {
            const localizedType = this.localize(r);
            
            const title = r.title != null ? r.title : `${namelessLabel} #${++counter}`;
            const result: UiRow = Object.assign(r, {
                title,
                localizedType,
                searchStrings: [title.toLowerCase(), localizedType.toLowerCase()]
            });
            return result;
        });
    }
    
    public localize(row: ComponentPartListDTO|ComponentPartSummaryDTO): string {
        if (row && row.mainType && row.subType) {
            const mt = translationOrDefault(this.translate, 'component-part.mets.types.' + row.mainType, row.mainType);
            const st = translationOrDefault(this.translate, 'component-part.mets.sub-types.' + row.subType, row.subType);
            return `${mt} > ${st}`;
        } else if (row && row.subType) {
            return translationOrDefault(this.translate, 'component-part.mets.sub-types.' + row.subType, row.subType);
        } else {
            return '';
        }
    }

    private filterByQuery(query: string, d: UiRow[]): UiRow[] {
        if (query?.length > 0) {
            const normalizedFilter = query.toLowerCase();
            return d.filter(r => r.searchStrings.some(s => s.includes(normalizedFilter)));
        } else {
            return d;
        }
    }

    private filterByTypes(types: UiSummaryRow[], d: UiRow[]): UiRow[] {
        if (types?.length > 0) {
            return d.filter(r => types.some(t => t.localizedType === r.localizedType));
        } else {
            return d;
        }
    }

    public getPageSpan(row: ComponentPartListDTO): string|number {
        const pageNumbers = row.pageNumbers;
        
        if (pageNumbers.length === 0)
            return "";
        if (pageNumbers.length === 1)
            return pageNumbers[0];
        else
            return pageNumbers[0] + "-" + pageNumbers[pageNumbers.length - 1];
    }
    
    public isVisible(row: ComponentPartListDTO): boolean {
        return row.pageNumbers.includes(this.currentPage);
    }
    
    public isNavigable(row: ComponentPartListDTO): boolean {
        return row.pageNumbers.length > 0;
    };
    
    public goto(row: ComponentPartListDTO): void {
        this.currentBindingView.goToPage(new PageRequest(row.pageNumbers[0], PageRequestSource.TOC_CLICK));
        this.currentBindingView.setSelectedPartId(row.id);
    }

    public toggleMeta(row: ComponentPartListDTO): void {
        if (this.selectedComponentPartFinnaId === row.finnaId) {
            this.currentBindingView.showComponentPartMetadata(null);
            this.selectedComponentPartFinnaId = null;
        } else {
            this.currentBindingView.showComponentPartMetadata(row.finnaId);
            this.selectedComponentPartFinnaId = row.finnaId;
        }
    }
    
    public isMetaDisplayed(row: ComponentPartListDTO): boolean {
        return row.finnaId === this.selectedComponentPartFinnaId;
    }
}

interface UiSummaryRow extends ComponentPartSummaryDTO {
    localizedType: string;
}

interface UiRow extends ComponentPartListDTO {
    localizedType: string;
    searchStrings: string[];
}