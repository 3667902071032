import {Component, Input, OnInit} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DateAccuracy, MissingBindingInfoDto, MissingBindingInfoType, SerialPublicationRestEndpoint, VariableAccuracyDate} from "../../../apina-digiweb";
import {formatVariableAccuracyDate, getDateAccuracy} from "../../../utils/date";

@Component({
    selector: 'app-missing-info-modal',
    template: `
        <div appModalDraggable [appModalDraggableHandle]="draggableHandle">
            <div class="modal-header" #draggableHandle>
                <h4 class="modal-title" translate>missing-binding.edit.title</h4>
                <button type="button" class="close" (click)="activeModal.dismiss()" aria-hidden="true">&times;</button>
            </div>
            <div class="modal-body">
                <app-progress-spinner *ngIf="loading"></app-progress-spinner>
                <form *ngIf="!loading" class="form-horizontal">
                    <div class="form-row">
                        <div class="form-group col-md-8">
                            <label translate>missing-binding.field.issn</label>
                            <p class="form-control-static">{{model.issn}}</p>
                        </div>
                        <div class="form-group col-md-4">
                            <label translate>missing-binding.field.date</label>
                            <p class="form-control-static">{{formattedDate}}</p>
                        </div>
                    </div>
    
                    <div class="form-row">
                        <div class="form-group col-8">
                            <label translate>missing-binding.field.type</label>
                            <select [(ngModel)]="model.type" name="type" class="form-control">
                                <option *ngFor="let type of missingBindingInfoTypes" [value]="type">
                                    {{type | translateMissingBindingInfoType}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-4">
                            <label translate>missing-binding.field.issue</label>
                            <input type="text" [(ngModel)]="model.issue" name="issue" class="form-control"/>
                        </div>
                        <div class="form-group col-12">
                            <label translate>missing-binding.field.description</label>
                            <textarea rows="5" [(ngModel)]="model.description" name="description"
                                      class="form-control"></textarea>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer" *ngIf="!loading">
                <a class="btn btn-kk-red" (click)="removeMissingBindingInfo()" [hidden]="!existing"
                   translate>common.action.delete</a>
                <a class="btn btn-kk-gray" (click)="activeModal.dismiss()" translate>common.action.cancel</a>
                <a class="btn btn-kk-blue" (click)="saveMissingBindingInfo()" translate>common.action.save</a>
            </div>
        </div>
    `
})
export class MissingInfoModalComponent implements OnInit {

    loading = true;

    @Input() issn: string;
    @Input() date: VariableAccuracyDate;

    missingBindingInfoTypes: MissingBindingInfoType[] = Object.keys(MissingBindingInfoType) as any[];

    model: MissingBindingInfoDto;
    existing: boolean;

    constructor(public activeModal: NgbActiveModal,
                private serialPublicationEndpoint: SerialPublicationRestEndpoint) {
    }

    ngOnInit(): void {
        this.serialPublicationEndpoint.findMissingBindingInfoForYear(this.issn, this.date).subscribe(result => {
            if (result.payload == null) {
                this.model = {
                    issn: this.issn,
                    date: this.date,
                    type: MissingBindingInfoType.MISSING,
                    description: '',
                    issue: ''
                };
                this.existing = false;
            } else {
                this.model = result.payload;
                this.existing = true;
            }
            this.loading = false;
        });
    }

    get dateAccuracy(): DateAccuracy {
        return getDateAccuracy(this.date);
    }

    get formattedDate(): string {
        return formatVariableAccuracyDate(this.date);
    }

    saveMissingBindingInfo() {
        this.serialPublicationEndpoint.saveMissingBindingInfo(this.model).subscribe(() => {
            this.activeModal.close();
        });
    }

    removeMissingBindingInfo() {
        this.serialPublicationEndpoint.deleteMissingBindingInfoForVariableDate(this.issn, this.date).subscribe(() => {
            this.activeModal.close();
        });
    }
}
