import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavigationService } from '../../navigation.service';
import { SettingsService } from '../../settings.service';
import { UserconsentService, ConsentCategories } from '../userconsent.service';

/**
 * Banner for quick and easy consent when no consent has been given
 */
@Component({
    selector: "userconsent-banner-component",
    templateUrl: "userconsent-banner.component.html",
    styleUrls: [
        "./userconsent-banner.component.scss"
    ]
})

export class UserconsentBannerComponent implements OnInit {

    isShown: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private settingsService: SettingsService,
        private navigationService: NavigationService,
        private userConsentService: UserconsentService
    ) {}

    get basePaths() { return this.navigationService.basePaths; }

    ngOnInit() {
        if(this.settingsService.commonOptions.includeExternalResources) this.validateConsent();
    }

    agreeConsent() {
        const consentForAll = ConsentCategories.ESSENTIAL + ',' + ConsentCategories.OTHER + ',' + ConsentCategories.MARKETING + ',' + ConsentCategories.PREFERENCE + ',' + ConsentCategories.STATISTIC;
        this.userConsentService.setAgreedCategories(consentForAll);
        location.reload();
    }

    agreeEssentialConsent() {
        this.userConsentService.setAgreedCategories(ConsentCategories.ESSENTIAL);
        location.reload();
    }

    validateConsent() {
        if(!!!this.userConsentService.getAgreedCategories()) {
            this.isShown.next(true);
        } else if (!!this.userConsentService.getAgreedCategories()) {
            this.isShown.next(false);
            this.userConsentService.loadServices();
        }
    }
}