import {Component, HostListener, Inject, Input} from "@angular/core";
import {BINDING_VIEW, ICurrentBindingView} from "../../binding/types";
import MouseEnterEvent = JQuery.MouseEnterEvent;
import MouseLeaveEvent = JQuery.MouseLeaveEvent;
import Timeout = NodeJS.Timeout;

const FADEOUT_MS = 5000;

@Component({
    selector: "app-binding-restricted-warning",
    template: `
        <div class="kk-bg-dark kk-mat-dark-theme restricted-warning" *ngIf="enabled">
            <div class="cursor-pointer p-3" (click)="currentBindingView.toggleAgreements()">
                <i class="fa fa-lock pr-1" style="color: yellow"></i>
                <span [innerHTML]="'restricted-material.copyright.warning' | translate"></span>
            </div>

            <button mat-icon-button attr.aria-label="{{'help.clipping.close'|translate}}" (click)="disable($event)">
                <i class="fa fa-times"></i>
            </button>
        </div>
    `,
    styleUrls: [
        "./binding-restricted-warning.scss"
    ]
})
export class BindingRestrictedWarningComponent {

    @Input() enabled: boolean;

    private mouseOver = false;
    private timeoutId: Timeout = null;

    @HostListener("mouseenter", ["$event"])
    onMouseEnter(event: MouseEnterEvent) {
        this.mouseOver = true;
    }

    @HostListener("mouseleave", ["$event"])
    onMouseLeave(event: MouseLeaveEvent) {
        this.mouseOver = false;
        this.startFadeoutTimer();
    }

    constructor(@Inject(BINDING_VIEW) public currentBindingView: ICurrentBindingView) {
        this.startFadeoutTimer();
    }

    disable(event: Event) {
        event.preventDefault();
        this.enabled = false;
    }

    private startFadeoutTimer() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }

        this.timeoutId = setTimeout(() => {
            if (!this.mouseOver) {
                this.enabled = false;
            }
        }, FADEOUT_MS);
    }
}
