// TODO: replace these by Apina-generated types
import {ArticleInfo, BindingInformation, ComponentPartBlock, OcrBlock, OcrData, OcrVersion, PageInformation, PageRequestSource, RedactionDto} from "../apina-digiweb";
import {BehaviorSubject, Observable} from "rxjs";
import {InjectionToken} from "@angular/core";
import {map, shareReplay} from "rxjs/operators";
import {filterNonNull, ResourceLoadingException} from "../utils/observable-utils";

export const BINDING_VIEW = new InjectionToken<ICurrentBindingView>('currentBindingView');

export enum ZoomType {NO_CHANGE, IN, OUT}

export enum FitBy {WIDTH, HEIGHT}

export class PercentagePoint {
    constructor(public readonly x: number,
                public readonly y: number) {
    }
}

export const PAGE_MIDDLE = new PercentagePoint(0.5, 0.5);

export class ZoomEvent {
    constructor(public readonly zoomLevel: number,
                public readonly type: ZoomType,
                public readonly focus?: PercentagePoint | IRegion) {
    }
}

export enum ClippingMode {OFF = "OFF", ARTICLE = "ARTICLE", REDACTION = "REDACTION", PRINT = "PRINT"}

export interface OcrInfo {
    ocrVersion: OcrVersion;
    reOcrAvailable: boolean;
}

export interface BindingViewParams {
    id: number;
    articleId: number;
}

/**
 * This is the state that is reflected in URL.
 */
export interface BindingViewState {
    page: number;
    displayMarc: boolean;
    displayNerLocations: boolean;
    displayNerPersons: boolean;
    displayOcr: boolean;
    oldOcr: boolean;
    ocrBorders: boolean;
    searchTerms: string[];
}

export interface NonUrlState {
    componentPart: string | null; // finnaId
    displayAgreements: boolean;
    displayArticles: boolean;
    displayBindingProblemDialog: boolean;
    displayCitation: boolean;
    displayComponentTree: boolean;
    displayHideBindingDialog: boolean;
    displayParts: boolean,
    displaySearch: boolean;
    displayToc: boolean;
}


export class PageRequest {
    constructor(public readonly pageNumber: number | null, /* null means page request without the page number defined in url, which gets redirected to page 1 */
                public readonly source: PageRequestSource) {
    }
}

export class ActivePageResult {
    constructor(public readonly pageRequest: PageRequest,
                public readonly redirectedPage: number,
                public readonly page: ClientPageInformation) {
    }
}

export interface ICurrentBindingView {
    allowClipping: boolean;
    bindingId: number;

    page$: Observable<number | null>;
    displayComponentTree$: Observable<boolean>;
    displayParts$: Observable<boolean>;
    displayOcr$: Observable<boolean>;
    displayMarc$: Observable<boolean>;
    oldOcr$: Observable<boolean>;
    ocrBorders$: Observable<boolean>;
    searchTerms$: Observable<string[]>;
    bindingViewState$: Observable<BindingViewState | null>;
    safeBindingViewState$: Observable<BindingViewState>;

    componentPart$: Observable<string | null>;
    displayAgreements$: Observable<boolean>;
    displayArticles$: Observable<boolean>;
    displayBindingProblemDialog$: Observable<boolean>;
    displayCitation$: Observable<boolean>;
    displayHideBindingDialog$: Observable<boolean>;
    displayNerLocations$: Observable<boolean>;
    displayNerPersons$: Observable<boolean>;
    displaySearch$: Observable<boolean>;
    displayToc$: Observable<boolean>;
    nonUrlState$: Observable<NonUrlState>
    
    continueAfterSave: boolean;
    dragMode: boolean;
    editExisting: boolean;
    enableOcr: boolean;
    clippingEditorOpenSnapshot: boolean;
    saving: boolean;

    clippingEditorOpen$: Observable<boolean>;
    clippingMode$: Observable<ClippingMode>;
    isSideViewActive$: Observable<boolean>;
    isRestrictedMaterialWarningVisible$: Observable<boolean>;
    zoomEvents$: Observable<ZoomEvent>;
    activePageResult$: Observable<ActivePageResult>;
    loadedPage$: Observable<IPageInformation>;
    loadedPageNumber$: Observable<number>;
    currentPageOcrData$: Observable<OcrData | ResourceLoadingException>;
    highlightedBlock$: Observable<OcrBlock | null>;
    componentTreeBlocks$: Observable<IRegion[] | null>;
    componentTreeUberBlock$: Observable<IRegion | null>;
    bindingInfo$: Observable<BindingInformation>;
    pages$: Observable<IPageInformation[]>;
    pagesCount$: Observable<number>;
    ocrInfo$: Observable<OcrInfo>
    ocrBlocks$: Observable<OcrBlock[] | null>;
    componentPartAreas$: Observable<ComponentPartBlock[] | ResourceLoadingException>;
    selectedComponentPartId$: Observable<number | null>;
    selectedComponentPartAreas$: Observable<ComponentPartBlock[]>;
    selectedComponentPartUberBlock$: Observable<ComponentPartBlock>
    userArticleClippingsOnCurrentPage$: Observable<number>; // the ones we aren't editing
    clippings$: Observable<IClipping[]>; // is used from ClippingFormComponent and PrintClippingFormComponent
    clippingsSnapshot: IClipping[];
    clippingsOnCurrentPage$: Observable<IClipping[]>;
    areArticlesVisible$: Observable<boolean>;
    areRedactionsVisible$: Observable<boolean>;
    editableRedaction$: Observable<RedactionDto | null>;

    initialize(params: BindingViewParams, initialState: BindingViewState): Observable<void>;

    editRedaction(redaction: RedactionDto): void;
    showHelp(): void;
    showComponentPartMetadata(finnaId: string): void;
    goToPage(pageRequest: PageRequest): void;
    nextPage(): void;
    previousPage(): void;
    toggleUserArticles(): void;
    toggleArticleEdit(): void;
    togglePrintEdit(): void;
    toggleRedactEdit(): void;
    toggleOcr(): void;
    toggleToc(): void;
    toggleSearch(): void;
    toggleComponentTree(): void;
    toggleParts(): void;
    toggleMarc(): void;
    toggleOcrBorders(): void;
    toggleOldOcr(): void;
    toggleAgreements(): void;
    toggleCitation(): void;
    toggleNerLocations(): void;
    toggleNerPersons(): void;
    toggleBindingProblemDialog(): void;
    toggleHideBindingDialog(): void;
    setPageView(view: IBindingPageView): void;
    rotate90(): void;
    isRotated(): boolean;
    setSearchTerms(terms: string[]): void;
    fitWidth(): void;
    fitHeight(): void;
    zoomIn(): void;
    zoomOut(): void;
    setZoom(value: number): void;
    focalZoom(value: number, point: PercentagePoint): void;
    zoomToRegion(block: IRegion): void;
    panToRegion(block: IRegion): void;
    isHorizontallyScrollable(): boolean;
    setHighlightedBlock(block: OcrBlock | null): void;
    setHighlightedBlocks(block: IRegion[] | null): void;
    setSelectedPartId(partId: number | null): void;
    addClipping(clipping: IClipping): void;
    removeClipping(clipping: IClipping): void;
    emitClippingsModified(): void;
    cancelClipping(refresh?: boolean): void;
    clippingFinished(result: ArticleInfo): void;
}

export interface IBindingPageView {
    rotate90(): void;
    isRotated(): boolean;
    fit(type: FitBy): void;
    panToRegion(region: IRegion): void;
    isHorizontallyScrollable(): boolean;
}

export interface IPageInformation {
    readonly info: PageInformation;
    // These attributes are calculated on client side
    readonly image: Observable<HTMLImageElement | null>;
    width?: number;
    height?: number;
    readonly hits?: Observable<IHit[] | null>;
    readonly ner: Observable<INerTag[] | null>;
    readonly nerLocations: Observable<INerTag[]>;
    readonly nerPersons: Observable<INerTag[]>;
}

export class ClientPageInformation implements IPageInformation {
    image = new BehaviorSubject<HTMLImageElement | null>(null);
    width?: number;
    height?: number;
    hits = new BehaviorSubject<IHit[] | null>(null);
    ner = new BehaviorSubject<INerTag[] | null>(null);
    nerLocations = this.ner.pipe(filterNonNull, map(a => a.filter(n => n.type === 'LOC') || []), shareReplay(1));
    nerPersons = this.ner.pipe(filterNonNull, map(a => a.filter(n => n.type === 'PER') || []), shareReplay(1));

    constructor(public readonly info: PageInformation) {
    }
}

export interface IHit extends IRegion {
    text: string;
}

export interface INerTag extends IRegion {
    type: string;
    id: string;
    text: string;
    label: string;
}

export interface IRegion {
    x: number;
    y: number;
    width: number;
    height: number;

    onChanges?(): Observable<void>;
}

export type RegionTransformHandle =
    'border-top'
    | 'border-bottom'
    | 'border-left'
    | 'border-right'
    | 'corner-top-left'
    | 'corner-top-right'
    | 'corner-bottom-left'
    | 'corner-bottom-right'
    | 'inside';

export interface IClipping extends IRegion {
    pageNumber: number;

    updateTo(x: number, y: number, width: number, height: number): void;
    contains(px: number, py: number): boolean;
    move(type: RegionTransformHandle, dx: number, dy: number, page: IPageInformation): void;
}
