import {Injectable} from "@angular/core";
import {
    BindingSearchCriteria,
    BindingSearchRestEndpoint,
    BindingSearchResultRow,
    CollectionInfo,
    ComponentPartSearchResultRow,
    Dictionary,
    GeneralType,
    ImportTime,
    InternationalizedString,
    QueryOrder,
    SerialPublicationPlaceInfo,
    TagInfo,
    TitleInfo,
    TitleType
} from "../../apina-digiweb";
import {SettingsService} from "../settings.service";
import {TranslateService} from "@ngx-translate/core";
import {IPopoverItem} from "../popover-picker/popover-picker.component";
import {combineLatest, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {mapToList} from "../../utils/dictionary-utils";
import {formatISODate} from "../../utils/date";
import {optionalDate} from "../../search/request-param-utils";
import {ParamMap} from "@angular/router";
import {fi} from "kotlin-gen";
import {MaterialTag} from "../../apina-types";
import {ProcessedBindingSearchResultRow, ResultMode, ResultType} from "./result/result-row";
import {formatDateWithAccuracy} from "../pipe/date-with-accuracy.pipe";
import {ONETIME_PARAM_SEEK_WITHIN} from "../binding/binding-view.component";
import {IPopoverItemProvider, IPopoverResult} from "../popover-picker/popover-picker-async.component";
import {joinVisible} from "../../utils/array-utils";
import * as _ from "lodash";

export const PARAMS_RESULT_MODE = "resultMode";
export const PARAMS_RESULT_TYPE = "resultType";

export interface ValueWithLabel<T> {
    value: T;
    label: string;
}

export interface CheckBoxListItem<T> extends ValueWithLabel<T> {
    selected: boolean;
}

export interface SearchUserData {
    lastSearchableDates: Dictionary<any>;
}

export interface SearchReferenceData {
    generalTypes: GeneralType[];
    userData: SearchUserData;
}

export interface PapersForDayReferenceData {
    generalTypes: GeneralType[];
    serialPublicationPlaces: SerialPublicationPlaceInfo[];
    userData: SearchUserData;
}

export interface IPopoverItemWithGeneralType extends IPopoverItem {
    generalType: GeneralType | null;
    titleType: TitleType | null;
    seriesGeneralTypes?: GeneralType[];
}

@Injectable()
export class SearchService {

    constructor(private readonly settingsService: SettingsService,
                private readonly translate: TranslateService,
                private readonly bindingSearchApi: BindingSearchRestEndpoint) {
    }

    // TODO use async popover
    getPapersForDayReferenceData(): Observable<PapersForDayReferenceData> {
        const generalTypes = [GeneralType.NEWSPAPER];

        // separate calls, so we can make use of HTTP caching for global data
        const globalData = this.bindingSearchApi.loadPapersForDayReferenceData(this.settingsService.cacheBuster);
        const userData = this.bindingSearchApi.loadUserSpecificSearchReferenceData(generalTypes);

        return combineLatest([globalData, userData]).pipe(map(([serialPublicationPlaces, user]) => {
            return {
                generalTypes,
                serialPublicationPlaces,
                userData: {
                    lastSearchableDates: user
                }
            };
        }));
    }

    getSearchReferenceData(supportedTypes: GeneralType[]): Observable<SearchReferenceData> {
        return this.bindingSearchApi.loadUserSpecificSearchReferenceData(supportedTypes).pipe(map((data) => {
            return {
                generalTypes: supportedTypes,
                userData: {
                    lastSearchableDates: data
                }
            };
        }));
    }

    formatGeneralTypes(types: GeneralType[]): IPopoverItem[] {
        return types.map(type => ({id: type, title: this.translate.instant(`general-type.plural.${type}`)}));
    }

    formatGeneralTypesWithLabel(types: GeneralType[]): ValueWithLabel<GeneralType>[] {
        return types.map(type => ({value: type, label: this.translate.instant(`general-type.plural.${type}`)}));
    }

    formatTags(tags: TagInfo[]): IPopoverItem[] {
        return tags.map(t => {
            if (t.infoText != null)
                return {id: t.tag.serialize(), title: t.infoText, info: this.formatTag(t.tag)};
            else if (t.tag.type.startsWith("marc"))
                return {id: t.tag.serialize(), title: t.tag.value, info: this.translate.instant(`tag-type.${t.tag.type}`)};
            else
                return {id: t.tag.serialize(), title: this.formatTag(t.tag), info: t.infoText};
        });
    }

    private formatTag(tag: MaterialTag) {
        if (tag.type === 'topic')
            return tag.value;

        const type = this.translate.instant('tag-type.' + tag.type);
        return `${type}:${tag.value}`;
    }

    createCheckBoxList<T>(selectedValues: T[], referenceData: ValueWithLabel<T>[]): CheckBoxListItem<T>[] {
        return referenceData.map(i => {
            return {
                value: i.value,
                label: i.label,
                selected: selectedValues.includes(i.value)
            };
        });
    }

    getSelectedItems<T>(checkboxes: CheckBoxListItem<T>[]): T[] {
        return checkboxes.filter(i => i.selected).map(i => i.value);
    }

    getHighlightsAsHtml(textHighlights: Dictionary<string[]>): string[] {
        return mapToList(textHighlights, (k, fragments) => {
            if (fragments.length > 0) {
                const joined = k === 'text' ? "..." + fragments.join("...") + "..." : fragments.join(", ");
                return `${this.translate.instant('search.highlight.prefix.' + k)}: ${joined}`;
            } else
                return null;
        }).filter(s => s !== null);
    }


    /**
     * Creates parameters for URL from criteria.
     */
    paramsFromCriteria(criteria: BindingSearchCriteria, page: number | null, partPage: number | null, resultMode: ResultMode | null, resultType: ResultType | null, removeDefaults: boolean): Dictionary<any> {
        const searchParams = new fi.kansalliskirjasto.search.kotlin.SearchParams(
            criteria.query,
            criteria.fuzzy,
            criteria.hasIllustrations,
            criteria.queryTargetsMetadata,
            criteria.queryTargetsOcrText,
            criteria.requireAllKeywords,
            criteria.showLastPage,
            criteria.searchForBindings,
            criteria.includeUnauthorizedResults,
            criteria.importTime,
            criteria.formats,
            criteria.languages,
            criteria.collections,
            null, // series is included in publications, i think
            criteria.publications,
            criteria.authors,
            criteria.districts,
            criteria.publicationPlaces,
            criteria.publishers,
            criteria.tags,
            formatISODate(criteria.importStartDate),
            formatISODate(criteria.endDate),
            formatISODate(criteria.startDate),
            criteria.orderBy,
            criteria.pages,
            page,
            partPage,
            resultMode,
            resultType
        );

        return fi.kansalliskirjasto.search.kotlin.toDictionary(searchParams, removeDefaults);
    }

    /**
     * Creates search criteria from parameters.
     */
    parseSearchParams(params: ParamMap): fi.kansalliskirjasto.search.kotlin.SearchParams {
        return fi.kansalliskirjasto.search.kotlin.parse(params);
    }

    criteriaFromParams(searchParams: fi.kansalliskirjasto.search.kotlin.SearchParams): BindingSearchCriteria {
        return {
            authors: searchParams.author,
            collections: searchParams.collection,
            exactCollectionMaterialType: false,
            districts: searchParams.district,
            endDate: optionalDate(searchParams.endDate),
            formats: searchParams.formats as GeneralType[],
            fuzzy: searchParams.fuzzy,
            hasIllustrations: searchParams.hasIllustrations,
            importStartDate: optionalDate(searchParams.importStartDate),
            importTime: searchParams.importTime as ImportTime,
            includeUnauthorizedResults: searchParams.showUnauthorizedResults,
            languages: searchParams.lang,
            orderBy: searchParams.orderBy as QueryOrder,
            pages: searchParams.pages,
            publicationPlaces: searchParams.publicationPlace,
            publications: searchParams.title,
            publishers: searchParams.publisher,
            query: searchParams.query,
            queryTargetsMetadata: searchParams.qMeta,
            queryTargetsOcrText: searchParams.qOcr,
            requireAllKeywords: searchParams.requireAllKeywords,
            searchForBindings: searchParams.searchForBindings,
            showLastPage: searchParams.showLastPage,
            startDate: optionalDate(searchParams.startDate),
            tags: searchParams.tag
        }
    }

    public processBindingRows(rows: BindingSearchResultRow[]): ProcessedBindingSearchResultRow[] {
        return rows.map(originalRow => {
            const row: ProcessedBindingSearchResultRow = Object.assign({}, originalRow) as any;
            const bindingName = row.issue != null ? `${row.bindingTitle} ${row.issue}` : row.bindingTitle;
            row.captionTitle = `${bindingName} s. ${row.pageNumber} \n ${formatDateWithAccuracy(row.date, row.dateAccuracy)} \n${row.publisher} \n ${row.placeOfPublication}`;
            row.mainLinkTitle = row.bindingTitle;
            row.authorList = row.authors.join("; ");
            row.highLights = this.getHighlightsAsHtml(row.textHighlights);
            return row;
        });
    }

    public processComponentPartRows(rows: ComponentPartSearchResultRow[]): ProcessedBindingSearchResultRow[] {
        const noLabel = this.translate.instant("component-part.no-title.substitute");
        
        return rows.map(originalRow => {
            const row: ProcessedBindingSearchResultRow = Object.assign({}, originalRow.bindingRow) as any;
            const bindingName = row.issue != null ? `${row.bindingTitle} ${row.issue}` : row.bindingTitle;
            row.captionTitle = `${bindingName} s. ${row.pageNumber} \n ${formatDateWithAccuracy(row.date, row.dateAccuracy)} \n${row.publisher} \n ${row.placeOfPublication}`;

            row.highLights = this.getHighlightsAsHtml(row.textHighlights);

            // part details
            row.mainLinkTitle = originalRow.title || noLabel; // no numbering here
            row.belongsToTitle = row.bindingTitle;

            // use part data if available, otherwise binding data
            const effectiveAuthors = originalRow.authors.length > 0 ? originalRow.authors : row.authors;
            row.authorList = effectiveAuthors.join("; ");

            // tweak url to contain seek instruction for binding-view
            row.url += "&" + ONETIME_PARAM_SEEK_WITHIN + "=" + row.pageNumber + "-";

            return row;
        });
    }

    createTitleProvider(generalTypes: () => GeneralType[], startYear: () => number, endYear: () => number): IPopoverItemProvider {
        const self = this;
        return {
            matches(item: IPopoverItem): boolean {
                const i = item as TitleInfo;
                const gt = generalTypes();
                const generalTypeMatches = gt.length === 0 || gt.includes(i.generalType);
                
                const y1 = startYear();
                const y2 = endYear();

                const dateMatches = (y1 == null || i.firstYear >= y1) && (y2 == null || i.lastYear <= y2);

                return generalTypeMatches && dateMatches;
            },

            fetch(query: string, selectedIds: string[], maxResults: number): Observable<IPopoverResult> {
                return self.bindingSearchApi.searchTitles({
                    selectedIds,
                    query,
                    generalTypes: generalTypes(),
                    maxResults
                }).pipe(map((r) => {
                    return {
                        selectedIds: r.selectedIdResults.map(t => self.formatTitle(t)),
                        queryResults: r.queryResults.map(t => self.formatTitle(t)),
                        totalResults: r.totalResults,
                        totalPossibleResults: r.totalPossibleResults
                    }
                }));
            }
        }
    }

    private formatTitle(t: TitleInfo): IPopoverItemWithGeneralType {
        const type = this.translate.instant(`title-type.${t.titleType}`);

        switch (t.titleType) {
            case TitleType.SERIAL_PUBLICATION:
            case TitleType.OPUS:
                return {
                    id: t.id,
                    title: t.title,
                    generalType: t.generalType,
                    titleType: t.titleType,
                    info: joinVisible([t.firstYear, t.publishingPlaces, t.publisher]),
                    info2: joinVisible([this.formatGeneralType(t), t.id]),
                    type
                };
            case TitleType.SERIES:
                return {
                    id: t.id,
                    title: t.title,
                    generalType: t.generalType,
                    titleType: t.titleType,
                    info: joinVisible([type, t.info]),
                    type,
                    seriesGeneralTypes: t.seriesGeneralTypes || undefined
                };
        }
    }
    
    private formatGeneralType(t: TitleInfo) {
        if (t.generalType == null)
            return null;
        else
            return this.translate.instant('general-type.' + t.generalType);
    }
    
    createCollectionProvider(): IPopoverItemProvider {
        const self = this;
        return {
            fetch(query: string, selectedIds: string[], maxResults: number): Observable<IPopoverResult> {
                return self.bindingSearchApi.searchCollections({
                    selectedIds,
                    query,
                    generalTypes: null,
                    maxResults
                }).pipe(map((r) => {
                    return {
                        selectedIds: r.selectedIdResults.map(s => self.formatCollection(s)),
                        queryResults: r.queryResults.map(s => self.formatCollection(s)),
                        totalResults: r.totalResults,
                        totalPossibleResults: r.totalPossibleResults
                    }
                }));
            },

            matches(item: IPopoverItem): boolean {
                return true;
            }
        };
    }
    
    private formatCollection(s: CollectionInfo): IPopoverItem {
        return {
            id: s.id,
            title: this.formatCollectionTitle(s),
            info: this.formatCollectionParentPath(s)
        };
    }

    private formatCollectionTitle(s: CollectionInfo): string {
        const lang = this.translate.currentLang;
        if (s.ancestors.length > 0) {
            const padding = _.pad("", s.ancestors.length * 3, ' ');
            return padding + this.localizeI18NString(s.name, lang);
        } else {
            return this.localizeI18NString(s.name, lang);
        }
    }

    private formatCollectionParentPath(s: CollectionInfo): string {
        const lang = this.translate.currentLang;
        if (s.ancestors.length > 0) {
            return s.ancestors.map(a => this.localizeI18NString(a.name, lang)).reverse().join(" > ");
        } else {
            return undefined;
        }
    }

    private localizeI18NString(s: InternationalizedString, lang: string): string {
        if  (lang === 'sv') {
            return s.sv || s.fi;
        } else if (lang === 'en') {
            return s.en || s.fi;
        } else {
            return s.fi;
        }
    }
}