import {Directive, EventEmitter, HostListener, Output} from "@angular/core";

@Directive({
    selector: "[appMousedownOrTouchstart]"
})
export class MousedownOrTouchstartDirective {

    @Output() appMousedownOrTouchstart = new EventEmitter<MouseEvent|TouchEvent>();

    @HostListener("mousedown", ["$event"])
    onMouseDown(event: MouseEvent) {
        this.appMousedownOrTouchstart.emit(event);
    }

    @HostListener("touchstart", ["$event"])
    onTouchStart(event: Event) { // webpack compilation fails on Firefox if we use TouchEvent here
        this.appMousedownOrTouchstart.emit(event as any);
    }
}
