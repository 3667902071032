import {Component} from "@angular/core";
import {SettingsService} from "../settings.service";

@Component({
    selector: "app-humansitemap",
    template: `
        <div class="container-fluid other-section">
            <h2 align="center" class="mt-5 mb-5">{{'sitemap.title' | translate}}</h2>
            
            <p class="sitemap_indent">
                <i class="fa-solid fa-house-chimney fa-fw"></i>
                    <a href="/">{{'breadcrumbs.frontpage' | translate}}</a><br>
                <i class="fa-solid fa-magnifying-glass fa-fw"></i>
                    <a href="/search">{{'search.main.header' | translate}}</a><br>
                <i class="fa-solid fa-rectangle-history fa-fw"></i>
                <a href="/collections">{{'kirja.main.links.collection' | translate}}</a><br>
                <i class="fa-solid fa-newspaper fa-fw"></i>
                <a href="/serial-publications">{{'serialpublications.main.header' | translate}}</a><br>
                <i class="fa-regular fa-calendar fa-fw"></i>
                <a href="/papers-for-day">{{'papers-for-day.main.header' | translate}}</a><br>
                <i class="fa-solid fa-scissors fa-fw"></i>
                <a href="/clippings">{{'main.links.user.articles' | translate}}</a><br>
            </p>
            
            <p class="sitemap_indent">
                <i class="fa-solid fa-question fa-fw fa-fw"></i>
                <a href="/faq">{{'faq.name' | translate}}</a><br>
                <i class="fa-solid fa-exclamation fa-fw fa-fw"></i>
                <a href="/terms">{{'main.terms.terms_of_use' | translate}}</a><br>
                <i class="fa-solid fa-lock fa-fw fa-fw"></i>
                <a href="/info">{{'main.info.service' | translate}}</a><br>
                <i class="fa-solid fa-dog-leashed fa-fw fa-fw"></i>
                <a href="/saavutettavuus">{{'main.accessibility.label' | translate}}</a><br>
                <i class="fa-solid fa-pen-nib fa-fw fa-fw"></i>
                <a href="/sanomalehti/directory">{{'legacy-news-articles.footer.title' | translate}}</a><br>
                <i class="fa-solid fa-binary fa-fw fa-fw"></i>
                <a href="/opendata">{{'opendata.footer.title' | translate}}</a><br>
                <i class="fa-solid fa-user-graduate fa-fw fa-fw"></i>
                <a href="/tutkain">{{'tutkain.page.title' | translate}}</a><br>

                <i class="fa-solid fa-book-arrow-right fa-fw fa-fw"></i>
                <a href="/other">{{'breadcrumbs.section.other' | translate}}</a><br>

                <i class="fa-solid fa-table fa-fw fa-fw"></i>
                <a href="/stats">{{'main.stats' | translate}}</a><br>

                <i class="fa-solid fa-file-magnifying-glass fa-fw fa-fw"></i>
                <a href="/name-search">{{'main.name-search' | translate}} (alpha)</a><br>

                <i class="fa-solid fa-cookie fa-fw fa-fw"></i>
                <a href="/evasteasetukset">{{'cookies.title' | translate}}</a><br>
                
            </p>
            
            <p class="sitemap_indent">
                <a href="/pienpainate/directory/2">{{'breadcrumbs.subsection.printings.publishers' | translate}}</a><br>
                <a href="/pienpainate/centuries">{{'breadcrumbs.subsection.printings.years' | translate}}</a><br>
                <br>
                <i class="fa-solid fa-lightbulb-on fa-fw fa-fw">&nbsp;</i>{{'id.tip'|translate}}<br>
                <a href="/id/fd2020-00029193">https://digi.kansalliskirjasto.fi/id/fd2020-00029193</a>
            </p>
            
        </div>
    `
})
export class HumansitemapComponent {  // other component

    constructor(private settingsService: SettingsService) {

    }

    get externalResourcesEnabled() {
        return this.settingsService.commonOptions.includeExternalResources;
    }


}
