import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FeedbackService } from './feedback.service';
import { TranslateService } from "@ngx-translate/core";

enum SendStatus {
    SENDING = "sending",
    FAILED = "failed",
    SUCCESS = "success",
    DEFAULT = ""
}

@Component({
    selector: "feedback-component",
    templateUrl: "feedback.component.html",
    styleUrls: [
        "./feedback.component.scss"
    ]
})

export class FeedbackComponent implements OnInit {
    sendStatus = SendStatus.DEFAULT;
    feedbackForm: FormGroup;

    constructor(
        public feedbackService: FeedbackService,
        private translate: TranslateService
    ) {
        this.initForm();
    }

    /**
     * Gets feedback form message to be displayed when component loads.
     */
    ngOnInit() {
        this.feedbackService.getFeedbackMessage();
    }

    /**
     * Initializes the feedback form with default values
     */
    initForm() {
        this.feedbackForm = new FormGroup({
            customer: new FormControl(""),
            customerEmail: new FormControl("", [
                Validators.maxLength(320),
                Validators.email
            ]),
            requestType: new FormControl("reqt-16"),
            subject: new FormControl("", [
                Validators.maxLength(500),
                Validators.required
            ]),
            description: new FormControl(this.initialDescription, [
                Validators.maxLength(5000),
                Validators.required
            ]),
            categoryLevel1: new FormControl("CAT-00002785"),
            categoryLevel2: new FormControl("CAT-00008085"),
            contactType: new FormControl("Verkkolomake"),
            unit: new FormControl("UNIT-00000087"),
            supportGroup: new FormControl("SG-00000130")
        });
        this.feedbackForm.get("customerEmail").valueChanges.subscribe(val => {
            this.feedbackForm.get("customer").setValue(val);
        });
    
    }

    /**
     * Send feedback to Efecte
     */
    async sendFeedback() {
        if(this.feedbackForm.invalid) return this.feedbackForm.markAllAsTouched();
        this.sendStatus = SendStatus.SENDING;
        try {
            let formValue = this.feedbackForm.getRawValue();
            formValue.description = formValue.description.replace(/\n/g, "\\n");
            const send = await this.feedbackService.sendFeedback(formValue) as any;
            if(send?.status === SendStatus.SUCCESS) {
                this.resetForm();
            } else {
                this.sendStatus = SendStatus.FAILED;
            }
        } catch (e) {
            this.sendStatus = SendStatus.FAILED;
        }
    }

    /**
     * Resets the feedback form
     */
    resetForm() {
        this.feedbackForm.get("customer").setValue("");
        this.feedbackForm.get("customerEmail").setValue("");
        this.feedbackForm.get("subject").setValue("");
        this.feedbackForm.get("description").setValue(this.initialDescription);
        this.feedbackService.toggleFeedbackForm();
        this.sendStatus = SendStatus.DEFAULT;
    }

    /**
     * Closes the feedback form
     */
    closeForm() {
        if(window.confirm(this.translate.instant("feedback.confirmClose"))) this.feedbackService.toggleFeedbackForm();
    }

    /**
     * Get initial description for feedback form description-field
     */
    get initialDescription() {
        return this.translate.instant("feedback.initialDescription", {href: window.location.href, referrer: document.referrer, userAgent: navigator.userAgent, screenWidth: screen.width, screenHeight: screen.height});
    }
}