import {AfterViewInit, Component, Renderer2} from "@angular/core";
import {DisplayService} from "./display.service";
import {NgbPaginationConfig} from "@ng-bootstrap/ng-bootstrap";
import {DocumentService} from "./document.service";
import {AccountService} from "./account/account.service";
import {SessionStorageService} from "./hacks/session-storage.service";
import {combineLatest} from "rxjs";
import { FeedbackService } from "./feedback/feedback.service";

@Component({
    selector: "app-digiweb",
    template: `
        <app-header></app-header>
        
        <app-error-message-once></app-error-message-once>
        
        <router-outlet></router-outlet>
        <userconsent-banner-component></userconsent-banner-component>
        <feedback-component *ngIf="feedbackService.displayFeedbackForm | async"></feedback-component>
    `,
    providers: [NgbPaginationConfig]
})
export class DigiwebAppComponent implements AfterViewInit {

    constructor(public displayService: DisplayService,
                private renderer: Renderer2,
                paginationConfig: NgbPaginationConfig,
                documentService: DocumentService,
                sessionStorageService: SessionStorageService,
                public feedbackService: FeedbackService,
                private accountService: AccountService) {

        const body = document.body;

        // We set the classes on document.body because of
        // 1. Legacy reasons
        // 2. It's difficult to set the "global" background color using other elements with our current design.
        //    The "full-screen mode" changes the header margin that effectively moves other elements upwards, revealing
        //    a white background at the bottom of the page, if background-color is not applied to body (or some other
        //    parent element that retains it's size and position).

        combineLatest([displayService.maximized$, displayService.articleMode$, displayService.clipView$])
            .subscribe(([max, article, clipView]) => {
                this.toggleClass(body, "maximized", max);
                this.toggleClass(body, "article", article);
                this.toggleClass(body, "clipview", clipView);
            });
        
        this.configureBootstrapPagination(paginationConfig);
    }

    toggleClass(element: any, clazz: string, value: boolean) {
        if (value)
            this.renderer.addClass(document.body, clazz);
        else
            this.renderer.removeClass(document.body, clazz);
    }

    configureBootstrapPagination(config: NgbPaginationConfig) {
        config.maxSize = 7;
        config.ellipses = false;
    }

    ngAfterViewInit(): void {
        // Prevent "content changed after it was checked" with this deferral.
        // TODO We might be doing something wrong, but anyways see https://github.com/angular/angular/issues/15634
        setTimeout(() => {
            this.accountService.checkConfirmations();
        });
    }
}
