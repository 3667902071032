import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {clipValue} from "../../utils/number-utils";
import {BindingInformation, BindingRestEndpoint, BindingSearchRestEndpoint, ClippingDto, ComponentPartBlock, GeneralType, NerOccurrence, OcrBlock, RedactionDto} from "../../apina-digiweb";
import {ClippingRect} from "../../binding/clipping-rect";
import {
    BINDING_VIEW,
    BindingViewState,
    ClientPageInformation,
    FitBy,
    IBindingPageView,
    IClipping,
    ICurrentBindingView,
    IHit,
    IPageInformation,
    IRegion,
    NonUrlState,
    PAGE_MIDDLE,
    PercentagePoint,
    RegionTransformHandle
} from "../../binding/types";
import {TranslateService} from "@ngx-translate/core";
import {ImageLoaderService} from "./image-loader.service";
import {LoggingService} from "../logging.service";
import {HttpClient} from "@angular/common/http";
import {NavigationService} from "../navigation.service";
import {IFocalZoomEvent} from "./pinchable.directive";
import {DisplayService} from "../display.service";
import {AccountService} from "../account/account.service";
import {debounceTime, filter, map, switchMap} from "rxjs/operators";
import {BehaviorSubject, combineLatest, Observable, of, Subject} from "rxjs";
import {filterNonNullArrays, Subscriptions} from "../../utils/observable-utils";
import {appendQueryParam} from "../../utils/url";
import {PlatformService} from "../platform.service";


/** The ratio of viewport width that we want to be filled by default */
const DEFAULT_ZOOM_BY_WIDTH = 0.999;
const DEFAULT_ZOOM_BY_HEIGHT = 0.999;

/** When automatically zooming into regions, we'll stay in the following bounds */
const REGION_ZOOM_MIN_WIDTH = 1000;
const REGION_ZOOM_MAX_WIDTH = 2000;
const REGION_ZOOM_MIN_HEIGHT = 800;
const REGION_ZOOM_MAX_HEIGHT = 2000;

/** How many pixels we'll try to leave at the top-left corner of zoomed region */
const AUTO_ZOOM_MARGIN = 10;

const PAGE_IMAGE_ERROR_URI = "/images/404/230x300.png";

class PageCoordinates {
    constructor(public x: number,
                public y: number) {
        if (isNaN(x) || isNaN(y))
            throw Error("PageCoordinates cannot be NaN");
    }
}

class InteractionEvent {
    constructor(public coords: PageCoordinates) {
    }
}

class InteractionMoveEvent extends InteractionEvent {}
class InteractionEndEvent extends InteractionEvent {}

class ClippingModificationStartEvent extends InteractionEvent {
    constructor(coords: PageCoordinates,
                public clipping: IClipping,
                public type: RegionTransformHandle) {
        super(coords);
    }
}

class ColorPalette {
    static readonly MAX_COLORS = 10;
    private i = 0;
    
    getNextColor(): string {
        if (this.i === ColorPalette.MAX_COLORS) {
            this.i = 0;
        }
        const next = this.i++;
        return `color${next}`;
    }
}

@Component({
    selector: "app-binding-page-view",
    template: `
        <section class="page-holder" #pageHolder
                 [appDraggable]="currentBindingView.dragMode"
                 [style.transform]="'rotate(-' + rotate + 'deg)'"
                 (mousemove)="onCanvasMouseMove($event)"
                 (mouseup)="onCanvasMouseUp($event)"
                 (touchmove)="onCanvasTouchMove($event)"
                 (touchend)="onCanvasTouchEnd($event)">
            <div *ngIf="loading" class="absolute-center">
                <app-progress-spinner [size]="'LARGE'"></app-progress-spinner>    
            </div>

            <!--
            The main image area, containing the image and all overlays for it. When image is zoomed, the size of this
            element is changed, causing other things to resize with it.
            -->
            <div *ngIf="vd$ | async as vd" appPinchable
                 (pinchZoom)="onPinchZoom($event)" [zoomLevel]="(currentBindingView.zoomEvents$ | async).zoomLevel"
                 [class]="cursorClass()" 
                 [ngClass]="{'image-wrapper': !loading, 'legacy-ios': iosWithScrollingProblems}"
                 [style.width.px]="canvasDimensions.width"
                 [style.height.px]="canvasDimensions.height"
                 (touchstart)="panStart($event)" (touchmove)="panMove($event)" (touchend)="panEnd($event)">

                <div *ngIf="currentPage$ | async as currentPage">

                    <!-- If we are in edit-mode, we'll have a div for each region of the edited article. -->
                    <div class="image-overlay clip-rect"
                         *ngFor="let editableClipping of vd.visibleClippings"
                         [appScaledPosition]="editableClipping" [scale]="scale">
                        <button class="remove btn btn-sm btn-kk-red" (click)="removeClipping(editableClipping)"
                                title="Poista palsta"><i class="fa fa-times fa-lg"></i></button>

                        <div class="hit-border-top"
                             (appMousedownOrTouchstart)="mouseDownOnClipping(editableClipping, $event, 'border-top')"></div>
                        <div class="hit-border-bottom"
                             (appMousedownOrTouchstart)="mouseDownOnClipping(editableClipping, $event, 'border-bottom')"></div>
                        <div class="hit-border-left"
                             (appMousedownOrTouchstart)="mouseDownOnClipping(editableClipping, $event, 'border-left')"></div>
                        <div class="hit-border-right"
                             (appMousedownOrTouchstart)="mouseDownOnClipping(editableClipping, $event, 'border-right')"></div>
                        <div class="hit-corner-top-left"
                             (appMousedownOrTouchstart)="mouseDownOnClipping(editableClipping, $event, 'corner-top-left')"></div>
                        <div class="hit-corner-top-right"
                             (appMousedownOrTouchstart)="mouseDownOnClipping(editableClipping, $event, 'corner-top-right')"></div>
                        <div class="hit-corner-bottom-left"
                             (appMousedownOrTouchstart)="mouseDownOnClipping(editableClipping, $event, 'corner-bottom-left')"></div>
                        <div class="hit-corner-bottom-right"
                             (appMousedownOrTouchstart)="mouseDownOnClipping(editableClipping, $event, 'corner-bottom-right')"></div>
                        <div class="hit-inside"
                             (appMousedownOrTouchstart)="mouseDownOnClipping(editableClipping, $event, 'inside')">
                            {{(currentBindingView.clippings$ | async).indexOf(editableClipping) + 1}}.
                        </div>
                    </div>

                    <!-- The currently drawn region, if we are editing a clipping. -->
                    <div *ngIf="activeClipRect | async as ac" class="image-overlay clip-rect active"
                         [appScaledPosition]="ac" [scale]="scale"></div>

                    <!-- Currently selected OCR block -->
                    <div *ngIf="currentBindingView.highlightedBlock$ | async as hlBlock" class="image-overlay clip-rect"
                         [appScaledPosition]="hlBlock" [scale]="scale"></div>

                    <div *ngIf="currentBindingView.componentTreeUberBlock$ | async as block">
                        <div [appScaledPosition]="block" [scale]="scale"
                             class="image-overlay component-part-area uber-block"></div>
                    </div>
                    <div *ngFor="let block of currentBindingView.componentTreeBlocks$ | async" class="image-overlay component-part-area color2"
                         [appScaledPosition]="block" [scale]="scale">
                    </div>
                    
                    <!-- All OCR-blocks -->
                    <div *ngIf="vd.state.displayOcr || vd.state.ocrBorders"
                         class="{{(currentBindingView.ocrInfo$ | async)?.ocrVersion}}"
                         [ngClass]="{'ocr-borders': vd.state.ocrBorders}">
                        <div (click)="selectBlock(block)" *ngFor="let block of (currentBindingView.ocrBlocks$ | async)"
                             class="image-overlay ocr-block"
                             [appScaledPosition]="block" [scale]="scale"></div>
                    </div>
                    
                    <div *ngIf="vd.state2.displayParts">
                        <div *ngIf="currentBindingView.selectedComponentPartUberBlock$ | async as block">
                            <div [appScaledPosition]="block" [scale]="scale"
                                 class="image-overlay component-part-area uber-block"></div>
                        </div>
                        <div *ngIf="currentBindingView.selectedComponentPartAreas$ | async as cpa">
                            <div *ngFor="let block of cpa" [appScaledPosition]="block" [scale]="scale"
                                 class="image-overlay component-part-area color2"
                                 [ngbTooltip]="block.partTitle" [placement]="'right'"></div>
                        </div>    
                    </div>
                    
                    <!-- Overlays for making search results -->
                    <div class="image-overlay hit-rect" *ngFor="let hit of currentPage.hits | async"
                         [appScaledPosition]="hit" [scale]="scale"
                         [title]="hit.text"></div>

                    <!-- highlight the named entities -->
                    <div *ngIf="vd.state.displayNerLocations">
                        <div class="image-overlay ner-rect" [ngClass]="locNer.type"
                             *ngFor="let locNer of currentPage.nerLocations | async" [appScaledPosition]="locNer"
                             [scale]="scale"
                             [title]="locNer.id" ngbTooltip="{{locNer.label}}: {{locNer.text}}"></div>
                    </div>

                    <div *ngIf="vd.state.displayNerPersons">
                        <div class="image-overlay ner-rect" [ngClass]="perNer.type"
                             *ngFor="let perNer of currentPage.nerPersons | async" [appScaledPosition]="perNer"
                             [scale]="scale"
                             [title]="perNer.id" ngbTooltip="{{perNer.label}}: {{perNer.text}}"></div>
                    </div>

                    <!-- User articles. Inside their own div so that nth-child selectors can be used to color them properly. -->
                    <div *ngIf="vd.clippingsVisible && vd.state2.displayArticles">
                        <div class="user-article" *ngFor="let article of userArticles$ | async">
                            <div *ngFor="let clipping of article.clippings">
                                <div *ngIf="clipping.pageNumber == currentPage.info.number"
                                     [ngbTooltip]="article.title" placement="right"
                                     class="image-overlay article-clip-rect"
                                     [appScaledPosition]="clipping"
                                     [scale]="scale"
                                     (click)="clickArticle($event, article)">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="vd.redactionsVisible">
                        <div class="redaction" *ngFor="let redaction of vd.bi.redactions">
                            <div *ngFor="let region of redaction.regions">
                                <div *ngIf="region.pageNumber == currentPage.info.number"
                                     [ngbTooltip]="redactionPopover(redaction)" placement="right"
                                     class="image-overlay redaction-region"
                                     (click)="clickRedaction($event, redaction, vd.bi.canRedact)"
                                     [ngClass]="{editable: vd.bi.canRedact, viewable: vd.bi.canViewRedactedData}"
                                     [appScaledPosition]="region"
                                     [scale]="scale">
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- The image itself -->
                    <img *ngIf="currentPage.image | async as image" id="pageImage" [src]="image.src" alt=""
                         (appMousedownOrTouchstart)="mouseDownOnPageImage($event)"/>
                </div>
            </div>
        </section>
    `,
    providers: [
        ImageLoaderService
    ],
    styleUrls: [
        "./binding-page-view.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BindingPageViewComponent implements IBindingPageView, OnInit, OnDestroy {

    constructor(private translate: TranslateService,
                private imageLoader: ImageLoaderService,
                private log: LoggingService,
                private http: HttpClient,
                private navigationService: NavigationService,
                private bindingRest: BindingRestEndpoint,
                private bindingSearchRest: BindingSearchRestEndpoint,
                public displayService: DisplayService,
                private accountService: AccountService,
                platformService: PlatformService,
                private cdr: ChangeDetectorRef,
                @Inject(BINDING_VIEW) public currentBindingView: ICurrentBindingView) {
        this.displayService.headerMinimized = true;
        this.iosWithScrollingProblems = platformService.iOSWithScrollingProblems;
        const cbv = currentBindingView;
        
        this.vd$ = combineLatest([cbv.bindingInfo$, cbv.bindingViewState$, cbv.nonUrlState$, cbv.clippingsOnCurrentPage$, cbv.areArticlesVisible$, cbv.areRedactionsVisible$]).pipe(
            map(([bi, state, state2, clippings, clippingsVisible, redactionsVisible]) => {
                return {
                    bi,
                    state,
                    state2,
                    visibleClippings: clippings,
                    clippingsVisible,
                    redactionsVisible
                }
            })
        )
    }
    
    private bindingId: number;

    vd$: Observable<ViewData>;

    private subs = new Subscriptions();

    get pageElement(): HTMLElement {
        return this.pageHolder && this.pageHolder.nativeElement as HTMLElement;
    }

    /**
     * Optimization for ignoring most mouse & touch events.
     */
    private listenForInteraction = false;

    private clippingStartEvent = new BehaviorSubject<ClippingModificationStartEvent | null>(null);

    onInteractionEvent = new Subject<InteractionEvent>();
    onClippingModificationEvent = combineLatest([this.onInteractionEvent, this.clippingStartEvent]).pipe(
        filterNonNullArrays,
        debounceTime(5) // optimization
    );

    /** are we currently in progress of loading? */
    loading = true;

    readonly iosWithScrollingProblems: boolean; 
    
    currentPage$ = new BehaviorSubject<IPageInformation | null>(null);
    get currentPageSnapshot(): IPageInformation | null {
        return this.currentPage$.getValue();
    }

    redrawSubject = new Subject<void>();

    redrawEvents$ = combineLatest([this.currentBindingView.zoomEvents$, this.redrawSubject]).pipe(map(([z, d]) => z));

    // overridden based on generalType
    fitBy = FitBy.WIDTH;

    /** title of the binding */
    bindingTitle = "";

    /** the region that user is currently drawing */
    activeClipRect = new BehaviorSubject<IClipping | null>(null);

    currentImageSnapshot: HTMLImageElement | null;

    userArticles$ = this.currentBindingView.bindingInfo$.pipe(map(d => d.userArticles));

    scale: number | undefined = undefined;

    canvasDimensions = {
        width: 0,
        height: 0
    };

    panStartX = 0;
    panStartY = 0;
    startScrollLeft = 0;
    startScrollTop = 0;
    panning = false;
    rotate = 0; // degrees

    // componentPartTitle -> colorClass
    componentPartColors = new Map<string, string>();
    colorPalette = new ColorPalette();

    @ViewChild("pageHolder", {static: true})
    private pageHolder: ElementRef;

    onCanvasMouseMove($event: MouseEvent) {
        if (this.listenForInteraction) {
            const coords = getPageCoordinates($event);
            if (coords)
                this.onInteractionEvent.next(new InteractionMoveEvent(coords));
        }
    }

    onCanvasMouseUp($event: MouseEvent) {
        if (this.listenForInteraction) {
            const coords = getPageCoordinates($event);
            if (coords)
                this.onInteractionEvent.next(new InteractionEndEvent(coords));
        }
    }

    onCanvasTouchMove($event: TouchEvent) {
        if (this.listenForInteraction) {
            const coords = getPageCoordinates($event);
            if (coords)
                this.onInteractionEvent.next(new InteractionMoveEvent(coords));
        }
    }

    onCanvasTouchEnd($event: TouchEvent) {
        if (this.listenForInteraction) {
            const coords = getPageCoordinates($event);
            if (coords)
                this.onInteractionEvent.next(new InteractionEndEvent(coords));
        }
    }

    redrawPage() {
        this.redrawSubject.next();
    }

    ngOnInit() {
        this.currentBindingView.setPageView(this);

        this.subs.add(this.redrawEvents$.subscribe((event) => {
            // wait for a bit with setTimeout() so that the page gets to render first, so we can get correct screen size
            setTimeout(() => {
                if (!!event.focus) {
                    if (event.focus instanceof PercentagePoint) {
                        this.updateFocus(event.zoomLevel, event.focus as PercentagePoint);
                    } else {
                        this.zoomToRegion(event.focus);
                    }
                } else
                    this.focusOnPageMiddle(event.zoomLevel);

                this.cdr.markForCheck();    
            });
        }));

        // TODO bind this stuff directly
        this.subs.add(this.currentBindingView.bindingInfo$.subscribe(data => {
            if (data) {
                this.bindingId = data.id;
                // Assumes that binding data is always emitted before pages
                this.fit(this.resolveFitBy(data.generalType));
            }
        }));

        const activePageResult$ = this.currentBindingView.activePageResult$;

        // load term hits
        this.subs.add(combineLatest([activePageResult$, this.currentBindingView.searchTerms$])
            .pipe(
                switchMap(([p, t]): Observable<[ClientPageInformation, IHit[]]> => {
                    if (t != null && t.length > 0) {
                        return this.bindingSearchRest.getOcrHits(this.bindingId, p.redirectedPage, t)
                            .pipe(
                                map(hits => [p.page, hits])
                            );
                    } else {
                        return of([p.page, []]);
                    }
                })
            ).subscribe(([p, hits]) => {
                // we keep the p parameter along, so we set the hits to the correct page
                p.hits.next(hits);
        }));

        this.subs.add(activePageResult$.subscribe((apr) => {
            const page = apr.page;
            this.currentPage$.next(page);
            this.currentImageSnapshot = page.image.getValue();

            if (this.currentImageSnapshot) {
                this.redrawPage();
            } else {
                this.loading = true;
                const trackingURI = appendQueryParam(page.info.imageUri, "tracking", apr.pageRequest.source);
                this.subs.switchable("image", this.imageLoader.loadImage(trackingURI).subscribe(image => {
                    this.setPageImage(page, image);
                }, e => {
                    this.log.error(e);
                    this.imageLoader.loadImage(PAGE_IMAGE_ERROR_URI).subscribe(image => {
                        this.setPageImage(page, image);
                    });
                }));
            }

            const nerSnapshot = page.ner.getValue();
            if (page.info.hasNerOccurrences && !nerSnapshot) {
                this.subs.switchable("ner", this.bindingRest.loadNerOccurrences(this.currentBindingView.bindingId, page.info.number).subscribe((response: NerOccurrence[]) => {
                    page.ner.next(response.map(occ => {
                        return {
                            type: occ.type,
                            label: this.translate.instant(`ner.type.${occ.type}`),
                            id: '#' + occ.stringId,
                            x: occ.x,
                            y: occ.y,
                            width: occ.w,
                            height: occ.h,
                            text: occ.ocrText
                        };
                    }));
                }));
            }
        }));

        this.subs.add(this.onClippingModificationEvent.subscribe(([a, i]) => {
            this.modifyClipping(a, i);
        }));

        this.subs.add(this.onInteractionEvent.pipe(filter(a => a instanceof InteractionEndEvent)).subscribe(() => {
            this.clippingStartEvent.next(null);
            this.listenForInteraction = false;
        }));
        
        this.subs.add(this.currentBindingView.selectedComponentPartUberBlock$.subscribe(ub => {
            if (!!ub)
                this.currentBindingView.panToRegion(ub);
        }));

        this.subs.add(this.currentBindingView.componentTreeUberBlock$.subscribe(ub => {
            if (!!ub)
                this.currentBindingView.panToRegion(ub);
        }));
    }

    private setPageImage(page: ClientPageInformation, image: HTMLImageElement) {
        this.loading = false;
        this.currentImageSnapshot = image;
        page.image.next(image);

        const scaleFactor = page.info.scaleFactor;

        page.width = image.width / scaleFactor;
        page.height = image.height / scaleFactor;
        this.redrawPage();
    }

    redactionPopover(redaction: RedactionDto): string {
        return this.translate.instant(`redaction-popover.reason.${redaction.reason.toLowerCase()}`);
    }

    rotate90() {
        this.rotate = (this.rotate + 90) % 360;
        this.cdr.markForCheck();
    }

    isRotated(): boolean {
        return this.rotate !== 0;
    }

    fit(type: FitBy) {
        this.fitBy = type;
        if (type === FitBy.HEIGHT) {
            // we have some extra padding in bottom of page so the top might be clipped depending on scroll position, fix that by this
            this.pageElement.scrollTop = 0;
        }
        this.currentBindingView.setZoom(1);
        this.redrawPage();
    }

    /**
     * Updates zoom and scroll so that given region is visible.
     */
    zoomToRegion(region: IRegion): void {
        const image = this.currentImageSnapshot;
        if (!image) return;

        const page = this.currentPageSnapshot;
        if (!page) return;

        const scaleFactor = page.info.scaleFactor;

        const effectiveWidth = clipValue(region.width, REGION_ZOOM_MIN_HEIGHT, REGION_ZOOM_MAX_HEIGHT);
        const effectiveHeight = clipValue(region.height, REGION_ZOOM_MIN_WIDTH, REGION_ZOOM_MAX_WIDTH);

        const widthRatio = effectiveWidth / this.pageElement.clientWidth;
        const heightRatio = effectiveHeight / this.pageElement.clientHeight;

        // If width is greater than height, we'll try to calculate fitting box for width and vice versa.
        if (widthRatio >= heightRatio) {
            const zoomLevel = image.width / (effectiveWidth * scaleFactor);
            this.currentBindingView.setZoom(zoomLevel);
        } else {
            const viewportRatio = this.preferredViewportHeight() / this.preferredViewportWidth();
            const zoomLevel = image.width * viewportRatio / (effectiveHeight * scaleFactor);
            this.currentBindingView.setZoom(zoomLevel);
        }

        // We can't perform updating scroll level right away: depending on our previous zoom-level,
        // the width or height of the page could be less than the desired scroll position: in that
        // case our scroll position would be clipped (e.g. we'd like to scroll to x-coordinate 4000,
        // but the width of the area is only 3000 at current zoom level).
        //
        // Using $timeout() fixes this: the DOM gets a change to update the size of elements based
        // on our current zoom level and then we can perform the scrolling.
        setTimeout(() => {
            this.panToRegion(region);
        });
    }

    panToRegion(region: IRegion): void {
        if (!!this.scale) {
            this.pageElement.scrollLeft = Math.max(0, this.scale * region.x - AUTO_ZOOM_MARGIN);
            this.pageElement.scrollTop = Math.max(0, this.scale * region.y - AUTO_ZOOM_MARGIN);
        }
    }

    focusOnPageMiddle(level: number): void {
        this.updateFocus(level, PAGE_MIDDLE);
    }

    onPinchZoom(event: IFocalZoomEvent): void {
        this.currentBindingView.focalZoom(event.level, new PercentagePoint(event.eventX, event.eventY));
    }

    updateFocus(zoomLevel: number, point: PercentagePoint): void {
        const page = this.pageElement;
        const bounds = page.getBoundingClientRect();

        // calculate how the event is relative to current scroll view: x%, y%

        const eventXInPx = this.canvasDimensions.width * point.x;
        const eventYInPx = this.canvasDimensions.height * point.y;

        // we want these to remain the same even after zooming
        const viewOffsetPercentX = (eventXInPx - page.scrollLeft) / bounds.width;
        const viewOffsetPercentY = (eventYInPx - page.scrollTop) / bounds.height;

        this.resizeCanvas(zoomLevel);

        // calculate where new focal point should be

        const newEventXInPx = this.canvasDimensions.width * point.x;
        const newEventYInPx = this.canvasDimensions.height * point.y;

        // how that translate to scroll view?

        const newScrollLeft = newEventXInPx - (viewOffsetPercentX * bounds.width);
        const newScrollTop = newEventYInPx - (viewOffsetPercentY * bounds.height);

        page.scrollLeft = newScrollLeft;
        page.scrollTop = newScrollTop;
    }

    private resizeCanvas(zoomLevel: number): void {
        const image = this.currentImageSnapshot;
        const scaleFactor = this.currentPageSnapshot && this.currentPageSnapshot.info.scaleFactor;

        if (image && scaleFactor) {
            if (this.fitBy === FitBy.WIDTH) {
                const width = zoomLevel * this.preferredViewportWidth();
                this.scale = scaleFactor * (width / image.width);
                const aspectRatio = image.height / image.width;
                this.canvasDimensions = {
                    width: Math.floor(width),
                    height: Math.floor(aspectRatio * width)
                };
            } else if (this.fitBy === FitBy.HEIGHT) {
                const height = zoomLevel * this.preferredViewportHeight();
                this.scale = scaleFactor * (height / image.height);
                const aspectRatio = image.width / image.height;
                this.canvasDimensions = {
                    width: Math.floor(aspectRatio * height),
                    height: Math.floor(height)
                };
            } else {
                throw Error("Unknown fit-by: " + this.fitBy);
            }
        }
    }

    private preferredViewportWidth(): number {
        return DEFAULT_ZOOM_BY_WIDTH * this.pageElement.clientWidth;
    }

    private preferredViewportHeight(): number {
        return DEFAULT_ZOOM_BY_HEIGHT * this.pageElement.clientHeight;
    }

    selectBlock(block: OcrBlock) {
        this.currentBindingView.setHighlightedBlock(block);
    }

    removeClipping(clipping: IClipping) {
        this.currentBindingView.removeClipping(clipping);
    }

    clickArticle(e: Event, article: ClippingDto) {
        if (this.currentBindingView.dragMode)
            return;

        e.preventDefault();

        this.navigationService.goTo(article.url);
    }

    clickRedaction(e: Event, redaction: RedactionDto, canRedact: boolean) {
        e.preventDefault();

        if (canRedact)
            this.currentBindingView.editRedaction(redaction);
    }

    isHorizontallyScrollable() {
        if (this.pageHolder == null)
            return false;

        const el = this.pageElement;
        return el.clientWidth < el.scrollWidth;
    }

    panStart(event: TouchEvent) {
        // If we start multi-touch, stop manual panning
        if (event.touches.length > 1)
            this.panning = false;

        const page = this.pageElement;
        if (event.touches.length === 1) {
            this.panning = true;

            const touch = event.touches[0];
            this.panStartX = touch.pageX;
            this.panStartY = touch.pageY;
            this.startScrollLeft = page.scrollLeft;
            this.startScrollTop = page.scrollTop;
        }
    }

    panMove(event: TouchEvent) {
        const page = this.pageElement;
        if (this.panning) {
            const touch = event.touches[0];
            const diffX = (touch.pageX - this.panStartX);
            const diffY = (touch.pageY - this.panStartY);
            page.scrollLeft = this.startScrollLeft - diffX;
            page.scrollTop = this.startScrollTop - diffY;
        }
    }

    panEnd(event: TouchEvent) {
        if (this.panning) {
            this.panning = false;
        }
    }

    mouseDownOnPageImage(event: MouseEvent | TouchEvent) {
        // Firefox will natively drag the image, unless we stop it with this. HTML attribute draggable="false", or
        // binding to drag events don't work.
        if (this.currentBindingView.dragMode)
            event.preventDefault();

        if (this.currentBindingView.clippingEditorOpenSnapshot && !this.currentBindingView.dragMode && isPrimarySelectionStart(event)) {
            event.preventDefault();
            this.listenForInteraction = true;
            const coords = getPageCoordinates(event);
            if (coords)
                this.startDrawingClipping(coords, event.target as HTMLElement);
        }
    }

    mouseDownOnClipping(clipping: IClipping, event: MouseEvent | TouchEvent, type: RegionTransformHandle) {
        if (isPrimarySelectionStart(event)) {
            event.preventDefault();
            this.listenForInteraction = true;
            const coords = getPageCoordinates(event);
            if (coords) {
                const startEvent = new ClippingModificationStartEvent(coords, clipping, type);
                this.clippingStartEvent.next(startEvent);
                this.onInteractionEvent.next(startEvent);
            }
        }
    }

    modifyClipping(event: InteractionEvent, start: ClippingModificationStartEvent) {
        const page = this.currentPageSnapshot;
        const scaleFactor = page && page.info.scaleFactor;
        const image = this.currentImageSnapshot;
        const imageWidth = image && image.width;
        const imageHeight = image && image.height;

        if (page && scaleFactor && imageWidth && imageHeight) {
            const dx = this.toImageX(event.coords.x - start.coords.x, scaleFactor, imageWidth);
            const dy = this.toImageY(event.coords.y - start.coords.y, scaleFactor, imageHeight);
            start.clipping.move(start.type, dx, dy, page);

            // FIXME XXX modifying event
            start.coords.x = event.coords.x;
            start.coords.y = event.coords.y;

            this.currentBindingView.emitClippingsModified();
        }
    }

    cursorClass() {
        return this.currentBindingView.dragMode ? "mouse-move"
            : this.currentBindingView.clippingEditorOpenSnapshot ? "mouse-crosshair"
                : "";
    }

    private startDrawingClipping(coords: PageCoordinates, container: HTMLElement): void {
        if (!this.currentImageSnapshot) {
            return;
        }

        const start = logicalMouseCoordinates(coords, container);

        const ctrl = this;

        function updateRect(c: PageCoordinates, finish: boolean) {
            const pos = logicalMouseCoordinates(c, container);
            const rect = ctrl.activeClipRect.getValue();
            const currentPage = ctrl.currentPageSnapshot;
            const scaleFactor = currentPage && currentPage.info.scaleFactor;
            const image = ctrl.currentImageSnapshot;
            const imageWidth = image && image.width;
            const imageHeight = image && image.height;

            if (pos && currentPage && currentPage.width && currentPage.height && scaleFactor && imageWidth && imageHeight) {
                const currentWidth: number = currentPage.width;
                const currentHeight: number = currentPage.height;
                const normalizeX = (xx: number) => Math.max(0, Math.min(currentWidth, ctrl.toImageX(xx, scaleFactor, imageWidth)));
                const normalizeY = (yy: number) => Math.max(0, Math.min(currentHeight, ctrl.toImageY(yy, scaleFactor, imageHeight)));

                const x1 = normalizeX(Math.min(pos.x, start.x));
                const y1 = normalizeY(Math.min(pos.y, start.y));
                const x2 = normalizeX(Math.max(pos.x, start.x));
                const y2 = normalizeY(Math.max(pos.y, start.y));
                const width = x2 - x1;
                const height = y2 - y1;

                if (rect) {
                    rect.updateTo(x1, y1, width, height);
                } else {
                    ctrl.activeClipRect.next(new ClippingRect(currentPage.info.number, x1, y1, width, height));
                }
            }

            if (finish) {
                if (rect) {
                    ctrl.activeClipRect.next(null);
                    ctrl.addClipping(rect);
                }
            }
        }

        const mouseSubscription = this.onInteractionEvent.subscribe(iEvent => {
            if (iEvent instanceof InteractionMoveEvent) {
                autoScrollOnOutOfBounds(container, iEvent.coords);
                updateRect(iEvent.coords, false);
            } else if (iEvent instanceof InteractionEndEvent) {
                mouseSubscription.unsubscribe();
                updateRect(iEvent.coords, true);
            }
        });
        this.subs.switchable("mouseInteraction", mouseSubscription);
    }

    private toImageX(x: number, scaleFactor: number, imageWidth: number): number {
        return (x / (this.canvasDimensions.width / imageWidth)) / scaleFactor;
    }


    private toImageY(y: number, scaleFactor: number, imageHeight: number): number {
        return (y / (this.canvasDimensions.height / imageHeight)) / scaleFactor;
    }

    private addClipping(clipping: IClipping): void {
        if (clipping.height > 50 && clipping.width > 50) {
            this.currentBindingView.addClipping(clipping);
        }
    }

    private resolveFitBy(generalType: GeneralType): FitBy {
        if (generalType === GeneralType.NEWSPAPER) {
            return FitBy.WIDTH;
        } else {
            return FitBy.HEIGHT;
        }
    }

    public getComponentPartColor(block: ComponentPartBlock): string {
        const result = this.componentPartColors.get(block.partTitle);
        if (!result) {
            const nextColor = this.colorPalette.getNextColor();
            this.componentPartColors.set(block.partTitle, nextColor)
            return nextColor;
        }
        return result;
    }
    
    ngOnDestroy(): void {
        this.displayService.headerMinimized = false;
        this.subs.unsubscribeAll();
    }
}

interface ViewData {
    bi: BindingInformation,
    state: BindingViewState,
    state2: NonUrlState,
    visibleClippings: IClipping[],
    clippingsVisible: boolean, 
    redactionsVisible: boolean
}

/**
 * Return true if event is start of pressing the primary mouse button or start with touch.
 */
function isPrimarySelectionStart(event: MouseEvent | TouchEvent): boolean {
    const e = event as any;
    return (e.touches && e.touches.length === 1) || e.which === 1;
}

function logicalMouseCoordinates(coords: PageCoordinates, container: HTMLElement): PageCoordinates {
    const bounds = container.getBoundingClientRect();
    return {
        x: coords.x - bounds.left,
        y: coords.y - bounds.top
    };
}

function autoScrollOnOutOfBounds(container: HTMLElement, coords: PageCoordinates) {
    const bounds = container.getBoundingClientRect();
    const x = coords.x - bounds.left;
    const y = coords.y - bounds.top;

    const dx = (x < 0) ? -1
        : (x >= bounds.width) ? 1
            : 0;
    const dy = (y < 0) ? -1
        : (y >= bounds.height) ? 1
            : 0;

    if (dx !== 0) {
        container.scrollLeft = container.scrollLeft + 5 * dx;
    }

    if (dy !== 0) {
        container.scrollTop = container.scrollTop + 5 * dy;
    }
}

function getPageCoordinates(event: MouseEvent | TouchEvent): PageCoordinates | null {
    try {
        if (event instanceof MouseEvent) {
            return new PageCoordinates(event.pageX, event.pageY);
        } else if (event instanceof TouchEvent) {
            const touch = event.touches[0];
            return new PageCoordinates(touch.pageX, touch.pageY);
        } else {
            console.error("unsupported event type " + event);
            return null;
        }
    } catch (err) {
        console.error("getPageCoordinates", err);
        return null;
    }
}
