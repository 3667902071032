import {ComponentRef, Directive, ElementRef, HostListener, Input} from "@angular/core";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";
import {NgbPopoverWindow} from "@ng-bootstrap/ng-bootstrap/popover/popover";

/**
 * Closes current ngbPopover automatically if user clicks outside the popover.
 *
 * Implementation lifted from https://github.com/ng-bootstrap/ng-bootstrap/issues/933#issuecomment-274223407
 * If the issue itself gets a native support, consider replacing with that.
 *
 * Update: The new [autoClose]="true" causes issues with our popover's filter <input>: if it's clicked the popover
 * instantly closes.
 */
@Directive({
    selector: '[appClosePopoverOnClickOutside][ngbPopover]'
})
export class ClosePopoverOnClickOutsideDirective {

    @Input() parentInput: any;

    constructor(private elementRef: ElementRef, private ngbPopover: NgbPopover) {
    }

    //noinspection JSUnusedGlobalSymbols
    @HostListener('document:click', ['$event'])
    documentClicked(event: MouseEvent) {

        // Popover is open
        if (this.ngbPopover && this.ngbPopover.isOpen()) {

            // Not clicked on self element
            if (!this.elementRef.nativeElement.contains(event.target)) {

                // Hacking typescript to access private member
                // TODO: accessing privates will prevent aggressive minification
                const popoverWindowRef: ComponentRef<NgbPopoverWindow> = (this.ngbPopover as any)._windowRef;

                const popoverIconClicked = popoverWindowRef.location.nativeElement.contains(event.target);
                const parentInputClicked = this.parentInput && this.parentInput.contains(event.target);

                if (!(popoverIconClicked || parentInputClicked)) {
                    this.ngbPopover.close();
                }
            }
        }
    }
}
