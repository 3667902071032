import {Component} from "@angular/core";
import {PageCountDto, RootRestEndpoint} from "../../apina-digiweb";

@Component({
    selector: "app-stats",
    template: `
        <app-progress-bar *ngIf="loading"></app-progress-bar>

        <section class="container">

            <h2 translate>amountofpages.text</h2>
            
            <div class="row" *ngIf="!loading">
                <div class="col-md-10" style="padding-top: 20px">
                    <table class="table table-responsive">
                        <thead>
                        <tr class="tableheader">
                            <th style="border-right:dotted" translate>form.search.format</th>
                            <th translate style="text-align:right">allpages.text</th>
                            <th colspan="2" translate>amountofpagestotal.text</th>
                            <th translate style="text-align:right; border-left:dotted; text-transform:capitalize;">title.page-count-indicator.bindings</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td class="rowheader" style="border-right:dotted" translate>sp.main.header</td>
                            <td>{{stats.newspaperPages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.newspaperFreePages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.newspaperFreePercentage}} %</td>
                            <td style="border-left:dotted">{{stats.newspaperBindings | number:'1.0-0':'fi'}}</td>
                        </tr>
                        <tr>
                            <td class="rowheader" style="border-right:dotted" translate>journal.main.header</td>
                            <td>{{stats.journalPages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.journalFreePages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.journalFreePercentage}} %</td>
                            <td style="border-left:dotted">{{stats.journalBindings | number:'1.0-0':'fi'}}</td>
                        </tr>
                        <tr>
                            <td class="rowheader" style="border-right:dotted" translate>printing.main.header</td>
                            <td>{{stats.ephemeraPages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.ephemeraPages | number:'1.0-0':'fi'}}</td>
                            <td>100 %</td>
                            <td style="border-left:dotted">{{stats.ephemeraBindings | number:'1.0-0':'fi'}}</td>
                        </tr>
                        <tr>
                            <td class="rowheader" style="border-right:dotted" translate>book.main.header</td>
                            <td>{{stats.bookPages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.bookFreePages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.bookFreePercentage}} %</td>
                            <td style="border-left:dotted">{{stats.bookBindings | number:'1.0-0':'fi'}}</td>
                        </tr>
                        <tr>
                            <td class="rowheader" style="border-right:dotted" translate>musicnotation.main.header</td>
                            <td>{{stats.musicNotationPages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.musicNotationFreePages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.musicNotationFreePercentage}} %</td>
                            <td style="border-left:dotted">{{stats.musicNotationBindings | number:'1.0-0':'fi'}}</td>
                        </tr>
                        <tr>
                            <td class="rowheader" style="border-right:dotted" translate>map.main.header</td>
                            <td>{{stats.mapPages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.mapFreePages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.mapFreePercentage}} %</td>
                            <td style="border-left:dotted">{{stats.mapBindings | number:'1.0-0':'fi'}}</td>
                        </tr>
                        <tr>
                            <td class="rowheader" style="border-right:dotted" translate>cards.main.header</td>
                            <td>{{stats.cardsPages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.cardsFreePages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.cardsFreePercentage}} %</td>
                            <td style="border-left:dotted">{{stats.cardsBindings | number:'1.0-0':'fi'}}</td>
                        </tr>
                        <tr>
                            <td class="rowheader" style="border-right:dotted" translate>manuscripts.main.header</td>
                            <td>{{stats.manuscriptPages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.manuscriptFreeCount | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.manuscriptFreePercentage}} %</td>
                            <td style="border-left:dotted">{{stats.manuscriptBindings | number:'1.0-0':'fi'}}</td>
                        </tr>
                        <tr class="totalrow">
                            <td class="rowheader" style="border-right:dotted" translate>allcategories.text</td>
                            <td>{{stats.allPages | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.totalFreeCount | number:'1.0-0':'fi'}}</td>
                            <td>{{stats.allFreePercentage}} %</td>
                            <td style="border-left:dotted">{{stats.allBindings | number:'1.0-0':'fi'}}</td>
                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    `,
    styleUrls: ['./stats.scss']
})
export class StatsComponent {
    loading = true;

    stats: PageCountDto;
    constructor(rootEndpoint: RootRestEndpoint) {
        rootEndpoint.getStats().subscribe(stats => {
            this.loading = false;
            this.stats = stats;
        });
    }
}
