import {AfterViewInit, Component, ElementRef, Input} from "@angular/core";

interface ITwitterWindow extends Window {
    twttr: any;
    widgets: {
        load(elem: any): void;
    };
}

@Component({
    selector: 'app-tweet',
    template: `<a href="https://twitter.com/share" [attr.data-text]="text" [attr.data-url]="url" data-hashtags="digikansalliskirjasto" class="twitter-share-button"></a>`
})
export class TweetComponent implements AfterViewInit {
    @Input() url: string;
    @Input() text = '';

    constructor(private elem: ElementRef) {
        // load twitter sdk if required
        const url = 'https://platform.twitter.com/widgets.js';
        if (!document.querySelector(`script[src='${url}']`)) {
            const script = document.createElement('script');
            script.src = url;
            document.body.appendChild(script);
        }
    }

    ngAfterViewInit(): void {
        // render tweet button
        const win = window as unknown as ITwitterWindow;
        const twitter = win.twttr;

        if (twitter) {
            twitter.widgets.load(this.elem.nativeElement);
        }
    }
}
