import {Injectable} from "@angular/core";
import {CookieService} from "ngx-cookie-service";

const COOKIE_TIMEOUT = 30;

@Injectable()
export class SocialPrivacyService {

    constructor(private cookieService: CookieService) {
    }

    isSocialShareAllowed(cookieName: string): boolean {
        return this.cookieService.check(cookieName);
    }

    allowSocialShare(cookieName: string) {
        const value = JSON.stringify({
            allowSocialShare: true
        });
        this.cookieService.set(cookieName, value, COOKIE_TIMEOUT, '/', null, true, "Strict");
    }
}
