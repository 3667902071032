import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";

@Injectable()
export class ImageLoaderService {

    // TODO cancel loading image when observers unsubscribe
    loadImage(url: string): Observable<HTMLImageElement> {
        const observable = new Subject<HTMLImageElement>();

        const image = new Image();

        image.onload = () => {
            if (image.complete) {
                observable.next(image);
                observable.complete();
            } else {
                // https://bugzilla.mozilla.org/show_bug.cgi?id=574330
                observable.error("onload was called without image being complete");
            }
        };

        image.onerror = () =>
            observable.error(`Error loading image from ${url}`);

        image.src = url;

        return observable;
    }
}

