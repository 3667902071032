import {BindingRestEndpoint, BindingVisibilityDTO} from "../../apina-digiweb";
import {Component, Inject, Input, OnInit} from "@angular/core";
import {BINDING_VIEW, ICurrentBindingView} from "../../binding/types";

@Component({
    selector: "app-hide-binding",
    template: `
        <app-sidebar>
            <app-sidebar-header titleKey="material.visibility.title" (closeSidebar)="cbv.toggleHideBindingDialog()"></app-sidebar-header>
            <app-sidebar-content>
                <form class="form-horizontal" *ngIf="model != null">
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label translate>material.visibility.level</label>
                            <div class="radio">
                                <label>
                                    <input type="radio" [(ngModel)]="model.visibilityType" name="visibilityType" [disabled]="loading" value="NORMAL">
                                    {{'material.visibility.level.NORMAL' | translate}}
                                </label>
                            </div>
                            <div class="radio">
                                <label>
                                    <input type="radio" [(ngModel)]="model.visibilityType" name="visibilityType" [disabled]="loading" value="HIDDEN_QA">
                                    {{'material.visibility.level.QA' | translate}}
                                </label>
                            </div>
                            <div class="radio">
                                <label>
                                    <input type="radio" [(ngModel)]="model.visibilityType" name="visibilityType" [disabled]="loading" value="HIDDEN_COPYRIGHT">
                                    {{'material.visibility.level.COPYRIGHT' | translate}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-12">
                            <label translate>material.visibility.description</label>
                            <textarea class="form-control" rows="5" [(ngModel)]="model.description" name="description" maxlength="100" [disabled]="model.visibilityType === 'NORMAL'"></textarea>
                        </div>    
                    </div>
                </form>
                <div>
                    <button type="button" class="btn btn-kk-blue" (click)="save()" translate [disabled]="loading">common.action.save</button>
                </div>
            </app-sidebar-content>
        </app-sidebar>
    `
})
export class HideBindingComponent implements OnInit {

    constructor(@Inject(BINDING_VIEW) public cbv: ICurrentBindingView,
                private api: BindingRestEndpoint) {
    }

    @Input() bindingId: number;
    
    public loading = true;
    public model: BindingVisibilityDTO = null;

    ngOnInit() {
        this.api.getBindingVisibility(this.bindingId).subscribe(response => {
            this.model = response;
            this.loading = false;
        });
    }

    save() {
        this.api.setBindingVisibility(this.model).subscribe(() => {
            this.cbv.toggleHideBindingDialog();
        });
    }
}
