import {debounceTime, skip} from 'rxjs/operators';
import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {BindingTitleBrowseListDto, GeneralType, LocalizedValue, PublicationsPlacesByCountryEntry, TitleBrowseRestEndpoint} from "../../apina-digiweb";
import {NgForm} from "@angular/forms";
import {NavigationService} from "../navigation.service";
import {SettingsService} from "../settings.service";
import {ValueWithLabel} from "../search/search.service";
import {BehaviorSubject, combineLatest, Subscription} from "rxjs";
import {BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";


@Component({
    selector: "app-browse-titles",
    template: `
        <app-progress-bar *ngIf="loading | async"></app-progress-bar>
        
        <div class="kk-bg-lightgray search-box" [hidden]="loading | async">
            <section class="container search-form" style="padding-top: 15px">
                <form name="titleFilterForm" #filterForm="ngForm" class="browse-search-filters row form-group">
                    <div class="col-md-12">
                        <app-checkbox-list [(ngModel)]="filters.generalTypes" [referenceData]="supportedGeneralTypes" name="generalTypes"></app-checkbox-list>
                    </div>
                    <div class="col-md-3">
                        <input name="name" title="{{'papers.search'|translate}}" type="text" id="titleFilter" [(ngModel)]="filters.name" autofocus
                               class="form-control" placeholder="{{'common.name' | translate}}">
                    </div>
                    <div class="col-md-2 col-xl-1">
                        <input name="year"  title="{{'common.year'|translate}}" type="text" id="yearFilter" [(ngModel)]="filters.year"
                               class="form-control" appValidateYear
                               placeholder="{{'common.year' | translate}}"
                               (change)="filterPublicationPlacesByYear()"/>
                    </div>
                    <div class="col-md-2">
                        <select name="language" title="{{'browse.all-languages'| translate}}" [(ngModel)]="filters.language" class="form-control">
                            <option value="">{{'browse.all-languages' | translate}}</option>
                            <option *ngFor="let language of languages" [value]="language.key">
                                {{language.localization}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3">
                        <select name="location" title="{{'popover-picker.all.publication-place'|translate}}" [compareWith]="countryAndCityEquals" [(ngModel)]="filters.location"
                                class="form-control">
                            <option [ngValue]="null" [selected]="true">
                                {{'browse.all-publishing-locations' | translate}}
                            </option>
                            <ng-template ngFor let-country [ngForOf]="places">
                                <optgroup label="{{country.localizedCountryName}}"
                                          *ngIf="country.places.length > 0">
                                    <option *ngFor="let place of country.places"
                                            [ngValue]="{city: place.city, countryCode: place.countryCode}">
                                        {{place.city}}
                                    </option>
                                </optgroup>
                            </ng-template>
    
                        </select>
                    </div>
                    <div class="col-md-2 col-xl-3 browse-search-checkboxes">
                        <label>
                            <input name="accessibleOnly" type="checkbox" [(ngModel)]="filters.accessibleOnly"/>
                            {{'browse.show-only-accessible' | translate}}
                        </label>
                        <label>
                            <input name="newOnly" type="checkbox" [(ngModel)]="filters.newTitlesOnly"/>
                            {{'browse.show-only-recently-updated' | translate}}
                        </label>
                    </div>
                    <div class="col-8 help-block" [innerHTML]="'serialpublications.legend1' | translate"></div>
                    <div class="col-8 help-block" [innerHTML]="'serialpublications.legend2' | translate"></div>
                </form>
            </section>
        </div>

        <section class="container browse-all-titles" style="padding-top:0;">
            <div class="browse-results" *ngIf="(loading | async) === false">
                <div class="row">
                    
                    <div class="col-6 text-left">
                        <app-search-result-count-badge [total]="filteredTitles.length"></app-search-result-count-badge>
                    </div>
                    <div class="col-6 text-right" *ngIf="settingsService.commonOptions.excelDownloadEnabled">
                        <a class="btn btn-kk-blue" [href]="excelDownloadUrl" rel="nofollow" id="exc" [ngbTooltip]="'search.excel.tooltip' | translate">
                            <label for="exc"><span class="sr-only" translate>titles.excel.tooltip</span></label>
                            <i class="fa fa-file-excel-o"></i>
                        </a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style="margin-top:20px">
                        <div infinite-scroll [infiniteScrollDistance]="3" (scrolled)="loadMore()">
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let title of titlesBuffer; trackBy: trackTitle">
                                    <app-title-basic-info [title]="title"
                                                          [curyear]="filters.year"
                                                          [isAccessible]="isAccessible(title)"></app-title-basic-info>

                                    <app-title-details [title]="title" 
                                                       [digitizationPercentage]="getFormattedDigitizationPercentage(title)"
                                                       [gaplessPublishedYears]="getGaplessPublishedYears(title)">
                                    </app-title-details>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    `,
    styleUrls: ["./browse-titles.component.scss"]
})
export class BrowseTitlesComponent implements OnInit, OnDestroy {

    @ViewChild("filterForm", {static: true}) filterForm: NgForm;

    supportedGeneralTypes: ValueWithLabel<GeneralType>[] = [
        {
            value: GeneralType.NEWSPAPER,
            label: this.translate.instant("sp.main.header")  // "sanomalehdet"
        },
        {
            value: GeneralType.JOURNAL,
            label: this.translate.instant("journal.main.header") // "aikakauslehdet"
        }
    ];

    // FIXME the events in this page are getting too complex and unwieldy
    // The observables should be designed from the ground up and the form probably converted to reactive form

    loading = new BehaviorSubject(true);

    filters: TitleFilters = {
        generalTypes: this.supportedGeneralTypes.map(i => i.value),
        name: "",
        year: null,
        language: "",
        location: null,
        accessibleOnly: false,
        newTitlesOnly: false
    };
    places: PublicationsPlacesByCountryEntry[];
    allPlacesByCountry: PublicationsPlacesByCountryEntry[];
    languages: LocalizedValue[];
    titles: BindingTitleBrowseListDto[] = [];
    filteredTitles: BindingTitleBrowseListDto[] = [];
    titlesBuffer: BindingTitleBrowseListDto[] = [];

    noMoreResults = false;
    loadingMore = false;

    excelDownloadUrl: string;
    private onFormChanges: Subscription;
    private referenceDataSubscription: Subscription;

    constructor(private titleBrowseApi: TitleBrowseRestEndpoint,
                private navigationService: NavigationService,
                private router: Router,
                public settingsService: SettingsService,
                private readonly translate: TranslateService,
                breadcrumbs: BreadcrumbsService) {

        breadcrumbs.setLocalizedText('serialpublications.main.header');
    }

    ngOnInit(): void {
        this.referenceDataSubscription = this.titleBrowseApi.getSerialPublicationTitles(null).subscribe(result => {
            this.titles = result.titles;
            this.allPlacesByCountry = this.places = result.digitizationTimesByLocation;
            this.languages = result.languages;
            this.titlesBuffer = this.titles.slice(0, 100);

            this.loading.next(false);
            this.readFiltersFromUrl();

            const generalTypes2 = [GeneralType.NEWSPAPER, GeneralType.JOURNAL];
            this.supportedGeneralTypes = this.formatGeneralTypesWithLabel(generalTypes2);

            this.filterTitles(true);

        });

        // skip initial value as we are only interested in the "loading finished" -event
        const onLoaded = this.loading.pipe(skip(1));

        const onLoadedFormChanges = combineLatest([this.filterForm.control.valueChanges, onLoaded]).pipe(
            debounceTime(200)
        );

        this.onFormChanges = onLoadedFormChanges.subscribe(() => {
            this.filterTitles();
            this.updateExcelUrl();
        });
    }

    ngOnDestroy(): void {
        this.referenceDataSubscription.unsubscribe();
        this.onFormChanges.unsubscribe();
    }

    // idea copied from search.service.ts
    formatGeneralTypesWithLabel(types: GeneralType[]): ValueWithLabel<GeneralType>[] {
        return types.map(type => ({value: type, label: this.translate.instant(`general-type.plural.${type}`)}));
    }


    updateExcelUrl() {
        const urlTree = this.router.createUrlTree(["/titles/download/serial-publications.xlsx"], {
            queryParams: {
                generalTypes: this.filters.generalTypes
            }
        });
        this.excelDownloadUrl = urlTree.toString();
    }

    isAccessible(title: BindingTitleBrowseListDto): boolean {
        return title.digitizationTimes.some(time => time.availability !== "RESTRICTED");
    }

    getFormattedDigitizationPercentage(title: BindingTitleBrowseListDto): string {
        return Math.floor(title.digitizationCoverage * 100) + '%';
    }

    /**
     * Returns a short string representation of the publishing years. Gaps in the years are not displayed for brevity.
     */
    getGaplessPublishedYears(title: BindingTitleBrowseListDto): string {
        let minYear: number = null;
        let maxYear: number = null;

        for (const pp of title.publishedTimes) {
            minYear = definedOrMin(minYear, pp.from);
            maxYear = definedOrMax(maxYear, pp.to);
        }

        return minYear !== maxYear ? minYear + "-" + maxYear : "" + minYear;
    }

    trackTitle(index: number, item: BindingTitleBrowseListDto) {
        return item.identification;
    }

    countryAndCityEquals(cc1: CountryAndCity, cc2: CountryAndCity) {
        return (cc1 === null && cc2 === null) || (cc1 !== null && cc2 !== null && cc1.countryCode === cc2.countryCode && cc1.city === cc2.city);
    }

    loadMore() {
        if (!this.loadingMore) {
            this.loadingMore = true;

            let lastLoadedIndex = this.titlesBuffer.length;
            if (lastLoadedIndex < this.filteredTitles.length) {
                lastLoadedIndex += 100;

                this.titlesBuffer = this.filteredTitles.slice(0, lastLoadedIndex);
            } else {
                this.noMoreResults = true;
            }

            this.loadingMore = false;
        }
    }

    private updateFiltersUrl(replaceUrl: boolean) {
        const urlFilters: any = {};
        const filtersAsAny: any = this.filters;
        for (const key of Object.keys(filtersAsAny)) {
            const value = filtersAsAny[key];
            if (value !== undefined && value !== null && value !== '' && value !== false) {
                if (key === "location") {
                    const countryAndCity = value as CountryAndCity;
                    urlFilters[key] = countryAndCity.countryCode + "__" + countryAndCity.city;
                } else {
                    urlFilters[key] = value;
                }
            }
        }
        this.navigationService.setSearch(urlFilters, replaceUrl);
    }

    private readFiltersFromUrl() {
        const searchPath = this.navigationService.search;

        Object.assign(this.filters, searchPath);

        if (typeof this.filters.generalTypes as any === "string")
            this.filters.generalTypes = [this.filters.generalTypes] as any;

        if (searchPath.location) {
            const i = searchPath.location.indexOf("__");
            if (i > -1) {
               this.filters.location = {countryCode: searchPath.location.substring(0, i), city: searchPath.location.substring(i + 2)};
            } else {
                this.filters.location = null;
            }
        }
    }

    // Title filtering
    // ***************
    private filterTitles(replaceUrl: boolean = false) {
        this.titlesBuffer = [];

        this.filteredTitles = this.titles.filter(title => {
           return this.filterByGeneralType(title)
               && this.filterByTitleName(title) && this.filterByYear(title)
               && this.filterByLanguage(title) && this.filterByPlace(title)
               && this.filterByAccessibility(title) && this.filterByNewTitles(title);
        });

        this.titlesBuffer = this.filteredTitles.slice(0, 100);
        this.updateFiltersUrl(replaceUrl);

    }

    private filterByGeneralType(title: BindingTitleBrowseListDto): boolean {
        return this.filters.generalTypes.includes(title.generalType);
    }

    private filterByTitleName(title: BindingTitleBrowseListDto): boolean {
        if (!this.filters.name) {
            return true;
        } else {
            const normalizedTitle = title.title.toLowerCase();
            const query = this.filters.name.toLowerCase();

            return normalizedTitle.indexOf(query) !== -1;
        }
    }

    private filterByYear(title: BindingTitleBrowseListDto): boolean {
        const year = this.getYearFilterValue();

        if (year === null)
            return true;

        return title.digitizationTimes.some(dt => {
           return isBetween(year, dt.yearSpan.from, dt.yearSpan.to);
        });
    }

    private filterByLanguage(title: BindingTitleBrowseListDto): boolean {
        const language = this.filters.language;

        if (!language)
            return true;

        return title.languageCodes.includes(language);

    }

    private filterByPlace(title: BindingTitleBrowseListDto): boolean {
        const place = this.filters.location;
        const year = this.getYearFilterValue();
        if (!place)
            return true;

        return title.publishingPlaces.some(pp => {
            return pp.countryCode === place.countryCode && pp.city === place.city &&
                (year === null || isBetween(year, pp.fromYear, pp.toYear));
        });
    }

    private filterByAccessibility(title: BindingTitleBrowseListDto): boolean {
        const accessible = this.filters.accessibleOnly;
        if (!accessible)
            return true;

        return this.isAccessible(title);
    }

    private filterByNewTitles(title: BindingTitleBrowseListDto): boolean {
        if (!this.filters.newTitlesOnly)
            return true;

        return title.hasNewBindingsWithinWeek;
    }

    filterPublicationPlacesByYear() {
        const year = this.getYearFilterValue();
        const placeFilterValue = this.filters.location;

        this.places = year === null ? this.allPlacesByCountry : this.allPlacesByCountry.map(entry => {
            return {localizedCountryName: entry.localizedCountryName, places: entry.places.filter(place => {
                // jos jokin paikka on valittu, jätetään se TODO: conflict tyyli, vaatii select2:sen
                return (placeFilterValue !== null && place.countryCode === placeFilterValue.countryCode && place.city === placeFilterValue.city) || place.digitizationTimes.some(dt => isBetween(year, dt.from, dt.to));
            })} as PublicationsPlacesByCountryEntry;
        });
    }

    // validate that year is number
    private getYearFilterValue(): number {
        const year = Number(this.filters.year);
        return isNaN(year) || year <= 0 ? null : year;
    }
}

function definedOrBiFunction<T>(func: (a: T, b: T) => T): (a: T, b: T) => T {
    return (a: T, b: T): T => {
        if (a === undefined || a === null) {
            return b;
        } else if (b === undefined || b === null) {
            return a;
        } else {
            return func(a, b);
        }
    };
}

function isBetween(year: number, from: number, to: number): boolean {
    return year >= from && year <= to;
}

const definedOrMin = definedOrBiFunction(Math.min);
const definedOrMax = definedOrBiFunction(Math.max);

interface TitleFilters {
    generalTypes: GeneralType[];
    name: string;
    year: number;
    language: string;
    location: CountryAndCity;
    accessibleOnly: boolean;
    newTitlesOnly: boolean;
}

interface CountryAndCity {
    countryCode: string;
    city: string;
}
