(function (root, factory) {
  if (typeof define === 'function' && define.amd)
    define(['exports'], factory);
  else if (typeof exports === 'object')
    factory(module.exports);
  else
    root['kansalliskirjasto-search-multiplatform'] = factory(typeof this['kansalliskirjasto-search-multiplatform'] === 'undefined' ? {} : this['kansalliskirjasto-search-multiplatform']);
}(this, function (_) {
  'use strict';
  AbstractMutableCollection.prototype = Object.create(AbstractCollection.prototype);
  AbstractMutableCollection.prototype.constructor = AbstractMutableCollection;
  AbstractMutableList.prototype = Object.create(AbstractMutableCollection.prototype);
  AbstractMutableList.prototype.constructor = AbstractMutableList;
  AbstractMutableSet.prototype = Object.create(AbstractMutableCollection.prototype);
  AbstractMutableSet.prototype.constructor = AbstractMutableSet;
  AbstractEntrySet.prototype = Object.create(AbstractMutableSet.prototype);
  AbstractEntrySet.prototype.constructor = AbstractEntrySet;
  AbstractMutableMap.prototype = Object.create(AbstractMap.prototype);
  AbstractMutableMap.prototype.constructor = AbstractMutableMap;
  ArrayList.prototype = Object.create(AbstractMutableList.prototype);
  ArrayList.prototype.constructor = ArrayList;
  EntrySet.prototype = Object.create(AbstractEntrySet.prototype);
  EntrySet.prototype.constructor = EntrySet;
  HashMap.prototype = Object.create(AbstractMutableMap.prototype);
  HashMap.prototype.constructor = HashMap;
  ChainEntry.prototype = Object.create(SimpleEntry.prototype);
  ChainEntry.prototype.constructor = ChainEntry;
  EntrySet_0.prototype = Object.create(AbstractEntrySet.prototype);
  EntrySet_0.prototype.constructor = EntrySet_0;
  LinkedHashMap.prototype = Object.create(HashMap.prototype);
  LinkedHashMap.prototype.constructor = LinkedHashMap;
  PrimitiveKClassImpl.prototype = Object.create(KClassImpl.prototype);
  PrimitiveKClassImpl.prototype.constructor = PrimitiveKClassImpl;
  NothingKClassImpl.prototype = Object.create(KClassImpl.prototype);
  NothingKClassImpl.prototype.constructor = NothingKClassImpl;
  SimpleKClassImpl.prototype = Object.create(KClassImpl.prototype);
  SimpleKClassImpl.prototype.constructor = SimpleKClassImpl;
  Long.prototype = Object.create(Number_0.prototype);
  Long.prototype.constructor = Long;
  Exception.prototype = Object.create(Error.prototype);
  Exception.prototype.constructor = Exception;
  RuntimeException.prototype = Object.create(Exception.prototype);
  RuntimeException.prototype.constructor = RuntimeException;
  IllegalArgumentException.prototype = Object.create(RuntimeException.prototype);
  IllegalArgumentException.prototype.constructor = IllegalArgumentException;
  NoSuchElementException.prototype = Object.create(RuntimeException.prototype);
  NoSuchElementException.prototype.constructor = NoSuchElementException;
  IllegalStateException.prototype = Object.create(RuntimeException.prototype);
  IllegalStateException.prototype.constructor = IllegalStateException;
  UnsupportedOperationException.prototype = Object.create(RuntimeException.prototype);
  UnsupportedOperationException.prototype.constructor = UnsupportedOperationException;
  NumberFormatException.prototype = Object.create(IllegalArgumentException.prototype);
  NumberFormatException.prototype.constructor = NumberFormatException;
  IndexOutOfBoundsException.prototype = Object.create(RuntimeException.prototype);
  IndexOutOfBoundsException.prototype.constructor = IndexOutOfBoundsException;
  NullPointerException.prototype = Object.create(RuntimeException.prototype);
  NullPointerException.prototype.constructor = NullPointerException;
  ClassCastException.prototype = Object.create(RuntimeException.prototype);
  ClassCastException.prototype.constructor = ClassCastException;
  function getOrNull(_this_, index) {
    return (index >= 0 ? index <= _get_lastIndex_(_this_) : false) ? _this_[index] : null;
  }
  function _get_lastIndex_(_this_) {
    return _this_.length - 1 | 0;
  }
  function indexOf(_this_, element) {
    if (element == null) {
      var inductionVariable = 0;
      var last = _this_.length - 1 | 0;
      if (inductionVariable <= last)
        do {
          var index = inductionVariable;
          inductionVariable = inductionVariable + 1 | 0;
          if (_this_[index] == null) {
            return index;
          }}
         while (inductionVariable <= last);
    } else {
      var inductionVariable_0 = 0;
      var last_0 = _this_.length - 1 | 0;
      if (inductionVariable_0 <= last_0)
        do {
          var index_0 = inductionVariable_0;
          inductionVariable_0 = inductionVariable_0 + 1 | 0;
          if (equals(element, _this_[index_0])) {
            return index_0;
          }}
         while (inductionVariable_0 <= last_0);
    }
    return -1;
  }
  function joinToString(_this_, separator, prefix, postfix, limit, truncated, transform) {
    return joinTo(_this_, StringBuilder_init_$Create$(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function joinToString$default(_this_, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      separator = ', ';
    if (!(($mask0 & 2) === 0))
      prefix = '';
    if (!(($mask0 & 4) === 0))
      postfix = '';
    if (!(($mask0 & 8) === 0))
      limit = -1;
    if (!(($mask0 & 16) === 0))
      truncated = '...';
    if (!(($mask0 & 32) === 0))
      transform = null;
    return joinToString(_this_, separator, prefix, postfix, limit, truncated, transform);
  }
  function joinTo(_this_, buffer, separator, prefix, postfix, limit, truncated, transform) {
    buffer.append_2(prefix);
    Unit_getInstance();
    var count = 0;
    var indexedObject = _this_;
    var inductionVariable = 0;
    var last = indexedObject.length;
    $l$break: while (inductionVariable < last) {
      var element = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      count = count + 1 | 0;
      if (count > 1) {
        buffer.append_2(separator);
        Unit_getInstance();
      } else {
      }
      if (limit < 0 ? true : count <= limit) {
        appendElement(buffer, element, transform);
      } else
        break $l$break;
    }
    if (limit >= 0 ? count > limit : false) {
      buffer.append_2(truncated);
      Unit_getInstance();
    }buffer.append_2(postfix);
    Unit_getInstance();
    return buffer;
  }
  function joinToString_0(_this_, separator, prefix, postfix, limit, truncated, transform) {
    return joinTo_0(_this_, StringBuilder_init_$Create$(), separator, prefix, postfix, limit, truncated, transform).toString();
  }
  function joinToString$default_0(_this_, separator, prefix, postfix, limit, truncated, transform, $mask0, $handler) {
    if (!(($mask0 & 1) === 0))
      separator = ', ';
    if (!(($mask0 & 2) === 0))
      prefix = '';
    if (!(($mask0 & 4) === 0))
      postfix = '';
    if (!(($mask0 & 8) === 0))
      limit = -1;
    if (!(($mask0 & 16) === 0))
      truncated = '...';
    if (!(($mask0 & 32) === 0))
      transform = null;
    return joinToString_0(_this_, separator, prefix, postfix, limit, truncated, transform);
  }
  function joinTo_0(_this_, buffer, separator, prefix, postfix, limit, truncated, transform) {
    buffer.append_2(prefix);
    Unit_getInstance();
    var count = 0;
    var tmp0_iterator = _this_.iterator_19();
    $l$break: while (tmp0_iterator.hasNext_2()) {
      var element = tmp0_iterator.next_2();
      count = count + 1 | 0;
      if (count > 1) {
        buffer.append_2(separator);
        Unit_getInstance();
      } else {
      }
      if (limit < 0 ? true : count <= limit) {
        appendElement(buffer, element, transform);
      } else
        break $l$break;
    }
    if (limit >= 0 ? count > limit : false) {
      buffer.append_2(truncated);
      Unit_getInstance();
    }buffer.append_2(postfix);
    Unit_getInstance();
    return buffer;
  }
  function _no_name_provided_(this$0) {
    this._this$0 = this$0;
  }
  _no_name_provided_.prototype.invoke = function (it) {
    return it === this._this$0 ? '(this Collection)' : toString_0(it);
  };
  _no_name_provided_.prototype.invoke_72 = function (p1) {
    return this.invoke((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided_.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractCollection() {
  }
  AbstractCollection.prototype.contains_4 = function (element) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(this, Collection)) {
        tmp = this.isEmpty_7();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = false;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = this.iterator_19();
      while (tmp0_iterator_1.hasNext_2()) {
        var element_2 = tmp0_iterator_1.next_2();
        if (equals(element_2, element)) {
          tmp$ret$0 = true;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = false;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractCollection.prototype.containsAll_7 = function (elements) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp;
      if (isInterface(elements, Collection)) {
        tmp = elements.isEmpty_7();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = elements.iterator_19();
      while (tmp0_iterator_1.hasNext_2()) {
        var element_2 = tmp0_iterator_1.next_2();
        if (!this.contains_4(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractCollection.prototype.isEmpty_7 = function () {
    return this._get_size__16() === 0;
  };
  AbstractCollection.prototype.toString = function () {
    return joinToString$default_0(this, ', ', '[', ']', 0, null, _no_name_provided_$factory(this), 24, null);
  };
  AbstractCollection.prototype.toArray = function () {
    return copyToArrayImpl_0(this);
  };
  AbstractCollection.$metadata$ = {
    simpleName: 'AbstractCollection',
    kind: 'class',
    interfaces: [Collection]
  };
  function _no_name_provided_$factory(this$0) {
    var i = new _no_name_provided_(this$0);
    return function (p1) {
      return i.invoke(p1);
    };
  }
  function Companion_0() {
    Companion_instance = this;
  }
  Companion_0.prototype.checkElementIndex = function (index, size) {
    if (index < 0 ? true : index >= size) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', size: ' + size);
    }};
  Companion_0.prototype.checkPositionIndex = function (index, size) {
    if (index < 0 ? true : index > size) {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', size: ' + size);
    }};
  Companion_0.prototype.orderedHashCode = function (c) {
    var hashCode_0 = 1;
    var tmp0_iterator = c.iterator_19();
    while (tmp0_iterator.hasNext_2()) {
      var e = tmp0_iterator.next_2();
      var tmp = imul(31, hashCode_0);
      var tmp1_safe_receiver = e;
      var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : hashCode(tmp1_safe_receiver);
      hashCode_0 = tmp + (tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs) | 0;
    }
    return hashCode_0;
  };
  Companion_0.prototype.orderedEquals = function (c, other) {
    if (!(c._get_size__16() === other._get_size__16()))
      return false;
    var otherIterator = other.iterator_19();
    var tmp0_iterator = c.iterator_19();
    while (tmp0_iterator.hasNext_2()) {
      var elem = tmp0_iterator.next_2();
      var elemOther = otherIterator.next_2();
      if (!equals(elem, elemOther)) {
        return false;
      }}
    return true;
  };
  Companion_0.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance;
  function Companion_getInstance() {
    if (Companion_instance == null)
      new Companion_0();
    return Companion_instance;
  }
  function toString($this, o) {
    return o === $this ? '(this Map)' : toString_0(o);
  }
  function implFindEntry($this, key) {
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_firstOrNull_0 = $this._get_entries__3();
      var tmp0_iterator_1 = tmp0_firstOrNull_0.iterator_19();
      while (tmp0_iterator_1.hasNext_2()) {
        var element_2 = tmp0_iterator_1.next_2();
        if (equals(element_2._get_key__2(), key)) {
          tmp$ret$0 = element_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    return tmp$ret$0;
  }
  function Companion_1() {
    Companion_instance_0 = this;
  }
  Companion_1.prototype.entryHashCode = function (e) {
    var tmp2_safe_receiver_4 = e._get_key__2();
    var tmp3_elvis_lhs_3 = tmp2_safe_receiver_4 == null ? null : hashCode(tmp2_safe_receiver_4);
    var tmp = tmp3_elvis_lhs_3 == null ? 0 : tmp3_elvis_lhs_3;
    var tmp0_safe_receiver_6 = e._get_value__2();
    var tmp1_elvis_lhs_5 = tmp0_safe_receiver_6 == null ? null : hashCode(tmp0_safe_receiver_6);
    return tmp ^ (tmp1_elvis_lhs_5 == null ? 0 : tmp1_elvis_lhs_5);
  };
  Companion_1.prototype.entryToString = function (e) {
    return '' + e._get_key__2() + '=' + e._get_value__2();
  };
  Companion_1.prototype.entryEquals = function (e, other) {
    if (!(!(other == null) ? isInterface(other, Entry) : false))
      return false;
    else {
    }
    return equals(e._get_key__2(), other._get_key__2()) ? equals(e._get_value__2(), other._get_value__2()) : false;
  };
  Companion_1.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_0;
  function Companion_getInstance_0() {
    if (Companion_instance_0 == null)
      new Companion_1();
    return Companion_instance_0;
  }
  function _no_name_provided__0(this$0) {
    this._this$0_0 = this$0;
  }
  _no_name_provided__0.prototype.invoke_1 = function (it) {
    return this._this$0_0.toString_0(it);
  };
  _no_name_provided__0.prototype.invoke_72 = function (p1) {
    return this.invoke_1((!(p1 == null) ? isInterface(p1, Entry) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__0.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function AbstractMap() {
    Companion_getInstance_0();
    this.__keys = null;
    this.__values = null;
  }
  AbstractMap.prototype.containsKey_3 = function (key) {
    return !(implFindEntry(this, key) == null);
  };
  AbstractMap.prototype.containsEntry_5 = function (entry) {
    if (!(!(entry == null) ? isInterface(entry, Entry) : false))
      return false;
    else {
    }
    var key = entry._get_key__2();
    var value = entry._get_value__2();
    var ourValue = (isInterface(this, Map_0) ? this : THROW_CCE()).get_9(key);
    if (!equals(value, ourValue)) {
      return false;
    }var tmp;
    if (ourValue == null) {
      tmp = !(isInterface(this, Map_0) ? this : THROW_CCE()).containsKey_3(key);
    } else {
      tmp = false;
    }
    if (tmp) {
      return false;
    } else {
    }
    return true;
  };
  AbstractMap.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, Map_0) : false))
      return false;
    else {
    }
    if (!(this._get_size__16() === other._get_size__16()))
      return false;
    var tmp$ret$0;
    l$ret$1: do {
      var tmp0_all_0 = other._get_entries__3();
      var tmp;
      if (isInterface(tmp0_all_0, Collection)) {
        tmp = tmp0_all_0.isEmpty_7();
      } else {
        {
          tmp = false;
        }
      }
      if (tmp) {
        tmp$ret$0 = true;
        break l$ret$1;
      } else {
      }
      var tmp0_iterator_1 = tmp0_all_0.iterator_19();
      while (tmp0_iterator_1.hasNext_2()) {
        var element_2 = tmp0_iterator_1.next_2();
        if (!this.containsEntry_5(element_2)) {
          tmp$ret$0 = false;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = true;
    }
     while (false);
    return tmp$ret$0;
  };
  AbstractMap.prototype.get_9 = function (key) {
    var tmp0_safe_receiver = implFindEntry(this, key);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__2();
  };
  AbstractMap.prototype.hashCode = function () {
    return hashCode(this._get_entries__3());
  };
  AbstractMap.prototype._get_size__16 = function () {
    return this._get_entries__3()._get_size__16();
  };
  AbstractMap.prototype.toString = function () {
    var tmp = this._get_entries__3();
    return joinToString$default_0(tmp, ', ', '{', '}', 0, null, _no_name_provided_$factory_0(this), 24, null);
  };
  AbstractMap.prototype.toString_0 = function (entry) {
    return toString(this, entry._get_key__2()) + '=' + toString(this, entry._get_value__2());
  };
  AbstractMap.$metadata$ = {
    simpleName: 'AbstractMap',
    kind: 'class',
    interfaces: [Map_0]
  };
  function _no_name_provided_$factory_0(this$0) {
    var i = new _no_name_provided__0(this$0);
    return function (p1) {
      return i.invoke_1(p1);
    };
  }
  function Companion_2() {
    Companion_instance_1 = this;
  }
  Companion_2.prototype.unorderedHashCode = function (c) {
    var hashCode_0 = 0;
    var tmp0_iterator = c.iterator_19();
    while (tmp0_iterator.hasNext_2()) {
      var element = tmp0_iterator.next_2();
      var tmp = hashCode_0;
      var tmp1_safe_receiver = element;
      var tmp2_elvis_lhs = tmp1_safe_receiver == null ? null : hashCode(tmp1_safe_receiver);
      hashCode_0 = tmp + (tmp2_elvis_lhs == null ? 0 : tmp2_elvis_lhs) | 0;
    }
    return hashCode_0;
  };
  Companion_2.prototype.setEquals = function (c, other) {
    if (!(c._get_size__16() === other._get_size__16()))
      return false;
    return c.containsAll_7(other);
  };
  Companion_2.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_1;
  function Companion_getInstance_1() {
    if (Companion_instance_1 == null)
      new Companion_2();
    return Companion_instance_1;
  }
  function _get_lastIndex__0(_this_) {
    return _this_._get_size__16() - 1 | 0;
  }
  function KClassifier() {
  }
  KClassifier.$metadata$ = {
    simpleName: 'KClassifier',
    kind: 'interface',
    interfaces: []
  };
  function appendElement(_this_, element, transform) {
    if (!(transform == null)) {
      _this_.append_2(transform(element));
      Unit_getInstance();
    } else {
      if (element == null ? true : isCharSequence(element)) {
        _this_.append_2(element);
        Unit_getInstance();
      } else {
        if (element instanceof Char) {
          _this_.append_1(element);
          Unit_getInstance();
        } else {
          {
            _this_.append_2(toString_0(element));
            Unit_getInstance();
          }
        }
      }
    }
  }
  function toIntOrNull(_this_) {
    return toIntOrNull_0(_this_, 10);
  }
  function numberFormatError(input) {
    throw NumberFormatException_init_$Create$('' + "Invalid number format: '" + input + "'");
  }
  function toIntOrNull_0(_this_, radix) {
    checkRadix(radix);
    Unit_getInstance();
    var length = _this_.length;
    if (length === 0)
      return null;
    var start;
    var isNegative;
    var limit;
    var firstChar = charSequenceGet(_this_, 0);
    if (firstChar.compareTo(new Char(48)) < 0) {
      if (length === 1)
        return null;
      start = 1;
      if (firstChar.equals(new Char(45))) {
        isNegative = true;
        limit = IntCompanionObject_getInstance()._MIN_VALUE;
      } else if (firstChar.equals(new Char(43))) {
        isNegative = false;
        limit = -IntCompanionObject_getInstance()._MAX_VALUE | 0;
      } else
        return null;
    } else {
      start = 0;
      isNegative = false;
      limit = -IntCompanionObject_getInstance()._MAX_VALUE | 0;
    }
    var limitForMaxRadix = (-IntCompanionObject_getInstance()._MAX_VALUE | 0) / 36 | 0;
    var limitBeforeMul = limitForMaxRadix;
    var result = 0;
    var inductionVariable = start;
    if (inductionVariable < length)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var digit = digitOf(charSequenceGet(_this_, i), radix);
        if (digit < 0)
          return null;
        if (result < limitBeforeMul) {
          if (limitBeforeMul === limitForMaxRadix) {
            limitBeforeMul = limit / radix | 0;
            if (result < limitBeforeMul) {
              return null;
            }} else {
            return null;
          }
        }result = imul(result, radix);
        if (result < (limit + digit | 0))
          return null;
        result = result - digit | 0;
      }
       while (inductionVariable < length);
    return isNegative ? result : -result | 0;
  }
  function _get_lastIndex__1(_this_) {
    return charSequenceLength(_this_) - 1 | 0;
  }
  function _UShort___init__impl_(data) {
    return data;
  }
  function _UShort___get_data__impl_(this_0) {
    return this_0;
  }
  function CharSequence() {
  }
  CharSequence.$metadata$ = {
    simpleName: 'CharSequence',
    kind: 'interface',
    interfaces: []
  };
  function Comparable() {
  }
  Comparable.$metadata$ = {
    simpleName: 'Comparable',
    kind: 'interface',
    interfaces: []
  };
  function Iterator() {
  }
  Iterator.$metadata$ = {
    simpleName: 'Iterator',
    kind: 'interface',
    interfaces: []
  };
  function MutableIterator() {
  }
  MutableIterator.$metadata$ = {
    simpleName: 'MutableIterator',
    kind: 'interface',
    interfaces: [Iterator]
  };
  function Number_0() {
  }
  Number_0.$metadata$ = {
    simpleName: 'Number',
    kind: 'class',
    interfaces: []
  };
  function Unit() {
    Unit_instance = this;
  }
  Unit.prototype.toString = function () {
    return 'kotlin.Unit';
  };
  Unit.$metadata$ = {
    simpleName: 'Unit',
    kind: 'object',
    interfaces: []
  };
  var Unit_instance;
  function Unit_getInstance() {
    if (Unit_instance == null)
      new Unit();
    return Unit_instance;
  }
  function IntCompanionObject_0() {
    IntCompanionObject_instance = this;
    this._MIN_VALUE = -2147483648;
    this._MAX_VALUE = 2147483647;
    this._SIZE_BYTES = 4;
    this._SIZE_BITS = 32;
  }
  IntCompanionObject_0.prototype._get_MIN_VALUE_ = function () {
    return this._MIN_VALUE;
  };
  IntCompanionObject_0.prototype._get_MAX_VALUE_ = function () {
    return this._MAX_VALUE;
  };
  IntCompanionObject_0.prototype._get_SIZE_BYTES_ = function () {
    return this._SIZE_BYTES;
  };
  IntCompanionObject_0.prototype._get_SIZE_BITS_ = function () {
    return this._SIZE_BITS;
  };
  IntCompanionObject_0.$metadata$ = {
    simpleName: 'IntCompanionObject',
    kind: 'object',
    interfaces: []
  };
  Object.defineProperty(IntCompanionObject_0.prototype, 'MIN_VALUE', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_MIN_VALUE_
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'MAX_VALUE', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_MAX_VALUE_
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'SIZE_BYTES', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_SIZE_BYTES_
  });
  Object.defineProperty(IntCompanionObject_0.prototype, 'SIZE_BITS', {
    configurable: true,
    get: IntCompanionObject_0.prototype._get_SIZE_BITS_
  });
  var IntCompanionObject_instance;
  function IntCompanionObject_getInstance() {
    if (IntCompanionObject_instance == null)
      new IntCompanionObject_0();
    return IntCompanionObject_instance;
  }
  function copyToArray_0(collection) {
    var tmp;
    if (collection.toArray !== undefined) {
      var tmp0_unsafeCast_0 = collection.toArray();
      tmp = tmp0_unsafeCast_0;
    } else {
      {
        var tmp1_unsafeCast_0 = copyToArrayImpl_0(collection);
        tmp = tmp1_unsafeCast_0;
      }
    }
    return tmp;
  }
  function copyToArrayImpl_0(collection) {
    var array = [];
    var iterator = collection.iterator_19();
    while (iterator.hasNext_2()) {
      array.push(iterator.next_2());
    }
    return array;
  }
  function AbstractMutableCollection() {
    AbstractCollection.call(this);
  }
  AbstractMutableCollection.prototype.toJSON = function () {
    return this.toArray();
  };
  AbstractMutableCollection.prototype.checkIsMutable_2 = function () {
  };
  AbstractMutableCollection.$metadata$ = {
    simpleName: 'AbstractMutableCollection',
    kind: 'class',
    interfaces: [MutableCollection]
  };
  function IteratorImpl($outer) {
    this._$this = $outer;
    this._index = 0;
    this._last = -1;
  }
  IteratorImpl.prototype.hasNext_2 = function () {
    return this._index < this._$this._get_size__16();
  };
  IteratorImpl.prototype.next_2 = function () {
    if (!this.hasNext_2())
      throw NoSuchElementException_init_$Create$();
    var tmp = this;
    var tmp0_this = this;
    var tmp1 = tmp0_this._index;
    tmp0_this._index = tmp1 + 1 | 0;
    tmp._last = tmp1;
    return this._$this.get_19(this._last);
  };
  IteratorImpl.$metadata$ = {
    simpleName: 'IteratorImpl',
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function AbstractMutableList() {
    AbstractMutableCollection.call(this);
    this._modCount = 0;
  }
  AbstractMutableList.prototype._set_modCount__0 = function (_set___) {
    this._modCount = _set___;
  };
  AbstractMutableList.prototype._get_modCount__0 = function () {
    return this._modCount;
  };
  AbstractMutableList.prototype.add_11 = function (element) {
    this.checkIsMutable_2();
    this.add_5(this._get_size__16(), element);
    return true;
  };
  AbstractMutableList.prototype.iterator_19 = function () {
    return new IteratorImpl(this);
  };
  AbstractMutableList.prototype.contains_4 = function (element) {
    return this.indexOf_0(element) >= 0;
  };
  AbstractMutableList.prototype.indexOf_0 = function (element) {
    var inductionVariable = 0;
    var last = _get_lastIndex__0(this);
    if (inductionVariable <= last)
      do {
        var index = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        if (equals(this.get_19(index), element)) {
          return index;
        }}
       while (!(index === last));
    return -1;
  };
  AbstractMutableList.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, List) : false))
      return false;
    else {
    }
    return Companion_getInstance().orderedEquals(this, other);
  };
  AbstractMutableList.prototype.hashCode = function () {
    return Companion_getInstance().orderedHashCode(this);
  };
  AbstractMutableList.$metadata$ = {
    simpleName: 'AbstractMutableList',
    kind: 'class',
    interfaces: [MutableList]
  };
  function SimpleEntry(key, value) {
    this._key = key;
    this.__value = value;
  }
  SimpleEntry.prototype._get_key__2 = function () {
    return this._key;
  };
  SimpleEntry.prototype._get_value__2 = function () {
    return this.__value;
  };
  SimpleEntry.prototype.setValue_1 = function (newValue) {
    var oldValue = this.__value;
    this.__value = newValue;
    return oldValue;
  };
  SimpleEntry.prototype.hashCode = function () {
    return Companion_getInstance_0().entryHashCode(this);
  };
  SimpleEntry.prototype.toString = function () {
    return Companion_getInstance_0().entryToString(this);
  };
  SimpleEntry.prototype.equals = function (other) {
    return Companion_getInstance_0().entryEquals(this, other);
  };
  SimpleEntry.$metadata$ = {
    simpleName: 'SimpleEntry',
    kind: 'class',
    interfaces: [MutableEntry]
  };
  function AbstractEntrySet() {
    AbstractMutableSet.call(this);
  }
  AbstractEntrySet.prototype.contains_4 = function (element) {
    return this.containsEntry_4(element);
  };
  AbstractEntrySet.$metadata$ = {
    simpleName: 'AbstractEntrySet',
    kind: 'class',
    interfaces: []
  };
  function AbstractMutableMap() {
    AbstractMap.call(this);
    this.__keys_0 = null;
    this.__values_0 = null;
  }
  AbstractMutableMap.$metadata$ = {
    simpleName: 'AbstractMutableMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function AbstractMutableSet() {
    AbstractMutableCollection.call(this);
  }
  AbstractMutableSet.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(!(other == null) ? isInterface(other, Set) : false))
      return false;
    else {
    }
    return Companion_getInstance_1().setEquals(this, other);
  };
  AbstractMutableSet.prototype.hashCode = function () {
    return Companion_getInstance_1().unorderedHashCode(this);
  };
  AbstractMutableSet.$metadata$ = {
    simpleName: 'AbstractMutableSet',
    kind: 'class',
    interfaces: [MutableSet]
  };
  function ArrayList_init_$Init$($this) {
    ArrayList.call($this, []);
    return $this;
  }
  function ArrayList_init_$Create$() {
    return ArrayList_init_$Init$(Object.create(ArrayList.prototype));
  }
  function ArrayList_init_$Init$_0(initialCapacity, $this) {
    ArrayList.call($this, []);
    return $this;
  }
  function ArrayList_init_$Create$_0(initialCapacity) {
    return ArrayList_init_$Init$_0(initialCapacity, Object.create(ArrayList.prototype));
  }
  function rangeCheck($this, index) {
    Companion_getInstance().checkElementIndex(index, $this._get_size__16());
    return index;
  }
  function insertionRangeCheck($this, index) {
    Companion_getInstance().checkPositionIndex(index, $this._get_size__16());
    return index;
  }
  function ArrayList(array) {
    AbstractMutableList.call(this);
    this._array = array;
    this._isReadOnly = false;
  }
  ArrayList.prototype._get_size__16 = function () {
    return this._array.length;
  };
  ArrayList.prototype.get_19 = function (index) {
    var tmp = this._array[rangeCheck(this, index)];
    return (tmp == null ? true : isObject(tmp)) ? tmp : THROW_CCE();
  };
  ArrayList.prototype.add_11 = function (element) {
    this.checkIsMutable_2();
    var tmp0_asDynamic_0 = this._array;
    tmp0_asDynamic_0.push(element);
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
    return true;
  };
  ArrayList.prototype.add_5 = function (index, element) {
    this.checkIsMutable_2();
    var tmp0_asDynamic_0 = this._array;
    tmp0_asDynamic_0.splice(insertionRangeCheck(this, index), 0, element);
    var tmp0_this = this;
    var tmp1 = tmp0_this._get_modCount__0();
    tmp0_this._set_modCount__0(tmp1 + 1 | 0);
    Unit_getInstance();
  };
  ArrayList.prototype.indexOf_0 = function (element) {
    return indexOf(this._array, element);
  };
  ArrayList.prototype.toString = function () {
    return arrayToString(this._array);
  };
  ArrayList.prototype.toArray_0 = function () {
    return [].slice.call(this._array);
  };
  ArrayList.prototype.toArray = function () {
    return this.toArray_0();
  };
  ArrayList.prototype.checkIsMutable_2 = function () {
    if (this._isReadOnly)
      throw UnsupportedOperationException_init_$Create$();
  };
  ArrayList.$metadata$ = {
    simpleName: 'ArrayList',
    kind: 'class',
    interfaces: [MutableList, RandomAccess]
  };
  function HashCode() {
    HashCode_instance = this;
  }
  HashCode.prototype.equals_1 = function (value1, value2) {
    return equals(value1, value2);
  };
  HashCode.prototype.getHashCode_0 = function (value) {
    var tmp0_safe_receiver = value;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : hashCode(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  };
  HashCode.$metadata$ = {
    simpleName: 'HashCode',
    kind: 'object',
    interfaces: [EqualityComparator]
  };
  var HashCode_instance;
  function HashCode_getInstance() {
    if (HashCode_instance == null)
      new HashCode();
    return HashCode_instance;
  }
  function EqualityComparator() {
  }
  EqualityComparator.$metadata$ = {
    simpleName: 'EqualityComparator',
    kind: 'interface',
    interfaces: []
  };
  function EntrySet($outer) {
    this._$this_0 = $outer;
    AbstractEntrySet.call(this);
  }
  EntrySet.prototype.add_8 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on entries');
  };
  EntrySet.prototype.add_11 = function (element) {
    return this.add_8((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet.prototype.containsEntry_4 = function (element) {
    return this._$this_0.containsEntry_5(element);
  };
  EntrySet.prototype.iterator_19 = function () {
    return this._$this_0._internalMap.iterator_19();
  };
  EntrySet.prototype._get_size__16 = function () {
    return this._$this_0._get_size__16();
  };
  EntrySet.$metadata$ = {
    simpleName: 'EntrySet',
    kind: 'class',
    interfaces: []
  };
  function HashMap_init_$Init$(internalMap, $this) {
    AbstractMutableMap.call($this);
    HashMap.call($this);
    $this._internalMap = internalMap;
    $this._equality = internalMap._get_equality__0();
    return $this;
  }
  function HashMap_init_$Init$_0($this) {
    HashMap_init_$Init$(new InternalHashCodeMap(HashCode_getInstance()), $this);
    return $this;
  }
  function HashMap_init_$Create$() {
    return HashMap_init_$Init$_0(Object.create(HashMap.prototype));
  }
  HashMap.prototype.containsKey_3 = function (key) {
    return this._internalMap.contains_7(key);
  };
  HashMap.prototype._get_entries__3 = function () {
    if (this.__entries == null) {
      this.__entries = this.createEntrySet_0();
    }return ensureNotNull(this.__entries);
  };
  HashMap.prototype.createEntrySet_0 = function () {
    return new EntrySet(this);
  };
  HashMap.prototype.get_9 = function (key) {
    return this._internalMap.get_9(key);
  };
  HashMap.prototype.put_4 = function (key, value) {
    return this._internalMap.put_4(key, value);
  };
  HashMap.prototype._get_size__16 = function () {
    return this._internalMap._get_size__16();
  };
  function HashMap() {
    this.__entries = null;
  }
  HashMap.$metadata$ = {
    simpleName: 'HashMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function computeNext($this) {
    if ($this._chainOrEntry != null ? $this._isChain : false) {
      var tmp0_unsafeCast_0 = $this._chainOrEntry;
      var chainSize = tmp0_unsafeCast_0.length;
      var tmp0_this = $this;
      tmp0_this._itemIndex = tmp0_this._itemIndex + 1 | 0;
      if (tmp0_this._itemIndex < chainSize)
        return 0;
      else {
      }
    }var tmp1_this = $this;
    tmp1_this._keyIndex = tmp1_this._keyIndex + 1 | 0;
    if (tmp1_this._keyIndex < $this._keys.length) {
      $this._chainOrEntry = $this._this$0_1._backingMap[$this._keys[$this._keyIndex]];
      var tmp = $this;
      var tmp_0 = $this._chainOrEntry;
      tmp._isChain = !(tmp_0 == null) ? isArray(tmp_0) : false;
      $this._itemIndex = 0;
      return 0;
    } else {
      {
        $this._chainOrEntry = null;
        return 1;
      }
    }
  }
  function getEntry($this, key) {
    var tmp0_elvis_lhs = getChainOrEntryOrNull($this, $this._equality_0.getHashCode_0(key));
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return null;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var chainOrEntry = tmp;
    if (!(!(chainOrEntry == null) ? isArray(chainOrEntry) : false)) {
      var entry = chainOrEntry;
      if ($this._equality_0.equals_1(entry._get_key__2(), key)) {
        return entry;
      } else {
        return null;
      }
    } else {
      {
        var chain = chainOrEntry;
        return findEntryInChain(chain, $this, key);
      }
    }
  }
  function findEntryInChain(_this_, $this, key) {
    var tmp$ret$0;
    l$ret$1: do {
      var indexedObject = _this_;
      var inductionVariable = 0;
      var last = indexedObject.length;
      while (inductionVariable < last) {
        var element_2 = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if ($this._equality_0.equals_1(element_2._get_key__2(), key)) {
          tmp$ret$0 = element_2;
          break l$ret$1;
        } else {
        }
      }
      tmp$ret$0 = null;
    }
     while (false);
    return tmp$ret$0;
  }
  function getChainOrEntryOrNull($this, hashCode_0) {
    var chainOrEntry = $this._backingMap[hashCode_0];
    return chainOrEntry === undefined ? null : chainOrEntry;
  }
  function _no_name_provided__1(this$0) {
    this._this$0_1 = this$0;
    this._state = -1;
    this._keys = Object.keys(this._this$0_1._backingMap);
    this._keyIndex = -1;
    this._chainOrEntry = null;
    this._isChain = false;
    this._itemIndex = -1;
    this._lastEntry = null;
  }
  _no_name_provided__1.prototype.hasNext_2 = function () {
    if (this._state === -1)
      this._state = computeNext(this);
    return this._state === 0;
  };
  _no_name_provided__1.prototype.next_2 = function () {
    if (!this.hasNext_2())
      throw NoSuchElementException_init_$Create$();
    var tmp;
    if (this._isChain) {
      var tmp0_unsafeCast_0 = this._chainOrEntry;
      tmp = tmp0_unsafeCast_0[this._itemIndex];
    } else {
      var tmp1_unsafeCast_0 = this._chainOrEntry;
      tmp = tmp1_unsafeCast_0;
    }
    var lastEntry = tmp;
    this._lastEntry = lastEntry;
    this._state = -1;
    return lastEntry;
  };
  _no_name_provided__1.$metadata$ = {
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function InternalHashCodeMap(equality) {
    this._equality_0 = equality;
    this._backingMap = this.createJsMap_0();
    this._size = 0;
  }
  InternalHashCodeMap.prototype._get_equality__0 = function () {
    return this._equality_0;
  };
  InternalHashCodeMap.prototype._get_size__16 = function () {
    return this._size;
  };
  InternalHashCodeMap.prototype.put_4 = function (key, value) {
    var hashCode_0 = this._equality_0.getHashCode_0(key);
    var chainOrEntry = getChainOrEntryOrNull(this, hashCode_0);
    if (chainOrEntry == null) {
      this._backingMap[hashCode_0] = new SimpleEntry(key, value);
    } else {
      if (!(!(chainOrEntry == null) ? isArray(chainOrEntry) : false)) {
        var entry = chainOrEntry;
        if (this._equality_0.equals_1(entry._get_key__2(), key)) {
          return entry.setValue_1(value);
        } else {
          var tmp0_arrayOf_0 = [entry, new SimpleEntry(key, value)];
          this._backingMap[hashCode_0] = tmp0_arrayOf_0;
          var tmp0_this = this;
          var tmp1 = tmp0_this._size;
          tmp0_this._size = tmp1 + 1 | 0;
          Unit_getInstance();
          return null;
        }
      } else {
        {
          var chain = chainOrEntry;
          var entry_0 = findEntryInChain(chain, this, key);
          if (!(entry_0 == null)) {
            return entry_0.setValue_1(value);
          }chain.push(new SimpleEntry(key, value));
        }
      }
    }
    var tmp2_this = this;
    var tmp3 = tmp2_this._size;
    tmp2_this._size = tmp3 + 1 | 0;
    Unit_getInstance();
    return null;
  };
  InternalHashCodeMap.prototype.contains_7 = function (key) {
    return !(getEntry(this, key) == null);
  };
  InternalHashCodeMap.prototype.get_9 = function (key) {
    var tmp0_safe_receiver = getEntry(this, key);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__2();
  };
  InternalHashCodeMap.prototype.iterator_19 = function () {
    return new _no_name_provided__1(this);
  };
  InternalHashCodeMap.$metadata$ = {
    simpleName: 'InternalHashCodeMap',
    kind: 'class',
    interfaces: [InternalMap]
  };
  function InternalMap() {
  }
  InternalMap.prototype.createJsMap_0 = function () {
    var result = Object.create(null);
    result['foo'] = 1;
    jsDeleteProperty(result, 'foo');
    return result;
  };
  InternalMap.$metadata$ = {
    simpleName: 'InternalMap',
    kind: 'interface',
    interfaces: [MutableIterable]
  };
  function EntryIterator($outer) {
    this._$this_1 = $outer;
    this._last_0 = null;
    this._next = null;
    this._next = this._$this_1._$this_3._head;
  }
  EntryIterator.prototype.hasNext_2 = function () {
    return !(this._next === null);
  };
  EntryIterator.prototype.next_2 = function () {
    if (!this.hasNext_2())
      throw NoSuchElementException_init_$Create$();
    var current = ensureNotNull(this._next);
    this._last_0 = current;
    var tmp = this;
    var tmp0_takeIf_0 = current._next_0;
    var tmp_0;
    if (!(tmp0_takeIf_0 === this._$this_1._$this_3._head)) {
      tmp_0 = tmp0_takeIf_0;
    } else {
      {
        tmp_0 = null;
      }
    }
    tmp._next = tmp_0;
    return current;
  };
  EntryIterator.$metadata$ = {
    simpleName: 'EntryIterator',
    kind: 'class',
    interfaces: [MutableIterator]
  };
  function ChainEntry($outer, key, value) {
    this._$this_2 = $outer;
    SimpleEntry.call(this, key, value);
    this._next_0 = null;
    this._prev = null;
  }
  ChainEntry.prototype.setValue_1 = function (newValue) {
    this._$this_2.checkIsMutable_2();
    return SimpleEntry.prototype.setValue_1.call(this, newValue);
  };
  ChainEntry.$metadata$ = {
    simpleName: 'ChainEntry',
    kind: 'class',
    interfaces: []
  };
  function EntrySet_0($outer) {
    this._$this_3 = $outer;
    AbstractEntrySet.call(this);
  }
  EntrySet_0.prototype.add_8 = function (element) {
    throw UnsupportedOperationException_init_$Create$_0('Add is not supported on entries');
  };
  EntrySet_0.prototype.add_11 = function (element) {
    return this.add_8((!(element == null) ? isInterface(element, MutableEntry) : false) ? element : THROW_CCE());
  };
  EntrySet_0.prototype.containsEntry_4 = function (element) {
    return this._$this_3.containsEntry_5(element);
  };
  EntrySet_0.prototype.iterator_19 = function () {
    return new EntryIterator(this);
  };
  EntrySet_0.prototype._get_size__16 = function () {
    return this._$this_3._get_size__16();
  };
  EntrySet_0.$metadata$ = {
    simpleName: 'EntrySet',
    kind: 'class',
    interfaces: []
  };
  function addToEnd(_this_, $this) {
    var tmp0_check_0 = _this_._next_0 == null ? _this_._prev == null : false;
    if (!tmp0_check_0) {
      var message_2_1 = 'Check failed.';
      throw IllegalStateException_init_$Create$(toString_1(message_2_1));
    }var _head = $this._head;
    if (_head == null) {
      $this._head = _this_;
      _this_._next_0 = _this_;
      _this_._prev = _this_;
    } else {
      var tmp1_checkNotNull_0 = _head._prev;
      var tmp$ret$0;
      l$ret$1: do {
        if (tmp1_checkNotNull_0 == null) {
          var message_2_1_0 = 'Required value was null.';
          throw IllegalStateException_init_$Create$(toString_1(message_2_1_0));
        } else {
          tmp$ret$0 = tmp1_checkNotNull_0;
          break l$ret$1;
        }
      }
       while (false);
      var _tail = tmp$ret$0;
      _this_._prev = _tail;
      _this_._next_0 = _head;
      _head._prev = _this_;
      _tail._next_0 = _this_;
    }
  }
  function LinkedHashMap_init_$Init$($this) {
    HashMap_init_$Init$_0($this);
    LinkedHashMap.call($this);
    $this._map = HashMap_init_$Create$();
    return $this;
  }
  function LinkedHashMap_init_$Create$() {
    return LinkedHashMap_init_$Init$(Object.create(LinkedHashMap.prototype));
  }
  LinkedHashMap.prototype.containsKey_3 = function (key) {
    return this._map.containsKey_3(key);
  };
  LinkedHashMap.prototype.createEntrySet_0 = function () {
    return new EntrySet_0(this);
  };
  LinkedHashMap.prototype.get_9 = function (key) {
    var tmp0_safe_receiver = this._map.get_9(key);
    return tmp0_safe_receiver == null ? null : tmp0_safe_receiver._get_value__2();
  };
  LinkedHashMap.prototype.put_4 = function (key, value) {
    this.checkIsMutable_2();
    var old = this._map.get_9(key);
    if (old == null) {
      var newEntry = new ChainEntry(this, key, value);
      this._map.put_4(key, newEntry);
      Unit_getInstance();
      addToEnd(newEntry, this);
      return null;
    } else {
      return old.setValue_1(value);
    }
  };
  LinkedHashMap.prototype._get_size__16 = function () {
    return this._map._get_size__16();
  };
  LinkedHashMap.prototype.checkIsMutable_2 = function () {
    if (this._isReadOnly_0)
      throw UnsupportedOperationException_init_$Create$();
  };
  function LinkedHashMap() {
    this._head = null;
    this._isReadOnly_0 = false;
  }
  LinkedHashMap.$metadata$ = {
    simpleName: 'LinkedHashMap',
    kind: 'class',
    interfaces: [MutableMap]
  };
  function RandomAccess() {
  }
  RandomAccess.$metadata$ = {
    simpleName: 'RandomAccess',
    kind: 'interface',
    interfaces: []
  };
  function _get_js_(_this_) {
    return (_this_ instanceof KClassImpl ? _this_ : THROW_CCE())._get_jClass__2();
  }
  function KCallable() {
  }
  KCallable.$metadata$ = {
    simpleName: 'KCallable',
    kind: 'interface',
    interfaces: []
  };
  function KClass() {
  }
  KClass.$metadata$ = {
    simpleName: 'KClass',
    kind: 'interface',
    interfaces: [KClassifier]
  };
  function KClassImpl(jClass) {
    this._jClass = jClass;
  }
  KClassImpl.prototype._get_jClass__2 = function () {
    return this._jClass;
  };
  KClassImpl.prototype.equals = function (other) {
    var tmp;
    if (other instanceof KClassImpl) {
      tmp = equals(this._get_jClass__2(), other._get_jClass__2());
    } else {
      {
        tmp = false;
      }
    }
    return tmp;
  };
  KClassImpl.prototype.hashCode = function () {
    var tmp0_safe_receiver = this._get_simpleName__2();
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : getStringHashCode(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 0 : tmp1_elvis_lhs;
  };
  KClassImpl.prototype.toString = function () {
    return '' + 'class ' + this._get_simpleName__2();
  };
  KClassImpl.$metadata$ = {
    simpleName: 'KClassImpl',
    kind: 'class',
    interfaces: [KClass]
  };
  function PrimitiveKClassImpl(jClass, givenSimpleName, isInstanceFunction) {
    KClassImpl.call(this, jClass);
    this._givenSimpleName = givenSimpleName;
    this._isInstanceFunction = isInstanceFunction;
  }
  PrimitiveKClassImpl.prototype.equals = function (other) {
    if (!(other instanceof PrimitiveKClassImpl))
      return false;
    else {
    }
    return KClassImpl.prototype.equals.call(this, other) ? this._givenSimpleName === other._givenSimpleName : false;
  };
  PrimitiveKClassImpl.prototype._get_simpleName__2 = function () {
    return this._givenSimpleName;
  };
  PrimitiveKClassImpl.$metadata$ = {
    simpleName: 'PrimitiveKClassImpl',
    kind: 'class',
    interfaces: []
  };
  function NothingKClassImpl() {
    NothingKClassImpl_instance = this;
    KClassImpl.call(this, Object);
    this._simpleName = 'Nothing';
  }
  NothingKClassImpl.prototype._get_simpleName__2 = function () {
    return this._simpleName;
  };
  NothingKClassImpl.prototype._get_jClass__2 = function () {
    throw UnsupportedOperationException_init_$Create$_0("There's no native JS class for Nothing type");
  };
  NothingKClassImpl.prototype.equals = function (other) {
    return other === this;
  };
  NothingKClassImpl.prototype.hashCode = function () {
    return 0;
  };
  NothingKClassImpl.$metadata$ = {
    simpleName: 'NothingKClassImpl',
    kind: 'object',
    interfaces: []
  };
  var NothingKClassImpl_instance;
  function NothingKClassImpl_getInstance() {
    if (NothingKClassImpl_instance == null)
      new NothingKClassImpl();
    return NothingKClassImpl_instance;
  }
  function ErrorKClass() {
  }
  ErrorKClass.prototype.equals = function (other) {
    return other === this;
  };
  ErrorKClass.prototype.hashCode = function () {
    return 0;
  };
  ErrorKClass.$metadata$ = {
    simpleName: 'ErrorKClass',
    kind: 'class',
    interfaces: [KClass]
  };
  function SimpleKClassImpl(jClass) {
    KClassImpl.call(this, jClass);
    var tmp = this;
    var tmp0_safe_receiver = jClass.$metadata$;
    var tmp0_unsafeCast_0 = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.simpleName;
    tmp._simpleName_0 = tmp0_unsafeCast_0;
  }
  SimpleKClassImpl.prototype._get_simpleName__2 = function () {
    return this._simpleName_0;
  };
  SimpleKClassImpl.$metadata$ = {
    simpleName: 'SimpleKClassImpl',
    kind: 'class',
    interfaces: []
  };
  function KProperty1() {
  }
  KProperty1.$metadata$ = {
    simpleName: 'KProperty1',
    kind: 'interface',
    interfaces: [KProperty]
  };
  function KProperty() {
  }
  KProperty.$metadata$ = {
    simpleName: 'KProperty',
    kind: 'interface',
    interfaces: [KCallable]
  };
  var functionClasses;
  function _no_name_provided__2() {
  }
  _no_name_provided__2.prototype.invoke_43 = function (it) {
    return isObject(it);
  };
  _no_name_provided__2.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__2.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__3() {
  }
  _no_name_provided__3.prototype.invoke_43 = function (it) {
    return isNumber(it);
  };
  _no_name_provided__3.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__3.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__4() {
  }
  _no_name_provided__4.prototype.invoke_43 = function (it) {
    return !(it == null) ? typeof it === 'boolean' : false;
  };
  _no_name_provided__4.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__4.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__5() {
  }
  _no_name_provided__5.prototype.invoke_43 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__5.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__5.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__6() {
  }
  _no_name_provided__6.prototype.invoke_43 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__6.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__6.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__7() {
  }
  _no_name_provided__7.prototype.invoke_43 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__7.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__7.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__8() {
  }
  _no_name_provided__8.prototype.invoke_43 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__8.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__8.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__9() {
  }
  _no_name_provided__9.prototype.invoke_43 = function (it) {
    return !(it == null) ? typeof it === 'number' : false;
  };
  _no_name_provided__9.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__9.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__10() {
  }
  _no_name_provided__10.prototype.invoke_43 = function (it) {
    return !(it == null) ? isArray(it) : false;
  };
  _no_name_provided__10.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__10.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__11() {
  }
  _no_name_provided__11.prototype.invoke_43 = function (it) {
    return !(it == null) ? typeof it === 'string' : false;
  };
  _no_name_provided__11.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__11.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__12() {
  }
  _no_name_provided__12.prototype.invoke_43 = function (it) {
    return it instanceof Error;
  };
  _no_name_provided__12.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__12.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__13() {
  }
  _no_name_provided__13.prototype.invoke_43 = function (it) {
    return !(it == null) ? isBooleanArray(it) : false;
  };
  _no_name_provided__13.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__13.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__14() {
  }
  _no_name_provided__14.prototype.invoke_43 = function (it) {
    return !(it == null) ? isCharArray(it) : false;
  };
  _no_name_provided__14.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__14.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__15() {
  }
  _no_name_provided__15.prototype.invoke_43 = function (it) {
    return !(it == null) ? isByteArray(it) : false;
  };
  _no_name_provided__15.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__15.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__16() {
  }
  _no_name_provided__16.prototype.invoke_43 = function (it) {
    return !(it == null) ? isShortArray(it) : false;
  };
  _no_name_provided__16.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__16.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__17() {
  }
  _no_name_provided__17.prototype.invoke_43 = function (it) {
    return !(it == null) ? isIntArray(it) : false;
  };
  _no_name_provided__17.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__17.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__18() {
  }
  _no_name_provided__18.prototype.invoke_43 = function (it) {
    return !(it == null) ? isLongArray(it) : false;
  };
  _no_name_provided__18.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__18.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__19() {
  }
  _no_name_provided__19.prototype.invoke_43 = function (it) {
    return !(it == null) ? isFloatArray(it) : false;
  };
  _no_name_provided__19.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__19.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__20() {
  }
  _no_name_provided__20.prototype.invoke_43 = function (it) {
    return !(it == null) ? isDoubleArray(it) : false;
  };
  _no_name_provided__20.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__20.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__21($arity) {
    this._$arity = $arity;
  }
  _no_name_provided__21.prototype.invoke_43 = function (it) {
    var tmp;
    if (typeof it === 'function') {
      tmp = it.length === this._$arity;
    } else {
      tmp = false;
    }
    return tmp;
  };
  _no_name_provided__21.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__21.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function PrimitiveClasses_0() {
    PrimitiveClasses_instance = this;
    var tmp = this;
    var tmp0_unsafeCast_0 = Object;
    var tmp_0 = tmp0_unsafeCast_0;
    tmp._anyClass = new PrimitiveKClassImpl(tmp_0, 'Any', _no_name_provided_$factory_1());
    var tmp_1 = this;
    var tmp0_unsafeCast_0_0 = Number;
    var tmp_2 = tmp0_unsafeCast_0_0;
    tmp_1._numberClass = new PrimitiveKClassImpl(tmp_2, 'Number', _no_name_provided_$factory_2());
    this._nothingClass = NothingKClassImpl_getInstance();
    var tmp_3 = this;
    var tmp0_unsafeCast_0_1 = Boolean;
    var tmp_4 = tmp0_unsafeCast_0_1;
    tmp_3._booleanClass = new PrimitiveKClassImpl(tmp_4, 'Boolean', _no_name_provided_$factory_3());
    var tmp_5 = this;
    var tmp0_unsafeCast_0_2 = Number;
    var tmp_6 = tmp0_unsafeCast_0_2;
    tmp_5._byteClass = new PrimitiveKClassImpl(tmp_6, 'Byte', _no_name_provided_$factory_4());
    var tmp_7 = this;
    var tmp0_unsafeCast_0_3 = Number;
    var tmp_8 = tmp0_unsafeCast_0_3;
    tmp_7._shortClass = new PrimitiveKClassImpl(tmp_8, 'Short', _no_name_provided_$factory_5());
    var tmp_9 = this;
    var tmp0_unsafeCast_0_4 = Number;
    var tmp_10 = tmp0_unsafeCast_0_4;
    tmp_9._intClass = new PrimitiveKClassImpl(tmp_10, 'Int', _no_name_provided_$factory_6());
    var tmp_11 = this;
    var tmp0_unsafeCast_0_5 = Number;
    var tmp_12 = tmp0_unsafeCast_0_5;
    tmp_11._floatClass = new PrimitiveKClassImpl(tmp_12, 'Float', _no_name_provided_$factory_7());
    var tmp_13 = this;
    var tmp0_unsafeCast_0_6 = Number;
    var tmp_14 = tmp0_unsafeCast_0_6;
    tmp_13._doubleClass = new PrimitiveKClassImpl(tmp_14, 'Double', _no_name_provided_$factory_8());
    var tmp_15 = this;
    var tmp0_unsafeCast_0_7 = Array;
    var tmp_16 = tmp0_unsafeCast_0_7;
    tmp_15._arrayClass = new PrimitiveKClassImpl(tmp_16, 'Array', _no_name_provided_$factory_9());
    var tmp_17 = this;
    var tmp0_unsafeCast_0_8 = String;
    var tmp_18 = tmp0_unsafeCast_0_8;
    tmp_17._stringClass = new PrimitiveKClassImpl(tmp_18, 'String', _no_name_provided_$factory_10());
    var tmp_19 = this;
    var tmp0_unsafeCast_0_9 = Error;
    var tmp_20 = tmp0_unsafeCast_0_9;
    tmp_19._throwableClass = new PrimitiveKClassImpl(tmp_20, 'Throwable', _no_name_provided_$factory_11());
    var tmp_21 = this;
    var tmp0_unsafeCast_0_10 = Array;
    var tmp_22 = tmp0_unsafeCast_0_10;
    tmp_21._booleanArrayClass = new PrimitiveKClassImpl(tmp_22, 'BooleanArray', _no_name_provided_$factory_12());
    var tmp_23 = this;
    var tmp0_unsafeCast_0_11 = Uint16Array;
    var tmp_24 = tmp0_unsafeCast_0_11;
    tmp_23._charArrayClass = new PrimitiveKClassImpl(tmp_24, 'CharArray', _no_name_provided_$factory_13());
    var tmp_25 = this;
    var tmp0_unsafeCast_0_12 = Int8Array;
    var tmp_26 = tmp0_unsafeCast_0_12;
    tmp_25._byteArrayClass = new PrimitiveKClassImpl(tmp_26, 'ByteArray', _no_name_provided_$factory_14());
    var tmp_27 = this;
    var tmp0_unsafeCast_0_13 = Int16Array;
    var tmp_28 = tmp0_unsafeCast_0_13;
    tmp_27._shortArrayClass = new PrimitiveKClassImpl(tmp_28, 'ShortArray', _no_name_provided_$factory_15());
    var tmp_29 = this;
    var tmp0_unsafeCast_0_14 = Int32Array;
    var tmp_30 = tmp0_unsafeCast_0_14;
    tmp_29._intArrayClass = new PrimitiveKClassImpl(tmp_30, 'IntArray', _no_name_provided_$factory_16());
    var tmp_31 = this;
    var tmp0_unsafeCast_0_15 = Array;
    var tmp_32 = tmp0_unsafeCast_0_15;
    tmp_31._longArrayClass = new PrimitiveKClassImpl(tmp_32, 'LongArray', _no_name_provided_$factory_17());
    var tmp_33 = this;
    var tmp0_unsafeCast_0_16 = Float32Array;
    var tmp_34 = tmp0_unsafeCast_0_16;
    tmp_33._floatArrayClass = new PrimitiveKClassImpl(tmp_34, 'FloatArray', _no_name_provided_$factory_18());
    var tmp_35 = this;
    var tmp0_unsafeCast_0_17 = Float64Array;
    var tmp_36 = tmp0_unsafeCast_0_17;
    tmp_35._doubleArrayClass = new PrimitiveKClassImpl(tmp_36, 'DoubleArray', _no_name_provided_$factory_19());
  }
  PrimitiveClasses_0.prototype._get_anyClass_ = function () {
    return this._anyClass;
  };
  PrimitiveClasses_0.prototype._get_numberClass_ = function () {
    return this._numberClass;
  };
  PrimitiveClasses_0.prototype._get_nothingClass_ = function () {
    return this._nothingClass;
  };
  PrimitiveClasses_0.prototype._get_booleanClass_ = function () {
    return this._booleanClass;
  };
  PrimitiveClasses_0.prototype._get_byteClass_ = function () {
    return this._byteClass;
  };
  PrimitiveClasses_0.prototype._get_shortClass_ = function () {
    return this._shortClass;
  };
  PrimitiveClasses_0.prototype._get_intClass_ = function () {
    return this._intClass;
  };
  PrimitiveClasses_0.prototype._get_floatClass_ = function () {
    return this._floatClass;
  };
  PrimitiveClasses_0.prototype._get_doubleClass_ = function () {
    return this._doubleClass;
  };
  PrimitiveClasses_0.prototype._get_arrayClass_ = function () {
    return this._arrayClass;
  };
  PrimitiveClasses_0.prototype._get_stringClass_ = function () {
    return this._stringClass;
  };
  PrimitiveClasses_0.prototype._get_throwableClass_ = function () {
    return this._throwableClass;
  };
  PrimitiveClasses_0.prototype._get_booleanArrayClass_ = function () {
    return this._booleanArrayClass;
  };
  PrimitiveClasses_0.prototype._get_charArrayClass_ = function () {
    return this._charArrayClass;
  };
  PrimitiveClasses_0.prototype._get_byteArrayClass_ = function () {
    return this._byteArrayClass;
  };
  PrimitiveClasses_0.prototype._get_shortArrayClass_ = function () {
    return this._shortArrayClass;
  };
  PrimitiveClasses_0.prototype._get_intArrayClass_ = function () {
    return this._intArrayClass;
  };
  PrimitiveClasses_0.prototype._get_longArrayClass_ = function () {
    return this._longArrayClass;
  };
  PrimitiveClasses_0.prototype._get_floatArrayClass_ = function () {
    return this._floatArrayClass;
  };
  PrimitiveClasses_0.prototype._get_doubleArrayClass_ = function () {
    return this._doubleArrayClass;
  };
  PrimitiveClasses_0.prototype.functionClass = function (arity) {
    var tmp0_elvis_lhs = functionClasses[arity];
    var tmp;
    if (tmp0_elvis_lhs == null) {
      var tmp0_unsafeCast_0_3 = Function;
      var tmp_0 = tmp0_unsafeCast_0_3;
      var tmp_1 = '' + 'Function' + arity;
      var result_2 = new PrimitiveKClassImpl(tmp_0, tmp_1, _no_name_provided_$factory_20(arity));
      var tmp1_asDynamic_0_5 = functionClasses;
      tmp1_asDynamic_0_5[arity] = result_2;
      tmp = result_2;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  };
  PrimitiveClasses_0.$metadata$ = {
    simpleName: 'PrimitiveClasses',
    kind: 'object',
    interfaces: []
  };
  Object.defineProperty(PrimitiveClasses_0.prototype, 'anyClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_anyClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'numberClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_numberClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'nothingClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_nothingClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'booleanClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_booleanClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'byteClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_byteClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'shortClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_shortClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'intClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_intClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'floatClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_floatClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'doubleClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_doubleClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'arrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_arrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'stringClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_stringClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'throwableClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_throwableClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'booleanArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_booleanArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'charArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_charArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'byteArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_byteArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'shortArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_shortArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'intArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_intArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'longArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_longArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'floatArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_floatArrayClass_
  });
  Object.defineProperty(PrimitiveClasses_0.prototype, 'doubleArrayClass', {
    configurable: true,
    get: PrimitiveClasses_0.prototype._get_doubleArrayClass_
  });
  var PrimitiveClasses_instance;
  function PrimitiveClasses_getInstance() {
    if (PrimitiveClasses_instance == null)
      new PrimitiveClasses_0();
    return PrimitiveClasses_instance;
  }
  function _no_name_provided_$factory_1() {
    var i = new _no_name_provided__2();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_2() {
    var i = new _no_name_provided__3();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_3() {
    var i = new _no_name_provided__4();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_4() {
    var i = new _no_name_provided__5();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_5() {
    var i = new _no_name_provided__6();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_6() {
    var i = new _no_name_provided__7();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_7() {
    var i = new _no_name_provided__8();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_8() {
    var i = new _no_name_provided__9();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_9() {
    var i = new _no_name_provided__10();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_10() {
    var i = new _no_name_provided__11();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_11() {
    var i = new _no_name_provided__12();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_12() {
    var i = new _no_name_provided__13();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_13() {
    var i = new _no_name_provided__14();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_14() {
    var i = new _no_name_provided__15();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_15() {
    var i = new _no_name_provided__16();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_16() {
    var i = new _no_name_provided__17();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_17() {
    var i = new _no_name_provided__18();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_18() {
    var i = new _no_name_provided__19();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_19() {
    var i = new _no_name_provided__20();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function _no_name_provided_$factory_20($arity) {
    var i = new _no_name_provided__21($arity);
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function functionClasses$init$() {
    var tmp0_arrayOfNulls_0 = 0;
    return fillArrayVal(Array(tmp0_arrayOfNulls_0), null);
  }
  function getKClass_0(jClass) {
    var tmp;
    if (Array.isArray(jClass)) {
      tmp = getKClassM_0(jClass);
    } else {
      tmp = getKClass1_0(jClass);
    }
    return tmp;
  }
  function getKClassM_0(jClasses) {
    var tmp0_subject = jClasses.length;
    var tmp;
    switch (tmp0_subject) {
      case 1:
        tmp = getKClass1_0(jClasses[0]);
        break;
      case 0:
        var tmp0_unsafeCast_0 = NothingKClassImpl_getInstance();
        tmp = tmp0_unsafeCast_0;
        break;
      default:var tmp1_unsafeCast_0 = new ErrorKClass();
        tmp = tmp1_unsafeCast_0;
        break;
    }
    return tmp;
  }
  function getKClass1_0(jClass) {
    if (jClass === String) {
      var tmp0_unsafeCast_0 = PrimitiveClasses_getInstance()._stringClass;
      return tmp0_unsafeCast_0;
    }var metadata = jClass.$metadata$;
    var tmp;
    if (metadata != null) {
      var tmp_0;
      if (metadata.$kClass$ == null) {
        var kClass = new SimpleKClassImpl(jClass);
        metadata.$kClass$ = kClass;
        tmp_0 = kClass;
      } else {
        tmp_0 = metadata.$kClass$;
      }
      tmp = tmp_0;
    } else {
      tmp = new SimpleKClassImpl(jClass);
    }
    return tmp;
  }
  function Appendable() {
  }
  Appendable.$metadata$ = {
    simpleName: 'Appendable',
    kind: 'interface',
    interfaces: []
  };
  function StringBuilder_init_$Init$($this) {
    StringBuilder.call($this, '');
    return $this;
  }
  function StringBuilder_init_$Create$() {
    return StringBuilder_init_$Init$(Object.create(StringBuilder.prototype));
  }
  function StringBuilder(content) {
    this._string = !(content === undefined) ? content : '';
  }
  StringBuilder.prototype._get_length__0 = function () {
    var tmp0_asDynamic_0 = this._string;
    return tmp0_asDynamic_0.length;
  };
  StringBuilder.prototype.get_19 = function (index) {
    var tmp0_getOrElse_0 = this._string;
    var tmp;
    if (index >= 0 ? index <= _get_lastIndex__1(tmp0_getOrElse_0) : false) {
      tmp = charSequenceGet(tmp0_getOrElse_0, index);
    } else {
      throw IndexOutOfBoundsException_init_$Create$('' + 'index: ' + index + ', length: ' + this._get_length__0() + '}');
    }
    return tmp;
  };
  StringBuilder.prototype.append_1 = function (value) {
    var tmp0_this = this;
    tmp0_this._string = tmp0_this._string + value;
    return this;
  };
  StringBuilder.prototype.append_2 = function (value) {
    var tmp0_this = this;
    tmp0_this._string = tmp0_this._string + toString_0(value);
    return this;
  };
  StringBuilder.prototype.toString = function () {
    return this._string;
  };
  StringBuilder.$metadata$ = {
    simpleName: 'StringBuilder',
    kind: 'class',
    interfaces: [Appendable, CharSequence]
  };
  function checkRadix(radix) {
    if (!(2 <= radix ? radix <= 36 : false)) {
      throw IllegalArgumentException_init_$Create$('' + 'radix ' + radix + ' was not in valid range 2..36');
    }return radix;
  }
  function toInt(_this_) {
    var tmp0_elvis_lhs = toIntOrNull(_this_);
    var tmp;
    if (tmp0_elvis_lhs == null) {
      numberFormatError(_this_);
    } else {
      tmp = tmp0_elvis_lhs;
    }
    return tmp;
  }
  function digitOf(char, radix) {
    var tmp0_let_0 = (char.compareTo(new Char(48)) >= 0 ? char.compareTo(new Char(57)) <= 0 : false) ? char.minus(new Char(48)) : (char.compareTo(new Char(65)) >= 0 ? char.compareTo(new Char(90)) <= 0 : false) ? char.minus(new Char(65)) + 10 | 0 : (char.compareTo(new Char(97)) >= 0 ? char.compareTo(new Char(122)) <= 0 : false) ? char.minus(new Char(97)) + 10 | 0 : -1;
    return tmp0_let_0 >= radix ? -1 : tmp0_let_0;
  }
  function Companion_3() {
    Companion_instance_2 = this;
    this._MIN_VALUE_0 = new Char(0);
    this._MAX_VALUE_0 = new Char(65535);
    this._MIN_HIGH_SURROGATE = new Char(55296);
    this._MAX_HIGH_SURROGATE = new Char(56319);
    this._MIN_LOW_SURROGATE = new Char(56320);
    this._MAX_LOW_SURROGATE = new Char(57343);
    this._MIN_SURROGATE = new Char(55296);
    this._MAX_SURROGATE = new Char(57343);
    this._SIZE_BYTES_0 = 2;
    this._SIZE_BITS_0 = 16;
  }
  Companion_3.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_2;
  function Companion_getInstance_2() {
    if (Companion_instance_2 == null)
      new Companion_3();
    return Companion_instance_2;
  }
  function Char(code) {
    Companion_getInstance_2();
    var tmp = this;
    tmp._value = _UShort___get_data__impl_(code) & 65535;
  }
  Char.prototype.compareTo = function (other) {
    return this._value - other._value | 0;
  };
  Char.prototype.minus = function (other) {
    return this._value - other._value | 0;
  };
  Char.prototype.toInt_5 = function () {
    return this._value;
  };
  Char.prototype.equals = function (other) {
    if (other === this)
      return true;
    if (!(other instanceof Char))
      return false;
    else {
    }
    return this._value === other._value;
  };
  Char.prototype.hashCode = function () {
    return this._value;
  };
  Char.prototype.toString = function () {
    var tmp0_unsafeCast_0 = String.fromCharCode(this._value);
    return tmp0_unsafeCast_0;
  };
  Char.$metadata$ = {
    simpleName: 'Char',
    kind: 'class',
    interfaces: [Comparable]
  };
  function Iterable() {
  }
  Iterable.$metadata$ = {
    simpleName: 'Iterable',
    kind: 'interface',
    interfaces: []
  };
  function MutableList() {
  }
  MutableList.$metadata$ = {
    simpleName: 'MutableList',
    kind: 'interface',
    interfaces: [List, MutableCollection]
  };
  function List() {
  }
  List.$metadata$ = {
    simpleName: 'List',
    kind: 'interface',
    interfaces: [Collection]
  };
  function Entry() {
  }
  Entry.$metadata$ = {
    simpleName: 'Entry',
    kind: 'interface',
    interfaces: []
  };
  function Map_0() {
  }
  Map_0.$metadata$ = {
    simpleName: 'Map',
    kind: 'interface',
    interfaces: []
  };
  function MutableEntry() {
  }
  MutableEntry.$metadata$ = {
    simpleName: 'MutableEntry',
    kind: 'interface',
    interfaces: [Entry]
  };
  function MutableMap() {
  }
  MutableMap.$metadata$ = {
    simpleName: 'MutableMap',
    kind: 'interface',
    interfaces: [Map_0]
  };
  function MutableCollection() {
  }
  MutableCollection.$metadata$ = {
    simpleName: 'MutableCollection',
    kind: 'interface',
    interfaces: [Collection, MutableIterable]
  };
  function Collection() {
  }
  Collection.$metadata$ = {
    simpleName: 'Collection',
    kind: 'interface',
    interfaces: [Iterable]
  };
  function Set() {
  }
  Set.$metadata$ = {
    simpleName: 'Set',
    kind: 'interface',
    interfaces: [Collection]
  };
  function MutableSet() {
  }
  MutableSet.$metadata$ = {
    simpleName: 'MutableSet',
    kind: 'interface',
    interfaces: [Set, MutableCollection]
  };
  function MutableIterable() {
  }
  MutableIterable.$metadata$ = {
    simpleName: 'MutableIterable',
    kind: 'interface',
    interfaces: [Iterable]
  };
  function toString_0(_this_) {
    var tmp0_safe_receiver = _this_;
    var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : toString_1(tmp0_safe_receiver);
    return tmp1_elvis_lhs == null ? 'null' : tmp1_elvis_lhs;
  }
  function fillArrayVal(array, initValue) {
    var inductionVariable = 0;
    var last = array.length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        array[i] = initValue;
      }
       while (!(i === last));
    return array;
  }
  var buf;
  var bufFloat64;
  var bufInt32;
  var lowIndex;
  var highIndex;
  function getNumberHashCode(obj) {
    var tmp0_unsafeCast_0 = jsBitwiseOr(obj, 0);
    if (tmp0_unsafeCast_0 === obj) {
      return numberToInt(obj);
    } else {
    }
    bufFloat64[0] = obj;
    return imul(bufInt32[highIndex], 31) + bufInt32[lowIndex] | 0;
  }
  function bufFloat64$init$() {
    var tmp0_unsafeCast_0 = new Float64Array(buf);
    return tmp0_unsafeCast_0;
  }
  function bufInt32$init$() {
    var tmp0_unsafeCast_0 = new Int32Array(buf);
    return tmp0_unsafeCast_0;
  }
  function lowIndex$init$() {
    bufFloat64[0] = -1.0;
    return !(bufInt32[0] === 0) ? 1 : 0;
  }
  function charSequenceGet(a, index) {
    var tmp;
    if (isString(a)) {
      var tmp0_unsafeCast_0 = a.charCodeAt(index);
      var tmp1_Char_0 = tmp0_unsafeCast_0;
      var tmp_0;
      Companion_getInstance_2();
      var tmp0__get_code__0_1 = new Char(0);
      if (tmp1_Char_0 < tmp0__get_code__0_1.toInt_5()) {
        tmp_0 = true;
      } else {
        {
          Companion_getInstance_2();
          var tmp1__get_code__0_2 = new Char(65535);
          tmp_0 = tmp1_Char_0 > tmp1__get_code__0_2.toInt_5();
        }
      }
      if (tmp_0) {
        throw IllegalArgumentException_init_$Create$('' + 'Invalid Char code: ' + tmp1_Char_0);
      } else {
      }
      tmp = new Char(_UShort___init__impl_(toShort(tmp1_Char_0)));
    } else {
      tmp = a.get_19(index);
    }
    return tmp;
  }
  function isString(a) {
    return typeof a === 'string';
  }
  function charSequenceLength(a) {
    var tmp;
    if (isString(a)) {
      var tmp0_unsafeCast_0 = a.length;
      tmp = tmp0_unsafeCast_0;
    } else {
      tmp = a._get_length__0();
    }
    return tmp;
  }
  function arrayToString(array) {
    return joinToString$default(array, ', ', '[', ']', 0, null, _no_name_provided_$factory_21(), 24, null);
  }
  function _no_name_provided__22() {
  }
  _no_name_provided__22.prototype.invoke_43 = function (it) {
    return toString_1(it);
  };
  _no_name_provided__22.prototype.invoke_72 = function (p1) {
    return this.invoke_43((p1 == null ? true : isObject(p1)) ? p1 : THROW_CCE());
  };
  _no_name_provided__22.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided_$factory_21() {
    var i = new _no_name_provided__22();
    return function (p1) {
      return i.invoke_43(p1);
    };
  }
  function getObjectHashCode(obj) {
    if (!jsIn('kotlinHashCodeValue$', obj)) {
      var hash = jsBitwiseOr(Math.random() * 4.294967296E9, 0);
      var descriptor = new Object();
      descriptor.value = hash;
      descriptor.enumerable = false;
      Object.defineProperty(obj, 'kotlinHashCodeValue$', descriptor);
    }var tmp0_unsafeCast_0 = obj['kotlinHashCodeValue$'];
    return tmp0_unsafeCast_0;
  }
  function toString_1(o) {
    var tmp;
    if (o == null) {
      tmp = 'null';
    } else if (isArrayish(o)) {
      tmp = '[...]';
    } else {
      var tmp0_unsafeCast_0 = o.toString();
      tmp = tmp0_unsafeCast_0;
    }
    return tmp;
  }
  function hashCode(obj) {
    if (obj == null)
      return 0;
    var tmp0_subject = typeof obj;
    var tmp;
    switch (tmp0_subject) {
      case 'object':
        tmp = 'function' === typeof obj.hashCode ? obj.hashCode() : getObjectHashCode(obj);
        break;
      case 'function':
        tmp = getObjectHashCode(obj);
        break;
      case 'number':
        tmp = getNumberHashCode(obj);
        break;
      case 'boolean':
        var tmp_0;
        if (obj) {
          tmp_0 = 1;
        } else {
          {
            tmp_0 = 0;
          }
        }

        tmp = tmp_0;
        break;
      default:tmp = getStringHashCode(hashCode$outlinedJsCode$(obj));
        break;
    }
    return tmp;
  }
  function getStringHashCode(str) {
    var hash = 0;
    var length = str.length;
    var inductionVariable = 0;
    var last = length - 1 | 0;
    if (inductionVariable <= last)
      do {
        var i = inductionVariable;
        inductionVariable = inductionVariable + 1 | 0;
        var code = str.charCodeAt(i);
        hash = imul(hash, 31) + code | 0;
      }
       while (!(i === last));
    return hash;
  }
  function equals(obj1, obj2) {
    if (obj1 == null) {
      return obj2 == null;
    }if (obj2 == null) {
      return false;
    }if (typeof obj1 === 'object' ? typeof obj1.equals === 'function' : false) {
      return obj1.equals(obj2);
    }if (obj1 !== obj1) {
      return obj2 !== obj2;
    }if (typeof obj1 === 'number' ? typeof obj2 === 'number' : false) {
      var tmp;
      if (obj1 === obj2) {
        var tmp_0;
        if (obj1 !== 0) {
          tmp_0 = true;
        } else {
          var tmp0_asDynamic_0 = 1;
          var tmp_1 = tmp0_asDynamic_0 / obj1;
          var tmp1_asDynamic_0 = 1;
          tmp_0 = tmp_1 === tmp1_asDynamic_0 / obj2;
        }
        tmp = tmp_0;
      } else {
        tmp = false;
      }
      return tmp;
    }return obj1 === obj2;
  }
  function captureStack(instance, constructorFunction) {
    if (Error.captureStackTrace != null) {
      Error.captureStackTrace(instance, constructorFunction);
    } else {
      instance.stack = (new Error()).stack;
    }
  }
  function extendThrowable(this_, message, cause) {
    Error.call(this_);
    setPropertiesToThrowableInstance(this_, message, cause);
  }
  function setPropertiesToThrowableInstance(this_, message, cause) {
    if (!hasOwnPrototypeProperty(this_, 'message')) {
      var tmp;
      if (message == null) {
        var tmp_0;
        if (!(message === null)) {
          var tmp0_safe_receiver = cause;
          var tmp1_elvis_lhs = tmp0_safe_receiver == null ? null : tmp0_safe_receiver.toString();
          tmp_0 = tmp1_elvis_lhs == null ? undefined : tmp1_elvis_lhs;
        } else {
          tmp_0 = undefined;
        }
        tmp = tmp_0;
      } else {
        tmp = message;
      }
      this_.message = tmp;
    }if (!hasOwnPrototypeProperty(this_, 'cause')) {
      this_.cause = cause;
    }this_.name = Object.getPrototypeOf(this_).constructor.name;
  }
  function hasOwnPrototypeProperty(o, name) {
    var tmp0_unsafeCast_0 = Object.getPrototypeOf(o).hasOwnProperty(name);
    return tmp0_unsafeCast_0;
  }
  function hashCode$outlinedJsCode$(obj) {
    return String(obj);
  }
  function ensureNotNull(v) {
    var tmp;
    if (v == null) {
      THROW_NPE();
    } else {
      tmp = v;
    }
    return tmp;
  }
  function THROW_NPE() {
    throw NullPointerException_init_$Create$();
  }
  function THROW_CCE() {
    throw ClassCastException_init_$Create$();
  }
  function Long() {
  }
  Long.prototype.toInt_5 = function () {
    return this._low;
  };
  Long.$metadata$ = {
    simpleName: 'Long',
    kind: 'class',
    interfaces: [Comparable]
  };
  function imul(a_local, b_local) {
    var lhs = jsBitwiseAnd(a_local, 4.29490176E9) * jsBitwiseAnd(b_local, 65535);
    var rhs = jsBitwiseAnd(a_local, 65535) * b_local;
    return jsBitwiseOr(lhs + rhs, 0);
  }
  function numberToInt(a) {
    var tmp;
    if (a instanceof Long) {
      tmp = a.toInt_5();
    } else {
      {
        tmp = doubleToInt(a);
      }
    }
    return tmp;
  }
  function doubleToInt(a) {
    return a > 2.147483647E9 ? 2147483647 : a < -2.147483648E9 ? -2147483648 : jsBitwiseOr(a, 0);
  }
  function toShort(a) {
    var tmp0_unsafeCast_0 = toShort$outlinedJsCode$_1(a);
    return tmp0_unsafeCast_0;
  }
  function toShort$outlinedJsCode$_1(a) {
    return a << 16 >> 16;
  }
  var propertyRefClassMetadataCache;
  function getPropertyCallableRef(name, paramCount, type, getter, setter) {
    getter.get = getter;
    getter.set = setter;
    getter.callableName = name;
    var tmp0_unsafeCast_0 = getPropertyRefClass(getter, getKPropMetadata(paramCount, setter, type));
    return tmp0_unsafeCast_0;
  }
  function getPropertyRefClass(obj, metadata) {
    obj.$metadata$ = metadata;
    obj.constructor = obj;
    return obj;
  }
  function getKPropMetadata(paramCount, setter, type) {
    var mdata = propertyRefClassMetadataCache[paramCount][setter == null ? 0 : 1];
    if (mdata.interfaces.length == 0) {
      mdata.interfaces.push(type);
    }return mdata;
  }
  function propertyRefClassMetadataCache$init$() {
    var tmp = {kind: 'class', interfaces: []};
    var tmp0_arrayOf_0 = [tmp, {kind: 'class', interfaces: []}];
    var tmp_0 = tmp0_arrayOf_0;
    var tmp_1 = {kind: 'class', interfaces: []};
    var tmp1_arrayOf_0 = [tmp_1, {kind: 'class', interfaces: []}];
    var tmp_2 = tmp1_arrayOf_0;
    var tmp_3 = {kind: 'class', interfaces: []};
    var tmp2_arrayOf_0 = [tmp_3, {kind: 'class', interfaces: []}];
    var tmp3_arrayOf_0 = [tmp_0, tmp_2, tmp2_arrayOf_0];
    return tmp3_arrayOf_0;
  }
  function isArrayish(o) {
    var tmp;
    if (isJsArray(o)) {
      tmp = true;
    } else {
      var tmp0_unsafeCast_0 = ArrayBuffer.isView(o);
      tmp = tmp0_unsafeCast_0;
    }
    return tmp;
  }
  function isJsArray(obj) {
    var tmp0_unsafeCast_0 = Array.isArray(obj);
    return tmp0_unsafeCast_0;
  }
  function isInterface(obj, iface) {
    var tmp0_elvis_lhs = obj.constructor;
    var tmp;
    if (tmp0_elvis_lhs == null) {
      return false;
    } else {
      tmp = tmp0_elvis_lhs;
    }
    var ctor = tmp;
    return isInterfaceImpl(ctor, iface);
  }
  function isInterfaceImpl(ctor, iface) {
    if (ctor === iface)
      return true;
    var metadata = ctor.$metadata$;
    if (!(metadata == null)) {
      var interfaces = metadata.interfaces;
      var indexedObject = interfaces;
      var inductionVariable = 0;
      var last = indexedObject.length;
      while (inductionVariable < last) {
        var i = indexedObject[inductionVariable];
        inductionVariable = inductionVariable + 1 | 0;
        if (isInterfaceImpl(i, iface)) {
          return true;
        }}
    }var superPrototype = !(ctor.prototype == null) ? Object.getPrototypeOf(ctor.prototype) : null;
    var superConstructor = superPrototype != null ? superPrototype.constructor : null;
    return !(superConstructor == null) ? isInterfaceImpl(superConstructor, iface) : false;
  }
  function isArray(obj) {
    var tmp;
    if (isJsArray(obj)) {
      tmp = !obj.$type$;
    } else {
      tmp = false;
    }
    return tmp;
  }
  function isObject(obj) {
    var objTypeOf = typeof obj;
    var tmp0_subject = objTypeOf;
    switch (tmp0_subject) {
      case 'string':
        return true;
      case 'number':
        return true;
      case 'boolean':
        return true;
      case 'function':
        return true;
      default:return jsInstanceOf(obj, Object);
    }
  }
  function isNumber(a) {
    var tmp;
    if (typeof a === 'number') {
      tmp = true;
    } else {
      tmp = a instanceof Long;
    }
    return tmp;
  }
  function isCharSequence(value) {
    return typeof value === 'string' ? true : isInterface(value, _get_js_(getKClass_0(CharSequence)));
  }
  function isBooleanArray(a) {
    return isJsArray(a) ? a.$type$ === 'BooleanArray' : false;
  }
  function isByteArray(a) {
    return jsInstanceOf(a, Int8Array);
  }
  function isShortArray(a) {
    return jsInstanceOf(a, Int16Array);
  }
  function isCharArray(a) {
    return isJsArray(a) ? a.$type$ === 'CharArray' : false;
  }
  function isIntArray(a) {
    return jsInstanceOf(a, Int32Array);
  }
  function isFloatArray(a) {
    return jsInstanceOf(a, Float32Array);
  }
  function isLongArray(a) {
    return isJsArray(a) ? a.$type$ === 'LongArray' : false;
  }
  function isDoubleArray(a) {
    return jsInstanceOf(a, Float64Array);
  }
  function Exception_init_$Init$($this) {
    extendThrowable($this, void 1, void 1);
    Exception.call($this);
    return $this;
  }
  function Exception_init_$Init$_0(message, $this) {
    extendThrowable($this, message, void 1);
    Exception.call($this);
    return $this;
  }
  function Exception() {
    captureStack(this, Exception);
  }
  Exception.$metadata$ = {
    simpleName: 'Exception',
    kind: 'class',
    interfaces: []
  };
  function IllegalArgumentException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IllegalArgumentException.call($this);
    return $this;
  }
  function IllegalArgumentException_init_$Create$(message) {
    var tmp = IllegalArgumentException_init_$Init$(message, Object.create(IllegalArgumentException.prototype));
    captureStack(tmp, IllegalArgumentException_init_$Create$);
    return tmp;
  }
  function IllegalArgumentException() {
    captureStack(this, IllegalArgumentException);
  }
  IllegalArgumentException.$metadata$ = {
    simpleName: 'IllegalArgumentException',
    kind: 'class',
    interfaces: []
  };
  function RuntimeException_init_$Init$($this) {
    Exception_init_$Init$($this);
    RuntimeException.call($this);
    return $this;
  }
  function RuntimeException_init_$Init$_0(message, $this) {
    Exception_init_$Init$_0(message, $this);
    RuntimeException.call($this);
    return $this;
  }
  function RuntimeException() {
    captureStack(this, RuntimeException);
  }
  RuntimeException.$metadata$ = {
    simpleName: 'RuntimeException',
    kind: 'class',
    interfaces: []
  };
  function NoSuchElementException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NoSuchElementException.call($this);
    return $this;
  }
  function NoSuchElementException_init_$Create$() {
    var tmp = NoSuchElementException_init_$Init$(Object.create(NoSuchElementException.prototype));
    captureStack(tmp, NoSuchElementException_init_$Create$);
    return tmp;
  }
  function NoSuchElementException() {
    captureStack(this, NoSuchElementException);
  }
  NoSuchElementException.$metadata$ = {
    simpleName: 'NoSuchElementException',
    kind: 'class',
    interfaces: []
  };
  function IllegalStateException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IllegalStateException.call($this);
    return $this;
  }
  function IllegalStateException_init_$Create$(message) {
    var tmp = IllegalStateException_init_$Init$(message, Object.create(IllegalStateException.prototype));
    captureStack(tmp, IllegalStateException_init_$Create$);
    return tmp;
  }
  function IllegalStateException() {
    captureStack(this, IllegalStateException);
  }
  IllegalStateException.$metadata$ = {
    simpleName: 'IllegalStateException',
    kind: 'class',
    interfaces: []
  };
  function UnsupportedOperationException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    UnsupportedOperationException.call($this);
    return $this;
  }
  function UnsupportedOperationException_init_$Create$() {
    var tmp = UnsupportedOperationException_init_$Init$(Object.create(UnsupportedOperationException.prototype));
    captureStack(tmp, UnsupportedOperationException_init_$Create$);
    return tmp;
  }
  function UnsupportedOperationException_init_$Init$_0(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    UnsupportedOperationException.call($this);
    return $this;
  }
  function UnsupportedOperationException_init_$Create$_0(message) {
    var tmp = UnsupportedOperationException_init_$Init$_0(message, Object.create(UnsupportedOperationException.prototype));
    captureStack(tmp, UnsupportedOperationException_init_$Create$_0);
    return tmp;
  }
  function UnsupportedOperationException() {
    captureStack(this, UnsupportedOperationException);
  }
  UnsupportedOperationException.$metadata$ = {
    simpleName: 'UnsupportedOperationException',
    kind: 'class',
    interfaces: []
  };
  function NumberFormatException_init_$Init$(message, $this) {
    IllegalArgumentException_init_$Init$(message, $this);
    NumberFormatException.call($this);
    return $this;
  }
  function NumberFormatException_init_$Create$(message) {
    var tmp = NumberFormatException_init_$Init$(message, Object.create(NumberFormatException.prototype));
    captureStack(tmp, NumberFormatException_init_$Create$);
    return tmp;
  }
  function NumberFormatException() {
    captureStack(this, NumberFormatException);
  }
  NumberFormatException.$metadata$ = {
    simpleName: 'NumberFormatException',
    kind: 'class',
    interfaces: []
  };
  function IndexOutOfBoundsException_init_$Init$(message, $this) {
    RuntimeException_init_$Init$_0(message, $this);
    IndexOutOfBoundsException.call($this);
    return $this;
  }
  function IndexOutOfBoundsException_init_$Create$(message) {
    var tmp = IndexOutOfBoundsException_init_$Init$(message, Object.create(IndexOutOfBoundsException.prototype));
    captureStack(tmp, IndexOutOfBoundsException_init_$Create$);
    return tmp;
  }
  function IndexOutOfBoundsException() {
    captureStack(this, IndexOutOfBoundsException);
  }
  IndexOutOfBoundsException.$metadata$ = {
    simpleName: 'IndexOutOfBoundsException',
    kind: 'class',
    interfaces: []
  };
  function NullPointerException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    NullPointerException.call($this);
    return $this;
  }
  function NullPointerException_init_$Create$() {
    var tmp = NullPointerException_init_$Init$(Object.create(NullPointerException.prototype));
    captureStack(tmp, NullPointerException_init_$Create$);
    return tmp;
  }
  function NullPointerException() {
    captureStack(this, NullPointerException);
  }
  NullPointerException.$metadata$ = {
    simpleName: 'NullPointerException',
    kind: 'class',
    interfaces: []
  };
  function ClassCastException_init_$Init$($this) {
    RuntimeException_init_$Init$($this);
    ClassCastException.call($this);
    return $this;
  }
  function ClassCastException_init_$Create$() {
    var tmp = ClassCastException_init_$Init$(Object.create(ClassCastException.prototype));
    captureStack(tmp, ClassCastException_init_$Create$);
    return tmp;
  }
  function ClassCastException() {
    captureStack(this, ClassCastException);
  }
  ClassCastException.$metadata$ = {
    simpleName: 'ClassCastException',
    kind: 'class',
    interfaces: []
  };
  function jsIn(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = jsIn$outlinedJsCode$(lhs_hack, rhs_hack);
    return tmp0_unsafeCast_0;
  }
  function jsBitwiseOr(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = jsBitwiseOr$outlinedJsCode$_0(lhs_hack, rhs_hack);
    return tmp0_unsafeCast_0;
  }
  function jsDeleteProperty(obj_hack, property_hack) {
    jsDeleteProperty$outlinedJsCode$_1(obj_hack, property_hack);
  }
  function jsTypeOf(value_hack) {
    var tmp0_unsafeCast_0 = jsTypeOf$outlinedJsCode$_2(value_hack);
    return tmp0_unsafeCast_0;
  }
  function jsInstanceOf(obj_hack, jsClass_hack) {
    var tmp0_unsafeCast_0 = jsInstanceOf$outlinedJsCode$_3(obj_hack, jsClass_hack);
    return tmp0_unsafeCast_0;
  }
  function jsBitwiseAnd(lhs_hack, rhs_hack) {
    var tmp0_unsafeCast_0 = jsBitwiseAnd$outlinedJsCode$_4(lhs_hack, rhs_hack);
    return tmp0_unsafeCast_0;
  }
  function jsIn$outlinedJsCode$(lhs_hack, rhs_hack) {
    return lhs_hack in rhs_hack;
  }
  function jsBitwiseOr$outlinedJsCode$_0(lhs_hack, rhs_hack) {
    return lhs_hack | rhs_hack;
  }
  function jsDeleteProperty$outlinedJsCode$_1(obj_hack, property_hack) {
    return delete obj_hack[property_hack];
  }
  function jsTypeOf$outlinedJsCode$_2(value_hack) {
    return typeof value_hack;
  }
  function jsInstanceOf$outlinedJsCode$_3(obj_hack, jsClass_hack) {
    return obj_hack instanceof jsClass_hack;
  }
  function jsBitwiseAnd$outlinedJsCode$_4(lhs_hack, rhs_hack) {
    return lhs_hack & rhs_hack;
  }
  function ClippingsSearchParams(query, fuzzy, qMeta, qOcr, requireAllKeywords, formats, collection, categoryId, subjectId, title, keyword, endDate, startDate, orderBy, includeCollected, page, resultMode) {
    this._query = query;
    this._fuzzy = fuzzy;
    this._qMeta = qMeta;
    this._qOcr = qOcr;
    this._requireAllKeywords = requireAllKeywords;
    this._formats = formats;
    this._collection = collection;
    this._categoryId = categoryId;
    this._subjectId = subjectId;
    this._title = title;
    this._keyword = keyword;
    this._endDate = endDate;
    this._startDate = startDate;
    this._orderBy = orderBy;
    this._includeCollected = includeCollected;
    this._page = page;
    this._resultMode = resultMode;
  }
  ClippingsSearchParams.prototype._get_query__0 = function () {
    return this._query;
  };
  ClippingsSearchParams.prototype._get_fuzzy__0 = function () {
    return this._fuzzy;
  };
  ClippingsSearchParams.prototype._get_qMeta__0 = function () {
    return this._qMeta;
  };
  ClippingsSearchParams.prototype._get_qOcr__0 = function () {
    return this._qOcr;
  };
  ClippingsSearchParams.prototype._get_requireAllKeywords__0 = function () {
    return this._requireAllKeywords;
  };
  ClippingsSearchParams.prototype._get_formats__0 = function () {
    return this._formats;
  };
  ClippingsSearchParams.prototype._get_collection__0 = function () {
    return this._collection;
  };
  ClippingsSearchParams.prototype._get_categoryId_ = function () {
    return this._categoryId;
  };
  ClippingsSearchParams.prototype._get_subjectId_ = function () {
    return this._subjectId;
  };
  ClippingsSearchParams.prototype._get_title__0 = function () {
    return this._title;
  };
  ClippingsSearchParams.prototype._get_keyword_ = function () {
    return this._keyword;
  };
  ClippingsSearchParams.prototype._get_endDate__0 = function () {
    return this._endDate;
  };
  ClippingsSearchParams.prototype._get_startDate__0 = function () {
    return this._startDate;
  };
  ClippingsSearchParams.prototype._get_orderBy__0 = function () {
    return this._orderBy;
  };
  ClippingsSearchParams.prototype._get_includeCollected_ = function () {
    return this._includeCollected;
  };
  ClippingsSearchParams.prototype._get_page__0 = function () {
    return this._page;
  };
  ClippingsSearchParams.prototype._get_resultMode__0 = function () {
    return this._resultMode;
  };
  ClippingsSearchParams.$metadata$ = {
    simpleName: 'ClippingsSearchParams',
    kind: 'class',
    interfaces: []
  };
  Object.defineProperty(ClippingsSearchParams.prototype, 'query', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_query__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'fuzzy', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_fuzzy__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'qMeta', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_qMeta__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'qOcr', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_qOcr__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'requireAllKeywords', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_requireAllKeywords__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'formats', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_formats__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'collection', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_collection__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'categoryId', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_categoryId_
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'subjectId', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_subjectId_
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'title', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_title__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'keyword', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_keyword_
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'endDate', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_endDate__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'startDate', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_startDate__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'orderBy', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_orderBy__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'includeCollected', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_includeCollected_
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'page', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_page__0
  });
  Object.defineProperty(ClippingsSearchParams.prototype, 'resultMode', {
    configurable: true,
    get: ClippingsSearchParams.prototype._get_resultMode__0
  });
  function param($this, property, default_0, parse_1) {
    var tmp0_also_0 = new ClippingsUrlSearchParam(property, default_0, parse_1);
    var tmp1_also_0 = _no_name_provided_$factory_22($this._allValues);
    tmp1_also_0(tmp0_also_0);
    return tmp0_also_0;
  }
  function booleanParam($this, property, default_0) {
    return param($this, property, default_0, _no_name_provided_$factory_23());
  }
  function booleanParam$default($this, property, default_0, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      default_0 = false;
    return booleanParam($this, property, default_0);
  }
  function intParam($this, property, default_0) {
    return param($this, property, default_0, _no_name_provided_$factory_24());
  }
  function stringParam($this, property, default_0) {
    return param($this, property, default_0, _no_name_provided_$factory_25());
  }
  function stringParam$default($this, property, default_0, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      default_0 = '';
    return stringParam($this, property, default_0);
  }
  function stringParamOpt($this, property, default_0) {
    return param($this, property, default_0, _no_name_provided_$factory_26());
  }
  function stringParamOpt$default($this, property, default_0, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      default_0 = null;
    return stringParamOpt($this, property, default_0);
  }
  function intArrayParam($this, property, default_0) {
    return param($this, property, default_0, _no_name_provided_$factory_27());
  }
  function intArrayParam$default($this, property, default_0, $mask0, $handler) {
    if (!(($mask0 & 4) === 0)) {
      default_0 = [];
    }return intArrayParam($this, property, default_0);
  }
  function stringArrayParam($this, property, default_0) {
    return param($this, property, default_0, _no_name_provided_$factory_28());
  }
  function stringArrayParam$default($this, property, default_0, $mask0, $handler) {
    if (!(($mask0 & 4) === 0)) {
      default_0 = [];
    }return stringArrayParam($this, property, default_0);
  }
  function param$add(receiver, p0) {
    receiver.add_11(p0);
    Unit_getInstance();
  }
  function _no_name_provided__23($boundThis) {
    this._$boundThis = $boundThis;
  }
  _no_name_provided__23.prototype.invoke_45 = function (p0) {
    return param$add(this._$boundThis, p0);
  };
  _no_name_provided__23.prototype.invoke_72 = function (p1) {
    this.invoke_45(p1 instanceof ClippingsUrlSearchParam ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__23.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__24() {
  }
  _no_name_provided__24.prototype.invoke_71 = function (array) {
    var tmp0_safe_receiver = getOrNull(array, 0);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = tmp0_safe_receiver === 'true';
    }
    return tmp;
  };
  _no_name_provided__24.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__24.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__25() {
  }
  _no_name_provided__25.prototype.invoke_71 = function (array) {
    var tmp0_safe_receiver = getOrNull(array, 0);
    return tmp0_safe_receiver == null ? null : toInt(tmp0_safe_receiver);
  };
  _no_name_provided__25.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__25.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__26() {
  }
  _no_name_provided__26.prototype.invoke_71 = function (array) {
    return getOrNull(array, 0);
  };
  _no_name_provided__26.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__26.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__27() {
  }
  _no_name_provided__27.prototype.invoke_71 = function (array) {
    return getOrNull(array, 0);
  };
  _no_name_provided__27.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__27.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__28() {
  }
  _no_name_provided__28.prototype.invoke_71 = function (array) {
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(array.length);
    var indexedObject = array;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var item_2_3 = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      tmp0_mapTo_0_1.add_11(toInt(item_2_3));
      Unit_getInstance();
    }
    var tmp0_toTypedArray_0 = tmp0_mapTo_0_1;
    return copyToArray_0(tmp0_toTypedArray_0);
  };
  _no_name_provided__28.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__28.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__29() {
  }
  _no_name_provided__29.prototype.invoke_71 = function (array) {
    return array;
  };
  _no_name_provided__29.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__29.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Companion_4() {
    Companion_instance_3 = this;
    var tmp = this;
    tmp._allValues = ArrayList_init_$Create$();
    this._REQUIRE_ALL_KEYWORDS = booleanParam(this, requireAllKeywords$factory(), true);
    var tmp_0 = this;
    var tmp_1 = fuzzy$factory();
    tmp_0._FUZZY = booleanParam$default(this, tmp_1, false, 4, null);
    this._QUERY_TARGETS_OCR_TEXT = booleanParam(this, qOcr$factory(), true);
    this._QUERY_TARGETS_METADATA = booleanParam(this, qMeta$factory(), true);
    this._PAGE = intParam(this, page$factory(), 1);
    var tmp_2 = this;
    var tmp_3 = query$factory();
    tmp_2._QUERY = stringParam$default(this, tmp_3, null, 4, null);
    var tmp_4 = this;
    var tmp_5 = startDate$factory();
    tmp_4._START_DATE = stringParamOpt$default(this, tmp_5, null, 4, null);
    var tmp_6 = this;
    var tmp_7 = endDate$factory();
    tmp_6._END_DATE = stringParamOpt$default(this, tmp_7, null, 4, null);
    var tmp_8 = this;
    var tmp_9 = collection$factory();
    tmp_8._COLLECTIONS = intArrayParam$default(this, tmp_9, null, 4, null);
    var tmp_10 = this;
    var tmp_11 = categoryId$factory();
    tmp_10._CATEGORIES = intArrayParam$default(this, tmp_11, null, 4, null);
    var tmp_12 = this;
    var tmp_13 = subjectId$factory();
    tmp_12._SUBJECTS = intArrayParam$default(this, tmp_13, null, 4, null);
    var tmp_14 = this;
    var tmp_15 = keyword$factory();
    tmp_14._KEYWORDS = stringArrayParam$default(this, tmp_15, null, 4, null);
    var tmp_16 = this;
    var tmp_17 = title$factory();
    tmp_16._TITLES = stringArrayParam$default(this, tmp_17, null, 4, null);
    var tmp_18 = this;
    var tmp_19 = formats$factory();
    tmp_18._FORMATS = stringArrayParam$default(this, tmp_19, null, 4, null);
    this._ORDER_BY = stringParam(this, orderBy$factory(), 'RELEVANCE');
    var tmp_20 = this;
    var tmp_21 = resultMode$factory();
    tmp_20._RESULT_MODE = stringParamOpt$default(this, tmp_21, null, 4, null);
    this._INCLUDE_COLLECTED = booleanParam(this, includeCollected$factory(), false);
  }
  Companion_4.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_3;
  function Companion_getInstance_3() {
    if (Companion_instance_3 == null)
      new Companion_4();
    return Companion_instance_3;
  }
  function ClippingsUrlSearchParam(property, default_0, parse_1) {
    Companion_getInstance_3();
    this._default = default_0;
    this._parse = parse_1;
    this._key_0 = property.callableName;
  }
  ClippingsUrlSearchParam.prototype.resolve_0 = function (value) {
    var tmp0_elvis_lhs = !(value == null) ? this._parse(value) : null;
    return tmp0_elvis_lhs == null ? this._default : tmp0_elvis_lhs;
  };
  ClippingsUrlSearchParam.$metadata$ = {
    simpleName: 'ClippingsUrlSearchParam',
    kind: 'class',
    interfaces: []
  };
  function parseClippingsParams(paramMap) {
    var tmp0_query = resolve(paramMap, Companion_getInstance_3()._QUERY);
    var tmp1_fuzzy = resolve(paramMap, Companion_getInstance_3()._FUZZY);
    var tmp2_qMeta = resolve(paramMap, Companion_getInstance_3()._QUERY_TARGETS_METADATA);
    var tmp3_qOcr = resolve(paramMap, Companion_getInstance_3()._QUERY_TARGETS_OCR_TEXT);
    var tmp4_requireAllKeywords = resolve(paramMap, Companion_getInstance_3()._REQUIRE_ALL_KEYWORDS);
    var tmp5_formats = resolve(paramMap, Companion_getInstance_3()._FORMATS);
    var tmp6_collection = resolve(paramMap, Companion_getInstance_3()._COLLECTIONS);
    var tmp7_title = resolve(paramMap, Companion_getInstance_3()._TITLES);
    var tmp8_categoryId = resolve(paramMap, Companion_getInstance_3()._CATEGORIES);
    var tmp9_subjectId = resolve(paramMap, Companion_getInstance_3()._SUBJECTS);
    var tmp10_keyword = resolve(paramMap, Companion_getInstance_3()._KEYWORDS);
    var tmp11_endDate = resolve(paramMap, Companion_getInstance_3()._END_DATE);
    var tmp12_startDate = resolve(paramMap, Companion_getInstance_3()._START_DATE);
    var tmp13_orderBy = resolve(paramMap, Companion_getInstance_3()._ORDER_BY);
    var tmp14_includeCollected = resolve(paramMap, Companion_getInstance_3()._INCLUDE_COLLECTED);
    var tmp15_page = resolve(paramMap, Companion_getInstance_3()._PAGE);
    var tmp16_resultMode = resolve(paramMap, Companion_getInstance_3()._RESULT_MODE);
    return new ClippingsSearchParams(tmp0_query, tmp1_fuzzy, tmp2_qMeta, tmp3_qOcr, tmp4_requireAllKeywords, tmp5_formats, tmp6_collection, tmp8_categoryId, tmp9_subjectId, tmp7_title, tmp10_keyword, tmp11_endDate, tmp12_startDate, tmp13_orderBy, tmp14_includeCollected, tmp15_page, tmp16_resultMode);
  }
  function resolve(paramMap, param_1) {
    var result = param_1.resolve_0(paramMap.get_9(param_1._key_0));
    if (!(result == null) ? isArray(result) : false) {
      var tmp0_copyOf_0 = result;
      var tmp = tmp0_copyOf_0.slice();
      return isObject(tmp) ? tmp : THROW_CCE();
    } else {
      return result;
    }
  }
  function requireAllKeywords$factory() {
    return getPropertyCallableRef('requireAllKeywords', 1, KProperty1, function (receiver) {
      return receiver._requireAllKeywords;
    }, null);
  }
  function fuzzy$factory() {
    return getPropertyCallableRef('fuzzy', 1, KProperty1, function (receiver) {
      return receiver._fuzzy;
    }, null);
  }
  function qOcr$factory() {
    return getPropertyCallableRef('qOcr', 1, KProperty1, function (receiver) {
      return receiver._qOcr;
    }, null);
  }
  function qMeta$factory() {
    return getPropertyCallableRef('qMeta', 1, KProperty1, function (receiver) {
      return receiver._qMeta;
    }, null);
  }
  function page$factory() {
    return getPropertyCallableRef('page', 1, KProperty1, function (receiver) {
      return receiver._page;
    }, null);
  }
  function query$factory() {
    return getPropertyCallableRef('query', 1, KProperty1, function (receiver) {
      return receiver._query;
    }, null);
  }
  function startDate$factory() {
    return getPropertyCallableRef('startDate', 1, KProperty1, function (receiver) {
      return receiver._startDate;
    }, null);
  }
  function endDate$factory() {
    return getPropertyCallableRef('endDate', 1, KProperty1, function (receiver) {
      return receiver._endDate;
    }, null);
  }
  function collection$factory() {
    return getPropertyCallableRef('collection', 1, KProperty1, function (receiver) {
      return receiver._collection;
    }, null);
  }
  function categoryId$factory() {
    return getPropertyCallableRef('categoryId', 1, KProperty1, function (receiver) {
      return receiver._categoryId;
    }, null);
  }
  function subjectId$factory() {
    return getPropertyCallableRef('subjectId', 1, KProperty1, function (receiver) {
      return receiver._subjectId;
    }, null);
  }
  function keyword$factory() {
    return getPropertyCallableRef('keyword', 1, KProperty1, function (receiver) {
      return receiver._keyword;
    }, null);
  }
  function title$factory() {
    return getPropertyCallableRef('title', 1, KProperty1, function (receiver) {
      return receiver._title;
    }, null);
  }
  function formats$factory() {
    return getPropertyCallableRef('formats', 1, KProperty1, function (receiver) {
      return receiver._formats;
    }, null);
  }
  function orderBy$factory() {
    return getPropertyCallableRef('orderBy', 1, KProperty1, function (receiver) {
      return receiver._orderBy;
    }, null);
  }
  function resultMode$factory() {
    return getPropertyCallableRef('resultMode', 1, KProperty1, function (receiver) {
      return receiver._resultMode;
    }, null);
  }
  function includeCollected$factory() {
    return getPropertyCallableRef('includeCollected', 1, KProperty1, function (receiver) {
      return receiver._includeCollected;
    }, null);
  }
  function _no_name_provided_$factory_22($boundThis) {
    var i = new _no_name_provided__23($boundThis);
    return function (p1) {
      i.invoke_45(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_23() {
    var i = new _no_name_provided__24();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function _no_name_provided_$factory_24() {
    var i = new _no_name_provided__25();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function _no_name_provided_$factory_25() {
    var i = new _no_name_provided__26();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function _no_name_provided_$factory_26() {
    var i = new _no_name_provided__27();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function _no_name_provided_$factory_27() {
    var i = new _no_name_provided__28();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function _no_name_provided_$factory_28() {
    var i = new _no_name_provided__29();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function param_0($this, property, default_0, parse_1) {
    var tmp0_also_0 = new UrlSearchParam(property, default_0, parse_1);
    var tmp1_also_0 = _no_name_provided_$factory_29($this._allValues_0);
    tmp1_also_0(tmp0_also_0);
    return tmp0_also_0;
  }
  function booleanParam_0($this, property, default_0) {
    return param_0($this, property, default_0, _no_name_provided_$factory_30());
  }
  function booleanParam$default_0($this, property, default_0, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      default_0 = false;
    return booleanParam_0($this, property, default_0);
  }
  function intParam_0($this, property, default_0) {
    return param_0($this, property, default_0, _no_name_provided_$factory_31());
  }
  function stringParam_0($this, property, default_0) {
    return param_0($this, property, default_0, _no_name_provided_$factory_32());
  }
  function stringParam$default_0($this, property, default_0, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      default_0 = '';
    return stringParam_0($this, property, default_0);
  }
  function stringParamOpt_0($this, property, default_0) {
    return param_0($this, property, default_0, _no_name_provided_$factory_33());
  }
  function stringParamOpt$default_0($this, property, default_0, $mask0, $handler) {
    if (!(($mask0 & 4) === 0))
      default_0 = null;
    return stringParamOpt_0($this, property, default_0);
  }
  function intArrayParam_0($this, property, default_0) {
    return param_0($this, property, default_0, _no_name_provided_$factory_34());
  }
  function intArrayParam$default_0($this, property, default_0, $mask0, $handler) {
    if (!(($mask0 & 4) === 0)) {
      default_0 = [];
    }return intArrayParam_0($this, property, default_0);
  }
  function stringArrayParam_0($this, property, default_0) {
    return param_0($this, property, default_0, _no_name_provided_$factory_35());
  }
  function stringArrayParam$default_0($this, property, default_0, $mask0, $handler) {
    if (!(($mask0 & 4) === 0)) {
      default_0 = [];
    }return stringArrayParam_0($this, property, default_0);
  }
  function param$add_0(receiver, p0) {
    receiver.add_11(p0);
    Unit_getInstance();
  }
  function _no_name_provided__30($boundThis) {
    this._$boundThis_0 = $boundThis;
  }
  _no_name_provided__30.prototype.invoke_59 = function (p0) {
    return param$add_0(this._$boundThis_0, p0);
  };
  _no_name_provided__30.prototype.invoke_72 = function (p1) {
    this.invoke_59(p1 instanceof UrlSearchParam ? p1 : THROW_CCE());
    return Unit_getInstance();
  };
  _no_name_provided__30.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__31() {
  }
  _no_name_provided__31.prototype.invoke_71 = function (array) {
    var tmp0_safe_receiver = getOrNull(array, 0);
    var tmp;
    if (tmp0_safe_receiver == null) {
      tmp = null;
    } else {
      tmp = tmp0_safe_receiver === 'true';
    }
    return tmp;
  };
  _no_name_provided__31.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__31.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__32() {
  }
  _no_name_provided__32.prototype.invoke_71 = function (array) {
    var tmp0_safe_receiver = getOrNull(array, 0);
    return tmp0_safe_receiver == null ? null : toInt(tmp0_safe_receiver);
  };
  _no_name_provided__32.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__32.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__33() {
  }
  _no_name_provided__33.prototype.invoke_71 = function (array) {
    return getOrNull(array, 0);
  };
  _no_name_provided__33.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__33.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__34() {
  }
  _no_name_provided__34.prototype.invoke_71 = function (array) {
    return getOrNull(array, 0);
  };
  _no_name_provided__34.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__34.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__35() {
  }
  _no_name_provided__35.prototype.invoke_71 = function (array) {
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(array.length);
    var indexedObject = array;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var item_2_3 = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      tmp0_mapTo_0_1.add_11(toInt(item_2_3));
      Unit_getInstance();
    }
    var tmp0_toTypedArray_0 = tmp0_mapTo_0_1;
    return copyToArray_0(tmp0_toTypedArray_0);
  };
  _no_name_provided__35.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__35.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function _no_name_provided__36() {
  }
  _no_name_provided__36.prototype.invoke_71 = function (array) {
    return array;
  };
  _no_name_provided__36.prototype.invoke_72 = function (p1) {
    return this.invoke_71((!(p1 == null) ? isArray(p1) : false) ? p1 : THROW_CCE());
  };
  _no_name_provided__36.$metadata$ = {
    kind: 'class',
    interfaces: []
  };
  function Companion_5() {
    Companion_instance_4 = this;
    var tmp = this;
    tmp._allValues_0 = ArrayList_init_$Create$();
    this._REQUIRE_ALL_KEYWORDS_0 = booleanParam_0(this, requireAllKeywords$factory_0(), true);
    var tmp_0 = this;
    var tmp_1 = fuzzy$factory_0();
    tmp_0._FUZZY_0 = booleanParam$default_0(this, tmp_1, false, 4, null);
    this._QUERY_TARGETS_OCR_TEXT_0 = booleanParam_0(this, qOcr$factory_0(), true);
    var tmp_2 = this;
    var tmp_3 = qMeta$factory_0();
    tmp_2._QUERY_TARGETS_METADATA_0 = booleanParam$default_0(this, tmp_3, false, 4, null);
    var tmp_4 = this;
    var tmp_5 = hasIllustrations$factory();
    tmp_4._HAS_ILLUSTRATIONS = booleanParam$default_0(this, tmp_5, false, 4, null);
    var tmp_6 = this;
    var tmp_7 = searchForBindings$factory();
    tmp_6._SEARCH_FOR_BINDINGS = booleanParam$default_0(this, tmp_7, false, 4, null);
    var tmp_8 = this;
    var tmp_9 = showLastPage$factory();
    tmp_8._SHOW_LAST_PAGE = booleanParam$default_0(this, tmp_9, false, 4, null);
    var tmp_10 = this;
    var tmp_11 = showUnauthorizedResults$factory();
    tmp_10._SHOW_UNAUTHORIZED_RESULTS = booleanParam$default_0(this, tmp_11, false, 4, null);
    this._PAGE_0 = intParam_0(this, page$factory_0(), 1);
    this._PART_PAGE = intParam_0(this, partPage$factory(), 1);
    var tmp_12 = this;
    var tmp_13 = pages$factory();
    tmp_12._PAGES = stringParam$default_0(this, tmp_13, null, 4, null);
    var tmp_14 = this;
    var tmp_15 = query$factory_0();
    tmp_14._QUERY_0 = stringParam$default_0(this, tmp_15, null, 4, null);
    this._IMPORT_TIME = stringParam_0(this, importTime$factory(), 'ANY');
    var tmp_16 = this;
    var tmp_17 = startDate$factory_0();
    tmp_16._START_DATE_0 = stringParamOpt$default_0(this, tmp_17, null, 4, null);
    var tmp_18 = this;
    var tmp_19 = endDate$factory_0();
    tmp_18._END_DATE_0 = stringParamOpt$default_0(this, tmp_19, null, 4, null);
    var tmp_20 = this;
    var tmp_21 = importStartDate$factory();
    tmp_20._IMPORT_START_DATE = stringParamOpt$default_0(this, tmp_21, null, 4, null);
    var tmp_22 = this;
    var tmp_23 = collection$factory_0();
    tmp_22._COLLECTIONS_0 = intArrayParam$default_0(this, tmp_23, null, 4, null);
    var tmp_24 = this;
    var tmp_25 = series$factory();
    tmp_24._SERIES = stringArrayParam$default_0(this, tmp_25, null, 4, null);
    var tmp_26 = this;
    var tmp_27 = author$factory();
    tmp_26._AUTHORS = stringArrayParam$default_0(this, tmp_27, null, 4, null);
    var tmp_28 = this;
    var tmp_29 = tag$factory();
    tmp_28._TAGS = stringArrayParam$default_0(this, tmp_29, null, 4, null);
    var tmp_30 = this;
    var tmp_31 = publisher$factory();
    tmp_30._PUBLISHERS = stringArrayParam$default_0(this, tmp_31, null, 4, null);
    var tmp_32 = this;
    var tmp_33 = district$factory();
    tmp_32._DISTRICTS = stringArrayParam$default_0(this, tmp_33, null, 4, null);
    var tmp_34 = this;
    var tmp_35 = publicationPlace$factory();
    tmp_34._PUBLICATION_PLACES = stringArrayParam$default_0(this, tmp_35, null, 4, null);
    var tmp_36 = this;
    var tmp_37 = title$factory_0();
    tmp_36._TITLES_0 = stringArrayParam$default_0(this, tmp_37, null, 4, null);
    var tmp_38 = this;
    var tmp_39 = lang$factory();
    tmp_38._LANGUAGES = stringArrayParam$default_0(this, tmp_39, null, 4, null);
    var tmp_40 = this;
    var tmp_41 = formats$factory_0();
    tmp_40._FORMATS_0 = stringArrayParam$default_0(this, tmp_41, null, 4, null);
    this._ORDER_BY_0 = stringParam_0(this, orderBy$factory_0(), 'IMPORT_DATE');
    var tmp_42 = this;
    var tmp_43 = resultMode$factory_0();
    tmp_42._RESULT_MODE_0 = stringParamOpt$default_0(this, tmp_43, null, 4, null);
    this._RESULT_TYPE = stringParamOpt_0(this, resultType$factory(), 'BINDING_PAGES');
  }
  Companion_5.prototype.values = function () {
    var tmp0_toTypedArray_0 = this._allValues_0;
    return copyToArray_0(tmp0_toTypedArray_0);
  };
  Companion_5.$metadata$ = {
    simpleName: 'Companion',
    kind: 'object',
    interfaces: []
  };
  var Companion_instance_4;
  function Companion_getInstance_4() {
    if (Companion_instance_4 == null)
      new Companion_5();
    return Companion_instance_4;
  }
  function UrlSearchParam(property, default_0, parse_1) {
    Companion_getInstance_4();
    this._default_0 = default_0;
    this._parse_0 = parse_1;
    this._key_1 = property.callableName;
  }
  UrlSearchParam.prototype.resolve_0 = function (value) {
    var tmp0_elvis_lhs = !(value == null) ? this._parse_0(value) : null;
    return tmp0_elvis_lhs == null ? this._default_0 : tmp0_elvis_lhs;
  };
  UrlSearchParam.$metadata$ = {
    simpleName: 'UrlSearchParam',
    kind: 'class',
    interfaces: []
  };
  function parse(paramMap) {
    return new SearchParams(resolve_0(paramMap, Companion_getInstance_4()._QUERY_0), resolve_0(paramMap, Companion_getInstance_4()._FUZZY_0), resolve_0(paramMap, Companion_getInstance_4()._HAS_ILLUSTRATIONS), resolve_0(paramMap, Companion_getInstance_4()._QUERY_TARGETS_METADATA_0), resolve_0(paramMap, Companion_getInstance_4()._QUERY_TARGETS_OCR_TEXT_0), resolve_0(paramMap, Companion_getInstance_4()._REQUIRE_ALL_KEYWORDS_0), resolve_0(paramMap, Companion_getInstance_4()._SHOW_LAST_PAGE), resolve_0(paramMap, Companion_getInstance_4()._SEARCH_FOR_BINDINGS), resolve_0(paramMap, Companion_getInstance_4()._SHOW_UNAUTHORIZED_RESULTS), resolve_0(paramMap, Companion_getInstance_4()._IMPORT_TIME), resolve_0(paramMap, Companion_getInstance_4()._FORMATS_0), modernizeLanguageCodes(resolve_0(paramMap, Companion_getInstance_4()._LANGUAGES)), resolve_0(paramMap, Companion_getInstance_4()._COLLECTIONS_0), resolve_0(paramMap, Companion_getInstance_4()._SERIES), resolve_0(paramMap, Companion_getInstance_4()._TITLES_0), resolve_0(paramMap, Companion_getInstance_4()._AUTHORS), resolve_0(paramMap, Companion_getInstance_4()._DISTRICTS), resolve_0(paramMap, Companion_getInstance_4()._PUBLICATION_PLACES), resolve_0(paramMap, Companion_getInstance_4()._PUBLISHERS), resolve_0(paramMap, Companion_getInstance_4()._TAGS), resolve_0(paramMap, Companion_getInstance_4()._IMPORT_START_DATE), resolve_0(paramMap, Companion_getInstance_4()._END_DATE_0), resolve_0(paramMap, Companion_getInstance_4()._START_DATE_0), resolve_0(paramMap, Companion_getInstance_4()._ORDER_BY_0), resolve_0(paramMap, Companion_getInstance_4()._PAGES), resolve_0(paramMap, Companion_getInstance_4()._PAGE_0), resolve_0(paramMap, Companion_getInstance_4()._PART_PAGE), resolve_0(paramMap, Companion_getInstance_4()._RESULT_MODE_0), resolve_0(paramMap, Companion_getInstance_4()._RESULT_TYPE));
  }
  function resolve_0(paramMap, param_1) {
    var result = param_1.resolve_0(paramMap.get_9(param_1._key_1));
    if (!(result == null) ? isArray(result) : false) {
      var tmp0_copyOf_0 = result;
      var tmp = tmp0_copyOf_0.slice();
      return isObject(tmp) ? tmp : THROW_CCE();
    } else {
      return result;
    }
  }
  function modernizeLanguageCodes(codes) {
    var tmp0_mapTo_0_1 = ArrayList_init_$Create$_0(codes.length);
    var indexedObject = codes;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var item_2_3 = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp0_subject_5 = item_2_3;
      tmp0_mapTo_0_1.add_11(tmp0_subject_5 === 'FI' ? 'FIN' : tmp0_subject_5 === 'SE' ? 'SWE' : tmp0_subject_5 === 'EN' ? 'ENG' : tmp0_subject_5 === 'DE' ? 'GER' : tmp0_subject_5 === 'FR' ? 'FRE' : tmp0_subject_5 === 'RU' ? 'RUS' : item_2_3);
      Unit_getInstance();
    }
    var tmp0_toTypedArray_0 = tmp0_mapTo_0_1;
    return copyToArray_0(tmp0_toTypedArray_0);
  }
  function requireAllKeywords$factory_0() {
    return getPropertyCallableRef('requireAllKeywords', 1, KProperty1, function (receiver) {
      return receiver._requireAllKeywords_0;
    }, null);
  }
  function fuzzy$factory_0() {
    return getPropertyCallableRef('fuzzy', 1, KProperty1, function (receiver) {
      return receiver._fuzzy_0;
    }, null);
  }
  function qOcr$factory_0() {
    return getPropertyCallableRef('qOcr', 1, KProperty1, function (receiver) {
      return receiver._qOcr_0;
    }, null);
  }
  function qMeta$factory_0() {
    return getPropertyCallableRef('qMeta', 1, KProperty1, function (receiver) {
      return receiver._qMeta_0;
    }, null);
  }
  function hasIllustrations$factory() {
    return getPropertyCallableRef('hasIllustrations', 1, KProperty1, function (receiver) {
      return receiver._hasIllustrations;
    }, null);
  }
  function searchForBindings$factory() {
    return getPropertyCallableRef('searchForBindings', 1, KProperty1, function (receiver) {
      return receiver._searchForBindings;
    }, null);
  }
  function showLastPage$factory() {
    return getPropertyCallableRef('showLastPage', 1, KProperty1, function (receiver) {
      return receiver._showLastPage;
    }, null);
  }
  function showUnauthorizedResults$factory() {
    return getPropertyCallableRef('showUnauthorizedResults', 1, KProperty1, function (receiver) {
      return receiver._showUnauthorizedResults;
    }, null);
  }
  function page$factory_0() {
    return getPropertyCallableRef('page', 1, KProperty1, function (receiver) {
      return receiver._page_0;
    }, null);
  }
  function partPage$factory() {
    return getPropertyCallableRef('partPage', 1, KProperty1, function (receiver) {
      return receiver._partPage;
    }, null);
  }
  function pages$factory() {
    return getPropertyCallableRef('pages', 1, KProperty1, function (receiver) {
      return receiver._pages;
    }, null);
  }
  function query$factory_0() {
    return getPropertyCallableRef('query', 1, KProperty1, function (receiver) {
      return receiver._query_0;
    }, null);
  }
  function importTime$factory() {
    return getPropertyCallableRef('importTime', 1, KProperty1, function (receiver) {
      return receiver._importTime;
    }, null);
  }
  function startDate$factory_0() {
    return getPropertyCallableRef('startDate', 1, KProperty1, function (receiver) {
      return receiver._startDate_0;
    }, null);
  }
  function endDate$factory_0() {
    return getPropertyCallableRef('endDate', 1, KProperty1, function (receiver) {
      return receiver._endDate_0;
    }, null);
  }
  function importStartDate$factory() {
    return getPropertyCallableRef('importStartDate', 1, KProperty1, function (receiver) {
      return receiver._importStartDate;
    }, null);
  }
  function collection$factory_0() {
    return getPropertyCallableRef('collection', 1, KProperty1, function (receiver) {
      return receiver._collection_0;
    }, null);
  }
  function series$factory() {
    return getPropertyCallableRef('series', 1, KProperty1, function (receiver) {
      return receiver._series;
    }, null);
  }
  function author$factory() {
    return getPropertyCallableRef('author', 1, KProperty1, function (receiver) {
      return receiver._author;
    }, null);
  }
  function tag$factory() {
    return getPropertyCallableRef('tag', 1, KProperty1, function (receiver) {
      return receiver._tag;
    }, null);
  }
  function publisher$factory() {
    return getPropertyCallableRef('publisher', 1, KProperty1, function (receiver) {
      return receiver._publisher;
    }, null);
  }
  function district$factory() {
    return getPropertyCallableRef('district', 1, KProperty1, function (receiver) {
      return receiver._district;
    }, null);
  }
  function publicationPlace$factory() {
    return getPropertyCallableRef('publicationPlace', 1, KProperty1, function (receiver) {
      return receiver._publicationPlace;
    }, null);
  }
  function title$factory_0() {
    return getPropertyCallableRef('title', 1, KProperty1, function (receiver) {
      return receiver._title_0;
    }, null);
  }
  function lang$factory() {
    return getPropertyCallableRef('lang', 1, KProperty1, function (receiver) {
      return receiver._lang;
    }, null);
  }
  function formats$factory_0() {
    return getPropertyCallableRef('formats', 1, KProperty1, function (receiver) {
      return receiver._formats_0;
    }, null);
  }
  function orderBy$factory_0() {
    return getPropertyCallableRef('orderBy', 1, KProperty1, function (receiver) {
      return receiver._orderBy_0;
    }, null);
  }
  function resultMode$factory_0() {
    return getPropertyCallableRef('resultMode', 1, KProperty1, function (receiver) {
      return receiver._resultMode_0;
    }, null);
  }
  function resultType$factory() {
    return getPropertyCallableRef('resultType', 1, KProperty1, function (receiver) {
      return receiver._resultType;
    }, null);
  }
  function _no_name_provided_$factory_29($boundThis) {
    var i = new _no_name_provided__30($boundThis);
    return function (p1) {
      i.invoke_59(p1);
      return Unit_getInstance();
    };
  }
  function _no_name_provided_$factory_30() {
    var i = new _no_name_provided__31();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function _no_name_provided_$factory_31() {
    var i = new _no_name_provided__32();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function _no_name_provided_$factory_32() {
    var i = new _no_name_provided__33();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function _no_name_provided_$factory_33() {
    var i = new _no_name_provided__34();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function _no_name_provided_$factory_34() {
    var i = new _no_name_provided__35();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function _no_name_provided_$factory_35() {
    var i = new _no_name_provided__36();
    return function (p1) {
      return i.invoke_71(p1);
    };
  }
  function SearchParams(query, fuzzy, hasIllustrations, qMeta, qOcr, requireAllKeywords, showLastPage, searchForBindings, showUnauthorizedResults, importTime, formats, lang, collection, series, title, author, district, publicationPlace, publisher, tag, importStartDate, endDate, startDate, orderBy, pages, page, partPage, resultMode, resultType) {
    this._query_0 = query;
    this._fuzzy_0 = fuzzy;
    this._hasIllustrations = hasIllustrations;
    this._qMeta_0 = qMeta;
    this._qOcr_0 = qOcr;
    this._requireAllKeywords_0 = requireAllKeywords;
    this._showLastPage = showLastPage;
    this._searchForBindings = searchForBindings;
    this._showUnauthorizedResults = showUnauthorizedResults;
    this._importTime = importTime;
    this._formats_0 = formats;
    this._lang = lang;
    this._collection_0 = collection;
    this._series = series;
    this._title_0 = title;
    this._author = author;
    this._district = district;
    this._publicationPlace = publicationPlace;
    this._publisher = publisher;
    this._tag = tag;
    this._importStartDate = importStartDate;
    this._endDate_0 = endDate;
    this._startDate_0 = startDate;
    this._orderBy_0 = orderBy;
    this._pages = pages;
    this._page_0 = page;
    this._partPage = partPage;
    this._resultMode_0 = resultMode;
    this._resultType = resultType;
  }
  SearchParams.prototype._get_query__0 = function () {
    return this._query_0;
  };
  SearchParams.prototype._get_fuzzy__0 = function () {
    return this._fuzzy_0;
  };
  SearchParams.prototype._get_hasIllustrations_ = function () {
    return this._hasIllustrations;
  };
  SearchParams.prototype._get_qMeta__0 = function () {
    return this._qMeta_0;
  };
  SearchParams.prototype._get_qOcr__0 = function () {
    return this._qOcr_0;
  };
  SearchParams.prototype._get_requireAllKeywords__0 = function () {
    return this._requireAllKeywords_0;
  };
  SearchParams.prototype._get_showLastPage_ = function () {
    return this._showLastPage;
  };
  SearchParams.prototype._get_searchForBindings_ = function () {
    return this._searchForBindings;
  };
  SearchParams.prototype._get_showUnauthorizedResults_ = function () {
    return this._showUnauthorizedResults;
  };
  SearchParams.prototype._get_importTime_ = function () {
    return this._importTime;
  };
  SearchParams.prototype._get_formats__0 = function () {
    return this._formats_0;
  };
  SearchParams.prototype._get_lang_ = function () {
    return this._lang;
  };
  SearchParams.prototype._get_collection__0 = function () {
    return this._collection_0;
  };
  SearchParams.prototype._get_series_ = function () {
    return this._series;
  };
  SearchParams.prototype._get_title__0 = function () {
    return this._title_0;
  };
  SearchParams.prototype._get_author_ = function () {
    return this._author;
  };
  SearchParams.prototype._get_district_ = function () {
    return this._district;
  };
  SearchParams.prototype._get_publicationPlace_ = function () {
    return this._publicationPlace;
  };
  SearchParams.prototype._get_publisher_ = function () {
    return this._publisher;
  };
  SearchParams.prototype._get_tag_ = function () {
    return this._tag;
  };
  SearchParams.prototype._get_importStartDate_ = function () {
    return this._importStartDate;
  };
  SearchParams.prototype._get_endDate__0 = function () {
    return this._endDate_0;
  };
  SearchParams.prototype._get_startDate__0 = function () {
    return this._startDate_0;
  };
  SearchParams.prototype._get_orderBy__0 = function () {
    return this._orderBy_0;
  };
  SearchParams.prototype._get_pages_ = function () {
    return this._pages;
  };
  SearchParams.prototype._get_page__0 = function () {
    return this._page_0;
  };
  SearchParams.prototype._get_partPage_ = function () {
    return this._partPage;
  };
  SearchParams.prototype._get_resultMode__0 = function () {
    return this._resultMode_0;
  };
  SearchParams.prototype._get_resultType_ = function () {
    return this._resultType;
  };
  SearchParams.$metadata$ = {
    simpleName: 'SearchParams',
    kind: 'class',
    interfaces: []
  };
  Object.defineProperty(SearchParams.prototype, 'query', {
    configurable: true,
    get: SearchParams.prototype._get_query__0
  });
  Object.defineProperty(SearchParams.prototype, 'fuzzy', {
    configurable: true,
    get: SearchParams.prototype._get_fuzzy__0
  });
  Object.defineProperty(SearchParams.prototype, 'hasIllustrations', {
    configurable: true,
    get: SearchParams.prototype._get_hasIllustrations_
  });
  Object.defineProperty(SearchParams.prototype, 'qMeta', {
    configurable: true,
    get: SearchParams.prototype._get_qMeta__0
  });
  Object.defineProperty(SearchParams.prototype, 'qOcr', {
    configurable: true,
    get: SearchParams.prototype._get_qOcr__0
  });
  Object.defineProperty(SearchParams.prototype, 'requireAllKeywords', {
    configurable: true,
    get: SearchParams.prototype._get_requireAllKeywords__0
  });
  Object.defineProperty(SearchParams.prototype, 'showLastPage', {
    configurable: true,
    get: SearchParams.prototype._get_showLastPage_
  });
  Object.defineProperty(SearchParams.prototype, 'searchForBindings', {
    configurable: true,
    get: SearchParams.prototype._get_searchForBindings_
  });
  Object.defineProperty(SearchParams.prototype, 'showUnauthorizedResults', {
    configurable: true,
    get: SearchParams.prototype._get_showUnauthorizedResults_
  });
  Object.defineProperty(SearchParams.prototype, 'importTime', {
    configurable: true,
    get: SearchParams.prototype._get_importTime_
  });
  Object.defineProperty(SearchParams.prototype, 'formats', {
    configurable: true,
    get: SearchParams.prototype._get_formats__0
  });
  Object.defineProperty(SearchParams.prototype, 'lang', {
    configurable: true,
    get: SearchParams.prototype._get_lang_
  });
  Object.defineProperty(SearchParams.prototype, 'collection', {
    configurable: true,
    get: SearchParams.prototype._get_collection__0
  });
  Object.defineProperty(SearchParams.prototype, 'series', {
    configurable: true,
    get: SearchParams.prototype._get_series_
  });
  Object.defineProperty(SearchParams.prototype, 'title', {
    configurable: true,
    get: SearchParams.prototype._get_title__0
  });
  Object.defineProperty(SearchParams.prototype, 'author', {
    configurable: true,
    get: SearchParams.prototype._get_author_
  });
  Object.defineProperty(SearchParams.prototype, 'district', {
    configurable: true,
    get: SearchParams.prototype._get_district_
  });
  Object.defineProperty(SearchParams.prototype, 'publicationPlace', {
    configurable: true,
    get: SearchParams.prototype._get_publicationPlace_
  });
  Object.defineProperty(SearchParams.prototype, 'publisher', {
    configurable: true,
    get: SearchParams.prototype._get_publisher_
  });
  Object.defineProperty(SearchParams.prototype, 'tag', {
    configurable: true,
    get: SearchParams.prototype._get_tag_
  });
  Object.defineProperty(SearchParams.prototype, 'importStartDate', {
    configurable: true,
    get: SearchParams.prototype._get_importStartDate_
  });
  Object.defineProperty(SearchParams.prototype, 'endDate', {
    configurable: true,
    get: SearchParams.prototype._get_endDate__0
  });
  Object.defineProperty(SearchParams.prototype, 'startDate', {
    configurable: true,
    get: SearchParams.prototype._get_startDate__0
  });
  Object.defineProperty(SearchParams.prototype, 'orderBy', {
    configurable: true,
    get: SearchParams.prototype._get_orderBy__0
  });
  Object.defineProperty(SearchParams.prototype, 'pages', {
    configurable: true,
    get: SearchParams.prototype._get_pages_
  });
  Object.defineProperty(SearchParams.prototype, 'page', {
    configurable: true,
    get: SearchParams.prototype._get_page__0
  });
  Object.defineProperty(SearchParams.prototype, 'partPage', {
    configurable: true,
    get: SearchParams.prototype._get_partPage_
  });
  Object.defineProperty(SearchParams.prototype, 'resultMode', {
    configurable: true,
    get: SearchParams.prototype._get_resultMode__0
  });
  Object.defineProperty(SearchParams.prototype, 'resultType', {
    configurable: true,
    get: SearchParams.prototype._get_resultType_
  });
  function parse_0(params) {
    var map = LinkedHashMap_init_$Create$();
    var indexedObject = params.keys;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var k = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp0_set_0 = params.getAll(k);
      map.put_4(k, tmp0_set_0);
      Unit_getInstance();
    }
    return parse(map);
  }
  function toDictionary(searchParams, removeDefaults) {
    var result = new Object();
    var src = searchParams;
    var r = result;
    var indexedObject = Companion_getInstance_4().values();
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var param_1 = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var v = src[param_1._key_1];
      if (!removeDefaults ? true : v != param_1._default_0) {
        r[param_1._key_1] = v;
      }}
    return result;
  }
  function parseClippingsParams_0(params) {
    var map = LinkedHashMap_init_$Create$();
    var indexedObject = params.keys;
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var k = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var tmp0_set_0 = params.getAll(k);
      map.put_4(k, tmp0_set_0);
      Unit_getInstance();
    }
    return parseClippingsParams(map);
  }
  function clippingsParamsToDictionary(searchParams, removeDefaults) {
    var result = new Object();
    var src = searchParams;
    var r = result;
    var indexedObject = Companion_getInstance_4().values();
    var inductionVariable = 0;
    var last = indexedObject.length;
    while (inductionVariable < last) {
      var param_1 = indexedObject[inductionVariable];
      inductionVariable = inductionVariable + 1 | 0;
      var v = src[param_1._key_1];
      if (!removeDefaults ? true : v != param_1._default_0) {
        r[param_1._key_1] = v;
      }}
    return result;
  }
  AbstractMap.prototype._get_entries__3 = Map_0.prototype._get_entries__3;
  AbstractMutableList.prototype.get_19 = List.prototype.get_19;
  AbstractMutableMap.prototype._get_entries__3 = MutableMap.prototype._get_entries__3;
  InternalHashCodeMap.prototype.createJsMap_0 = InternalMap.prototype.createJsMap_0;
  KClassImpl.prototype._get_simpleName__2 = KClass.prototype._get_simpleName__2;
  functionClasses = functionClasses$init$();
  buf = new ArrayBuffer(8);
  bufFloat64 = bufFloat64$init$();
  bufInt32 = bufInt32$init$();
  lowIndex = lowIndex$init$();
  highIndex = 1 - lowIndex | 0;
  propertyRefClassMetadataCache = propertyRefClassMetadataCache$init$();
  var $fi = _.fi || (_.fi = {});
  var $fi$kansalliskirjasto = $fi.kansalliskirjasto || ($fi.kansalliskirjasto = {});
  var $fi$kansalliskirjasto$search = $fi$kansalliskirjasto.search || ($fi$kansalliskirjasto.search = {});
  var $fi$kansalliskirjasto$search$kotlin = $fi$kansalliskirjasto$search.kotlin || ($fi$kansalliskirjasto$search.kotlin = {});
  $fi$kansalliskirjasto$search$kotlin.ClippingsSearchParams = ClippingsSearchParams;
  $fi$kansalliskirjasto$search$kotlin.SearchParams = SearchParams;
  $fi$kansalliskirjasto$search$kotlin.parse = parse_0;
  $fi$kansalliskirjasto$search$kotlin.toDictionary = toDictionary;
  $fi$kansalliskirjasto$search$kotlin.parseClippingsParams = parseClippingsParams_0;
  $fi$kansalliskirjasto$search$kotlin.clippingsParamsToDictionary = clippingsParamsToDictionary;
  return _;
}));
