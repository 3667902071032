import {Component, Input} from "@angular/core";
import {TitleCalendarMonth} from "../../../apina-digiweb";
import {TimeTranslationService} from "../../../translations/time-translations";

@Component({
    selector: 'app-calendar-month-missing',
    template: `
        <div class="month-none mb-1" [ngClass]="{'has-daily-bindings': monthCalendar.hasBindings, 'has-bindings': monthCalendar.bindings[0]}">
            <div *ngFor="let week of monthCalendar.weeks" class="">
                <div *ngFor="let day of week.days; trackBy: trackByIndex" class="{{day?.dayOfMonth != null ? 'day-of-month-none' : ''}}" [ngClass]="day?.cssClasses" >
                    <div *ngIf="day">
                        <div *ngIf="day.hasMissingBinding">
                            {{monthNames[monthCalendar.monthValue-1]}} {{day.dayOfMonth}}.:{{day.tooltip}}&nbsp; 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: [
        "./calendar-month.scss"
    ]
})
export class CalendarMonthMissingComponent {
    @Input() monthCalendar: TitleCalendarMonth;
    monthNames: string[];
    weekDays: string[];

    constructor(timeTranslations: TimeTranslationService) {
        this.monthNames = timeTranslations.monthNamesFull;
        this.weekDays = timeTranslations.weekDays;
    }

    // trackByFunction<any>
    public trackByIndex(index: number) {
        return index;
    }
}
