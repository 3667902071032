import {NgModule} from "@angular/core";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {HttpExceptionHandler} from "./http-exception-handler";
import {LoggedInDetector} from "./logged-in-detector";

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpExceptionHandler,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoggedInDetector,
            multi: true
        }
    ]
})
export class InterceptorModule {

}
