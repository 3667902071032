import {Directive, ElementRef, HostListener} from "@angular/core";
import {TagSelectComponent} from "../common/tag-select.component";

/**
 * Implementation lifted from https://github.com/ng-bootstrap/ng-bootstrap/issues/933#issuecomment-274223407
 */
@Directive({
    selector: '[appCloseTagSelectOnClickOutside].tag-select-container'
})
export class CloseTagSelectOnClickOutsideDirective {

    constructor(private elementRef: ElementRef, private tagSelect: TagSelectComponent) {
    }

    //noinspection JSUnusedGlobalSymbols
    @HostListener('document:click', ['$event'])
    documentClicked(event: MouseEvent) {
        if (this.tagSelect && this.tagSelect.focused) {
            // Not clicked on self element
            if (!this.elementRef.nativeElement.contains(event.target)) {
                this.tagSelect.reset();
            }
        }
    }
}
