import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ApinaConfig, BindingSearchCriteria, BindingSearchRestEndpoint, BindingSearchResultsBatch, ComponentPartSearchResultsBatch, Dictionary, QueryOrder} from "../../apina-digiweb";
import {NavigationService} from "../navigation.service";
import {SettingsService} from "../settings.service";
import {excelFileName} from "../../search/excel-utils";
import {buildQueryString} from "../../utils/url";
import * as _ from "lodash";
import {BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";
import {TranslateService} from "@ngx-translate/core";
import {SearchService} from "./search.service";
import {BehaviorSubject, combineLatest, Observable, Subject, Subscription} from "rxjs";
import {debounceTime, shareReplay, switchMap, take} from "rxjs/operators";
import {updateIfChanged} from "../../utils/observable-utils";
import {ParamMap} from "@angular/router";
import {ProcessedBindingSearchResultRow, ResultMode, ResultType} from "./result/result-row";
import {NgbNav} from "@ng-bootstrap/ng-bootstrap";

const PAGE_SIZE = 20;

@Component({
    template: `
        <app-progress-bar *ngIf="loading"></app-progress-bar>
        
        <div class="kk-bg-lightgray search-box">
            <section class="container pt-3">
                <app-binding-search-form [criteria]="criteria" (submitForm)="onFormSubmit()" (initialized)="loading = false"></app-binding-search-form>
            </section>
        </div>

        <div #resultsMarker></div>

        <section class="container mb-5" *ngIf="results || componentPartResults else progress">
            <ul ngbNav #resultTabs="ngbNav" [(activeId)]="resultTab" class="nav-tabs kk-nav-tabs">
                <li [ngbNavItem]="resultsTypes.BINDING_PAGES">
                    <a ngbNavLink>
                        <app-search-result-count-badge *ngIf="results as rs else miniProgress"
                                                       [total]="rs.totalResults" ></app-search-result-count-badge>
                        {{'search-result-tabs.BINDING_PAGES' | translate}}
                    </a>
                    <ng-template ngbNavContent>
                        <app-result-navigation *ngIf="results?.totalResults > 0"
                                [totalResults]="accessibleTotalResults(results.totalResults)"
                                [resultMode]="resultMode" (resultModeChange)="setResultMode($event)"
                                [excelDownloadUrl]="excelDownloadUrl()"
                                [pageSize]="pageSize"
                                [orderBy]="resultsCriteria.orderBy" (orderByChange)="onClickOrderBy($event)" 
                                [currentPage]="page" (currentPageChange)="pageChange($event)"
                                [supportedModes]="bindingResultModes"></app-result-navigation>
                        
                        <app-binding-search-results *ngIf="resultMode != 'CHART'"
                                [resultMode]="resultMode"
                                [items]="bindingRows"></app-binding-search-results>

                        <ngb-pagination [page]="page" *ngIf="bindingRows?.length > 0 && resultMode != 'CHART'"
                                [collectionSize]="accessibleTotalResults(results.totalResults)"
                                [pageSize]="pageSize"
                                [boundaryLinks]="false"
                                [rotate]="true"
                                (pageChange)="pageChange($event)"></ngb-pagination>
                
                        <app-search-result-chart *ngIf="resultMode == 'CHART'" [results]="results"></app-search-result-chart>
                    </ng-template>
                </li>
                <li [ngbNavItem]="resultsTypes.COMPONENT_PARTS">
                    <a ngbNavLink>
                        <app-search-result-count-badge *ngIf="componentPartResults as rs else miniProgress" 
                                                       [total]="rs.totalResults" [resultType]="'COMPONENT_PART'"></app-search-result-count-badge>
                        {{'search-result-tabs.COMPONENT_PARTS' | translate}}
                    </a>
                    <ng-template ngbNavContent>
                        <app-result-navigation *ngIf="componentPartResults?.totalResults > 0"
                                [totalResults]="componentPartResults.totalResults"
                                [resultMode]="resultMode" (resultModeChange)="setResultMode($event)"
                                [pageSize]="pageSize"
                                [orderBy]="resultsCriteria.orderBy" [currentPage]="componentPartPage"
                                (currentPageChange)="partPageChange($event)"
                                (orderByChange)="onClickOrderBy($event)"
                                [supportedModes]="componentPartResultModes"></app-result-navigation>
                        
                        <app-binding-search-results
                                [resultMode]="resultMode"
                                [items]="componentPartRows"></app-binding-search-results>

                        <ngb-pagination [page]="componentPartPage" *ngIf="componentPartRows?.length > 0"
                                        [collectionSize]="accessibleTotalResults(componentPartResults.totalResults)"
                                        [pageSize]="pageSize"
                                        [boundaryLinks]="false"
                                        [rotate]="true"
                                        (pageChange)="partPageChange($event)"></ngb-pagination>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="resultTabs" class="mt-3"></div>
        </section>

        <ng-template #progress>
            <app-progress-spinner class="pt-5 d-block"></app-progress-spinner>
        </ng-template>

        <ng-template #miniProgress>
            <app-progress-spinner [size]="'XX-SMALL'" [style.display]="'inline-block'" [style.vertical-align]="'middle'"></app-progress-spinner>
        </ng-template>
    `,
    styleUrls: [
        "./binding-search.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BindingSearchComponent implements OnInit, OnDestroy {
    
    loading = true;
    
    criteria: BindingSearchCriteria;
    
    resultsTypes = ResultType;
    
    @ViewChild('resultsMarker') resultsContainer: ElementRef;
    
    @ViewChild(NgbNav) resultTabs: NgbNav;

    bindingResultModes = [ResultMode.TEXT, ResultMode.TEXT_WITH_THUMB, ResultMode.THUMB, ResultMode.CHART];
    componentPartResultModes = [ResultMode.TEXT, ResultMode.TEXT_WITH_THUMB, ResultMode.THUMB];

    results: BindingSearchResultsBatch | null;
    componentPartResults: ComponentPartSearchResultsBatch | null;

    private bindingRows$ = new BehaviorSubject<ProcessedBindingSearchResultRow[] | null>(null);
    private componentPartRows$ = new BehaviorSubject<ProcessedBindingSearchResultRow[] | null>(null);
    private resultTabsLoaded$ = new Subject<number[]>(); // Fires whenever both tabs have loaded search results. Array contains (visible) result counts of each tab in order.
    
    private resultModeIsExplicitlySelected = false;
    private orderByIsExplicitlySelected = false;

    private subscription = new Subscription();

    // changing these triggers search
    private refreshResults$ = new Subject<void>();
    private resultsCriteria$ = new BehaviorSubject<BindingSearchCriteria>(null);
    private resultMode$ = new BehaviorSubject<ResultMode>(ResultMode.TEXT_WITH_THUMB);
    private resultTab$ = new BehaviorSubject<ResultType>(ResultType.BINDING_PAGES);
    
    // only fire when the page actually changes
    private bindingResultsPage$ = new BehaviorSubject<number>(1);
    private componentPartResultsPage$ = new BehaviorSubject<number>(1);

    private urlAffectingPropertiesChanged$ = combineLatest([this.resultsCriteria$, this.bindingResultsPage$, this.componentPartResultsPage$, this.resultMode$, this.resultTab$]);
    
    private bindingResultsBatch$: Observable<BindingSearchResultsBatch> = combineLatest([this.resultsCriteria$, this.bindingResultsPage$, this.refreshResults$]).pipe(
        debounceTime(10), // stabilize triggers a bit
        switchMap(([crit, page]) => this.bindingSearchApi.searchBindings(crit, this.getOffset(page), PAGE_SIZE)),
        shareReplay(1)
    );
    private componentPartResultsBatch$: Observable<ComponentPartSearchResultsBatch> = combineLatest([this.resultsCriteria$, this.componentPartResultsPage$, this.refreshResults$]).pipe(
        debounceTime(10),  // stabilize triggers a bit
        switchMap(([crit, page]) => this.bindingSearchApi.searchComponentParts(crit, this.getOffset(page), PAGE_SIZE)),
        shareReplay(1)
    );
    
    // setters/getters

    /**
     * Criteria used to fetch current search results, only updated when user triggers a new search.
     *
     * Storing this separately from UI-criteria allows us to page through current search results without altering the
     * query in case that user has changed them after the initial search.
     */
    get resultsCriteria() {
        return this.resultsCriteria$.value;
    }

    set resultsCriteria(val: BindingSearchCriteria) {
        this.resultsCriteria$.next(val);
    }

    get bindingRows(): ProcessedBindingSearchResultRow[] | null {
        return this.bindingRows$.value;
    }
    
    set bindingRows(value: ProcessedBindingSearchResultRow[] | null) {
        this.bindingRows$.next(value);
    }

    get componentPartRows(): ProcessedBindingSearchResultRow[] | null {
        return this.componentPartRows$.value;
    }
    
    set componentPartRows(value: ProcessedBindingSearchResultRow[] | null) {
        this.componentPartRows$.next(value);
    }

    get resultTab() {
        return this.resultTab$.value;
    }
    
    set resultTab(val: ResultType) {
        updateIfChanged(this.resultTab$, val);
        // CHART is not supported by component parts view
        if (val === ResultType.COMPONENT_PARTS && this.resultMode === ResultMode.CHART)
            this.resultMode = ResultMode.THUMB;
    }
    
    get page() {
        return this.bindingResultsPage$.value;
    }
    
    set page(value: number) {
        updateIfChanged(this.bindingResultsPage$, value);
    }
    
    get componentPartPage() {
        return this.componentPartResultsPage$.value;
    }

    set componentPartPage(value: number) {
        updateIfChanged(this.componentPartResultsPage$, value);
    }
    
    get resultMode(): ResultMode {
        return this.resultMode$.value;
    }
    
    set resultMode(val: ResultMode) {
        updateIfChanged(this.resultMode$, val);
    }
    
    get pageSize() {
        return PAGE_SIZE;
    }

    constructor(private readonly searchService: SearchService,
                private readonly navigationService: NavigationService,
                public readonly settingsService: SettingsService,
                private readonly bindingSearchApi: BindingSearchRestEndpoint,
                private readonly apinaConfig: ApinaConfig,
                private readonly cd: ChangeDetectorRef,
                private readonly breadcrumbs: BreadcrumbsService,
                translate: TranslateService) {

        breadcrumbs.setBreadcrumbLinks([
            {
                localizedText: translate.instant("main.links.search"),
            }
        ]);
    }

    ngOnInit(): void {
        const nonDefaultState = this.initializeFormState();
        
        // trace tab loading state
        let waitForLoading = true;
        this.subscription.add(combineLatest([this.bindingRows$, this.componentPartRows$]).subscribe(([br, cpr]) => {
            if (br == null && cpr == null) {
                // both are null means new search
                waitForLoading = true;
            } else if (waitForLoading && br != null && cpr != null) {
                // both not null means both searches have finished loading results
                waitForLoading = false;
                this.resultTabsLoaded$.next([br.length, cpr.length]);
            }
        }));
        
        this.subscription.add(this.resultTabsLoaded$.subscribe(([t1, t2]) => {
            if (this.resultTabs == null) return;
            
            if (t1 === 0 && t2 > 0)
                this.resultTabs.select(ResultType.COMPONENT_PARTS);
            else if (t1 > 0 && t2 === 0)
                this.resultTabs.select(ResultType.BINDING_PAGES);
        }));
        
        this.subscription.add(this.bindingResultsBatch$.subscribe((results) => {
            this.results = results;
            this.bindingRows = this.searchService.processBindingRows(results.rows);
            this.cd.detectChanges();
        }));

        this.subscription.add(this.componentPartResultsBatch$.subscribe((results) => {
            this.componentPartResults = results;
            this.componentPartRows = this.searchService.processComponentPartRows(results.rows);
            this.cd.detectChanges();
        }));

        this.subscription.add(this.urlAffectingPropertiesChanged$.subscribe(([crit, page, partPage, resultMode, resultType]) => {
            if (this.resultsCriteria == null) return;

            const queryParams = this.createQueryParams();
            this.updateBreadcrumbs(queryParams);
            this.navigationService.search = queryParams;
        }));

        if (!nonDefaultState) {
            this.defaultSearch();
        } else {
            this.newSearch(false, false);
        }
    }

    /**
     * Initializes state from URL.
     * 
     * @return true if URL contained non-default state parameters
     */
    private initializeFormState(): boolean {
        const params: ParamMap = this.navigationService.search2;
        const searchParams = this.searchService.parseSearchParams(params);
        this.criteria = this.searchService.criteriaFromParams(searchParams);

        const urlResultMode = searchParams.resultMode as ResultMode;
        if (urlResultMode) {
            this.resultMode = urlResultMode;
            this.resultModeIsExplicitlySelected = true;
        }

        if (searchParams.page != null)
            this.page = this.validatePageParameter(searchParams.page, this.criteria.searchForBindings);

        if (isNaN(this.page))
            this.page = 1;
        
        if (searchParams.partPage != null) 
            this.componentPartPage = +searchParams.partPage || 1;
        
        if (searchParams.resultType != null)
            this.resultTab = searchParams.resultType as any;
        
        return params.keys.length > 0;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    excelDownloadUrl(): string | null {
        if (!this.settingsService.commonOptions.excelDownloadEnabled) 
            return null;
        
        const filename = excelFileName("bindings", this.resultsCriteria.query, this.resultsCriteria.startDate, this.resultsCriteria.endDate);
        return `/search/download/bindings/${filename}.xlsx` + buildQueryString({
            criteria: JSON.stringify(this.apinaConfig.serialize(this.resultsCriteria, 'BindingSearchCriteria'))
        });
    }

    public setResultMode(mode: ResultMode) {
        this.resultMode = mode;
        this.resultModeIsExplicitlySelected = true;
    }

    public pageChange(page: number) {
        this.bindingRows = null;
        this.page = page;
    }

    public partPageChange(page: number) {
        this.componentPartRows = null;
        this.componentPartPage = page;
    }

    public onClickOrderBy(orderBy: QueryOrder) {
        this.criteria.orderBy = orderBy;
        this.resultsCriteria.orderBy = orderBy;
        this.bindingRows = null;
        this.componentPartRows = null;
        this.resultsCriteria$.next(this.resultsCriteria);
        this.orderByIsExplicitlySelected = true;
    }

    formatBindingPagesTitle(results: BindingSearchResultsBatch): string {
        return 'niteen sivua';
    }
    
    formatComponentPartsTitle(results: ComponentPartSearchResultsBatch): string {
        return 'niteen osaa';
    }
    
    private defaultSearch() {
        this.resultMode = ResultMode.THUMB;
        this.criteria.orderBy = QueryOrder.IMPORT_DATE;
        this.newSearch(false, false);
    }

    public onFormSubmit(e?: Event) {
        if (e) e.preventDefault();

        const emptyQuery = /^\s*$/.test(this.criteria.query);
        if (!this.resultModeIsExplicitlySelected) {
            this.resultMode = emptyQuery ? ResultMode.THUMB : ResultMode.TEXT_WITH_THUMB;
        }
        
        if (!this.orderByIsExplicitlySelected) {
            this.criteria.orderBy = emptyQuery ? QueryOrder.IMPORT_DATE : QueryOrder.RELEVANCE;
        }
        
        this.newSearch(true, true);
    }
    
    private newSearch(scrollToResults: boolean, resetPaging: boolean) {
        if (resetPaging) {
            this.page = 1;
            this.componentPartPage = 1;
            this.results = null;
            this.componentPartResults = null;
            this.bindingRows = null;
            this.componentPartRows = null;
        }
        
        this.resetResultsCriteria();
        
        if (scrollToResults) {
            const afterNextResultsLoad = this.bindingResultsBatch$.pipe(take(1));
            afterNextResultsLoad.subscribe(() => {
                this.animateScrollTo(this.resultsContainer);
            })
        }
        
        this.triggerFetchResults();
    }
    
    private triggerFetchResults() {
        this.refreshResults$.next();
    }
    
    private getOffset(page: number): number {
        return (page - 1) * PAGE_SIZE;
    }

    private animateScrollTo(target: ElementRef) {
        const nativeElement = target.nativeElement as Element;
        nativeElement.scrollIntoView({behavior: "smooth"});
    }

    private createQueryParams(): Dictionary<string> {
        const resultMode = this.resultModeIsExplicitlySelected ? this.resultMode : null;
        return this.searchService.paramsFromCriteria(this.resultsCriteria, this.page, this.componentPartPage, resultMode, this.resultTab, true);
    }

    private updateBreadcrumbs(params: Dictionary<any>) {
        this.breadcrumbs.setLatestLocation([
            {
                translationKey: "breadcrumbs.search-link",
                commands: [this.navigationService.basePaths.search],
                tooltipKey: "breadcrumbs.tooltip.search",
                queryParams: params
            }
        ]);
    }

    /**
     * Stores active search criteria when search is executed. Active search is only updated when new search is executed, changes in criteria inputs
     * do not change the active criteria.
     */
    private resetResultsCriteria() {

        // Either OCR or meta (or both) search condition must be enabled.
        // Checkboxes should already handle this, but gotta make sure and default to the ocr search.
        if (!this.criteria.queryTargetsOcrText && !this.criteria.queryTargetsMetadata)
            this.criteria.queryTargetsOcrText = true;

        this.resultsCriteria = _.cloneDeep(this.criteria);
    }

    /**
     * Returns page number or max page number if given page number exceeds max page number.
     * If original page from param is invalid, also url param is changed.
     */
    private validatePageParameter(pageParam: number, isBindingAggregate: boolean): number {
        const maxResultSize = isBindingAggregate ? this.settingsService.commonOptions.bindingSearchMaxResults : this.settingsService.commonOptions.searchMaxResults;
        const maxPage = Math.ceil(maxResultSize / this.pageSize);
        if (pageParam > maxPage) {
            this.navigationService.setSearchParam("page", maxPage);
            return maxPage;
        } else {
            return pageParam;
        }
    }

    accessibleTotalResults(total: number): number {
        const maxSize = this.settingsService.commonOptions.searchMaxResults;
        return total > maxSize ? maxSize : total;
    }
}
