import {Component, Input, OnInit} from "@angular/core";

@Component({
    selector: 'app-progress-bar-indicator',
    template: `<span class="progress-bar-indicator"><span [ngStyle]="{width: width}"></span></span>`
})
export class ProgressBarIndicatorComponent implements OnInit {
    width: string;

    @Input() fullness: string;

    ngOnInit(): void {
        const fullness = this.fullness;

        if (fullness === undefined) {
            throw new Error("Attribute fullness must be defined");
        }

        const n = parseFloat(fullness);

        this.width = n > 1 ? "100%" : Math.round(n * 100) + "%";
    }
}
