import {AfterContentInit, ChangeDetectorRef, Directive, ElementRef, Input} from "@angular/core";

/**
 * Not to be confused with the native autofocus attribute of input elements, which only works on initial page-load.
 *
 * TODO for some reason does not seem to work in browse-titles.component.
 */
@Directive({
    selector: "input[appAutofocus]"
})
export class AutofocusDirective implements AfterContentInit {

    @Input() set autofocusDelay(millis: number) {
        this.delay = millis;
    }

    delay: number;

    constructor(private elementRef: ElementRef,
                private cd: ChangeDetectorRef) {
    }

    ngAfterContentInit(): void {
        if (this.delay > 0) {
            setTimeout(() => {
                this.focus();
            }, this.delay);
        } else {
            this.focus();
        }
    }

    private focus() {
        if (this.elementRef.nativeElement instanceof HTMLInputElement) {
            const el = this.elementRef.nativeElement as HTMLInputElement;
            el.focus();
            // hopefully this fixes ExpressionChangedAfterItHasBeenCheckedError that clients have been having
            this.cd.detectChanges();
        }
    }
}
