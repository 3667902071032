import {Component, HostBinding} from "@angular/core";
import {BreadcrumbLink, BreadcrumbsService} from "./breadcrumbs.service";
import {DisplayService} from "../display.service";
import {NavigationService} from "../navigation.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: "app-breadcrumbs",
    template: `
        <ol class="breadcrumb" *ngIf="display" [ngClass]="{'mt-3 mb-3': !displayService.headerMinimized}">
            <li class="breadcrumb-item" *ngIf="displayService.headerMinimized">
                <a [routerLink]="navigationService.basePaths.frontPage"
                   [ngbTooltip]="'breadcrumbs.root.tooltip' | translate" placement="bottom"
                   attr.aria-label="{{'breadcrumbs.root' | translate}}">
                    <i class="fa fa-home"></i>
                </a>
            </li>
            <li class="breadcrumb-item" [ngClass]="{'active': isLinky(item)}" *ngFor="let item of items">
                <span *ngIf="isLinky(item)">
                    <a [routerLink]="item.commands" [queryParams]="item.queryParams" *ngIf="isModern(item)" 
                       [ngbTooltip]="getTooltip(item)" placement="bottom">{{getText(item)}}</a>
                    <a [href]="item.link" *ngIf="!isModern(item)">{{getText(item)}}</a>
                </span>
                <span *ngIf="!isLinky(item)" [ngbTooltip]="getText(item)" placement="bottom-left" 
                      autoClose="false" triggers="manual" #t="ngbTooltip" (click)="t.open()"
                      tooltipClass="kk-tooltip-wide">{{getText(item)}}</span>
            </li>
        </ol>`,
    styleUrls: [
        "./breadcrumbs.scss"
    ]
})
export class BreadcrumbsComponent {
    items: BreadcrumbLink[];

    @HostBinding('class.mobile') mobile = false;

    /**
     * Some clarification:
     *
     * In main categories (frontpage, search view, etc.) We don't usually need to show any breadcrumbs. The cases where
     * we do need to show them are:
     *
     * 1) The header is minimized, hiding the main navigation links. The user needs to see where she is.
     * 2) We are not in the main category pages, but in some subsection of our site that doesn't adhere to the main
     *    navigation categories (such as the old newspaper article index, or footer links, such as /other or /faq).
     *
     * In case (2) we would always want to display breadcrumbs, but we don't have a mechanism to tell when we are in
     * those "non-canonical" pages. And we certainly don't want to show the breadcrumbs in the main categories, such
     * as /collections, because it's really redundant to have "collections" read twice right next to each other. So
     * for now we use a heuristic of: "if breadcrumbs has only one item and it's not a link, assume it's a main category
     * and hide it". This leads to some false positives.
     */
    get display() {
        if (this.displayService.headerMinimized)
            return true;

        if (this.items.length > 1)
            return true;

        return this.items.length === 1 && this.isLinky(this.items[0]);
    }

    constructor(breadcrumbService: BreadcrumbsService,
                public displayService: DisplayService,
                public navigationService: NavigationService,
                private readonly translateService: TranslateService) {

        this.mobile = this.displayService.isMobile;

        breadcrumbService.getBreadcrumbLinks().subscribe(links => {
            this.items = links || [];
        });
    }

    /**
     * Returns true if the breadcrumb item contain an actual link, not just text.
     */
    isLinky(link: BreadcrumbLink): boolean {
        return link.link != null || this.hasCommands(link);
    }
    /**
     * Whether the given link was created in Angular or is legacy (probably server-side) code.
     */
    isModern(link: BreadcrumbLink) {
        return this.hasCommands(link);
    }

    /**
     * Commands that can be given to Angular's routerLink as parameters array.
     */
    hasCommands(link: BreadcrumbLink) {
        return link.commands && link.commands.length > 0;
    }

    getText(item: BreadcrumbLink): string {
        return this.preferTranslationKey(item.translationKey, item.localizedText);
    }

    getTooltip(item: BreadcrumbLink): string {
        return this.preferTranslationKey(item.tooltipKey, item.tooltip);
    }

    private preferTranslationKey(key: string, value: string) {
        if (key)
            return this.translateService.instant(key);
        else
            return value || '';
    }
}
