import {Component} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    template: `

        <div class="modal-header">
            <h4 class="modal-title" translate>help.clipping.title</h4>
            <button type="button" class="close" (click)="activeModal.dismiss()" aria-hidden="true">&times;</button>
        </div>
        <div class="modal-body">
            <div [innerHtml]="'help.clipping.text' | translate"></div>
        </div>
        <div class="modal-footer">
            <div style="margin: auto">
                <button class="btn btn-kk" (click)="activeModal.close()" translate>help.clipping.close</button>
            </div>
        </div>
    `
})
export class ClippingHelpComponent {

    constructor(public activeModal: NgbActiveModal) {}
}
