import {Component, Input} from "@angular/core";
import {ProcessedBindingSearchResultRow, ResultMode} from "./result/result-row";

@Component({
    selector: "app-binding-search-results",
    template: `
        <div *ngIf="items != null else loading">
            <div *ngIf="items.length == 0" class="col text-center mt-3">
                
                <span class="badge badge-danger" style="font-size: 20px;" translate>query.result.no-results</span><br>
                
                <br><p class="" style="white-space:nowrap;" translate>search.info.tip1</p>
                <p class="" translate>search.info.tip2</p><br>
                <p style="" [innerHTML]="'search.info.tip3' | translate"></p>
                <p style="" [innerHTML]="'search.info.usehaka' | translate"></p>
            </div>

            <div class="result row" *ngIf="resultMode != 'CHART'">
                <div *ngFor="let row of items; let idx = index" [ngClass]="resultMode == 'THUMB' ? 'col-6 col-md-4 col-lg-3' : 'col-md-12'">
                    <ng-container [ngSwitch]="resultMode">
                        <app-search-result-text-thumb *ngSwitchCase="'TEXT'" [showThumb]="false" [isAggregation]="isAggregation" [row]="row"></app-search-result-text-thumb>
                        <app-search-result-text-thumb *ngSwitchCase="'TEXT_WITH_THUMB'" [showThumb]="true" [isAggregation]="isAggregation" [row]="row"></app-search-result-text-thumb>
                        <app-search-result-thumb *ngSwitchCase="'THUMB'" [row]="row" [highlightPlacement]="(idx % 4) < 2 ? 'right' : 'left'"></app-search-result-thumb>
                    </ng-container>
                </div>
            </div>
            
            <div class="result row" *ngIf="resultMode == 'CHART'">
                <span class="badge badge-danger">Result mode not supported</span>
            </div>
        </div>
        
        <ng-template #loading>
            <div class="mt-3">
                <app-progress-spinner></app-progress-spinner>
            </div>
        </ng-template>
    `,
    styleUrls: [
        "./binding-search-results.scss"
    ]
})
export class BindingSearchResultsComponent {

    @Input() resultMode = ResultMode;

    @Input() items: ProcessedBindingSearchResultRow[] | null;

    @Input() isAggregation: boolean;
    
    @Input() excelDownloadUrl: string;
}
