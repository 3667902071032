import {DateTimeFormatter, Instant, ZoneId} from "@js-joda/core";

export function formatISOInstant(instant: Instant): string {
    if (!instant) return null;

    return DateTimeFormatter.ISO_INSTANT.format(instant);
}

export function parseISOInstant(instant: string): Instant {
    if (!instant) return null;

    return Instant.parse(instant);
}

export function formatAsLocalDateTime(instant: Instant): string {
    if (!instant)
        return "";

    const localDateTime = instant.atZone(ZoneId.systemDefault()).toLocalDateTime();
    return DateTimeFormatter.ofPattern("dd.MM.yyyy HH.mm").format(localDateTime);
}
