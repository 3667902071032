import {DateAccuracy, VariableAccuracyDate} from "../apina-digiweb";
import {DateTimeFormatter, LocalDate} from "@js-joda/core";

// We allow single digit month and days here, since so many people get errors with them
// TODO trace the actual source
const ISO_DATE_PATTERN = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
// TODO I don't think 'european' is exactly what this is...
const EUROPEAN_DATE_PATTERN = /^([\d.]+)?-([\d.]+)?$/;

function asInt(s: string): number {
    return parseInt(s, 10);
}

export function currentDate(): LocalDate {
    return LocalDate.now();
}

export function previousDay(date: LocalDate): LocalDate {
    return date.minusDays(1);
}

export function nextDay(date: LocalDate): LocalDate {
    return date.plusDays(1);
}

export function parseEuropeanDate(s: string): LocalDate|null {
    try {
        if (!s) return null;

        const parts = s.match(/^(\d{1,2})\.(\d{1,2})\.(\d{4})$/);

        if (parts != null) {
            const year = asInt(parts[3]);
            const month = asInt(parts[2]);
            const day = asInt(parts[1]);
            return LocalDate.of(year, month, day);
        } else
            return null;
    } catch (err) {
        console.error(err);
        return null;
    }
}

export function parseEuropeanDateRange(value: string): { start: LocalDate; end: LocalDate } {
    if (!value) return null;

    const match = EUROPEAN_DATE_PATTERN.exec(value);
    if (match) {
        return {
            start: match[1] && parseEuropeanDate(match[1]),
            end: match[2] && parseEuropeanDate(match[2])
        };
    }

    return null;
}

export function formatEuropeanDate(date: LocalDate): string {
    if (!date) return null;

    return DateTimeFormatter.ofPattern("dd.MM.yyyy").format(date);
}

export function formatUSDate(date: LocalDate): string {
    if (!date) return null;

    return DateTimeFormatter.ofPattern("dd/MM/yyyy").format(date);
}

export function formatCompactDate(date: LocalDate): string {
    if (!date) return null;

    return DateTimeFormatter.ofPattern("yyyyMMdd").format(date);
}

export function formatISODate(localDate: LocalDate): string {
    if (!localDate) return null;

    return DateTimeFormatter.ISO_LOCAL_DATE.format(localDate);
}

export function formatVariableAccuracyDate(date: VariableAccuracyDate): string {
    if (!date) return null;

    const dateAccuracy = getDateAccuracy(date);

    switch (dateAccuracy) {
        case DateAccuracy.DATE:
            return formatEuropeanDate(LocalDate.of(date.year, date.month, date.day));
        case DateAccuracy.MONTH:
            return formatMonthYear(firstPossibleDate(date));
        case DateAccuracy.YEAR:
            return "" + date.year;
        default:
            throw Error(`unsupported date accuracy: ${dateAccuracy}`);
    }
}

export function formatMonthYear(localDate: LocalDate): string {
    if (!localDate) return null;

    return DateTimeFormatter.ofPattern("MM.yyyy").format(localDate);
}

export function parseISODate(localDate: string): LocalDate {
    if (!localDate) return null;

    const match = ISO_DATE_PATTERN.exec(localDate);

    if (!match)
        throw Error("Invalid date: " + localDate);

    return LocalDate.of(
        asInt(match[1]),
        asInt(match[2]),
        asInt(match[3])
    );
}

/**
 * Returns true if first argument is before second argument.
 */
export function isAfter(lhs: LocalDate, rhs: LocalDate): boolean {
    return lhs.isAfter(rhs);
}

export function isBefore(lhs: LocalDate, rhs: LocalDate): boolean {
    return lhs.isBefore(rhs);
}

export function toVariableAccuracyDate(date: LocalDate, dateAccuracy: DateAccuracy): VariableAccuracyDate {
    switch (dateAccuracy) {
        case DateAccuracy.DATE:
            return {
                year: date.year(),
                month: date.monthValue(),
                day: date.dayOfMonth()
            };
        case DateAccuracy.MONTH:
            return {
                year: date.year(),
                month: date.monthValue(),
                day: null
            };
        case DateAccuracy.YEAR:
            return {
                year: date.year(),
                month: null,
                day: null
            };
        default:
            throw Error("unsupported date accuracy: " + dateAccuracy);
    }
}


export function variableAccuracyDate(year: number, month: number, day: number): VariableAccuracyDate {
    return {
        year,
        month,
        day
    };
}

export function getDateAccuracy(val: VariableAccuracyDate): DateAccuracy {
    if (val.month == null && val.day == null) {
        return DateAccuracy.YEAR;
    } else if (val.day == null) {
        if (val.month == null)
            throw Error("if day is defined, month cannot be null");
        return DateAccuracy.MONTH;
    } else {
        return DateAccuracy.DATE;
    }
}

export function firstPossibleDate(vad: VariableAccuracyDate): LocalDate {
    return LocalDate.of(
        vad.year,
        vad.month || 1,
        vad.day || 1
    );
}

export function firstDateOfYear(year: number): LocalDate {
    return LocalDate.of(
        year,
        1,
        1
    );
}

export function lastDateOfYear(year: number): LocalDate {
    return LocalDate.of(
        year,
        12,
        31
    );
}
