import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";

export interface ISearchDrawerParameters {
    tab?: string;
}

@Injectable()
export class SearchDrawerService {

    private _parameters = new BehaviorSubject<ISearchDrawerParameters>({});

    setParameters(params: ISearchDrawerParameters) {
        if (params == null)
            throw Error("params cannot be null");

        this._parameters.next(params);
    }

    get parameters(): Observable<ISearchDrawerParameters> {
        return this._parameters.asObservable();
    }
}
