import {IPopoverItem, IPopoverItemProvider, IPopoverResult} from "./popover-picker-async.component";
import {Observable} from "rxjs";
import {escapeRegExp} from "../../utils/escape";
import {map} from "rxjs/operators";
import {BasicPopoverItem} from "../../apina-digiweb";

export class ItemAdapter<T> {
    createQueryFilter: (query: string) => (a: T) => boolean;
    extractId: (result: T) => string;
    mapResult: (result: T) => IPopoverItem;
}

export abstract class InMemoryProvider<T> implements IPopoverItemProvider {

    protected constructor(private readonly allResults$: Observable<T[]>,
                          private readonly adapter: ItemAdapter<T>) {
    }

    fetch(query: string, selectedIds: string[], maxResults: number): Observable<IPopoverResult> {
        const self = this;
        
        return this.allResults$.pipe(map((keywords) => {
            const filterPredicate = self.adapter.createQueryFilter(query);
            const selectionPredicate = (a: T) => selectedIds.length === 0 ? false : selectedIds.includes(self.adapter.extractId(a));

            const selectedIdResults = keywords.filter(kw => selectionPredicate(kw));
            const queryResults = keywords.filter(kw => filterPredicate(kw)).filter(kw => !selectionPredicate(kw));
            const totalMatches = queryResults.length;
            queryResults.splice(maxResults); // modifies the array
            return {
                selectedIds: selectedIdResults.map(a => self.adapter.mapResult(a)),
                queryResults: queryResults.map(a => self.adapter.mapResult(a)),
                totalResults: totalMatches,
                totalPossibleResults: keywords.length
            }
        }));
    }

    matches(item: IPopoverItem): boolean {
        // TODO support external filtering
        return true;
    }
}

export class StringInMemoryProvider extends InMemoryProvider<string> {
    constructor(allResults$: Observable<string[]>) {
        super(allResults$,
            {
                createQueryFilter: (q) => createStringFilter(q),
                extractId: (a: string) => a,
                mapResult: (a: string) => ({id: a, title: a})
            });
    }
}

export class BasicInMemoryProvider extends InMemoryProvider<BasicPopoverItem> {
    constructor(allResults$: Observable<BasicPopoverItem[]>) {
        super(allResults$,
            {
                createQueryFilter: (q) => createBasicItemFilter(q),
                extractId: (a: BasicPopoverItem) => a.id,
                mapResult: (a: BasicPopoverItem) => (a)
            });
    }
}

function createStringFilter(query: string): (a: string) => boolean {
    if (query?.length > 0) {
        const regex = createLowercaseMatcher(query);
        return a => regex.test(a);
    } else {
        return () => true;
    }
}

function createBasicItemFilter(query: string): (a: BasicPopoverItem) => boolean {
    if (query?.length > 0) {
        const regex = createLowercaseMatcher(query);
        return a => regex.test(a.title) || regex.test(a.info) || regex.test(a.info2);
    } else {
        return () => true;
    }
}

function createLowercaseMatcher(query: string): RegExp {
    return new RegExp(escapeRegExp(query), 'i');
}