import {Component, OnDestroy, OnInit} from "@angular/core";
import {QueryOrder, SerialPublicationRestEndpoint} from "../../apina-digiweb";
import {TranslateService} from "@ngx-translate/core";
import {NavigationService} from "../navigation.service";
import {BreadcrumbLink, BreadcrumbsService} from "../breadcrumbs/breadcrumbs.service";
import {SearchDrawerService} from "../nav/search-drawer.service";
import {ActivatedRoute} from "@angular/router";
import {TitleMarkingsService} from "./title/title-markings.service";
import {publishBehavior, switchMap} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {Subscription} from "rxjs";

type DisplayMode = 'CALENDAR' | 'THUMB' | 'LIST' | 'MARK';
type Context = 'sanomalehti' | 'aikakausi';

const DISPLAY_MODES: DisplayMode[] = ['CALENDAR', 'THUMB', 'LIST', 'MARK'];
const SUPPORTED_CONTEXTS: Context[] = ['sanomalehti', 'aikakausi'];

interface IViewOptions {
    display: DisplayMode;
    year: number;
}

@Component({
    selector: "app-browse-issues-by-year",
    template: `
        <app-progress-bar *ngIf="initializing"></app-progress-bar>
        
        <section class="container">
            <div *ngIf="!initializing">
                <app-sticky-header [modifyScrollingOffset]="50" (isScrolling)="isScrolling($event)">
                    <div class="browse-issues-header" [ngClass]="{'scrolling-position': scrollingPosition}">
                        <div>
                            <h3 class="calendar-title" style="display:inline">{{title}}&nbsp;<app-title-info [context]="context" [issn]="issn"></app-title-info>&nbsp;<span
                                    style="display:inline;text-transform:none">{{issn}}</span></h3>
                        </div>

                        <div class="d-flex align-items-center justify-content-between flex-wrap controls" *ngIf="accessible">
                            <div>
                                <mat-button-toggle-group name="displayMode" attr.aria-label="{{'browser.select-display-mode' | translate}}" [formControl]="viewModeControl">
                                    <mat-button-toggle value="CALENDAR" aria-label="{{'browser-cal.label' | translate}}"><i class="fa fa-calendar"></i></mat-button-toggle>
                                    <mat-button-toggle value="THUMB" aria-label="{{'browser-thumb.label' | translate}}"><i class="fa fa-th"></i></mat-button-toggle>
                                    <mat-button-toggle value="LIST" *ngIf="canMarkProblems" aria-label="{{'browser-list.label' | translate}}"><i class="fa fa-bars"></i></mat-button-toggle>
                                    <mat-button-toggle value="MARK" *ngIf="canMarkProblems" title="{{'title.mark-missing-binding' | translate}}" aria-label="{{'browser-mark.label' | translate}}"><i class="fa fa-pencil"></i></mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>

                            <div class="d-flex align-items-center">
                                <button type="button" class="btn btn-kk-blue" [disabled]="!hasPreviousYear()" (click)="selectPreviousYear()"
                                        attr.aria-label="{{'browser-prev.label' | translate}}">
                                    <i class="fa fa-caret-left unselectable"></i></button>

                                <select [(ngModel)]="viewOptions.year" (change)="viewOptionsChanged()" name="year" class="form-control year-selection" attr.aria-label="{{'browser-cal-years' | translate}}">
                                    <option *ngFor="let val of years" [ngValue]="val">{{val}}</option>
                                </select>

                                <button type="button" class="btn btn-kk-blue" [disabled]="!hasNextYear()" (click)="selectNextYear()" attr.aria-label="{{'browser-next.label' | translate}}">
                                    <i class="fa fa-caret-right unselectable"></i></button>
                            </div>

                            <button class="btn btn-kk-gray" (click)="addMarking()" *ngIf="canMarkProblems">
                                <i class="fa fa-pencil mr-1" style="color: darkorange"></i>{{'year-binding-list.new-marking' | translate}}
                            </button>
                        
                            <div>
                                <div class="title-search d-flex align-items-center">
                                    <form #form="ngForm">
                                        <div class="d-flex justify-content-between">
                                            <label for="srchfi1" class="sr-only" hidden>{{'frontpage.search.vb' | translate}}</label>
                                            <input id="srchfi1" type="text" value="" class="form-control" appAutofocus appValidQuerySyntax [(ngModel)]="query" name="query"
                                                   [placeholder]="'title.search.placeholder' | translate" [innerHTML]>
                                            <button class="btn btn-kk-blue btn-block ml-3" style="width: 9rem; max-width: 20%;" (click)="gotoSearch($event)" [disabled]="form.invalid"
                                                    [ngbTooltip]="'form.search.submit' | translate"><i class="fa fa-search fa-lg" title="'form.search.submit' | translate"></i><span
                                                    class="sr-only">{{'form.search.submit'|translate}}</span></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </app-sticky-header>

                <div [ngSwitch]="viewOptions.display" class="browse-issues-content" *ngIf="!loading">

                    <div *ngSwitchCase="'THUMB'">
                        <app-thumbs-by-month
                                [thumbsByMonth]="true"
                                [year]="viewOptions.year"
                                [issn]="issn">
                        </app-thumbs-by-month>
                    </div>

                    <div *ngSwitchCase="'CALENDAR'">
                        <app-issue-calendar
                                [issn]="issn"
                                [year]="viewOptions.year">
                        </app-issue-calendar>
                    </div>

                    <div *ngSwitchCase="'LIST'">
                        <app-year-binding-list *ngIf="canMarkProblems else accessDenied"
                                               [issn]="issn"
                                               [year]="viewOptions.year">
                        </app-year-binding-list>
                    </div>

                    <div *ngSwitchCase="'MARK'">
                        <app-issue-calendar-mark *ngIf="canMarkProblems else accessDenied"
                                                 [issn]="issn"
                                                 [year]="viewOptions.year">

                        </app-issue-calendar-mark>
                    </div>
                </div>
            </div>
        </section>

        <ng-template #accessDenied>
            <div class="alert alert-warning" translate>error.access-denied</div>
        </ng-template>
    `,
    providers: [
        TitleMarkingsService
    ],
    styleUrls: [
        "./browse-issues-by-year.scss"
    ]
})
export class BrowseIssuesByYearComponent implements OnInit, OnDestroy {

    initializing = true;

    query = "";

    context: Context;
    defaultDisplayMode: DisplayMode = "THUMB";
    issn: string;
    thumbsByMonth = false;

    scrollingPosition: boolean;

    title: string;

    /** years in descending order */
    years: number[];

    canMarkProblems = false;
    validYear = true;

    viewOptions: IViewOptions = {
        display: "CALENDAR",
        year: null
    };

    viewModeControl = new FormControl(this.viewOptions.display);
    
    loading = false;
    
    private sub = new Subscription();

    constructor(private readonly serialPublicationEndpoint: SerialPublicationRestEndpoint,
                private readonly translateService: TranslateService,
                private readonly navigationService: NavigationService,
                private readonly searchDrawerService: SearchDrawerService,
                private readonly breadcrumbsService: BreadcrumbsService,
                private readonly titleMarkingsService: TitleMarkingsService,
                route: ActivatedRoute) {
        const routeParams = route.snapshot.params;
        this.context = this.parseContext(routeParams.context);
        this.issn = routeParams.publicationId;

        this.searchDrawerService.setParameters({
            tab: 'titles'
        });
    }

    ngOnInit(): void {
        this.serialPublicationEndpoint.availableYears(this.issn).subscribe(result => {
            this.title = result.title;
            this.canMarkProblems = result.canMarkProblems;
            this.years = result.years.reverse();
            this.viewOptions.year = this.years[0];
            this.initializing = false;

            this.resetViewOptionsFromURL();
            this.sub.add(this.viewModeControl.valueChanges.subscribe(mode => {
                this.setDisplayMode(mode);
            }));

            this.setBreadcrumbs();
            this.updateBreadcrumbs();
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    get accessible(): boolean {
        return this.years && this.years.length > 0;
    }

    gotoSearch($event: Event) {
        $event.preventDefault();

        this.navigationService.goTo2([this.navigationService.basePaths.search], {
            query: this.query,
            title: this.issn,
            startDate: this.viewOptions.year + '-01-01',
            endDate: this.viewOptions.year + '-12-31',
            orderBy: QueryOrder.RELEVANCE

        });
    }

    isScrolling(scrolling: boolean) {
        this.scrollingPosition = scrolling;
    }

    viewOptionsChanged() {
        this.updateLocation();
        this.updateBreadcrumbs();
    }

    setDisplayMode(mode: DisplayMode) {
        this.viewOptions.display = mode;
        this.viewOptionsChanged();
    }

    updateLocation(replaceUrl: boolean = false) {
        this.navigationService.setSearch(this.viewOptions, replaceUrl);

        if (!this.viewOptions.year)  {
            this.viewOptions.year = this.years[-1];
        }
    }

    hasPreviousYear() {
        return this.viewOptions.year && this.getCurrentYearIndex() < this.years.length - 1;
    }

    hasNextYear() {
        return this.viewOptions.year && this.getCurrentYearIndex() > 0;
    }

    selectPreviousYear() {
        const nextIndex = this.getCurrentYearIndex() + 1;
        if (nextIndex < this.years.length) {
            this.viewOptions.year = this.years[nextIndex];
        }
        this.viewOptionsChanged();
    }

    selectNextYear() {
        const previousIndex = this.getCurrentYearIndex() - 1;
        if (previousIndex >= 0) {
            this.viewOptions.year = this.years[previousIndex];
        }
        this.viewOptionsChanged();
    }

    getCurrentYearIndex() {
        return this.years.indexOf(this.viewOptions.year, 0);
    }

    resetViewOptionsFromURL() {
        const search = this.navigationService.search;
        let year = +search.year;

        if (!year || !this.years.includes(year)) {
            year = this.years[0];
            setTimeout(() => {
                publishBehavior('smooth');
                this.updateLocation(true);
            });
        }

        this.viewOptions = {
            display: search.display || this.defaultDisplayMode,
            year
        };
        
        this.viewModeControl.setValue(this.viewOptions.display);
    }

    parseContext(ctx: string): Context {
        if (!SUPPORTED_CONTEXTS.includes(ctx as Context)) {
            throw new Error("Unsupported context: " + ctx);
        }

        return ctx as Context;
    }

    getDefaultDisplayMode(display: any) {
        if (display && !DISPLAY_MODES.includes(display)) {
            throw new Error("Unsupported display mode: " + display);
        }
        return display || 'THUMB';
    }

    private setBreadcrumbs() {
        const breadcrumbs = [
            this.breadcrumbsService.createLocalizedBreadcrumbLink('serialpublications.main.header', [this.navigationService.basePaths.serialPublications]),
            this.breadcrumbsService.createBreadcrumbLink(this.title, [])
        ];

        this.breadcrumbsService.setBreadcrumbLinks(breadcrumbs);
    }

    private updateBreadcrumbs() {
        // todo we should remember the filters on this page also?
        const category: BreadcrumbLink = {
            translationKey: "serialpublications.main.header",
            commands: [this.navigationService.basePaths.serialPublications]
        };

        this.breadcrumbsService.setLatestLocation([
            category
        ]);
    }

    addMarking() {
        this.titleMarkingsService.showVariableAccuracyDateDialog(this.viewOptions.year).pipe(
            switchMap(date => this.titleMarkingsService.showMissingBindingInfoDialog(this.issn, date))
        ).subscribe(() => {
            this.refreshContent();
        }, () => {
            // usually cancellation
        });
    }

    refreshContent() {
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        });
    }
}
