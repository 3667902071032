import {Component, Input} from "@angular/core";
import {ArticleRestEndpoint} from "../../apina-digiweb";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-article-notes',
    template: `
        <div class="personal-notes">
            <h4 class="tip" translate>personal-notes.title</h4>

            <app-progress-spinner *ngIf="loading"></app-progress-spinner>
            <form *ngIf="!loading">
                <div class="form-group">
                    <textarea class="form-control" rows="5" cols="40" [(ngModel)]="notes" name="notes"></textarea>
                </div>

                <div class="form-group">
                    <input type="submit" class="btn btn-sm btn-kk-light btn-block" [disabled]="saveDisabled()" (click)="saveNotes()" [value]="saveText()">
                </div>
            </form>
        </div>
    `
})
export class ArticleNotesComponent {

    private _articleId: number;

    @Input() set articleId(value: number) {
        this._articleId = value;

        this.articleRest.loadNotes(this._articleId).toPromise().then(n => {
            this.hasNotes = n.notes != null;
            this.notes = this.originalNotes = n.notes;
            this.loading = false;
        });
    }

    hasNotes: boolean;
    originalNotes: string;
    notes: string;
    saving = false;
    loading = true;

    constructor(private translateService: TranslateService,
                private articleRest: ArticleRestEndpoint) {
    }

    saveDisabled() {
        return this.saving || (this.hasNotes && this.notes === this.originalNotes);
    }

    saveText() {
        const key = this.saving ? "common.action.saving"
            : this.hasNotes ? "personal-notes.save-existing"
                : "personal-notes.save-fresh";

        return this.translateService.instant(key);
    }

    saveNotes() {
        this.saving = true;

        this.articleRest.saveNotes(this._articleId, {
            notes: this.notes
        }).toPromise().then(() => {
            this.hasNotes = true;
            this.originalNotes = this.notes;
            this.saving = false;
        }, () => {
            this.saving = false;
        });
    }
}
