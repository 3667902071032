import {BindingSearchResultRow, Dictionary, GeneralType, UserArticleRow} from "../../../apina-digiweb";
import {LocalDate} from "@js-joda/core";
import {PublicationId} from "../../../apina-types";

export enum ResultMode {TEXT = "TEXT", TEXT_WITH_THUMB = "TEXT_WITH_THUMB", THUMB = "THUMB", CHART = "CHART"}

export enum ResultType { BINDING_PAGES = "BINDING_PAGES", COMPONENT_PARTS = "COMPONENT_PARTS" }

export class ProcessedBindingSearchResultRow implements BindingSearchResultRow {
    authors: string[];
    bindingId: number;
    bindingTitle: string;
    
    // to be used instead of bindingTitle, can be either bindingTitle or componentPartTitle
    mainLinkTitle: string;
    
    // in case of component part, the binding it belongs to
    belongsToTitle: string | null;
    
    date: LocalDate | null;
    dateAccuracy: string | null;
    importDate: LocalDate | null;
    generalType: GeneralType;
    issue: string | null;
    pageNumber: number;
    placeOfPublication: string | null;
    publicationId: PublicationId;
    publisher: string | null;
    score: number | null;
    terms: string[];
    textHighlights: Dictionary<string[]>;
    thumbnailUrl: string;
    url: string;
    authorized: boolean;
    language: string[];

    captionTitle: string;
    authorList: string;
    highLights: string[];
}

export interface ProcessedUserArticleRow extends UserArticleRow {
    highLights: string[];
    displayOCR: boolean;
}
