import {Component} from "@angular/core";
import {SettingsService} from "../settings.service";
import {currentDate} from "../../utils/date";
import {NavigationService} from "../navigation.service";

@Component({
    selector: "app-footer",
    template: `
        <footer>
            <div class="extended-info kk-bg-dark">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-3">
                            
                            <img alt="{{'main.title'|translate }}" title="{{'main.title'|translate }}" [src]="settingsService.logoPathLight"
                                 [style.height.px]="95" [style.padding-right.px]="12">
                            
                            <div class="address-info" style="margin-top: 1.4rem">
                            <div translate>footer.email.title</div> 
                            <div><a href="mailto:{{'organization.email'|translate}}">{{'organization.email'|translate}}</a></div>
                            </div>
                            
                            <div class="address-info"  style="margin-top: 1.4rem">
                                <div translate>footer.address</div>
                                <div class="org-name">{{'main.title'|translate }}</div>
                                PL&nbsp;15&nbsp;<br/>
                                00014&nbsp;{{'footer.org'|translate }}<br/>
                                
                            </div>
                        </div>
                        <div class="col-12 col-md-3">
                            <h4 translate>footer.header</h4>
                            <div translate style="margin-top: 1rem">footer.description</div>
                            <p></p>
                            <p>&copy; &#8203;2001&#8211;&#8203;{{copyrightYear}}&nbsp;&#8203;<a href="{{'homepage.link' | translate}}" title="{{'homepage.title' | translate}}">{{'main.title' | translate}}</a>
                            </p>
                            <span class="kk-footer-link build" *ngIf="buildNumber">{{buildNumber}}</span>
                        </div>
                        <div class="col-12 col-md-3">
                            <h4 translate>footer.links.title</h4>
                            <a class="kk-footer-link icon arrow" [href]="basePaths.faqPage"
                                title="{{'faq' | translate}}">{{'faq.name' | translate}}</a>
                            <a class="kk-footer-link icon arrow" [href]="basePaths.termsPage"
                               title="{{'main.terms.terms_of_use' | translate}}">{{'main.terms.terms_of_use' | translate}}</a>
                            <a class="kk-footer-link icon arrow" [href]="basePaths.infoPage"
                               title="{{'main.info.service' | translate}}">{{'main.info.service' | translate}} </a>
                            <a class="kk-footer-link icon arrow" [href]="basePaths.accessibilityPage"
                               title="{{'main.accessibility.label' | translate}}">{{'main.accessibility.label' | translate}}</a>
                            <a class="kk-footer-link icon arrow" [href]="basePaths.tutkainPage"
                               title="{{'tutkain.page.title' | translate}}">{{'tutkain.page.title' | translate}}</a>
                            <a class="kk-footer-link icon arrow" [href]="basePaths.legacyNewsArticles"
                               title="{{'legacy-news-articles.footer.title' | translate}}">{{'legacy-news-articles.footer.title' | translate}}</a>
                            <a class="kk-footer-link icon arrow" [href]="basePaths.openData"
                               title="{{'opendata.footer.title' | translate}}">{{'opendata.footer.title' | translate}}</a>
                            <a class="kk-footer-link icon arrow" [href]="basePaths.otherPage"
                               title="{{'others.main.header' | translate}}">{{'others.main.header' | translate}} </a>
                            <a class="kk-footer-link icon arrow" [href]="basePaths.statsPage"
                               title="{{'main.stats' | translate}}">{{'main.stats' | translate}} </a>
                            <a class="kk-footer-link icon arrow" href="/name-search"
                               title="{{'footer.namesearch'|translate}}">{{'footer.namesearch'|translate}}</a>
                            <a class="kk-footer-link icon arrow" [href]="basePaths.cookieConsentPage"
                               title="{{'cookies.footer.linkText' | translate}}">{{'cookies.footer.linkText' | translate}} </a>
                            <a class="kk-footer-link icon arrow" href="/sivustokartta"
                               title="{{'sitemap.title'|translate}}">{{'sitemap.title'|translate}}</a>
                            
                        </div>
                        <div class="col-12 col-md-3">
                            <h4 translate>footer.follow</h4>
                            
                            <a class="kk-footer-somelink" href="{{'footer.some.fb' | translate}}"
                               title="{{'footer.some.fbtitle' | translate}}">
                                <i class="fa fa-facebook-f fa-fw" aria-hidden="true"></i>&nbsp;
                                {{'footer.some.fbtitle' | translate}}
                                &nbsp;<i class="fa-regular fa-arrow-up-right-from-square fa-fw"></i></a>

                            <hr style="border: 0.1px dashed darkslateblue; border-radius: 1px;">
                            
                            <a class="kk-footer-somelink" href="{{'footer.github.link' | translate}}"
                               title="{{'footer.github.title' | translate}}">
                                <i class="fa fa-github fa-fw" aria-hidden="true"></i>&nbsp;
                                {{'footer.github.title' | translate}}
                                &nbsp;<i class="fa-regular fa-arrow-up-right-from-square fa-fw"></i></a>



                            <hr style="border: 0.1px dashed darkslateblue; border-radius: 1px;">
                            <span class="build" title="Digin aihetunniste">#{{'hashtag.digi' | translate}}</span>

                            
                        </div>

                    </div>
                </div>
            </div>

            
        </footer>
    `,
    styleUrls: [
        "./footer.scss"
    ]
})
export class FooterComponent {

    copyrightYear = currentDate().year();
    buildNumber: string;

    constructor(public settingsService: SettingsService,
                private navigationService: NavigationService) {
        this.buildNumber = this.settingsService.buildNumberAbbr;
    }

    get basePaths() { return this.navigationService.basePaths; }

}
