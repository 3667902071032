import {Component} from "@angular/core";
import {
    AddOpenDataUsageReferenceDTO,
    OpenDataAvailableCategoryAndPackageDTO,
    OpenDataRequestDTO,
    OpenDataRestEndpoint
} from "../../apina-digiweb";
import * as _ from "lodash";
import {SettingsService} from "../settings.service";

@Component({
    selector: "app-opendata-submit",
    template: `
        <section class="container">
            <h2>{{'opendata.title' | translate}}</h2>
            
            <div>
                <form class="form-horizontal" #form="ngForm">
            
                   <p class="form-group" [innerHTML]="'opendata.form.long-description' | translate"></p>
        
                    <div *ngFor="let category of categories" class="form-group">
                        <div class="checkbox">
                            <i class="mr-2" [ngClass]="category.packagesExtended ? 'fa fa-chevron-circle-down fa-2x' : 'fa fa-chevron-circle-right fa-2x'" (click)="togglePackagesVisibility(category)"></i>
                            <label>
                                <input type="checkbox" [(ngModel)]="category.checked" (change)="toggleCategory(category)"
                                       [disabled]="formDisabled()" name="category-{{category.id}}"/> 
                                {{(('opendata.category.' + category.localizationKey) | translate) + ' (' + ('opendata.form.packet-amount' | translate) + ' ' + category.packages.length + ')'}}
                            </label>
                        </div>
                        <div style="width: auto;height: auto;overflow:auto;max-height: 300px;">
                            <div class="category-packages"
                                 [ngClass]="{'opendata_promote_tools': category.id == 10}"
                                 *ngFor="let pack of category.packages" [hidden]="!category.packagesExtended">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox"
                                               [(ngModel)]="pack.checked" [disabled]="formDisabled()" name="package-{{pack.id}}"/>
                                        {{pack.title}} <span class="text-muted">[v{{pack.version}}]</span> <span class="fileSize">({{pack.fileSize | fileSize}})</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
        
                    
                    <div class="row" *ngIf="!includeExternalResources">
                        <p class="alert alert-warning" align="center" [innerHTML]="'opendata.legaldepositworkstation.warning' | translate"></p>
                    </div>
                
                    <div *ngIf="includeExternalResources">
                        <hr class="form-group"/>
        
                        <p class="form-group" translate>opendata.form.description</p>
        
                        <div class="form-group">
                            <label class="col-md-2 col-form-label">{{'opendata.form.columns.description' | translate}} <span class="required-indicator" title="Pakollinen tieto">*</span></label>
                            <div class="col-md-4">
                                <textarea [(ngModel)]="usageModel.description" class="form-control" required maxlength="1000" [disabled]="formDisabled()" name="usageDesc"
                                          attr.aria-label="{{'opendata.form.columns.description' | translate}}"></textarea>
                            </div>
                        </div>
        
                        <div class="form-group">
                            <label class="col-md-2 col-form-label">{{'opendata.form.columns.link' | translate}}</label>
                            <div class="col-md-4">
                                <input type="text" class="form-control" [(ngModel)]="usageModel.externalUrl" placeholder="https://your.site.com" [disabled]="formDisabled()" name="externalUrl"
                                       attr.aria-label="{{'opendata.form.columns.link' | translate}}"/>
                            </div>
                        </div>
        
                        <div class="form-group">
                            <div class="col-md-4">
                                <label class="col-md-2 col-form-label" >{{'opendata.form.columns.email' | translate}}</label>
                                <input type="text" class="form-control" [(ngModel)]="usageModel.email" placeholder="you@site.com" [disabled]="formDisabled()" name="email"
                                       attr.aria-label="{{'opendata.form.columns.email' | translate}}"/>
                            </div>
                            <div class="col-md-4">{{'opendata.form.columns.email-description' | translate}}</div>
                        </div>
        
                        <div class="form-group">
                            <div class="offset-md-2 col-md-4">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" required [(ngModel)]="acceptTerms" [disabled]="formDisabled()" name="acceptTerms"
                                               attr.aria-label="{{'opendata.form.accept-conditions' | translate}}">
                                        <span  class="ml-1" 
                                               [innerHTML]="'opendata.form.accept-conditions' | translate"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
        
                        <hr/>
        
                        
                        <div class="form-group">
                            <div class="col-md-2">
                                <button class="btn btn-kk-blue btn-block" (click)="submit()" [disabled]="form.invalid || !canSubmit()" translate
                                        attr.aria-label="{{'opendata.form.submit' | translate}}">opendata.form.submit</button>
                            </div>
                        </div>
                    </div>
                    </form>
        
                    <div *ngIf="generatedHash" class="alert alert-success row">
                        {{'opendata.form.generated-link-description' | translate}}: <a href="/opendata/requested-packages/{{generatedHash}}">{{generatedHash}}</a>
                    </div>
                    <div class="alert alert-danger" *ngIf="submitError" translate>opendata.form.send-failure</div>
            </div>
        
            <a routerLink="/opendata" translate>opendata.form.back-to-listing</a>
            <div><img src="/images/digi_opendata_narrowb.jpg" alt="">
            </div>
        </section>
    `
})
export class OpendataSubmitComponent {

    public categories: ISelectableCategory[];
    public usageModel: AddOpenDataUsageReferenceDTO = {description: "", email: undefined, externalUrl: undefined};
    public acceptTerms = false;
    public reCaptchaResponse: string;
    public submitting = false;
    public submitError = false;
    public generatedHash: string;

    constructor(private api: OpenDataRestEndpoint,
                public readonly settingsService: SettingsService) {
        api.getPackages().subscribe(response => {
            this.categories = toViewModel(response);
        });
    }

    get includeExternalResources() {
        return this.settingsService.commonOptions.includeExternalResources;
    }

    // TODO sync between category selection and package selection is incomplete
    public toggleCategory(category: ISelectableCategory) {
        const value = category.checked;

        for (const pack of category.packages) {
            pack.checked = value;
        }
    }

    public togglePackagesVisibility(category: ISelectableCategory) {
        category.packagesExtended = !category.packagesExtended;
    }

// && this.reCaptchaResponse != null;
    public canSubmit(): boolean {
        return !this.submitting
            && !this.generatedHash
            && this.acceptTerms
            && _.some(this.categories, c => _.some(c.packages, p => p.checked))

    }

    public formDisabled() {
        return this.submitting || this.generatedHash;
    }

    public submit() {
        this.submitting = true;
        this.api.submitRequest(this.getApiModel()).toPromise().then(response => {
            this.submitting = false;
            this.generatedHash = response.hash;
        }, () => {
            this.submitError = true;
            this.submitting = false;
        });
    }

    private getApiModel(): OpenDataRequestDTO {
        return {
            packageIds: this.getSelectedPackageIds(),
            usageReference: this.usageModel,
            reCaptchaResponse: this.reCaptchaResponse
        };
    }

    private getSelectedPackageIds(): number[] {
        const selectedPackages = _.filter(_.flatMap(this.categories, c => c.packages), p => p.checked);
        return _.map(selectedPackages, p => p.id);
    }
}

interface ISelectableCategory {
    checked: boolean;
    id: number;
    localizationKey: string;
    packages: ISelectablePackage[];
    packagesExtended: boolean;
}

interface ISelectablePackage {
    checked: boolean;
    id: number;
    title: string;
    fileSize: number;
    version: number;
}

function toViewModel(apiDTOCategories: OpenDataAvailableCategoryAndPackageDTO[]): ISelectableCategory[] {
    return _.map(apiDTOCategories, c => {
        return {

            packagesExtended: c.availablePackages.length < 10,
            checked: false,
            id: c.id,
            localizationKey: c.localizationKey,
            packages: _.map(c.availablePackages, p => {
                return {
                    checked: false,
                    id: p.id,
                    title: p.title,
                    fileSize: p.fileSize,
                    version: p.version
                };
            })
        };
    });
}
