import {Injectable} from "@angular/core";
import {ArticleRestEndpoint} from "../../apina-digiweb";

import {Observable, of} from "rxjs";
import {shareReplay, tap} from "rxjs/operators";

/**
 * Not intended to be a global singleton.
 */
@Injectable()
export class UserArticleService {

    private articleCache = new Map<number, string[]>();

    constructor(private articleRest: ArticleRestEndpoint) {
    }

    public getArticleOCR(articleId: number): Observable<string[]> {
        const cached = this.articleCache.get(articleId);
        if (cached) {
            return of(cached);
        } else {
            return this.articleRest.fetchOcrTextForArticle(articleId).pipe(
                shareReplay(1),
                tap((result) => {
                    return this.articleCache.set(articleId, result);
                })
            );
        }
    }
}
