import {parseEuropeanDateRange, parseISODate} from "../utils/date";
import {LocalDate} from "@js-joda/core";

export function parseLegacyDates(value: string|string[], startDate: boolean): { start: LocalDate; end: LocalDate } {
    if (!value) return null;

    // Previous system supported multiple date ranges, but we only support one: throw away the others
    if (value instanceof Array) {
        if (value.length > 1)
            console.error(`Legacy link had ${value.length} parameter: throwing all but the first.`);

        return parseEuropeanDateRange(value[0]);
    } else {
        return parseEuropeanDateRange(value as string);
    }
}

export function normalizeQuery(query: string|string[]): string {
    if (query instanceof Array) {
        return query.join(' ');
    } else {
        return query as string;
    }
}

export function optionalDate(value: string): LocalDate {
    return value && parseISODate(value);
}

export function booleanOrDefault(value: any, defaultValue: boolean): boolean {
    return (value == null) ? defaultValue : /true/i.test(value);
}

export function valueOrDefault(key: string, values: {[key: string]: any}, defaults: {[key: string]: any}): any {
    const value = values[key];

    // if the variable is a boolean, any non-null string might be accidentally coerced to true
    if (value != null && typeof defaults[key] === "boolean") {
        return /true/i.test(value);
    }

    if (value != null)
        return value;
    else
        return defaults[key];
}

export function asArray(value: string | string[]): string[] {
    if (value instanceof Array) {
        return value as string[];
    } else if (value) {
        return [value as string];
    } else {
        return [];
    }
}

export function asArrayOrNull(value: string | string[]): string[] {
    if (value instanceof Array) {
        return value as string[];
    } else if (value) {
        return [value as string];
    } else {
        return null;
    }
}

export function asIntArray(value: string | string[]): number[] {
    return asArray(value).map(x => parseInt(x, 10));
}

export function asIntArrayOrNull(value: string | string[]): number[] {
    if (value) return asIntArray(value);
    return null;
}
