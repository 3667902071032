import {Component, Input} from "@angular/core";
import {ArticleRestEndpoint, CommentDto} from "../../apina-digiweb";

@Component({
    selector: 'app-article-comments',
    template: `
        <div>
            <b translate>user-article.comments</b>

            <app-progress-spinner *ngIf="loading"></app-progress-spinner>

            <div class="list-comments" *ngIf="!loading">
                <div *ngFor="let comment of comments" class="row" style="margin-left:3px;margin-right:3px; margin-bottom: 4px;">
                    <p>
                        {{comment.text}}<br>
                        <span class="float-right">
                    {{comment.authorName}} - {{comment.time | date:"dd.MM.yyyy HH:mm"}}
                </span>
                    </p>
                </div>
                <p *ngIf="comments?.length == 0" translate>common.no-comments</p>
            </div>
        </div>
    `
})
export class ArticleCommentsComponent {
    private _articleId: number;

    @Input() set articleId(value: number) {
        this._articleId = value;
        this.loadComments();
    }

    loading = true;
    comments: CommentDto[];

    constructor(private articleRest: ArticleRestEndpoint) {
    }

    private loadComments() {
        this.loading = true;
        this.articleRest.getCommentsForArticle(this._articleId).toPromise().then(comments => {
            this.loading = false;
            this.comments = comments;
        });
    }
}
