import {Pipe, PipeTransform} from "@angular/core";
import {DateTimeFormatter, LocalDate} from "@js-joda/core";

@Pipe({
    name: "dateWithAccuracy"
})
export class DateWithAccuracyPipe implements PipeTransform {

    transform(value: LocalDate, accuracy: string): string {
        return formatDateWithAccuracy(value, accuracy);
    }
}

export function formatDateWithAccuracy(value: LocalDate, accuracy: string): string {
    if (!value)
        return "";

    const format = (accuracy === 'v') ? 'yyyy'
        : (accuracy === 'k') ? 'M.yyyy'
            : 'd.M.yyyy';

    return DateTimeFormatter.ofPattern(format).format(value);
}
