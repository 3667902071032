import {Component, Injectable, OnDestroy} from "@angular/core";
import {AccountRestEndpoint, AccountSearchHistoryDto, Dictionary, ImportTime, QueryOrder, SearchHistoryBatch, SearchHistoryNameData} from "../../apina-digiweb";
import {map, switchMap} from "rxjs/operators";
import {BehaviorSubject, Subscription} from "rxjs";
import {formatAsLocalDateTime} from "../../utils/time";
import {formatEuropeanDate} from "../../utils/date";
import {SearchService} from "../search/search.service";
import {TranslateService} from "@ngx-translate/core";
import {MatPaginatorIntl, PageEvent} from "@angular/material/paginator";
import {NavigationService} from "../navigation.service";
import {AccountService} from "./account.service";
import {FormControl} from "@angular/forms";
import {BoolWrapper} from "../../utils/observable-utils";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmClearSearchHistoryModalComponent} from "./confirm-clear-search-history-modal.component";
import {LocalDate, ZoneId} from "@js-joda/core";
import {Paging} from "../../utils/paging-utils";
import {ResultMode, ResultType} from "../search/result/result-row";

interface SearchCriterion {
    matIcon?: string;
    label: string;
    values: any[];
}

interface UiData {
    total: number;
    rows: FormattedHistoryRow[];
}

interface FormattedHistoryRow {
    queryParams: Dictionary<any>;
    date: LocalDate;
    timestamp: string;
    query: string | null;
    dateStart: string;
    dateEnd: string;
    otherCriteria: SearchCriterion[];
    resultCount: number;
}

@Injectable()
export class PaginatorI18n extends MatPaginatorIntl {

    constructor(translate: TranslateService) {
        super();
        // tooltips disabled, because they clash visually with "clear history button"
        this.firstPageLabel = ""; translate.instant("mat-paginator.firstPageLabel");
        this.itemsPerPageLabel = ""; translate.instant("mat-paginator.itemsPerPageLabel");
        this.lastPageLabel = ""; translate.instant("mat-paginator.lastPageLabel");
        this.nextPageLabel = ""; // translate.instant("mat-paginator.nextPageLabel");
        this.previousPageLabel = ""; translate.instant("mat-paginator.previousPageLabel");
    }

    getRangeLabel = (page: number, pageSize: number, length: number) => {
        const first = page * pageSize + 1;
        const last = Math.min(length, (page + 1) * pageSize);
        return `${first} - ${last} / ${length}`;
    }
}

@Component({
    template: `
        <div class="container mt-4">
            <h2 translate>my-search-history.title</h2>

            <ng-template #loading>
                <app-progress-spinner></app-progress-spinner>
            </ng-template>

            <ng-template #pleaseLogin>
                <div class="alert alert-warning" translate>account.settings.login-required</div>
            </ng-template>

            <ng-template #noHistory>
                <div class="alert alert-info" translate>
                    my-search-history.history-is-empty
                </div>
            </ng-template>

            <div *ngIf="loggedIn$ | async as loggedIn; else loading">
                
                <div *ngIf="loggedIn.value; else pleaseLogin">

                    <div *ngIf="history$ | async as h; else loading">
                        
                        <div class="d-flex justify-content-between mb-3">
                            <label>
                                <input type="checkbox" (click)="toggleEnabled()" [formControl]="enabledToggle"/>
                                {{'account.settings.save-search-history' | translate}}
                            </label>
                            <button type="button" class="btn btn-kk-red btn-sm" (click)="confirmClearAll()" [disabled]="h.total == 0" translate>my-search-history.button.clear-history</button>
                        </div>
        
                        <div *ngIf="h.total > 0; else noHistory">
                            <mat-paginator *ngIf="h.total >= minPageSize"
                                           [length]="h.total"
                                           [pageSize]="pageSize"
                                           [pageSizeOptions]="sizeOptions"
                                           [pageIndex]="pageIndex"
                                           [showFirstLastButtons]="true"
                                           (page)="pagingChange($event)">
                            </mat-paginator>
        
                            <table class="table table-condensed">
                                <colgroup>
                                    <col [style.width]="'17ch'">
                                    <col [style.width]="'auto'">
                                    <col [style.width]="'10ch'">
                                    <col [style.width]="'fit-content'">
                                </colgroup>
                                <thead>
                                <tr>
                                    <th translate>my-search-history.timestamp</th>
                                    <th translate>my-search-history.criteria</th>
                                    <th class="text-right" translate>my-search-history.result-count</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let item of h.rows">
                                    <td>
                                        <a [routerLink]="'/search'" [queryParams]="item.queryParams" 
                                           [ngbTooltip]="'my-search-history.search-again.tooltip' | translate">{{item.timestamp}}</a>
                                    </td>
                                    <td class="criteria-details">
                                        <div class="query" *ngIf="item.query?.length > 0">{{item.query}}</div>
                                        <div class="dates" *ngIf="item.dateStart || item.dateEnd">
                                            <i class="fa fa-calendar criterion-label"></i>
                                            {{item.dateStart}}
                                            <ng-container *ngIf="item.dateStart || item.dateEnd">&mdash;</ng-container>
                                            {{item.dateEnd}}
                                        </div>
                                        <div *ngFor="let oth of item.otherCriteria">
                                            <mat-icon matSuffix *ngIf="oth.matIcon" class="criterion-icon">{{oth.matIcon}}</mat-icon>
                                            <span class="criterion-label">{{oth.label}}</span><span>:</span>
                                            <span *ngFor="let crit of oth.values; let i = index">
                                                <span *ngIf="i > 0" class="criterion-value-delim">|</span><span
                                                    class="criterion-value">{{crit}}</span>
                                            </span>
                                        </div>
                                    </td>
                                    <td class="text-right">{{item.resultCount}}</td>
                                    <td>
                                        <a [routerLink]="'/search'" [queryParams]="newlyImported(item.date, item.queryParams)"
                                           class="icon"
                                           [ngbTooltip]="'my-search-history.search-newly-added.tooltip' | translate:{date: item.date | localizedDate}">
                                            <mat-icon matSuffix>update</mat-icon>
                                        </a>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
        
                            <mat-paginator *ngIf="h.total >= minPageSize"
                                           [length]="h.total"
                                           [pageSize]="pageSize"
                                           [pageSizeOptions]="sizeOptions"
                                           [pageIndex]="pageIndex"
                                           [showFirstLastButtons]="true"
                                           (page)="pagingChange($event)">
                            </mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: [
        "./account-search-history.scss"
    ],
    providers: [
        {provide: MatPaginatorIntl, useClass: PaginatorI18n}
    ]
})
export class AccountSearchHistoryComponent implements OnDestroy {

    enabledToggle = new FormControl();

    pageIndex = 0;
    pageSize = 50;
    readonly minPageSize = 10;
    sizeOptions = [this.minPageSize, 50, 100, 500];

    refresh$ = new BehaviorSubject<Paging>(new Paging(1, this.pageSize));

    loggedIn$ = this.accountService.loggedIn$.pipe(map(l => new BoolWrapper(l)));

    history$ = this.refresh$.pipe(
        switchMap((paging) => this.accountRestEndpoint.getSearchHistory(paging.page, paging.pageSize)),
        map(h => this.convertForUI(h))
    );

    private subscription = new Subscription();

    constructor(private readonly accountRestEndpoint: AccountRestEndpoint,
                private readonly searchService: SearchService,
                private readonly translateService: TranslateService,
                public readonly navigationService: NavigationService,
                public readonly accountService: AccountService,
                private readonly matDialog: MatDialog) {

        this.subscription.add(this.accountService.preferences$.subscribe(prefs => {
            this.enabledToggle.setValue(prefs.saveSearchHistory);
        }));
    }

    toggleEnabled() {
        this.accountRestEndpoint.toggleSaveSearchHistory().subscribe(() => {
            this.accountService.refresh();
        });
    }

    pagingChange($event: PageEvent) {
        this.pageIndex = $event.pageIndex;
        this.pageSize = $event.pageSize;
        this.refresh$.next(Paging.from($event));
    }

    convertForUI(h: SearchHistoryBatch): UiData {
        return {
            total: h.totalSearches,
            rows: h.searches.map(r => this.convertRow(r, h.names))
        };
    }

    private convertRow(r: AccountSearchHistoryDto, names: SearchHistoryNameData): FormattedHistoryRow {
        return {
            queryParams: this.criteriaToQueryParams(r),
            date: r.timestamp.atZone(ZoneId.systemDefault()).toLocalDate(),
            timestamp: formatAsLocalDateTime(r.timestamp),
            query: r.query,
            dateStart: formatEuropeanDate(r.dateStart) || '',
            dateEnd: formatEuropeanDate(r.dateEnd) || '',
            otherCriteria: this.extractOtherCriteria(r, names),
            resultCount: r.resultCount
        };
    }

    criteriaToQueryParams(r: AccountSearchHistoryDto): Dictionary<any> {
        return this.searchService.paramsFromCriteria({
            authors: r.authors,
            collections: r.collections,
            exactCollectionMaterialType: false,
            districts: [],
            endDate: r.dateEnd,
            formats: r.generalTypes,
            fuzzy: r.fuzzy,
            hasIllustrations: r.illustrations,
            importTime: r.importTime,
            importStartDate: r.importStartDate,
            languages: r.languages,
            orderBy: r.queryOrder || QueryOrder.RELEVANCE,
            pages: r.pages,
            publicationPlaces: r.publishingPlaces,
            publications: r.titles,
            publishers: r.publishers,
            query: r.query,
            queryTargetsMetadata: r.targetsMeta,
            queryTargetsOcrText: r.targetsOcr,
            requireAllKeywords: r.requireAllWords || true,
            searchForBindings: r.searchForBindings,
            showLastPage: r.lastPageOnly,
            startDate: r.dateStart,
            tags: r.tags,
            includeUnauthorizedResults: r.includeUnauthorized
        }, 1, 1, ResultMode.THUMB, ResultType.BINDING_PAGES, true);
    }

    extractOtherCriteria(r: AccountSearchHistoryDto, names: SearchHistoryNameData): SearchCriterion[] {
        const result: SearchCriterion[] = [];
        const self = this;
        function appendList(criteriaLabel: string, items: string[], matIcon?: string) {
            const c = self.createCriterion(criteriaLabel, items);
            if (c != null) {
                c.matIcon = matIcon;
                result.push(c);
            }
        }

        function appendSingle(criteriaLabel: string, value: string, matIcon?: string) {
            if (!!value) {
                appendList(criteriaLabel, [value], matIcon);
            }
        }

        appendList("general-type", r.generalTypes && r.generalTypes.map(gt => this.translateService.instant('general-type.plural.' + gt)));
        appendList("author", r.authors);
        appendList("publishing-place", r.publishingPlaces);
        appendList("publisher", r.publishers);
        appendList("tags", r.tags && r.tags.map(t => this.formatTag(t)));
        appendList("title", r.titles && r.titles.map(t => names.titleNames[t] || t));
        appendList("collection", r.collections && r.collections.map(c => names.collectionNames[c] || c));
        appendList("language", r.languages && r.languages.map(l => this.translateService.instant("iso639." + l)));
        appendSingle("importTime", this.getFormattedImportTime(r), "update");

        return result;
    }

    private formatTag(tag: string): string {
        if (tag && tag.startsWith("topic:"))
            return tag.slice(6);
        else
            return tag;
    }

    createCriterion(criteriaLabel: string, items: string[]): SearchCriterion {
        if (items.length > 0)
            return {
                label: this.translateService.instant("my-search-history.criteria." + criteriaLabel),
                values: items.sort()
            };
        else
            return null;
    }

    confirmClearAll() {
        this.matDialog.open(ConfirmClearSearchHistoryModalComponent).afterClosed().subscribe(confirm => {
            if (confirm === true) {
                this.accountRestEndpoint.clearAllHistory().subscribe(() => {
                    this.refresh$.next(new Paging(1, this.pageSize));
                });
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    newlyImported(date: LocalDate, queryParams: Dictionary<any>): Dictionary<any> {
        return {
            ...queryParams,
            importTime: ImportTime.CUSTOM,
            importStartDate: date
        }
    }

    private getFormattedImportTime(r: AccountSearchHistoryDto): string | null {
        switch(r.importTime) {
            case ImportTime.ANY:
                return null;
            case ImportTime.TODAY:
            case ImportTime.LAST_7_DAYS:
            case ImportTime.LAST_30_DAYS:
            case ImportTime.LAST_365_DAYS:
                return this.translateService.instant("import-time." + r.importTime);
            case ImportTime.CUSTOM:
                return this.translateService.instant("my-search-history.criteria.import-time-custom") + " " + formatEuropeanDate(r.importStartDate);
        }
    }
}
