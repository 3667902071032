import {Injectable} from "@angular/core";

const ENABLE_DEBUG = false;

/**
 * Logging service stub.
 *
 * see https://github.com/angular/angular/pull/14308#pullrequestreview-49350479
 */
@Injectable()
export class LoggingService {

    // tip: if messages are hidden in Chrome, check browser's console logging level settings
    debug(message: string, optionalParam?: any): void {

        // noinspection TsLint
        if (ENABLE_DEBUG && console.debug)
            // noinspection TsLint
            console.debug(message, optionalParam);
    }

    info(message: string, optionalParam?: any): void {
        // noinspection TsLint
        console.info(message, optionalParam);
    }

    warn(message: string, optionalParam?: any): void {
        // noinspection TsLint
        console.warn(message, optionalParam);
    }

    error(message: string, error?: Error): void {
        // noinspection TsLint
        console.error(message, error);
    }
}
