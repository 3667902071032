import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {Subscription} from "rxjs";
import {BindingRestEndpoint, PoliticalOrientationInfo, TitleDataInfo} from "../../apina-digiweb";
import {SettingsService} from "../settings.service";
import {TranslateService} from "@ngx-translate/core";
import {LocalDate} from "@js-joda/core";
import {formatEuropeanDate} from "../../utils/date";

@Component({
    selector: "app-newspaper-metadata-details",
    template: `
        <div *ngIf="newspaperInfo as newspaperInfo else loading">
            <table class="table kk-bg-light">
                <tbody>
                <tr>
                    <td>{{'sanomalehti.title.data.maintitle' | translate}}</td>
                    <td>{{newspaperInfo.title}}</td>
                </tr>
                <tr *ngIf="newspaperInfo.additionalTitleData">
                    <td>{{'sanomalehti.title.data.title.additional' | translate}}</td>
                    <td>{{newspaperInfo.additionalTitleData}}</td>
                </tr>
                <tr>
                    <td>{{'sanomalehti.title.data.issn' | translate}}</td>
                    <td>{{newspaperInfo.identification}}</td>
                </tr>
                <tr *ngIf="newspaperInfo.layout">
                    <td>{{'sanomalehti.title.data.layout' | translate}}</td>
                    <td>{{newspaperInfo.layout}}</td>
                </tr>
                <tr>
                    <td>{{'sanomalehti.title.data.start.date' | translate}}</td>
                    <td>
                      {{formatDate(newspaperInfo.publishingStart)}}
                    </td>
                </tr>
                <tr>
                    <td>{{'sanomalehti.title.data.end.date' | translate}}</td>
                    <td>
                        {{formatDate(newspaperInfo.publishingEnd)}}
                    </td>
                </tr>
                <tr *ngIf="newspaperInfo.publisher">
                    <td>{{'sanomalehti.title.data.publisher' | translate}}</td>
                    <td>{{newspaperInfo.publisher}}</td>
                </tr>
                <tr *ngIf="newspaperInfo.editor">
                    <td>{{'sanomalehti.title.data.editor' | translate}}</td>
                    <td>{{newspaperInfo.editor}}</td>
                </tr>
                <tr *ngIf="newspaperInfo.variantTitles.length !== 0">
                    <td>{{'sanomalehti.title.data.variant' | translate}}</td>
                    <td>
                        <ul class="list-unstyled">
                            <li *ngFor="let variant of newspaperInfo.variantTitles">{{variant}}</li>
                        </ul>
                    </td>
                </tr>
                <tr *ngIf="newspaperInfo.publishingLocations">
                    <td>{{'sanomalehti.title.data.location' | translate}}</td>
                    <td>
                        <ul class="list-unstyled">
                            <li *ngFor="let loc of newspaperInfo.publishingLocations">
                                {{loc.city + ' ' + formatDate(loc.startDate) + " - " + formatDate(loc.endDate)}}
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr *ngIf="newspaperInfo.sampleCopies.length !== 0">
                    <td>{{'sanomalehti.title.data.sample' | translate}}</td>
                    <td>
                        <ul class="list-unstyled">
                            <li *ngFor="let copy of newspaperInfo.sampleCopies">
                                {{copy.name + ' ' + formatDate(copy.date)}}
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr *ngIf="newspaperInfo.regularSupplements.length !== 0">
                    <td>{{'sanomalehti.title.data.regular.supplement' | translate}}</td>
                    <td>
                        <ul class="list-unstyled">
                            <li *ngFor="let sup of newspaperInfo.regularSupplements">
                                {{sup.name + ' ' + supplementIssn(sup.localIssn) + ' ' + (sup.startAppr ? ' [ ' : '') + formatDate(sup.start) + (sup.startAppr ? ' ] ' : '') + ' - ' + (sup.endAppr ? ' [ ' : '') + formatDate(sup.end) + (sup.endAppr ? ' ] ' : '')}}
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr *ngIf="newspaperInfo.predecessors.length !== 0">
                    <td>{{'sanomalehti.title.data.predecessor' | translate}}</td>
                    <td>
                        <ul class="list-unstyled">
                            <li *ngFor="let predecessor of newspaperInfo.predecessors"><a
                                    href="/sanomalehti/titles/{{predecessor.identification}}?display=THUMB">{{predecessor.title}}</a>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr *ngIf="newspaperInfo.successors.length !== 0">
                    <td>{{'sanomalehti.title.data.successor' | translate}}</td>
                    <td>
                        <ul class="list-unstyled">
                            <li *ngFor="let successor of newspaperInfo.successors"><a
                                    href="/sanomalehti/titles/{{successor.identification}}?display=THUMB">{{successor.title}}</a>
                            </li>
                        </ul>
                    </td>
                </tr>
                </tbody>
            </table>
            <p>
                <a href="{{'natlibfi.metadatasrc' | translate}}{{newspaperInfo.identification}}"
                   target="_blank">{{'sanomalehti.title.data.fennicalink' | translate}}</a>

                <a href="https://fennica.linneanet.fi/cgi-bin/Pwebrecon.cgi?Search_Arg={{newspaperInfo.identification}}&SL=None&Search_Code=GKEY%5E*&CNT=25"
                   target="_blank">.&nbsp;</a>
                <!--{{'sanomalehti.title.data.fennicalink' | translate}}-->
            </p>

            <div *ngIf="getPoliticalOrientations(newspaperInfo.politicalOrientationInfos) as pos" class="pt-3 pb-3">

                <h4>{{'sanomalehti.title.data.politicalorientations.politicalorientation' | translate}}</h4>

                <table class="table kk-bg-light">
                    <tbody>
                    <tr *ngFor="let ori of pos.list">
                        <td>
                            <div>
                                {{ori.description}}<sup
                                    [title]="'sanomalehti.title.data.politicalorientations.source' | translate">{{ori.referenceNumber}}</sup>
                            </div>
                            <div class="text-muted">{{ori.dates}}</div>
                            <div class="text-muted" *ngIf="ori.comment as comment">
                                {{'sanomalehti.title.data.politicalorientations.comment' | translate}}: {{comment}}
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <hr/>
                <ul class="list-unstyled">
                    <li *ngFor="let ref of pos.references">
                        <sup>{{ref.referenceNumber}}</sup>&nbsp;<span [innerHTML]="ref.text"></span>
                    </li>
                </ul>
            </div>
        </div>
        
        <ng-template #loading>
            <app-progress-spinner></app-progress-spinner>
        </ng-template>
    `,
    styles: [
        "tr:first-child td { border-top: none; }"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewspaperMetadataDetailsComponent implements OnInit, OnDestroy {

    @Input() issn: string;

    @Output() title = new EventEmitter<string>();

    newspaperInfo: TitleDataInfo;

    private sub = new Subscription();

    constructor(private readonly bindingRest: BindingRestEndpoint,
                private readonly settingsService: SettingsService,
                private readonly translateService: TranslateService,
                private readonly cd: ChangeDetectorRef) {
    }

    get lang() {
        return this.settingsService.lang;
    }

    ngOnInit(): void {
        this.sub.add(this.bindingRest.getTitleDataForBinding(this.issn).subscribe(data => {
            this.newspaperInfo = data;
            this.title.emit(data.title);
            this.cd.markForCheck();
        }));
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    supplementIssn(issn: string): string {
        return issn || '';
    }

    formatDates(ori: PoliticalOrientationInfo): string {
        return `${this.formatDate(ori.start)} - ${this.formatDate(ori.end)}`;
    }

    formatDate(date: LocalDate): string {
        return formatEuropeanDate(date) || '';
    }

    getOrientationByLang(language: string, orientation: PoliticalOrientationInfo): string {
        if (language === "sv" && orientation.orientationSv != null) {
            return orientation.orientationSv;
        } else if (language === "en" && orientation.orientationEn != null) {
            return orientation.orientationEn;
        } else {
            return orientation.orientationFi;
        }
    }

    getCommentByLang(language: string, orientation: PoliticalOrientationInfo): string {
        if (language === "sv" && orientation.commentSv != null) {
            return orientation.commentSv;
        } else if (language === "en" && orientation.commentEn != null) {
            return orientation.commentEn;
        } else {
            return orientation.commentFi != null ? orientation.commentFi : "";
        }
    }

    getPoliticalOrientations(politicalOrientationInfos: PoliticalOrientationInfo[]): UiPoliticalOrientations | null {
        if (politicalOrientationInfos.length === 0) {
            return null;
        }

        let counter = 0;
        const referenceMap = new Map<string, number>();
        const list: UiPoliticalOrientation[] = [];

        for (const ori of politicalOrientationInfos) {
            let referenceNumber = referenceMap.get(ori.infoSourceCode);
            if (referenceNumber == null) {
                referenceNumber = ++counter;
                referenceMap.set(ori.infoSourceCode, referenceNumber);
            }

            list.push({
                dates: this.formatDates(ori),
                description: this.getOrientationByLang(this.lang, ori),
                comment: this.getCommentByLang(this.lang, ori),
                referenceNumber
            });
        }

        const references: UiSourceReference[] = [];

        referenceMap.forEach((n, code) => {
            references.push({
                referenceNumber: n,
                text: this.translateService.instant("sanomalehti.title.data.politicalorientations.source." + code)
            });
        });

        return {
            list,
            references
        }
    }
}

interface UiPoliticalOrientations {
    list: UiPoliticalOrientation[];
    references: UiSourceReference[];
}

interface UiPoliticalOrientation {
    dates: string;
    description: string;
    comment: string;
    referenceNumber: number;
}

interface UiSourceReference {
    referenceNumber: number;
    text: string;
}