import {Component, Input, OnInit} from "@angular/core";
import {MarcDataFieldInfo, MarcInfo} from "../../../apina-digiweb";
import {TranslateService} from "@ngx-translate/core";

interface FilteredRecord {
    filteredDataFields: FilteredDataField[];
}
interface FilteredDataField {
    tag: string;
    name: string;
    order: number;
    values: string[];
}

@Component({
    selector: "app-user-friendly-marc-record",
    template: `
        <ng-container *ngIf="filteredRecord != null">
            <div *ngFor="let df of filteredRecord.filteredDataFields" class="entry">
                <ng-container [ngSwitch]="df.tag">
                <div *ngSwitchCase="245" class="datafield-245">
                    {{df.name}}
                </div>
                <div *ngSwitchCase="856" class="overflow-break-words">
                    {{df.name}}
                </div>
                <div *ngSwitchDefault class="overflow-break-words">
                    {{df.name}}
                </div>
                </ng-container>

                <div class="overflow-break-words pl-3">
                    <ng-container [ngSwitch]="df.tag">
                        <span *ngSwitchCase="856">
                            <strong><a href="{{df.values[0]}}" target="_blank">{{df.values[0]}}</a></strong>
                        </span>
                        <ng-container *ngSwitchCase="['100', '110', '111', '130', '700', '710', '711', '720', '730'].includes(df.tag) ? df.tag : ''">
                            <span *ngFor="let value of df.values;  index as i">
                                <ng-container [ngSwitch]="i" >
                                    <ng-container *ngSwitchCase="0">
                                        <strong>{{value}}</strong>
                                    </ng-container>
                                    <ng-container *ngSwitchDefault>
                                        <br>{{value}}
                                    </ng-container>   
                                </ng-container>
                            </span>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <span *ngFor="let value of df.values">
                                {{value}}
                            </span>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    `,
    styleUrls: [
        "user-friendly-marc-record.scss"
    ]
})

export class UserFriendlyMarcRecordComponent implements OnInit {

    @Input() record: MarcInfo;
    filteredRecord: FilteredRecord;

    constructor( private readonly translateService: TranslateService){

    }

    ngOnInit(): void {

        this.filteredRecord = {filteredDataFields: []};

        for (const df of this.record.dataFieldInfos){
            const filteredDataField = this.handleDataField(df);
            if (filteredDataField.tag !== '-1'){
                this.filteredRecord.filteredDataFields.push(filteredDataField);
            }
        }

        // sort fields by order so that fields are displayed in correct order in Digi
        this.filteredRecord.filteredDataFields.sort((a,b) => a.order - b.order);
    }

    private handleDataField(currentDataField: MarcDataFieldInfo){
        const currentTag = currentDataField.tag;
        const subFieldsDict: Map<string, string> = new Map<string, string>();

        currentDataField.subFields.forEach((element) => {
            const dictKey = element.code;
            if (subFieldsDict.has(dictKey)) {
                subFieldsDict.set(dictKey, subFieldsDict.get(dictKey) + ', ' + element.value);
            }
            else {
                subFieldsDict.set(dictKey, element.value);
            }
        });

        const resultField: FilteredDataField = {tag:'-1', name:'-1', order:-1, values:[]};

        let subKey1 = "";
        let subKey2 = "";
        let subKey3 = "";

        const handleDefaultDataField = (order: number) => {
            resultField.name = this.translateService.instant("marc." + currentTag);
            resultField.tag = currentTag;
            resultField.order = order;

            subFieldsDict.forEach((value: string, key: string) => {
                resultField.values.push(value);
            });
        };

        switch (currentTag) {
            case "245":
                resultField.tag = currentTag;
                resultField.order = 0;
                subKey1 = "a";
                subKey2 = "b";
                subKey3 = "c";

                if (subFieldsDict.has(subKey1)) {
                    resultField.name = subFieldsDict.get(subKey1);
                }

                if (subFieldsDict.has(subKey2)) {
                    resultField.name += ' ' + subFieldsDict.get(subKey2);
                }

                if (subFieldsDict.has(subKey3)) {
                    resultField.name += ' ' + subFieldsDict.get(subKey3);
                }
                break;
            case "856":
                // add 856 field only once, so that there are only one urn displayed in Digi
                if (!this.filteredRecord.filteredDataFields.some(dataField => dataField.tag === currentTag)){
                    resultField.tag = currentTag;
                    resultField.order = 1;
                    subKey1 = "u";
                    resultField.name = this.translateService.instant("marc.urn");

                    if (subFieldsDict.has(subKey1)) {
                        resultField.values = [subFieldsDict.get(subKey1)];
                    }
                }
                break;
            case "100":
            case "110":
            case "111":
            case "130":
            case "700":
            case "710":
            case "711":
            case "720":
            case "730":
                resultField.tag = currentTag;
                resultField.order = 2;
                subKey1 = "e";
                subKey2 = "a";
                subKey3 = "0";

                if (subFieldsDict.has(subKey1)) {
                    resultField.name = subFieldsDict.get(subKey1);
                }
                else{
                    resultField.name = this.translateService.instant("marc.author");
                }

                if (subFieldsDict.has(subKey2)) {
                    resultField.values.push(subFieldsDict.get(subKey2));
                }

                if (subFieldsDict.has(subKey3)) {
                    resultField.values.push(subFieldsDict.get(subKey3));
                }
                break;
            case "776":
                subKey1 = "i";
                subKey2 = "d";
                subKey3 = "k";

                if (subFieldsDict.has(subKey1)) {
                    if (subFieldsDict.get(subKey1).includes('Digitoitu painetusta')) {
                        resultField.name = subFieldsDict.get(subKey1);
                        resultField.tag = currentTag;
                        resultField.order = 3;

                        if (subFieldsDict.has(subKey2)) {
                            resultField.values.push(subFieldsDict.get(subKey2));
                            subFieldsDict.delete(subKey2);
                        }

                        if (subFieldsDict.has(subKey3)) {
                            resultField.values.push(subFieldsDict.get(subKey3));
                            subFieldsDict.delete(subKey3);
                        }

                        subFieldsDict.forEach((value: string, key: string) => {
                            resultField.values.push(value);
                        });
                    }
                    else{
                        handleDefaultDataField(3);
                    }
                }
                else{
                    handleDefaultDataField(3);
                }
                break;
            case "300":
                subKey1 = "a";

                resultField.name = this.translateService.instant("marc.300");
                resultField.tag = currentTag;
                resultField.order = 4;

                if (subFieldsDict.has(subKey1)) {
                    resultField.values.push(subFieldsDict.get(subKey1));
                }
                break;
            case "506":
                subKey1 = "a";

                resultField.name = this.translateService.instant("marc.506");
                resultField.tag = currentTag;
                resultField.order = 5;

                if (subFieldsDict.has(subKey1)) {
                    resultField.values.push(subFieldsDict.get(subKey1));
                    subFieldsDict.delete(subKey1);

                }

                subFieldsDict.forEach((value: string, key: string) => {
                    resultField.values.push(value);
                });
                break;
            case "542":
                subKey1 = "l";

                resultField.name = this.translateService.instant("marc.542");
                resultField.tag = currentTag;
                resultField.order = 6;

                if (subFieldsDict.has(subKey1)) {
                    resultField.values.push(subFieldsDict.get(subKey1));
                    subFieldsDict.delete(subKey1);
                }

                subFieldsDict.forEach((value: string, key: string) => {
                    resultField.values.push(value);
                });
                break;
            default: {
                handleDefaultDataField(7);
                break;
            }
        }
        return resultField;
    }
}