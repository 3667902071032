import {Injectable} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, map, switchMap} from "rxjs/operators";

@Injectable()
export class DocumentService {

    constructor(private title: Title,
                private translate: TranslateService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
                map(() => this.activatedRoute),
                map((route) => {
                    let result = route;
                    while (result.firstChild) result = result.firstChild;
                    return result;
                }),
                switchMap(route => route.data)
            )
            .subscribe(data => {
                const dynamicTitle = data['dynamicTitle'] === true;
                if (dynamicTitle)
                    return;

                const titleKey = data['titleKey'];
                if (titleKey != null)
                    this.setContentTitle(this.translate.instant(titleKey));
                else
                    this.setDefaultTitle();
            });
    }

    setDefaultTitle() {
        this.setContentTitle(null);
    }

    /**
     * Sets the page title with the formal suffix, e.g. "Foo" -> "Foo - Kansalliskirjaston digitoidut aineistot"
     */
    setContentTitle(title: string | null) {
        if (title != null)
            this.title.setTitle(`${title} - ${this.translate.instant('header.title')}`);
        else
            this.title.setTitle(this.translate.instant('header.title'));
    }
}
