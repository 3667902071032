import {Directive, ElementRef, EventEmitter, Output} from "@angular/core";

/**
 * If the page does not reach window height due to lack of data to display, we don't know if we should stop.
 *
 * Stop emitting after this many times in those cases.
 */
const EMIT_SAFETY_LIMIT = 50;

@Directive({
    selector: "[appPrefill][infinite-scroll]"
})
export class InfiniteScrollPrefillDirective {

    /**
     * Event that is called if the infinite scroll content has not reached the bottom of window.
     *
     * Note that this directive doesn't know if the loadable content has already been exhausted, so the event
     * handler should take appropriate precautions. A maximum of EMIT_SAFETY_LIMIT events will be generated.
     */
    @Output() appPrefill = new EventEmitter<void>();

    constructor(private elementRef: ElementRef) {
        if (window && window.innerHeight) {
            const viewHeight = window.innerHeight;
            const el = this.elementRef.nativeElement as HTMLElement;

            let emitCounter = 0;

            const handle = setInterval(() => {
                const elementBottom = el.getBoundingClientRect().bottom;
                if (emitCounter >= EMIT_SAFETY_LIMIT || viewHeight < elementBottom) {
                    clearInterval(handle);
                } else {
                    this.appPrefill.emit();
                    emitCounter += 1;
                }
            }, 50);
        }
    }
}
