import {IRegion} from "../binding/types";

export function combineAreas(areas: IRegion[], padding: number): IRegion | null {
    if (areas == null || areas.length === 0) return null;

    let x = Number.POSITIVE_INFINITY;
    let y = Number.POSITIVE_INFINITY;
    let x1 = Number.NEGATIVE_INFINITY;
    let y1 = Number.NEGATIVE_INFINITY;

    for (const area of areas) {
        const areaX1 = getX1(area);
        const areaY1 = getY1(area);
        if (area.x < x) { x = area.x; }
        if (area.y < y) { y = area.y; }
        if (areaX1 > x1) { x1 = areaX1; }
        if (areaY1 > y1) { y1 = areaY1; }
    }

    return {
        x: x - padding,
        y: y - padding,
        width: x1 - x + padding * 2,
        height: y1 - y + padding * 2
    };
}

function getX1(area: IRegion): number {
    return area.x + area.width;
}

function getY1(area: IRegion): number {
    return area.y + area.height;
}