export function clipValue(value: number, min: number, max: number) {
    return Math.max(min, Math.min(max, value));
}

export function avg(a: number, b: number) {
    return (a + b) / 2;
}

/**
 * Returns random integer between 0 and max (exclusive).
 */
export function randomInt(max: number) {
    return Math.floor(Math.random() * max);
}