import {Injectable} from "@angular/core";
import {SettingsService} from "./settings.service";

@Injectable()
export class PlatformService {
    
    constructor(readonly settingsService: SettingsService) {
        try {
            const ua = navigator.userAgent;
            this.iOSWithScrollingProblems = /(iPad|iPhone); CPU( iPhone)? OS (9|10|11|12|13|14)/.test(ua);
        } catch (e) {
            this.iOSWithScrollingProblems = false;
        }

        const options = this.settingsService.commonOptions;
        this.isDownloadToolButtonsEnabled = options.downloadToolButtonsEnabled;
    }

    /**
     * Older iPads don't like our current system of CSS grid + HammerJS.
     */
    readonly iOSWithScrollingProblems: boolean;
    readonly isDownloadToolButtonsEnabled: boolean;

    userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera || undefined;
    isMobileDevice = (): boolean => {
        const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];
        return regexs.some((b) => this.userAgent.match(b));
    };

    isTabletDevice = (): boolean => {
        const regex = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
        return regex.test(this.userAgent.toLowerCase())
    };

    isDesktopDevice = (): boolean => !this.isMobileDevice() && !this.isTabletDevice();

    isWindows (): boolean {
        if (this.isDesktopDevice() && this.isDownloadToolButtonsEnabled) {
            return this.userAgent.indexOf('Win') !== -1;
        }
        else {
            return false;
        }
    }
}