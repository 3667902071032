import {Component, Input} from "@angular/core";
import {DisplayService} from "../display.service";
import {IArticleController} from "./user-article.component";
import {SocialPrivacyService} from "../social/social-privacy.service";

const SOCIAL_COOKIE_NAME = 'kkSocialShareArticle';

@Component({
    selector: "app-user-article-tabs",
    template: `
        <div *ngIf="articleCtrl != null">
            
            <app-tabbed-overlay [classes]="'user-article-side-tabs'">
                <app-tabbed-overlay-pane [faIconClass]="articleCtrl.displayOverlay ? 'fa-caret-right' : 'fa-caret-left'"
                                         [title]="(articleCtrl.displayOverlay ? 'user-article.hide-window' : 'user-article.show-window') | translate"
                                         type="action"
                                         (tabClick)="articleCtrl.toggleOverlay()">
                </app-tabbed-overlay-pane>
                
                <app-tabbed-overlay-pane faIconClass="fa-info-circle" [title]="'user-article.tabs.clipping-info.title' | translate">
        
                    <app-progress-spinner *ngIf="articleCtrl.article == null"></app-progress-spinner>
        
                    <div class="clipping-metadata" *ngIf="articleCtrl.article != null">
                        <h3>{{articleCtrl.article.title}}</h3>
        
                        <div style="margin-bottom: 10px;" *ngIf="articleCtrl.article.canShare && articleCtrl.includeSocialWidgets">
                            <div *ngIf="isSocialShareAllowed()">
                                <div style="display:grid;   grid-template-columns: repeat(auto-fill, minmax(100px, 0.1fr)); grid-gap: 5px;">
                                    <div style="margin-bottom: 5px; margin-top:1px; height: 20px;">
                                        <app-tweet [url]="articleCtrl.article.url"></app-tweet>
                                    </div>
                                     <div style="margin-bottom: 10px; height: 20px;">
                                        <app-facebook-like [url]="articleCtrl.article.url"></app-facebook-like>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <dl class="clipping-metadata" style="clear: both;">
                            
                            <dt translate>user-article.publication</dt>
                            <dd><a href="{{articleCtrl.article.bindingPageUrl}}">{{articleCtrl.article.bindingTitle}} s. {{articleCtrl.article.pageRange}}</a></dd>
                            
                            <div *ngFor="let item of articleCtrl.metadata">
                                <dt translate>{{item.key}}</dt>
                                <dd [innerHTML]="item.value"></dd>    
                            </div>
                        </dl>
        
                        <div class="clipping-actions" style="margin-bottom:12px">
                            <a href="{{articleCtrl.article.bindingPageUrl}}" class="btn btn-kk-auto btn-sm"
                               ngbTooltip="{{'user-article.showpage.tooltip' | translate}}" translate>user-article.showpage</a>
        
                            <a href="{{articleCtrl.article.editUrl}}" class="btn btn-kk-auto btn-sm" *ngIf="articleCtrl.article.editUrl"
                               ngbTooltip="{{'user-article.edit.tooltip' | translate}}" translate>user-article.edit</a>
                            
                            <form ngNoForm action="{{articleCtrl.article.deleteUrl}}" method="post" *ngIf="articleCtrl.article.deleteUrl != null" style="display: inline">
                                <button type="submit" class="btn btn-kk-red btn-sm" (click)="articleCtrl.confirmDelete()" translate>common.action.delete</button>
                            </form>
                        </div>
        
                        <app-article-notes *ngIf="articleCtrl.isLoggedIn$ | async" [articleId]="articleCtrl.article.id"></app-article-notes>
        
                        <app-article-comments [articleId]="articleCtrl.article.id"></app-article-comments>
                        
                    </div>
                </app-tabbed-overlay-pane>
                
                <!-- REFERENCES -->
                <app-tabbed-overlay-pane faIconClass="fa-quote-right" class="citation-info" title="{{'user-article.tabs.citation.title' | translate}}">
                    <h3 style="margin-bottom: 2rem" translate>citation.title</h3>
                    
                    <div *ngIf="articleCtrl.article">
                    
                        <div *ngIf="!(articleCtrl.article.canShare && articleCtrl.includeSocialWidgets)">
                            <div [innerHTML]="'user-article.tabs.citation.restricted-warning-part1' | translate"></div>
                            <div [innerHTML]="'user-article.tabs.citation.restricted-warning-part2' | translate"></div>
                        </div>
                        
            
                        <a href="#" style="margin-top: 20px" class="citation-select-tool" (click)="articleCtrl.selectCitableContent($event, 'textarea.citable-content:visible')">
                            <span translate>citation.select-text</span></a>
                        
                        <app-citation-info [citationInfo]="articleCtrl.article.citationInfo"></app-citation-info>
                    </div>
                </app-tabbed-overlay-pane>
                
                <!-- OCR pane--> 
                <app-tabbed-overlay-pane style="margin-bottom: 20px" faIconClass="fa-font" title="{{'clipping.astext' | translate}}" (onSelected)="ocrVisible = $event">

                    <div *ngIf="(articleCtrl.article.canShare)">  
                        <a href="#" class="citation-select-tool" (click)="articleCtrl.selectCitableContent($event, '.copyable-ocr-text:visible')"><span translate>citation.select-text</span></a>
                    </div>
                    <h3 translate>binding.ocr.title</h3>
                    
                    <div class="overlay-scroll-wrapper" *ngIf="ocrVisible">
                        <app-user-article-ocr [articleId]="articleCtrl.articleId"></app-user-article-ocr>
                    </div>
                    
                </app-tabbed-overlay-pane>
        
                <app-tabbed-overlay-pane [faIconClass]="displayService.maximized ? 'fa-compress' : 'fa-expand'" title="{{'user-article.fullscreen' | translate}}" type="action" (tabClick)="displayService.toggleMaximized()"></app-tabbed-overlay-pane>
            </app-tabbed-overlay>
        </div>
    `,
    styleUrls: [
        "./user-article-tabs.scss"
    ]
})
export class UserArticleTabsComponent {
    @Input() articleCtrl: IArticleController;

    ocrVisible = false;

    constructor(public displayService: DisplayService,
                private socialPrivacyService: SocialPrivacyService) {
    }

    isSocialShareAllowed(): boolean {
        return this.socialPrivacyService.isSocialShareAllowed(SOCIAL_COOKIE_NAME);
    }

    allowSocialShare() {
        this.socialPrivacyService.allowSocialShare(SOCIAL_COOKIE_NAME);
    }
}
