import {Injectable} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";

export interface IMessages {
    search: {
        normal: string;
        fuzzy: string;
        phrase: string;
        mandatory: string;
        forbidden: string;
        atDate(date: any): string;
        beforeDate(date: any): string;
        afterDate(date: any): string;
        betweenDates(start: any, end: any): string;
    };
    helpByType: {
        [key: string]: any;
    };
}

interface IAllMessages {
    [language: string]: IMessages;
    fi: IMessages;
}

const allMessages: IAllMessages = {
    fi: {
        search: {
            "normal": "Vapaa hakusana",
            "fuzzy": "Sumea hakusana",
            "phrase": "Fraasi",
            "mandatory": "Pakollinen hakusana",
            "forbidden": "Kielletty hakusana",
            atDate(date: any) { return date + ' ilmestyneet'; },
            beforeDate(date: any) { return date + ' ennen ilmestyneet'; },
            afterDate(date: any) { return date + ' jälkeen ilmestyneet'; },
            betweenDates(start: any, end: any) { return start + '-' + end + ' ilmestyneet'; }
        },
        helpByType: {
            "AUTHOR": "Henkilö",
            "EVENT": "Tapahtuma",
            "PLACE": "Paikka",
            "KEYWORD": "Asiasana",
            "TIME": "Aika"
        }
    },
    en: {
        search: {
            "normal": "Search term",
            "fuzzy": "Fuzzy term",
            "phrase": "Phrase",
            "mandatory": "Mandatory term",
            "forbidden": "Forbidden term",
            atDate(date: any) { return 'Published at ' + date; },
            beforeDate(date: any) { return 'Published before ' + date; },
            afterDate(date: any) { return 'Published after ' + date; },
            betweenDates(start: any, end: any) { return 'Published between ' + start + '-' + end; }
        },

        helpByType: {
            "AUTHOR": "Author",
            "EVENT": "Event",
            "PLACE": "Place",
            "KEYWORD": "Keyword",
            "TIME": "Time"
        }
    },
    sv: {
        // TODO: localize the swedish messages
        search: {
            "normal": "Fritt sökord",
            "fuzzy": "Oskarpt sökord",
            "phrase": "Fras",
            "mandatory": "Obligatoriskt sökord",
            "forbidden": "Förbjudet sökord",
            atDate(date: any) { return date; },
            beforeDate(date: any) { return '-' + date; },
            afterDate(date: any) { return date + '-'; },
            betweenDates(start: any, end: any) { return start + '-' + end; }
        },
        helpByType: {
            "AUTHOR": "Person",
            "EVENT": "Evenemang",
            "PLACE": "Plats",
            "KEYWORD": "Nyckelord",
            "TIME": "Tid"
        }
    }
};

@Injectable()
export class MessagesService {
    constructor(private translate: TranslateService) {}

    get(): IMessages {
        return allMessages[this.translate.currentLang] || allMessages.fi;
    }
}
