import {Component} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {distinctUntilChanged, map} from "rxjs/operators";
import {Observable} from "rxjs";
import {BreadcrumbLink, BreadcrumbsService} from "../../breadcrumbs/breadcrumbs.service";
import {NavigationService} from "../../navigation.service";

@Component({
    template: `
        <section class="container" *ngIf="issn$ | async as issn">
            <app-newspaper-metadata-details [issn]="issn" (title)="updateBreadCrumbs($event, issn)"></app-newspaper-metadata-details>
        </section>
    `
})
export class NewspaperMetadataComponent {
    issn$: Observable<string>;

    constructor(route: ActivatedRoute,
                private readonly breadcrumbsService: BreadcrumbsService,
                private readonly navigationService: NavigationService) {
        this.issn$ = route.paramMap.pipe(
            map(params => params.get("issn")),
            distinctUntilChanged()
        );
    }

    updateBreadCrumbs(title: string, issn: string) {
        const bc: BreadcrumbLink[] = [
            this.breadcrumbsService.createLocalizedBreadcrumbLink('serialpublications.main.header', [this.navigationService.basePaths.serialPublications]),
            this.breadcrumbsService.createBreadcrumbLink(title, [this.navigationService.basePaths.newspaperRoot, "titles", issn]),
            this.breadcrumbsService.createLocalizedBreadcrumbLink("sanomalehti.title.data", null)
        ];
        this.breadcrumbsService.setBreadcrumbLinks(bc);
    }
}