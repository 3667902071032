import {Directive, HostListener} from "@angular/core";
import {SessionStorageService} from "./session-storage.service";

@Directive({
    selector: 'a[appStoreSession]'
})
export class StoreSessionDirective {

    constructor(private readonly sessionStorageService: SessionStorageService) {
    }

    @HostListener('click')
    onClick() {
        this.sessionStorageService.beforeNewTabOpened();
    }

    /*
     * Many users always open links with right click + open link in new tab. This captures that too. If the user picks
     * something else than "open link in new tab" from the context menu, it shouldn't matter.
     */
    @HostListener('contextmenu')
    onContextMenu() {
        this.sessionStorageService.beforeNewTabOpened();
    }

    /*
     * If the user does something even more unusual, like opening the link with a middle mouse button, this should
     * capture that too.
     */
    @HostListener('auxclick')
    onAuxClick() {
        this.sessionStorageService.beforeNewTabOpened();
    }
}
