import {Component} from "@angular/core";
import {DisplayService} from "../../display.service";

@Component({
    selector: "app-sidebar",
    template: `
        <div class="sidebar kk-bg-dark kk-mat-dark-theme" [style.width.px]="displayService.sidebarWidth | async">
            <ng-content></ng-content>
        </div>
    `,
    styleUrls: [
        "./sidebar.scss"
    ]
})
export class SidebarComponent {

    constructor(readonly displayService: DisplayService) {
    }
}