import {Injectable} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {LoggingService} from "../logging.service";

@Injectable()
export class MatomoAnalyticsService {

    constructor(
        private router: Router,
        private log: LoggingService
    ) {}

    public init() {
        // TODO consider using 3rd party Matomo+Angular integration, such as https://github.com/Arnaud73/ngx-matomo
        
        // TODO what if matomo has not been loaded yet?
        const paq: any = (window as any)._paq;

        if (paq) {
            paq.push(['setCookieConsentGiven']);
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    const newPage = event.urlAfterRedirects;

                    if (paq) {
                        this.log.info("matomo:trackPageView", newPage); // TODO reduce to debug
                        paq.push(['setCustomUrl', newPage]);
                        paq.push(['setDocumentTitle', document.title]);
                        paq.push(['trackPageView']);
                    }

                    // TODO this now sends a pageview every time anything in the url changes, should we omit some of them?
                }
            });
        }
    }
}
