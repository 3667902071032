import {Component} from "@angular/core";
import {SettingsService} from "../settings.service";
import {DisplayService} from "../display.service";
import {NavigationService} from "../navigation.service";
import {AccountService} from "../account/account.service";
import {NgbPanelChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import { FeedbackService } from "../feedback/feedback.service";

@Component({
    selector: "app-mobile-navi",
    template: `
        <nav id="global-navi-mobile">
            <ngb-accordion [closeOthers]="true" (panelChange)="beforeChange($event)">
                <ngb-panel id="frontpage-link">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-home"></i> <span translate>main.links.main</span>
                    </ng-template>
                </ngb-panel>

                <ngb-panel id="search-link">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-search"></i> <span translate>search.main.header</span>
                    </ng-template>
                </ngb-panel>

                <ngb-panel id="clippings-link">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-scissors"></i> <span translate>main.links.user.articles</span>
                    </ng-template>
                </ngb-panel>

                <ngb-panel id="serialpubs-link">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-newspaper-o"></i> <span translate>serialpublications.main.header</span>
                    </ng-template>
                </ngb-panel>

                <ngb-panel id="papers-for-day-link">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-calendar"></i> <span translate>papers-for-day.main.header</span>
                    </ng-template>
                </ngb-panel>

                <ngb-panel id="collections-link">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-archive"></i> <span translate>kirja.main.links.collection</span>
                    </ng-template>
                </ngb-panel>
                
                <ngb-panel id="other-link">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-book"></i> <span translate>others.main.header</span>
                    </ng-template>
                </ngb-panel>
                <ngb-panel id="static-6">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-cog"></i> <span translate>mobile.menu.account</span>
                        <span *ngIf="accountService.currentUserInfo$ | async as cui">
                            <span *ngIf="cui.loggedIn"> ({{cui.name}})</span>    
                        </span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <ul *ngIf="accountService.currentUserInfo$ | async as cui">
                            <li *ngIf="!cui.loggedIn"><a (click)="accountService.login($event)" href="" rel="nofollow"><i class="fa fa-sign-in"></i> {{'account.login.action' | translate}}</a></li>
                            <li *ngIf="cui.loggedIn"><a href="/account" rel="nofollow" translate>scrapbook.saved-content</a></li>
                            <li *ngIf="cui.loggedIn && cui.passwordUser"><a href="/account/change-password" rel="nofollow" translate>account.action.change-password</a></li>
                            <li *ngIf="cui.loggedIn"><a href="/signout"><i class="fa fa-sign-in"></i> {{'account.logout' | translate}}</a></li>
                        </ul>
                    </ng-template>
                </ngb-panel>
                <ngb-panel id="static-7">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-flag-checkered"></i> <span translate>mobile.menu.language</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <ul>
                            <li>
                                <a href="#" rel="nofollow" (click)="navigationService.changeLanguage($event, 'fi')">Suomeksi</a>
                                <a href="#" rel="nofollow" (click)="navigationService.changeLanguage($event, 'sv')">På svenska</a>
                                <a href="#" rel="nofollow" (click)="navigationService.changeLanguage($event, 'en')">In English</a>
                            </li>
                        </ul>
                    </ng-template>
                </ngb-panel>
                <ngb-panel id="static-8">
                    <ng-template ngbPanelTitle>
                        <i class="fa fa-info-circle"></i> <span translate>mobile.menu.info</span>
                    </ng-template>
                    <ng-template ngbPanelContent>
                        <ul>
                            <li><a href="{{'digitisationcentre.url' | translate}}">{{'digitisationcentre.name' | translate}} <i class="fa fa-external-link"></i></a></li>
                            <li><a [href]="basePaths.infoPage">{{'main.info.service' | translate}}</a></li>
                            <li><a [href]="basePaths.faqPage">{{'faq.short' | translate}}</a></li>
                            <li><a [href]="basePaths.termsPage">{{'main.terms.terms_of_use' | translate}}</a></li>
                            <li><a [href]="basePaths.otherPage">{{'others.main.header' | translate}}</a></li>
                            <li><a [href]="basePaths.statsPage">{{'main.stats' | translate}}</a></li>

                            <li><span class="mobile-menu-entry">{{'software.version' | translate}} {{buildNumber}}</span></li>
                        </ul>
                    </ng-template>
                </ngb-panel>
                
                
                <ngb-panel id="static-9" *ngIf="feedbackEnabled">
                    <ng-template ngbPanelTitle>
                        <div (click)="$event.preventDefault(); openFeedbackDialog()"><i class="fa fa-comment"></i> <span translate>mobile.menu.feedback</span></div>
                    </ng-template>
                </ngb-panel>
            </ngb-accordion>
        </nav>
    `,
    styleUrls: [
        "./mobile-navi.scss"
    ]
})
export class MobileNaviComponent {
    buildNumber: string;

    private mainLinks: MainLink[] = [];

    get basePaths() { return this.navigationService.basePaths; }

    get accountPaths() { return this.navigationService.accountPaths; }

    get feedbackEnabled() {
        return this.settingsService.feedbackEnabled;
    }
 
    constructor(public displayService: DisplayService,
                public accountService: AccountService,
                public navigationService: NavigationService,
                public feedbackService: FeedbackService,
                public settingsService: SettingsService) {
        this.buildNumber = this.settingsService.buildNumberAbbr || 'N/A';

        const paths = this.navigationService.basePaths;

        this.mainLinks = [
            new MainLink('frontpage-link', paths.frontPage),
            new MainLink('search-link', paths.search),
            new MainLink('serialpubs-link', paths.serialPublications),
            new MainLink('papers-for-day-link', paths.papersForDay),
            new MainLink('clippings-link', paths.clippings),
            new MainLink('other-link', paths.otherPage),
            new MainLink('collections-link', paths.collections),
        ];
    }

    public beforeChange($event: NgbPanelChangeEvent) {
        const link = this.mainLinks.find(l => l.id === $event.panelId);

        if (link != null) {
            $event.preventDefault();

            this.navigationService.goTo2([link.uri]).then((() => {
                this.displayService.mobileMenuOpen = false;
            }));
        }
    }

    openFeedbackDialog() {
        this.displayService.mobileMenuOpen = false;
        this.feedbackService.toggleFeedbackForm()
    }
}

class MainLink {
    constructor(public id: string, public uri: string) {}
}
