import {Component, Inject} from "@angular/core";
import {BINDING_VIEW, ICurrentBindingView} from "../../binding/types";
import {RedactionDto, RedactionRestEndpoint} from "../../apina-digiweb";
import {ErrorService} from "../error.service";
import {CookieService} from "ngx-cookie-service";
import {LoggingService} from "../logging.service";
import {Observable} from "rxjs";

const COOKIE_NAME = 'kkRedactionMetadata';

@Component({
    selector: 'app-redaction-form',
    template: `
        <app-sidebar *ngIf="redaction$ | async as redaction">
            <app-sidebar-header titleKey="redact.title" (closeSidebar)="cbv.cancelClipping(true)"></app-sidebar-header>
            <app-sidebar-content>
                <form name="redactionForm" role="form" #f>
                    <div class="form-group">
                        <label for="title"><span translate>redact.form.title</span> *</label>

                        <input id="title" type="text" class="form-control" required [(ngModel)]="redaction.title"
                               name="title">
                    </div>

                    <div class="form-group">
                        <label for="author"><span translate>redact.form.author</span></label>

                        <input id="author" type="text" class="form-control" [(ngModel)]="redaction.author" name="author">
                    </div>

                    <div class="form-group">
                        <label for="reason"><span translate>redact.form.reason</span> *</label>
                        <span class="float-right" translate>redact.form.visible-to-all</span>

                        <select id="reason" class="form-control" required [(ngModel)]="redaction.reason" name="reason">
                            <option value="COPYRIGHT">{{'redact.form.reason.COPYRIGHT' | translate}}</option>
                            <option value="PRIVACY">{{'redact.form.reason.PRIVACY' | translate}}</option>
                        </select>
                    </div>

                    <div class="form-group">
                        <label for="otherInformation"><span translate>redact.form.other-info</span></label>

                        <input id="otherInformation" type="text" class="form-control"
                               [(ngModel)]="redaction.otherInformation" name="otherInfo">
                    </div>

                    <div class="form-group">
                        <div class="d-flex justify-content-between align-items-center">
                            <button class="btn btn-kk-red mr-1" (click)="remove(redaction)" *ngIf="canRemove(redaction)" translate>
                                common.action.delete
                            </button>
                            
                            <button type="button"
                                    class="btn btn-kk-light fa fa-copy fa-lg mr-1"
                                    (click)="copyMetadata(redaction)" ngbTooltip="{{'redact.copy.tooltip' | translate}}"></button>
                            
                            <button type="button" class="btn btn-kk-light fa fa-paste fa-lg mr-auto"
                                    (click)="pasteMetadata(redaction)" ngbTooltip="{{'redact.paste.tooltip' | translate}}"
                                    [disabled]="!metadataAvailable()"></button>
                        
                            <button type="button" class="btn btn-kk-blue mr-1" (click)="cancel()"
                                    [disabled]="cbv.saving" translate>common.action.cancel
                            </button>
                            
                            <button class="btn btn-kk-light" (click)="save(redaction)"
                                    [disabled]="cbv.saving || f.$invalid || !hasActiveClippings(redaction)">
                                <span translate>common.action.save</span>
                                <app-progress-spinner *ngIf="cbv.saving"></app-progress-spinner>
                            </button>
                        </div>
                    </div>
                </form>
            </app-sidebar-content>
        </app-sidebar>
    `
})
export class RedactionFormComponent {

    constructor(private errorService: ErrorService,
                @Inject(BINDING_VIEW) public cbv: ICurrentBindingView,
                private redactionRest: RedactionRestEndpoint,
                private cookieService: CookieService,
                private log: LoggingService) {
        
    }

    redaction$: Observable<RedactionDto> = this.cbv.editableRedaction$;

    cancel() {
        this.cbv.cancelClipping();
    }

    save(redaction: RedactionDto) {
        this.redactionRest.saveRedaction(redaction).toPromise().then(() => {
            this.cbv.cancelClipping(true);
        }, e => {
            this.errorService.showUnexpectedError(e);
        });
    }

    remove(redaction: RedactionDto) {
        this.redactionRest.deleteRedaction(redaction.id).toPromise().then(() => {
            this.cbv.cancelClipping(true);
        }, e => {
            this.errorService.showUnexpectedError(e);
        });
    }

    canRemove(redaction: RedactionDto) {
        return redaction.id != null;
    }

    hasActiveClippings(redaction: RedactionDto): boolean {
        return redaction.regions.length > 0;
    }

    metadataAvailable(): boolean {
        return this.cookieService.check(COOKIE_NAME);
    }

    copyMetadata(redaction: RedactionDto) {
        const value = JSON.stringify({
            title: redaction.title,
            author: redaction.author,
            reason: redaction.reason,
            otherInformation: redaction.otherInformation
        });

        this.cookieService.set(COOKIE_NAME, value, 7, '/', null, true, "Strict");
    }

    pasteMetadata(redaction: RedactionDto) {
        if (this.metadataAvailable()) {
            try {
                const cookieMetadata = JSON.parse(this.cookieService.get(COOKIE_NAME));

                redaction.title = cookieMetadata.title;
                redaction.author = cookieMetadata.author;
                redaction.reason = cookieMetadata.reason;
                redaction.otherInformation = cookieMetadata.otherInformation;
            } catch (e) {
                this.log.error("Error while reading redaction data from cookie", e);
            }
        }
    }
}
