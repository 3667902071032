export function escapeRegExp(s: string): string {
    return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
}

const entityMap: { [char: string]: string } = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': '&quot;',
    "'": '&#39;',
    "/": '&#x2F;'
};

export function escapeHtml(str: any): string {
    return String(str).replace(/[&<>"'\/]/g, s => entityMap[s]);
}
