import {Component} from "@angular/core";
import {SettingsService} from "../settings.service";

@Component({
    selector: "app-tutkainpage",
    template: `
        <div class="container-fluid other-section">
            <h2 align="center" class="mt-5 mb-5">{{'tutkain.page.title' | translate}}</h2>
            
            <p class="sitemap_indent" 
               style="display:block" [innerHTML]="'tutkain.page.desc' | translate">
            </p>
        </div>
        
    `
})
export class TutkainComponent {

    constructor(private settingsService: SettingsService) {

    }

    get externalResourcesEnabled() {
        return this.settingsService.commonOptions.includeExternalResources;
    }


}
