export type IPredicate<T> = (v: T) => boolean;

/**
 * Combines two predicates to new predicate which is the logical and or the
 * combined predicates. null values are considered as constant true predicates.
 */
export function predicateAnd<T>(first: IPredicate<T> | null, second: IPredicate<T> | null): IPredicate<T> | null {
    return (first != null && second != null) ? v => first(v) && second(v)
        : (first != null) ? first
        : (second != null) ? second
        : null;
}
