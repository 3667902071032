import {AfterViewInit, Component, ElementRef, OnDestroy} from "@angular/core";
import {FacebookService} from "./facebook.service";
import {Subscription} from "rxjs";

@Component({
    selector: "app-facebook-content",
    template: `
        <div class="fb-page"
             data-height="700"
             data-href="https://www.facebook.com/digikansalliskirjasto/"
             data-tabs="timeline"
             data-small-header="true"
             data-adapt-container-width="true"
             data-hide-cover="true"
             data-show-facepile="false">
            <blockquote cite="https://www.facebook.com/digikansalliskirjasto/"
                        class="fb-xfbml-parse-ignore">
                <a target="_new" href="https://www.facebook.com/digikansalliskirjasto/">digi.kansalliskirjasto.fi</a>
            </blockquote>
            <ng-content></ng-content>
        </div>
    `
})
export class FacebookContentComponent implements AfterViewInit, OnDestroy {
    private subscription: Subscription;

    constructor(private element: ElementRef,
                private facebookService: FacebookService) {
    }

    ngAfterViewInit(): void {
        this.subscription = this.facebookService.xfbml.subscribe(t => {
            t.parse(this.element.nativeElement);
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
