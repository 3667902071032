import {Component, Input} from "@angular/core";
import {IYearSpan, IYearSpanWithMaterialAvailability, MATERIAL_AVAILABILITIES} from "../../browse/browse-titles-types";

@Component({
    selector: 'app-digitization-years',
    template: `
        <span class="digitization-years-available" *ngIf="available != null"><i class="fa fa-newspaper"></i><span ngbTooltip="{{'title.info.years.tooltip' | translate}}">{{available}}</span></span>
        <span class="digitization-years-allowed" *ngIf="allowed != null"><i class="fa fa-unlock"></i><span ngbTooltip="{{'title.info.years.tooltip' | translate}}">{{allowed}}</span></span>
        <span class="digitization-years-restricted" *ngIf="restricted != null"><i class="fa fa-lock" ngbTooltip="{{'title.restricted-access' | translate}}"></i><span ngbTooltip="{{'title.info.years.tooltip' | translate}}">{{restricted}}</span></span>
    `,
    styles: [
        `i { margin-right: 0.1rem; }`
    ]
})
export class DigitizationYearsComponent {

    public available: string;
    public restricted: string;
    public allowed: string;

    _list: IYearSpanWithMaterialAvailability[];

    get list() {
        return this._list;
    }

    @Input()
    set list(value: IYearSpanWithMaterialAvailability[]) {
        this._list = value;
        const groups = _.groupBy(this.list, a => a.availability);
        this.available = formatGroup(groups[MATERIAL_AVAILABILITIES.PUBLIC]);
        this.restricted = formatGroup(groups[MATERIAL_AVAILABILITIES.RESTRICTED]);
        this.allowed = formatGroup(groups[MATERIAL_AVAILABILITIES.ALLOWED]);
    }
}

function formatYearSpan(yearSpan: IYearSpan): string {
    if (yearSpan.from === yearSpan.to) {
        return '' + yearSpan.from;
    } else {
        return yearSpan.from + "-" + yearSpan.to;
    }
}

const MAX_LISTED_YEAR_SPANS = 3;

function formatGroup(yearSpans: IYearSpanWithMaterialAvailability[]): string {
    if (!yearSpans) return null;

    if (yearSpans.length > MAX_LISTED_YEAR_SPANS) {
        return '' + yearSpans[0].yearSpan.from + "..." + yearSpans[yearSpans.length - 1].yearSpan.to;
    } else {
        return _.map(yearSpans, t => formatYearSpan(t.yearSpan)).join(", ");
    }
}
