import {ChangeDetectorRef, Component, Input} from "@angular/core";
import {CheckBoxListItem, SearchService, ValueWithLabel} from "../search/search.service";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

/**
 * Component that facilitates using lists of raw values (e.g. enums) as model for multiple checkboxes.
 */
@Component({
    selector: "app-checkbox-list",
    template: `
        <label class="checkbox-list-item" *ngFor="let item of items">
            <input type="checkbox" [(ngModel)]="item.selected" (change)="update()" [disabled]="disabled" /> {{item.label}}
        </label>
    `,
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: CheckboxListComponent, multi: true}
    ],
    styleUrls: [
        "checkbox-list.scss"
    ]
})
export class CheckboxListComponent implements ControlValueAccessor {

    @Input() referenceData: ValueWithLabel<object>[];

    private selectedValues: object[];

    items: CheckBoxListItem<object>[];

    disabled: boolean;

    private onChange: Function;

    constructor(private searchService: SearchService,
                private cd: ChangeDetectorRef) {}

    update() {
        this.selectedValues = this.searchService.getSelectedItems(this.items);
        this.onChange(this.selectedValues);
        this.cd.markForCheck();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    writeValue(obj: any): void {
        if (obj != null && !(obj instanceof Array))
            throw Error("Invalid argument: " + obj);

        this.selectedValues = obj || [];

        this.items = this.searchService.createCheckBoxList(this.selectedValues, this.referenceData);
    }
}
