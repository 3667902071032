import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {BindingYearListDto, BindingYearListMarkingsDto, BindingYearListWithAnomaliesDto, SerialPublicationRestEndpoint} from "../../../apina-digiweb";
import {BehaviorSubject, concat, Observable, of} from "rxjs";
import {formatEuropeanDate, toVariableAccuracyDate} from "../../../utils/date";
import {switchMap} from "rxjs/operators";
import {NavigationService} from "../../navigation.service";
import {LocalDate} from "@js-joda/core";
import {TitleMarkingsService} from "./title-markings.service";
import {TimeTranslationService} from "../../../translations/time-translations";

@Component({
    selector: "app-year-binding-list",
    template: `
        <table class="table table-striped table-sm text-monospace" *ngIf="list$ | async as list else loading">
            <thead>
            <tr>
                <th translate>year-binding-list.date</th>
                <th translate>year-binding-list.date-accuracy</th>
                <th translate>year-binding-list.issue</th>
                <th translate>year-binding-list.pages</th>
                <th translate>year-binding-list.markings</th>
                <th translate>year-binding-list.date-markings</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of list" [ngClass]="{anomaliesRow: hasAnomalies(item), markingsRow: !!item.markings}">
                <ng-container *ngIf="item.row as b">
                    <td [ngClass]="{anomalies: item.anomalies.duplicateDate}"><a href="{{getBindingUrl(b)}}" target="_blank" appStoreSession>{{formatDate(b.publishingDate)}}</a></td>
                    <td [ngClass]="{anomalies: item.anomalies.suspiciousDateAccuracy}">{{'year-binding-list.date-accuracies.' + b.dateAccuracy | translate}}</td>
                    <td [ngClass]="{anomalies: item.anomalies.duplicateIssue}">{{b.issueNumber}}</td>
                    <td [ngClass]="{anomalies: item.anomalies.suspiciousPageCount}">{{b.pageCount}}</td>
                    <td>
                        <div *ngFor="let prob of b.problems" title="{{'year-binding-list.binding-problems' | translate}}">
                            <span class="problem-type mr-1">{{prob.type | translateBindingProblemType}}</span>
                            <span class="problem-desc">{{prob.description}}</span>
                        </div>
                    </td>
                    <td></td>
                </ng-container>
                <ng-container *ngIf="item.markings as m">
                    <td><div class="cursor-pointer" (click)="openMarkingsDialog(m)">{{formatDate(m.date)}}</div></td>
                    <td>{{'year-binding-list.date-accuracies.' + m.dateAccuracy | translate}}</td>
                    <td>{{m.issue}}</td>
                    <td></td>
                    <td>
                        <div>{{'missing-binding.' + m.type | translate}}</div>
                        <div>{{m.description}}</div>
                    </td>
                    <td>{{formatDate(m.creationTime)}}</td>
                </ng-container>
                <ng-container *ngIf="!item.row && !item.markings">
                    <td colspan="2"></td>
                    <td class="anomalies">??</td>
                    <td colspan="2"></td>
                </ng-container>
            </tr>
            </tbody>
        </table>
        <ng-template #loading>
            <app-progress-spinner></app-progress-spinner>
        </ng-template>
    `,
    styles: [
        ".anomaliesRow { background-color: #ffcccc!important; }",
        ".anomalies, .anomalies a { color: red; font-weight: bold; }",
        ".markingsRow { background-color: rgba(255,138,0,0.62)!important; }",
        ".problem-type { color: saddlebrown; }",
        ".problem-desc { color: black; }"
    ]
})
export class YearBindingListComponent implements OnChanges {
    @Input() issn: string;
    @Input() year: number;

    private refresh$ = new BehaviorSubject<void>(undefined);

    public list$: Observable<BindingYearListWithAnomaliesDto[] | null> = this.refresh$.pipe(
        switchMap(() =>
            concat(
                of(null),
                this.serialPublicationEndpoint.listBindingsForYear(this.issn, this.year)
            )
        )
    );

    private readonly weekdays: string[];

    constructor(private readonly serialPublicationEndpoint: SerialPublicationRestEndpoint,
                private readonly navigationService: NavigationService,
                private readonly titleMarkingsService: TitleMarkingsService,
                timeTranslations: TimeTranslationService) {
        this.weekdays = timeTranslations.weekDays;
    }

    getBindingUrl(row: BindingYearListDto): string {
        return this.navigationService.getBindingUrl(row.generalType, row.bindingId);
    }

    formatDate(date: LocalDate) {
        return this.weekdays[date.dayOfWeek().ordinal()] + " " + formatEuropeanDate(date);
    }

    hasAnomalies(item: BindingYearListWithAnomaliesDto) {
        const a = item.anomalies;
        return a.suspiciousDateAccuracy || a.duplicateDate || a.duplicateIssue || a.missingIssue || a.suspiciousPageCount;
    }

    openMarkingsDialog(m: BindingYearListMarkingsDto) {
        this.titleMarkingsService.showMissingBindingInfoDialog(this.issn, toVariableAccuracyDate(m.date, m.dateAccuracy)).subscribe(() => {
            this.refresh$.next(undefined);
        }, () => {
            // usually cancellation
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.refresh$.next();
    }
}
