import {Component, EventEmitter, Output} from "@angular/core";
import {InfoMessage} from "../../apina-digiweb";
import {BasicInfoService} from "../basic-info.service";

const HIDE_TOKEN_PREFIX = "kk-hide-message-";

@Component({
    selector: "app-info-messages",
    template: `
        <section class="info-messages" *ngIf="hasMessages">
            <div *ngFor="let info of messages" class="info-message-row">
                <h4 style="font-size:120%"><i class="fa-regular fa-circle-info fa-fw"></i>{{info.formattedDate}}</h4>
                <p [innerHTML]="info.message"></p>
                <a title="{{'alert.close.tooltip'|translate}}" class="float-right close-button" href="#" (click)="$event.preventDefault(); close(info)">
                    <i class="fa fa-times" ></i>
                </a>
            </div>
        </section>
    `,
    styleUrls: [
        "./info-messages.scss"
    ]
})
export class InfoMessagesComponent {

    @Output() onRender = new EventEmitter();

    messages: InfoMessage[];

    get hasMessages() {
        return this.messages && this.messages.length > 0;
    }

    constructor(basicInfoService: BasicInfoService) {
        basicInfoService.infoMessages$.subscribe(messages => {
            this.messages = this.filterDismissed(messages);
            this.onRender.emit();
        });
    }

    close(message: InfoMessage) {
        try {
            if (!sessionStorage)
                return;

            sessionStorage.setItem(HIDE_TOKEN_PREFIX + message.hash, "hide");

            this.messages = this.filterDismissed(this.messages);
            this.onRender.emit();
        } catch (e) {
            // security exception
        }
    }

    private filterDismissed(messages: InfoMessage[]): InfoMessage[] {
        try {
            if (!sessionStorage)
                return messages;

            return messages.filter(m => {
                return sessionStorage.getItem(HIDE_TOKEN_PREFIX + m.hash) == null;
            });
        } catch (e) {
            // security exception
            return messages;
        }
    }
}
