import {Component, HostListener, Inject, OnDestroy} from "@angular/core";
import {debounceTime} from "rxjs/operators";
import {Subject, Subscription} from "rxjs";
import {BINDING_VIEW, ICurrentBindingView} from "../../binding/types";

/**
 * This component is not visible and only provides keyboard shortcuts for changing viewed page. 
 */
@Component({
    selector: "app-page-change",
    template: ``
})
export class PageChangeComponent implements OnDestroy {

    private sub = new Subscription();

    onKeyDown = new Subject<Key>();

    @HostListener('body:keydown', ['$event']) onKeydown(event: KeyboardEvent) {

        const body = event.target as Element;

        if (body == null || body.tagName !== 'BODY' || this.currentBindingView.isHorizontallyScrollable()) {
            // omit events from textareas, etc.
            return;
        }

        const key = event.key;

        if (key === 'ArrowLeft') {
            this.onKeyDown.next(Key.ARROW_LEFT);
        } else if (key === 'ArrowRight') {
            this.onKeyDown.next(Key.ARROW_RIGHT);
        }
    }

    constructor(@Inject(BINDING_VIEW) public currentBindingView: ICurrentBindingView) {
        this.sub.add(this.onKeyDown.pipe(
            debounceTime(75) // prevent mass scrolling (there's probably a better way for this)
        ).subscribe(key => {
            if (key === Key.ARROW_LEFT)
                this.currentBindingView.previousPage();
            else
                this.currentBindingView.nextPage();
        }));
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }
}

enum Key { ARROW_LEFT, ARROW_RIGHT }
