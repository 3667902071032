import {Directive, ElementRef, Input, OnDestroy} from "@angular/core";
import {IRegion} from "../../binding/types";
import {Subscription} from "rxjs";

@Directive({
    selector: "[appScaledPosition]",
})
export class ScaledPositionDirective implements OnDestroy {

    constructor(element: ElementRef) {
        const el: HTMLElement = element.nativeElement;
        this.style = el.style;
    }

    private _scale: number;
    private _subscription: Subscription;

    get scale() {
        return this._scale;
    }

    @Input() set scale(value: number) {
        this._scale = value;
        this.updatePosition();
    }

    private _region: IRegion;
    private style: CSSStyleDeclaration;

    @Input() set appScaledPosition(value: IRegion) {
        if (this._region !== value) {
            this._region = value;
            if (value && value.onChanges) {
                if (this._subscription) this._subscription.unsubscribe();
                this._subscription = value.onChanges().subscribe(() => {
                    this.updatePosition();
                });
            }
        }

        this.updatePosition();
    }

    updatePosition() {
        const scale = this.scale;
        const clipping = this._region;
        if (clipping != null) {
            this.style.top = px(clipping.y * scale);
            this.style.left = px(clipping.x * scale);
            this.style.width  = px(clipping.width * scale);
            this.style.height = px(clipping.height * scale);
        }
    }

    ngOnDestroy(): void {
        if (this._subscription)
            this._subscription.unsubscribe();
    }
}

function px(val: number): string {
    return "" + val + "px";
}
