import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Injectable} from "@angular/core";
import {ErrorPopupComponent} from "./error-popup.component";
import {LoggingService} from "./logging.service";
import {HttpErrorResponse} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";

@Injectable()
export class ErrorService {

    noPopup = false;

    constructor(private modalService: NgbModal,
                private log: LoggingService,
                private translateService: TranslateService) {
    }

    showError(title: string, message: string, error: any, suggestPageRefresh?: boolean) {
        this.log.error(error);

        if (this.noPopup) {
            return;
        }

        let modalRef = this.modalService.open(ErrorPopupComponent);
        let component = modalRef.componentInstance as ErrorPopupComponent;

        component.title = title;
        component.message = message;
        component.suggestPageRefresh = suggestPageRefresh;
    }

    showUnexpectedError(error: any) {
        const title = this.translateService.instant('error.unknown-error.title');
        const message = this.translateService.instant('error.unknown-error.message');
        this.showError(title, message, error);
    }

    showHttpError(error: HttpErrorResponse) {
        const errorCode = error.status + " " + error.statusText;
        const title = this.translateService.instant("error.server-error.title");
        const message = this.translateService.instant("error.server-error.message", {errorCode: errorCode});
        this.showError(title, message, error, true);
    }
}
