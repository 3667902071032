import {Injectable} from "@angular/core";
import {LoggingService} from "./logging.service";
import {ActivatedRoute, NavigationEnd, ParamMap, Params, Router} from "@angular/router";
import {Observable} from "rxjs";
import {GeneralType} from "../apina-digiweb";
import {filterInstanceOf} from "../utils/observable-utils";

// XXX unused
export type SiteSection = 'ROOT' | 'SEARCH' | 'CLIPPINGS' | 'SERIALS' | 'PAPERS_FOR_DAY' | 'ACCOUNT' | 'AIKAKAUSI' | 'PIENPAINATE' | 'SANOMALEHTI' | 'TEOS' | 'KOKOELMA' | 'OTHER';

// XXX unused
const sections: {[key: string]: SiteSection} = {
    '': 'ROOT',
    etusivu: 'ROOT',
    account: 'ACCOUNT',
    search: 'SEARCH',
    clippings: 'CLIPPINGS',
    'serial-publications': 'SERIALS',
    'papers-for-day': 'PAPERS_FOR_DAY',
    sanomalehti: 'SANOMALEHTI',
    aikakausi: 'AIKAKAUSI',
    pienpainate: 'PIENPAINATE',
    collections: 'KOKOELMA',
    teos: 'TEOS',
    other: 'OTHER',
};

const pattern = /^\/([a-z\\-]*)\/?.*$/;

@Injectable()
export class NavigationService {

    constructor(private log: LoggingService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
        log.debug("Initialized NavigationService", this);
    }

    // TODO what to do with these paths?
    public readonly basePaths = {
        siteRoot: '/',
        frontPage: '/etusivu',
        search: '/search',
        clippings: '/clippings',
        serialPublications: '/serial-publications',
        papersForDay: '/papers-for-day',
        newspaperRoot: '/sanomalehti',
        journalRoot: '/aikakausi',
        printingRoot: '/pienpainate',
        opusRoot: '/teos',
        collections: '/collections',
        otherPage: '/other',
        openData: '/opendata',
        legacyNewsArticles: '/sanomalehti/directory',

        infoPage: '/info',
        statsPage: '/stats',
        faqPage: '/faq',
        termsPage: '/terms',
        exprPage: '/expr',
        downloadtoolupdatePage: '/downloadtool-update-checker',
        accessibilityPage: '/saavutettavuus',
        cookieConsentPage: '/evasteasetukset',
        digitaliaPage: '/digitalia',
        mediaPage: '/media',
        tutkainPage: '/tutkain',
        ptoleQuick: '/ptolemaios',

        // account
        login: '/signin',
        logout: '/signout',
        register: '/register',
        changePassword: '/account/change-password',
        settings: '/account/settings',
        myClippings: '/account',
        mySearchHistory: '/account/my-search-history',
        removeAccount: '/account/remove-confirm',

        personSearch: '/admin/person-search',
        nameSearch: '/name-search'
    };

    public readonly accountPaths = {
        account: '/account'
    };

    get currentUrl(): string {
        return this.router.url;
    }

    /**
     * Same as AngularJS $location.search()
     */
    get search(): any {
        // TODO is using snapshot ok for now?
        return Object.assign({}, this.activatedRoute.snapshot.queryParams);
    }

    /**
     * Same as AngularJS $location.search(value)
     */
    set search(value: any) {
        this.setSearch(value, false);
    }

    /**
     * replaceUrl When true, navigates while replacing the current state in history.
     */
    setSearch(value: any, replaceUrl: boolean) {
        const params = Object.assign({}, value);

        removeEntriesWithEmptyValue(params);

        this.router.navigate([], {
            queryParams: params,
            replaceUrl
        }).catch(() => {
            this.log.warn("Could not set search parameters", params);
        });
    }

    get search2(): ParamMap {
        // TODO is using snapshot ok for now?
        return this.activatedRoute.snapshot.queryParamMap;
    }
    
    /**
     * Same as AngularJS $location.search(search, paramValue)
     */
    setSearchParam(param: string, value: string|number|null|string[]|boolean): void {
        const queryParams = this.search;
        queryParams[param] = value;

        this.search = queryParams;
    }

    /**
     * @deprecated use goTo2 instead, if possible
     */
    goTo(url: string): void {
        window.location.href = url;
    }

    goTo2(commands: any[], queryParams?: any): Promise<boolean> {
        return this.router.navigate(commands, {
            queryParams
        });
    }

    onPageNavigation(): Observable<NavigationEnd> {
        return this.router.events.pipe(filterInstanceOf(NavigationEnd));
    }

    changeLanguage($event: any, lang: string): void {
        $event.preventDefault();

        this.navigateAndReload({
            set_language: lang
        });
    }

    /**
     * @param overrideQueryParams Merges existing query params with given params.
     */
    navigateAndReload(overrideQueryParams: Params): void {
        const urlTree = this.router.parseUrl(this.router.url);

        Object.assign(urlTree.queryParams, overrideQueryParams);

        window.location.href = this.router.serializeUrl(urlTree);
    }

    getBindingUrl(generalType: GeneralType, bindingId: number): string {
        return this.getSection(generalType) + "/binding/" + bindingId;
    }

    getSection(generalType: GeneralType): string {
        switch (generalType) {
            case GeneralType.NEWSPAPER:
                return this.basePaths.newspaperRoot;
            case GeneralType.JOURNAL:
                return this.basePaths.journalRoot;
            case GeneralType.PRINTING:
                return this.basePaths.printingRoot;
            case GeneralType.BOOK:
            case GeneralType.MANUSCRIPT:
            case GeneralType.MAP:
            case GeneralType.MUSIC_NOTATION:
            case GeneralType.PICTURE:
            case GeneralType.CARD_INDEX:
                return this.basePaths.opusRoot;
            default:
                throw Error("unsupported general-type: " + generalType);
        }
    }
}

function removeEntriesWithEmptyValue(params: any): void {
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const val = params[key];
            if (val == null || val instanceof Array && val.length === 0) {
                delete params[key];
            }
        }
    }
}
