/**
 * This doesn't seem very robust but at least it's simple.
 */
export function sortNumeric(lhs: string, rhs: string): number {
    return (lhs as any) - (rhs as any);
}

export function joinVisible(list: any[]): string {
    return list.filter(a => !!a).join(", ");
}
