import {Injectable} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";
import {SettingsService} from "../settings.service";
import {LoggingService} from "../logging.service";

interface IFB {
    XFBML: IXFBML;
    init(attrs: any): void;
}

export interface IXFBML {
    parse(elem?: Element): void;
}

@Injectable()
export class FacebookService {

    private _xfbml = new ReplaySubject<IXFBML>(1);

    get xfbml(): Observable<IXFBML> {
        return this._xfbml;
    }

    constructor(settingsService: SettingsService,
                log: LoggingService) {
        if (!settingsService.commonOptions.includeExternalResources)
            return;

        const win = window as any;

        // initialise facebook sdk after it loads if required
        if (win.fbAsyncInit == null) {
            win.fbAsyncInit = () => {
                const fb = win.FB as IFB;
                fb.init({
                    appId: window.facebookAppId,
                    xfbml: false,
                    version: 'v2.0'
                });

                log.debug("Loaded facebook SDK");
                this._xfbml.next(fb.XFBML);
            };
        }

        // load facebook sdk if required
        const url = 'https://connect.facebook.net/en_US/sdk.js';
        if (!document.querySelector(`script[src='${url}']`)) {
            const script = document.createElement('script');
            script.src = url;
            document.body.appendChild(script);
        }
    }
}
