import {AfterViewInit, Component, ElementRef, OnDestroy} from "@angular/core";
import {NavigationService} from "../angular4/navigation.service";
import {Subscription} from "rxjs";

const SERVER_ERROR_CONTAINER_ID = 'kk-server-error';

@Component({
    selector: 'app-error-message-once',
    template: `
        <div class="container" *ngIf="showErrorMessage">
            <div class="alert alert-danger">{{errorMessage}}</div>
        </div>
    `
})
export class ErrorMessageOnceComponent implements AfterViewInit, OnDestroy {

    errorMessage: string;
    showErrorMessage = false;
    private subscription: Subscription;

    constructor(elem: ElementRef,
                private navigationService: NavigationService) {
        const serverError: HTMLElement = document.getElementById(SERVER_ERROR_CONTAINER_ID);

        this.errorMessage = serverError.innerText && serverError.innerText.trim();
        this.showErrorMessage = this.errorMessage && this.errorMessage !== '';
    }

    ngAfterViewInit(): void {
        // hack...listen to page navigation events *after* the initial page load
        setTimeout(() => {
            this.subscription = this.navigationService.onPageNavigation().subscribe(() => {
                this.showErrorMessage = false;
            });
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
