import {Component} from "@angular/core";
import {SettingsService} from "../settings.service";
import {DisplayService} from "../display.service";
import {BasicInfoService} from "../basic-info.service";

@Component({
    selector: 'app-main-title',
    template: `
        <div [ngClass]="{minimized: displayService.headerMinimized}">
            <!-- XL+ -->
            <div class="brand-title d-none d-xl-block">
                <div class="d-flex flex-column align-items-start">
                    <div class="digi-header-message d-flex justify-content-between">
                        <div class="pr-2 brand-subtitle">{{'main.subtitle' | translate}} <span *ngIf="showBadge" class="server-name">&nbsp; - &nbsp;{{overrideHeader}}</span></div>
                    </div>
                </div>
            </div>

            <!-- MD-XL -->
            <div class="brand-title d-none d-md-block d-xl-none">
                <div class="d-flex flex-column align-items-start">
                    <div class="digi-header-message d-flex justify-content-between">
                        <div class="pr-2 brand-subtitle">{{'main.subtitle' | translate}} <span *ngIf="showBadge" class="server-name">&nbsp; - &nbsp;{{overrideHeader}}</span></div>
                    </div>
                </div>
            </div>
    
            <!-- XS-MD -->
            <div class="brand-title brand-title-sm d-block d-md-none">
                <div class="d-flex flex-column align-items-start">
                    <div class="digi-header-message d-flex justify-content-between">
                        <div class="pr-2 brand-subtitle">{{'main.subtitle' | translate}}</div>
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: [
        "./main-title.scss"
    ]
})
export class MainTitleComponent {

    overrideHeader: string;

    pageCount: string;

    get showBadge() {
        // noinspection TsLint
        return this.overrideHeader != null && this.overrideHeader != "";
    }

    constructor(settingsService: SettingsService,
                public displayService: DisplayService,
                basicInfoService: BasicInfoService) {
        this.overrideHeader = settingsService.commonOptions.overrideHeader;

        basicInfoService.frontPageContent$.subscribe(response => {
            this.pageCount = formatPageCount(response.pageCount);
        });
    }
}

function formatPageCount(n: number) {
    return n.toLocaleString();
}
