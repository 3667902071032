import {formatCompactDate} from "../utils/date";
import {LocalDate} from "@js-joda/core";

export function excelFileName(baseName: string, queryString: string, startDate: LocalDate, endDate: LocalDate): string {
    const query = queryString.replace(/[^a-zA-Z0-9 ]/g, '').replace(/ /g, '_');

    let suffix = '';
    if (startDate) {
        suffix += `-${formatCompactDate(startDate)}`;
    }
    if (endDate) {
        suffix += `-${formatCompactDate(endDate)}`;
    }

    return query === "" && suffix === "" ? baseName : `${baseName}-${query}${suffix}`;
}
