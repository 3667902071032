import {AfterViewInit, Component, ElementRef, HostListener} from "@angular/core";
import {DisplayService} from "../display.service";
import {NavigationService} from "../navigation.service";
import {SettingsService} from "../settings.service";
import {combineLatest} from "rxjs";

/**
 * Top-level component containing all global and page-specific navigation elements that are intuitively recognized as
 * the page "header".
 */
@Component({
    selector: "app-header",
    template: `
        <div *ngIf="!isMobile" class="header-wrapper" [style.margin-top.px]="marginTop"
             [ngClass]="{ minimized: minimized }">
            <app-brand-area></app-brand-area>

            <app-info-messages (onRender)="updateHeight()"></app-info-messages>

            <div class="navigation-bar">

                <app-global-navi class="kk-bg-dark" [mobile]="false" *ngIf="!minimized"></app-global-navi>

                <div class="page-navigation-wrapper" [ngClass]="{'kk-bg-dark': minimized}">
                    <div class="container">
                        <div class="row d-flex">
                            <app-breadcrumbs></app-breadcrumbs>
                            <button *ngIf="minimized" attr.aria-label="{{'nav.toggler.btn' | translate}}"
                                    class="ml-auto btn btn-kk-light btn-sm"
                                    (click)="displayService.headerMinimized = false">
                                <i class="fa fa-angle-double-down" title="{{'nav.toggler.btn' | translate}}"
                                   attr.aria-label="{{'nav.toggler.btn' | translate}}"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="isMobile" class="pos-f-t" [style.margin-top.px]="marginTop">
            <nav class="navbar navbar-dark mobile-header">
                <a [href]="basePaths.frontPage"><img [src]="settingsService.logoPathLight" height="38px" alt=""/></a>

                <div style="color: white; text-transform: uppercase" translate>main.title.short</div>

                <button attr.aria-label="{{'nav.toggler.btn' | translate}}" class="navbar-toggler"
                        (click)="displayService.toggleMobileMenu()">
                    <span class="navbar-toggler-icon" title="nav.toggler.btn" translate></span>
                </button>
            </nav>

            <div class="collapse mobile-navi-overlay" [ngClass]="{show: displayService.mobileMenuOpen}">
                <app-global-navi [mobile]="true"></app-global-navi>
            </div>

            <app-info-messages (onRender)="updateHeight()"></app-info-messages>

            <app-breadcrumbs class="pl-3 pr-3"></app-breadcrumbs>
        </div>
    `,
    styleUrls: [
        "./header.scss"
    ]
})
export class HeaderComponent implements AfterViewInit {
    private element: HTMLElement;
    private height: number;

    private hide = false;

    public isMobile = false;

    get minimized() {
        return this.displayService.headerMinimized;
    }

    @HostListener("window:resize") onWindowResize() {
        this.updateHeight();
    }

    get marginTop() {
        return this.hide ? -this.height : 0;
    }

    get basePaths() { return this.navigationService.basePaths; }

    constructor(el: ElementRef,
                public displayService: DisplayService,
                private navigationService: NavigationService,
                public settingsService: SettingsService) {
        this.element = el.nativeElement as HTMLElement;

        this.isMobile = this.displayService.isMobile;

        const d = displayService;
        
        combineLatest([d.maximized$, d.headerVisibleHeight]).subscribe(([max, h]) => {
            this.hide = max;
            this.autoUpdateHeight();
        })
    }

    updateHeight() {
        // give child components time to render
        setTimeout(() => {
            const newHeight = this.element.getBoundingClientRect().height;
            if (newHeight !== this.height) {
                this.height = newHeight;
                this.displayService.updateHeaderHeight(newHeight);
            }
        });
    }

    ngAfterViewInit(): void {
        // set initial value after page has been rendered
        this.autoUpdateHeight();
    }

    private autoUpdateHeight() {
        setTimeout(() => {
            this.updateHeight();
        });
    }
}
