import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {UiNode} from "./component-tree.component";

@Component({
    selector: "app-component-tree-node",
    template: `
        <div class="kk-custom-node fn-pad-{{node.depth}}" [ngClass]="{'descendant-match': node.descendantFilterMatch && !node.filterMatch}" (click)="clickNode.emit(node)" (mouseenter)="hoverNode.emit(node)">
            <div [ngSwitch]="node.children?.length > 0" class="comp-node-icon">
                <i class="fa" [ngClass]="{'fa-plus': !node.expanded, 'fa-minus': node.expanded}" *ngSwitchCase="true"></i>
                <i class="fa fa-angle-left rot45" *ngSwitchCase="false"></i>
            </div>
            <div class="comp-type">{{node.type}}</div>
            <div class="comp-label">{{node.label}}</div>
            <div class="comp-page-range">{{node.firstPage}}
                <ng-container *ngIf="node.firstPage !== node.lastPage">&ndash;{{node.lastPage}}</ng-container>
            </div>
        </div>
    `,
    styleUrls: [
        "./component-tree.scss"
    ],
    changeDetection: ChangeDetectionStrategy.Default // node state changes, such as descendantFilterMatch, are not detected with OnPush
})
export class ComponentTreeNodeComponent {

    @Input() node: UiNode;
    @Output() clickNode = new EventEmitter<UiNode>();
    @Output() hoverNode = new EventEmitter<UiNode>();
}