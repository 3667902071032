import {AfterViewInit, Component, ElementRef, Input, ViewChild} from "@angular/core";
import {Chart, ChartDataSets} from "chart.js";
import {BindingSearchResultsBatch, Dictionary} from "../../../apina-digiweb";
import {TranslateService} from "@ngx-translate/core";
import "chartjs-plugin-zoom";

// Printing press was invented around this year. Dont' show results before this as they are probably incorrect and make
// the graph much less readable.
const MIN_REASONABLE_YEAR = 1439;

@Component({
    selector: "app-search-result-chart",
    template: `
        <div><canvas width='200' height='100' #resultChart></canvas></div>
        <div class="text-center pb-3">
            <button class="btn btn-secondary" type="button" title="{{'search.chart.resetzoom' | translate}}" attr.aria-label="{{'search.chart.resetzoom' | translate}}" (click)="resetZoom()">{{'search.chart.resetzoom' | translate}}</button>
        </div>
    `})
export class SearchResultChartComponent implements AfterViewInit {
    @ViewChild("resultChart", {static: true}) resultCanvas: ElementRef;

    @Input()
    set results(searchResults: BindingSearchResultsBatch) {
        this._results = searchResults;
        this.refreshDataSets();
    }

    private _results: BindingSearchResultsBatch;

    private chart: Chart;

    // chart data
    private labels: string[] = [];
    private datasets: ChartDataSets[] = [];
    private readonly hitsLabel: string;
    private readonly allHitsLabel: string;

    constructor(private translateService: TranslateService) {
        this.hitsLabel = this.translateService.instant("search.chart.pages-with-term");
        this.allHitsLabel = this.translateService.instant("search.chart.all-pages");
    }

    ngAfterViewInit(): void {
        const resultCtx = this.resultCanvas.nativeElement.getContext("2d");

        this.chart = new Chart(resultCtx,  {
            type: 'bar',
            data: {
                labels: this.labels,
                datasets: this.datasets
            },
            options: this.getChartOptions()
        });

    }

    resetZoom() {
        if (this.chart) {
            const chartAsAny = this.chart as any;
            chartAsAny.resetZoom();
        }
    }

    private getChartOptions(): any {
        return {
            scales: {
                yAxes: [{
                    id: "y-axis-1",
                    type: "linear",
                    position: "left",
                    gridLines: {
                        display: true
                    },
                    scaleLabel: {
                        display: true,
                        labelString: this.hitsLabel,
                        fontColor: "rgba(255,99,132,1)"
                    },
                    ticks: {
                        fontColor: "rgba(255,99,132,1)",
                        suggestedMin: 0,
                        suggestedMax: 100
                    }
                }, {
                    id: "y-axis-2",
                    type: "linear",
                    position: "right",
                    gridLines: {
                        display: false
                    },
                    scaleLabel: {
                        display: true,
                        labelString: this.allHitsLabel,
                        fontColor: "rgba(158,213,232,1)"
                    },
                    ticks: {
                        fontColor: "rgba(158,213,232,1)"
                    }
                }]
            },
            pan: {
                // Boolean to enable panning
                enabled: true,

                // Panning directions. Remove the appropriate direction to disable
                // Eg. 'y' would only allow panning in the y direction
                mode: 'x'
            },
            zoom: {
                // Boolean to enable zooming
                enabled: true,
                sensitivity: 1,

                // Zooming directions. Remove the appropriate direction to disable
                // Eg. 'y' would only allow zooming in the y direction
                mode: 'x'
            }};
    }

    private refreshDataSets() {
        if (!this._results)
            return;

        const hitsByYear = this._results.hitsByYear;
        const totalHitsByYear = this._results.totalHitsByYear;
        this.labels = getYearLabels(totalHitsByYear);

        this.datasets = [{
            label: this.allHitsLabel,
            type: 'line',
            data: this.labels.map(y => totalHitsByYear[y]),
            backgroundColor: "rgba(158,213,232,0.04)",
            borderColor: "rgba(158,213,232,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(158,213,232,0.4)",
            hoverBorderColor: "rgba(158,213,232,1)",
            yAxisID: 'y-axis-2'
        }];

        if (hitsByYear != null) {
            this.datasets.push({
                label: this.hitsLabel,
                data: this.labels.map(y => hitsByYear[y] || 0),
                backgroundColor: "rgba(255,99,132,0.2)",
                borderColor: "rgba(255,99,132,1)",
                borderWidth: 1,
                hoverBackgroundColor: "rgba(255,99,132,0.4)",
                hoverBorderColor: "rgba(255,99,132,1)",
                yAxisID: 'y-axis-1'
            });
        }

        if (this.chart != null) {
            this.chart.data.labels = this.labels;
            this.chart.data.datasets = this.datasets;
            this.chart.update();
        }
    }
}

const PADDING_YEARS = 5;

function getYearLabels(totalHitsByYear: Dictionary<any>): string[] {
    const keys = Object.keys(totalHitsByYear);

    const numbers = keys.map(y => +y).filter(y => y >= MIN_REASONABLE_YEAR).sort();
    const firstNonZero = numbers.findIndex((y) => totalHitsByYear[y] !== 0);

    // Don't start with a non-zero value, if we have some extra zero years. It makes the graph look nicer.
    const firstNiceIndex = Math.max(firstNonZero - PADDING_YEARS, 0);
    const trimmed = numbers.slice(firstNiceIndex);

    return trimmed.map(y => '' + y);
}
