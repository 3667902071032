import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {FormControl} from "@angular/forms";
import {ResultMode} from "./result-row";
import {SettingsService} from "../../settings.service";
import {Subscription} from "rxjs";
import {QueryOrder} from "../../../apina-digiweb";
import {DomSanitizer} from '@angular/platform-browser';
import {AccountService} from "../../account/account.service";
import {PlatformService} from "../../platform.service";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-result-navigation",
    template: `
        <div class="d-sm-flex justify-content-between align-items-center flex-wrap">

            <ngb-pagination *ngIf="paginationEnabled" [ngClass]="{'match-width': sortOrderEnabled && displayModeEnabled}"
                    [page]="_currentPage" [style.visibility]="resultMode == 'CHART' ? 'hidden' : 'visible'"
                    [collectionSize]="totalResults"
                    [pageSize]="pageSize"
                    [boundaryLinks]="false"
                    [rotate]="true"
                    (pageChange)="changePage($event)"></ngb-pagination>
            
            <div class="mb-3 d-flex align-items-center" *ngIf="displayModeEnabled">
                <mat-button-toggle-group name="displayMode" attr.aria-label="{{'browser.select-display-mode' | translate}}" [formControl]="viewModeControl">
                    <mat-button-toggle *ngIf="isEnabled('TEXT')" value="TEXT" title="{{'search.text.tooltip'|translate}}" aria-label="{{'search.text.tooltip'|translate}}"><i class="fa fa-align-justify"></i></mat-button-toggle>
                    <mat-button-toggle *ngIf="isEnabled('TEXT_WITH_THUMB')" value="TEXT_WITH_THUMB" title="{{'search.thumbtext.tooltip'|translate}}" aria-label="{{'search.thumbtext.tooltip'|translate}}"><i class="fa fa-th-list"></i></mat-button-toggle>
                    <mat-button-toggle *ngIf="isEnabled('THUMB')" value="THUMB" title="{{'search.thumb.tooltip'|translate}}" aria-label="{{'search.thumb.tooltip'|translate}}"><i class="fa fa-th"></i></mat-button-toggle>
                    <mat-button-toggle *ngIf="isEnabled('CHART')" value="CHART" title="{{'search.chart.tooltip'|translate}}" aria-label="{{'search.chart.tooltip'|translate}}"><i class="fa fa-bar-chart"></i></mat-button-toggle>
                </mat-button-toggle-group>

                <a *ngIf="excelDownloadUrl" attr.aria-label="{{'search.excel.tooltip' | translate}}" class="btn btn-kk-blue ml-3" [ngbTooltip]="'search.excel.tooltip' | translate" [href]="excelDownloadUrl" rel="nofollow"><i class="fa fa-file-excel-o"></i></a>
                <a *ngIf="isWindows" attr.aria-label="{{'search.downloadtool.tooltip' | translate}}" class="btn btn-kk-blue ml-1" [ngbTooltip]="'search.downloadtool.tooltip' | translate" #dlToolPopOver="ngbPopover" (click)="activatePopupTimer(dlToolPopOver)" [ngbPopover]="downloadtoolPopupText" [popoverTitle]="downloadtoolPopupTitle" [href]="formatDownloadtoolUrl()" rel="nofollow"><i class="fa fa-download"></i></a>
            </div>

            <select *ngIf="sortOrderEnabled" [ngClass]="{'match-width': paginationEnabled && displayModeEnabled}"
                    [(ngModel)]="_orderBy" aria-label="sort" class="form-control input-sm mb-3" (change)="changeOrderBy()" [style.visibility]="resultMode == 'CHART' ? 'hidden' : 'visible'">
                <option value="RELEVANCE" translate>search.sort.relevance</option>
                <option value="TITLE_ASC" translate>search.sort.title-asc</option>
                <option value="TITLE_DESC" translate>search.sort.title-desc</option>
                <option value="AUTHOR_ASC" translate>search.sort.author-asc</option>
                <option value="AUTHOR_DESC" translate>search.sort.author-desc</option>
                <option value="DATE" translate>search.sort.date-asc</option>
                <option value="DATE_DESC" translate>search.sort.date-desc</option>
                <option value="IMPORT_DATE" translate>search.sort.import-date</option>
            </select>

        </div>

        <ng-template #downloadtoolPopupTitle><b translate>search.downloadtool.popup-title</b></ng-template>
        <ng-template #downloadtoolPopupText><b [innerHtml]="'search.downloadtool.popup-text' | translate"></b></ng-template>
    `,
    styleUrls: [
        "./result-navigation.scss"
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResultNavigationComponent implements OnInit, OnDestroy {
    
    @Input() supportedModes: ResultMode[] = Object.keys(ResultMode) as ResultMode[];
    
    @Input() paginationEnabled = true;
    @Input() displayModeEnabled = true;
    @Input() sortOrderEnabled = true;
    
    @Input() pageSize: number;

    @Input() totalResults: number;
    private isUserHAKAUser: boolean;

    @Input()
    set currentPage(page: number) {
        this._currentPage = page;
    }

    @Input()
    set orderBy(value: QueryOrder) {
        this._orderBy = value;
    }

    @Input()
    get resultMode(): ResultMode {
        return this._resultMode;
    }

    set resultMode(mode: ResultMode) {
        // XXX CHART is not supported
        this._resultMode = mode;
        this.viewModeControl.setValue(mode, {
            emitEvent: false
        })
    }

    @Input() excelDownloadUrl: string | null;
    
    @Output() resultModeChange = new EventEmitter<ResultMode>();  // naming: this is named *Change as then this can be used from host with ([resultMode])="foobar" if prefered

    @Output() currentPageChange = new EventEmitter<number>();

    @Output() orderByChange = new EventEmitter<QueryOrder>();

    viewModeControl = new FormControl("THUMB");

    _resultMode: ResultMode;
    _currentPage: number;
    _orderBy: QueryOrder;

    private sub = new Subscription();

    isWindows = false;

    constructor(private readonly settingsService: SettingsService, private accountService: AccountService, private sanitizer:DomSanitizer, private platformService:PlatformService) {
        this.isWindows = platformService.isWindows();
    }

    ngOnInit(): void {
        this.sub.add(this.viewModeControl.valueChanges.subscribe((mode: ResultMode) => {
            this.changeResultMode(mode);
        }));

        this.isUserHAKAUser = false;

        this.accountService.currentUserInfo$.subscribe(info => {
            this.isUserHAKAUser = info.hakaUser;
        });
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    changePage(page: number) {
        // The NgbComponent emits NaN values on init. We don't care for those.
        if (!isNaN(page)) {
            this.currentPage = page;
            this.currentPageChange.emit(page);
        }
    }

    // resultMode change event is only emitted when this component explicitly changes the result mode
    changeResultMode(mode: ResultMode) {
        this.resultMode = mode;
        this.resultModeChange.emit(mode);
    }

    changeOrderBy() {
        this.orderByChange.emit(this._orderBy);
    }

    isEnabled(mode: ResultMode|string): boolean {
        return this.supportedModes.includes(mode as ResultMode);
    }

    formatDownloadtoolUrl() {
        return this.sanitizer.bypassSecurityTrustUrl("digidownloadtool://?searchUrl=" + encodeURIComponent(window.location.href) + "&haka=" + this.isUserHAKAUser);
    }

    activatePopupTimer(dlToolPopOver: NgbPopover) {
        setTimeout(()  => {
            if(dlToolPopOver.isOpen()){
                dlToolPopOver.close();
            }}, 10000);
    }
}