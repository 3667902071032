import {Component, ElementRef, forwardRef, Input, OnDestroy, ViewChild} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import * as _ from "lodash";
import {Observable, Subscription} from "rxjs";

export type KeywordType = 'KEYWORD';
export const ALL_KEYWORD_TYPES: KeywordType[] = ['KEYWORD'];

function parseKeywordType(value: string): KeywordType {
    if (ALL_KEYWORD_TYPES.includes(value as KeywordType))
        return value as KeywordType;

    throw Error("not supported as keyword: " + value);
}

export interface ITag {
    type: KeywordType;
    value: string;
    persisted: boolean;
}

export class PersistedTag implements ITag {
    type: KeywordType;
    value: string;
    persisted: boolean;

    constructor(type: KeywordType, value: string) {
        this.type = type;
        this.value = value;
        this.persisted = true;
    }

    static compare(a: PersistedTag, b: PersistedTag): number {
        const lhs = a.value;
        const rhs = b.value;
        if (lhs < rhs) return -1;
        else if (lhs === rhs) return 0;
        else return 1;
    }

    static serialize(val: ITag): string {
        return val.type + ":" + val.value;
    }

    static deserialize(val: string): ITag {
        const split = val.split(':');

        return new PersistedTag(parseKeywordType(split[0]), split[1]);
    }
}

const MAX_RENDERED_AUTOCOMPLETE_ITEMS = 100;

@Component({
    selector: "app-tag-select",
    template: `
        <div class="tag-select-container" [ngClass]="{'tag-select-focus': focused}" (click)="clicked($event)"
             tabindex="-1" appCloseTagSelectOnClickOutside>
            <div class="kk-tag" *ngFor="let tag of model" [ngClass]="'kk-tag-' + tag.type.toLowerCase()">
                <span class="kk-tag-class"><i class="fa" [ngClass]="getIconClass()"></i></span>
                <span class="kk-tag-value">{{tag.value}}</span>
                <span *ngIf="!tag.persisted" class="kk-new-tag" title="{{'tag-select.new-tag-tooltip' | translate}}"><i class="fa fa-star"></i></span>
                <button type="button" (click)="removeTag(tag)"><i class="fa fa-close"></i></button>
            </div>

            <input #textInput type="text"
                   (focus)="focusInput()"
                   (click)="focusInput()"
                   (keyup)="onKeyUp($event)"
                   (keydown)="onKeyDown($event)"
                   [(ngModel)]="userText"
                   [placeholder]="placeholder || ''" />

            <div class="tag-select-overlay" [hidden]="!overlayVisible" #overlay>
                <ul>
                    <li *ngFor="let item of autocompleteItems; index as i" (click)="addTag(item)"
                        [ngClass]="{'hilite': isHighLighted(i)}">
                        <i class="fa" [ngClass]="getIconClass()"></i> {{item.value}}
                        <span *ngIf="!item.persisted" class="kk-new-tag" title="{{'tag-select.new-tag-tooltip' | translate}}"><i
                                class="fa fa-star"></i></span>
                    </li>
                    <li class="no-autocomplete-results" *ngIf="noResultsVisible && !loading" (click)="reset()" translate>tag-select.no-suggestions</li>
                    <li class="no-autocomplete-results" *ngIf="loading" (click)="reset()"><span translate>tag-select.loading-suggestions</span> <i class="fa fa-spinner"></i></li>
                </ul>
            </div>
        </div>
    `,
    styleUrls: [
        "./tag-select.scss"
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TagSelectComponent),
            multi: true
        }
    ]
})
export class TagSelectComponent implements ControlValueAccessor, OnDestroy {
    private referenceDataSubscription: Subscription;

    /**
     * We use observable here, because the list is so huge that it causes visible slowdown because of Angular's change
     * detection (it seems to use some deep comparison of arrays). So just in case, *please* keep this thing explicitly
     * as an observable, even if we change the change detection strategy to OnPush.
     */
    @Input() set referenceData(value: Observable<ITag[]>) {
        if (value != null) {
            this.referenceDataSubscription = value.subscribe(tags => {
                this._allTags = tags;
                this.refresh(this._userText, true);
            });
        }
    }

    @Input() placeholder: string;

    // by default allow new tag creation
    @Input() allowUserTags = true;

    autocompleteInitialized = false;

    _allTags: ITag[];
    _selectableTags: ITag[];

    focused = false;

    @ViewChild("textInput", {static: true}) textInput: ElementRef;

    @ViewChild("overlay", {static: true}) overlay: ElementRef;

    model: Set<ITag> = new Set();

    overlayVisible = false;

    _userText = "";

    _highlightIndex: number | null = null;

    // ControlValueAccessor
    _onChange: Function;

    get loading(): boolean {
        return !this._allTags || this._allTags.length === 0;
    }

    get userText(): string {
        return this._userText;
    }

    set userText(value: string) {
        this.refresh(value, false);
    }

    get autocompleteItems() {
        return this._selectableTags;
    }

    get noResultsVisible(): boolean {
        return !this._selectableTags || this._selectableTags.length === 0;
    }

    isHighLighted(index: number) {
        return index === this._highlightIndex;
    }

    // places, authors, places and events were removed in DIGI-4031
    getIconClass() {
        return 'fa-tag';
    }

    addTag(tag: ITag) {
        this.model.add(tag);
        this.hideOverlay();
        this.refresh("");
        this._onChange(this.model);
    }

    removeTag(tag: ITag) {
        this.model.delete(tag);
        this.hideOverlay();
        this.refresh("");
        this._onChange(this.model);
    }

    focusInput() {
        this.focused = true;
        this.showOverlay();

        if (!this.autocompleteInitialized) {
            this.autocompleteInitialized = true;
            this.refresh(this._userText, true);
        } else {
            this.refresh(this._userText);
        }
    }

    clicked($event: Event) {
        if (matchElementClass($event.target as Element, "tag-select-container")) {
            this.textInput.nativeElement.focus();

            if (!this.overlayVisible) {
                this.showOverlay();
            }
        }
    }

    onKeyDown($event: KeyboardEvent) {
        this.preventFormEvents($event);
        this.showOverlay();

        if ($event.key === 'ArrowDown') {
            this.highlightNext();
        } else if ($event.key === 'ArrowUp') {
            this.highlightPrevious();
        }
    }

    onKeyUp($event: KeyboardEvent) {
        this.preventFormEvents($event);
        this.showOverlay();

        if ($event.key === 'Enter') {
            this.selectHighlighted();
        } else if ($event.key === 'Escape') {
            this.reset();
        }
    }

    private preventFormEvents($event: KeyboardEvent) {
        if ($event.key === 'Enter' || $event.key === 'Escape') {
            $event.preventDefault();
            $event.stopPropagation();
        }
    }

    reset() {
        this.refresh("");
        this.hideOverlay();
    }

    private isTagSelected(value: ITag): boolean {
        if (this.model == null)
            return false;

        let found = false;

        // Set.has(...) only supports primitives
        this.model.forEach(val => {
            if (tagEquals(value, val))
                found = true;
        });

        return found;
    }

    private refresh(value: string, force?: boolean) {
        const refreshRequired = value !== this._userText;
        this._userText = value;

        if (refreshRequired || force)
            this._selectableTags = this.filterSelectableItems(this.filterByUserInput(this._allTags));
    }

    private filterByUserInput(items: ITag[]): ITag[] {
        const normalized = this._userText && this._userText.toLowerCase().trim();

        return items ? items.filter(value => {
            return _.includes(value.value.toLowerCase().trim(), normalized);
        }) : [];
    }

    private filterSelectableItems(items: ITag[]): ITag[] {
        const userTag = this.allowUserTags ? createUserDefinedTag(this._userText) : null;

        if (!items)
            return [userTag];

        const filtered = items.filter(tag => !this.isTagSelected(tag));
        const systemTags = filtered.slice(0, MAX_RENDERED_AUTOCOMPLETE_ITEMS);

        return userTag !== null && this.includeUserCreatedTag(userTag, systemTags) ? systemTags.concat(userTag) : systemTags;
    }

    private includeUserCreatedTag(uTag: ITag, existingTags: ITag[]): boolean {
        return !this.isTagSelected(uTag) && !existingTags.some(tag => tag.value.trim() === uTag.value);
    }

    private showOverlay() {
        this.overlayVisible = true;
    }

    private hideOverlay() {
        this.overlayVisible = false;
        this.resetOverlayView();
    }

    private resetOverlayView() {
        this._highlightIndex = null;
        this.overlay.nativeElement.scrollTop = 0;
    }

    private highlightNext() {
        const items = this.autocompleteItems;
        if (!items || items.length === 0)
            return;

        if (this._highlightIndex === null)
            this._highlightIndex = 0;
        else if (this._highlightIndex < items.length - 1) {
            this._highlightIndex++;
        } else {
            this._highlightIndex = items.length - 1;
        }

        // when scrolling down, make sure item is visible
        const highlightedItem = this.getHighlightedItem();
        const overlayEl = this.overlay.nativeElement;

        if (highlightedItem != null && !this.isVisible(overlayEl, highlightedItem)) {
            const scrollTo = highlightedItem.offsetTop + highlightedItem.getBoundingClientRect().height - overlayEl.getBoundingClientRect().height;

            if (scrollTo > 0) {
                overlayEl.scrollTop = scrollTo;
            }
        }
    }

    private highlightPrevious() {
        const items = this.autocompleteItems;
        if (!items || items.length === 0)
            return;

        this._highlightIndex--;

        if (this._highlightIndex < 0)
            this._highlightIndex = 0;

        // when scrolling up, make sure item is visible
        const highlightedItem = this.getHighlightedItem();
        const overlayEl = this.overlay.nativeElement;

        if (highlightedItem != null && !this.isVisible(overlayEl, highlightedItem)) {
            const scrollTo = highlightedItem.offsetTop;

            if (scrollTo < overlayEl.scrollTop) {
                overlayEl.scrollTop = scrollTo;
            }
        }
    }

    private isVisible(scrollingParent: HTMLElement, child: HTMLElement): boolean {
        const childTop = child.offsetTop;
        const childBottom = child.offsetTop + child.getBoundingClientRect().height;

        const viewTop = scrollingParent.scrollTop;
        const viewBottom = scrollingParent.scrollTop + scrollingParent.getBoundingClientRect().height;

        return (childTop > viewTop && childTop < viewBottom) && (childBottom > viewTop && childBottom < viewBottom);
    }

    private getHighlightedItem(): HTMLElement | null {
        return this.overlay.nativeElement.querySelector(`li:nth-child(${this._highlightIndex + 1})`);
    }

    private selectHighlighted() {
        if (this._highlightIndex !== null) {
            this.addTag(this.autocompleteItems[this._highlightIndex]);
        }
        this.resetOverlayView();
    }

    writeValue(obj: any): void {
        if (obj instanceof Set) {
            this.model = obj;
        } else if (obj != null) {
            throw Error("bound object must be a Set<ITag>. Was: " + obj);
        }
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
    }

    ngOnDestroy(): void {
        if (this.referenceDataSubscription != null)
            this.referenceDataSubscription.unsubscribe();
    }
}

function matchElementClass(elem: Element, elemClass: string): boolean {
    if (!elem)
        return false;

    const classes = elem.className;
    return classes != null && _.includes(classes, elemClass);
}


function tagEquals(lhs: ITag, rhs: ITag): boolean {
    return lhs.type === rhs.type && lhs.value === rhs.value;
}

function createUserDefinedTag(input: string): ITag {
    const cleanInput = input.trim();
    if (cleanInput == null || cleanInput.length < 3)
        return null;
    else {
        return {
            type: "KEYWORD",
            value: cleanInput,
            persisted: false
        } as ITag;
    }
}
