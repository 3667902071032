import {Injectable} from "@angular/core";
import {MissingInfoModalComponent} from "./missing-info-modal.component";
import {VariableAccuracyDate} from "../../../apina-digiweb";
import {from, Observable} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {VariableAccuracyDateSelectionModalComponent} from "./variable-accuracy-date-selection-modal.component";

@Injectable()
export class TitleMarkingsService {

    constructor(private readonly ngbModal: NgbModal) {
    }

    showVariableAccuracyDateDialog(year: number): Observable<VariableAccuracyDate> {
        const ref = this.ngbModal.open(VariableAccuracyDateSelectionModalComponent);
        const componentInstance = ref.componentInstance as VariableAccuracyDateSelectionModalComponent;
        componentInstance.year = year;
        return from(ref.result);
    }

    showMissingBindingInfoDialog(issn: string, date: VariableAccuracyDate): Observable<any> {
        const ref = this.ngbModal.open(MissingInfoModalComponent);
        const componentInstance = ref.componentInstance as MissingInfoModalComponent;
        componentInstance.issn = issn;
        componentInstance.date = date;

        return from(ref.result);
    }
}

