import {Injectable, OnDestroy} from "@angular/core";
import {BindingAndAvailability, BindingRestEndpoint, ComponentPartBlock, MarcInfo, OcrData, TitleDataInfo} from "../../apina-digiweb";
import {LoggingService} from "../logging.service";
import {Observable, of, ReplaySubject, Subscription} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {BindingViewParams} from "../../binding/types";
import {ResourceLoadingException} from "../../utils/observable-utils";

/**
 * Services for loading binding data from server.
 */
@Injectable()
export class BindingService implements OnDestroy {

    private _bindingInformation = new ReplaySubject<BindingAndAvailability>(1);
    private _subscription: Subscription;

    get bindingInformation$(): Observable<BindingAndAvailability> {
        return this._bindingInformation;
    }

    constructor(private log: LoggingService,
                private bindingRest: BindingRestEndpoint) {
    }

    initialize(params: BindingViewParams) {
        this.log.debug("Loading binding using params", params);
        
        this._subscription = this.bindingRest.getBindingAndAccessDecision(params.id, params.articleId).subscribe(val => {
            this._bindingInformation.next(val);
        });
    }

    getNewspaperTitleData(): Observable<TitleDataInfo> {
        return this.bindingInformation$.pipe(
            switchMap(data => this.bindingRest.getTitleDataForBinding(data.bindingInformation.citationInfo.issn))
        );
    }

    getMarcRecords(): Observable<MarcInfo[]> {
        return this.bindingInformation$.pipe(
            switchMap(data => this.bindingRest.getMarcDataForBinding(data.bindingInformation.id))
        );
    }

    ngOnDestroy(): void {
        if (this._subscription != null)
            this._subscription.unsubscribe();
    }

    getOcrData(bindingId: number, page: number, oldOcr: boolean): Observable<OcrData | ResourceLoadingException> {
        // TODO cache results
        return this.bindingRest.getOcrDataForPage(bindingId, page, oldOcr).pipe(catchError(error => this.handleLoadingError(error)));
    }

    getComponentPartAreas(bindingId: number, page: number): Observable<ComponentPartBlock[] | ResourceLoadingException> {
        return this.bindingRest.getComponentPartsForPage(bindingId, page).pipe(catchError(error => this.handleLoadingError(error)));
    }

    private handleLoadingError(error: any): Observable<ResourceLoadingException> {
        if (error && error.status) {
            const status = error.status;
            if (status === 404) {
                return of((ResourceLoadingException.NOT_FOUND));
            } else {
                return of((ResourceLoadingException.SERVER_ERROR));
            }
        } else {
            return of(ResourceLoadingException.UNKNOWN);
        }
    }
}
