import {OpenDataRequestedPackageListDTO, OpenDataRestEndpoint} from "../../apina-digiweb";
import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {switchMap} from "rxjs/operators";

@Component({
    selector: "app-opendata-requested-packages",
    template: `
        <section class="container">
            <p [innerHTML]="'opendata.requested-packages.long-description' | translate"></p>

            <table class="table table-striped">
                <thead>
                <tr>
                    <th translate>opendata.requested-packages.columns.link</th>
                    <th translate>opendata.requested-packages.columns.description</th>
                    <th translate>opendata.requested-packages.columns.version</th>
                    <th translate>opendata.requested-packages.columns.filesize</th>
                    <th translate>opendata.requested-packages.columns.md5</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let row of packages">
                    <td><a href="/opendata-download-file/{{row.hash}}/{{row.fileName}}">{{row.fileName}}</a></td>
                    <td>{{row.title}}</td>
                    <td>{{row.version}}</td>
                    <td>{{row.fileSize | fileSize}}</td>
                    <td><code>{{row.md5}}</code></td>
                </tr>
                </tbody>
            </table>
        </section>
    `
})
export class OpendataRequestedPackagesComponent implements OnInit {

    packages: OpenDataRequestedPackageListDTO[];

    constructor(private route: ActivatedRoute,
                private api: OpenDataRestEndpoint) {
    }

    ngOnInit(): void {
        this.route.paramMap.pipe(
            switchMap((params: ParamMap) => {
                    return this.api.getRequestedPackages(params.get("hash"));
                }
            )).subscribe(response => {
            this.packages = response;
        });
    }
}
