import {Component} from "@angular/core";

@Component({
    selector: 'app-progress-bar',
    template: `
        <mat-progress-bar mode="indeterminate" [color]="'accent'"></mat-progress-bar>
    `,
    styleUrls: [
        "progress.scss"
    ]
})
export class ProgressBarComponent {
    
}