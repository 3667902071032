import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

export enum BroadcastMessageType {
    /**
     * data = true if logged in according to server, false otherwise.
     */
    SERVER_LOGIN_STATUS
}

export interface BroadcastMessage {
    type: BroadcastMessageType;
    data: any;
}

/**
 * This service should have NO internal app dependencies, to prevent circular dependencies.
 */
@Injectable()
export class BroadcastChannelService {

    private _channel = new Subject<BroadcastMessage>();

    public messages$ = this._channel.asObservable();

    send(type: BroadcastMessageType, data: any) {
        this._channel.next({
            type,
            data
        });
    }
}
