export function buildQueryString(obj: any): string {
    const parts: string[] = [];
    for (const p of Object.keys(obj))
        parts.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));

    return parts.length !== 0 ? "?" + parts.join("&") : '';
}

export function parseQueryString(query: string): { [elem: string]: string[] } {
    const result: { [elem: string]: string[] } = {};

    if (query[0] === '?')
        query = query.substr(1);

    for (let part of query.split('&')) {
        if (!part) continue;

        part = part.replace(/\+/g, ' ');

        const separatorIndex = part.indexOf("=");
        const key = decodeURIComponent(separatorIndex !== -1 ? part.substr(0, separatorIndex) : part);
        const value = separatorIndex !== -1 ? decodeURIComponent(part.substr(separatorIndex + 1)) : "";

        let values = result[key];
        if (values === undefined) {
            values = [];
            result[key] = values;
        }

        values.push(value);
    }

    return result;
}

// noinspection SpellCheckingInspection
export function appendQueryParam(urlish: string, key: string, value: any): string {
    const appendage = encodeURIComponent(key) + "=" + encodeURIComponent(value);
    if (urlish != null && urlish.includes("?")) {
        return urlish + "&" + appendage;
    } else {
        return urlish + "?" + appendage;
    }
}