import {Component} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
    template: `
        <div class="modal-header">
            <h3 class="modal-title" translate>binding.ocr.title</h3>
            <button type="button" class="close" (click)="activeModal.dismiss()" aria-hidden="true">&times;</button>
        </div>
        <div class="modal-body">
            <div translate>binding.ocr.info.text</div>
        </div>
    `
})
export class BindingOcrInfoModalComponent {

    constructor(public activeModal: NgbActiveModal) {
    }
}
