import {CollectionTreeNodeDTO} from "../../apina-digiweb";

/**
 * Returns an array with the matching collection in [0] and ancestors in [1..n], n being the index of the first ancestor.
 */
export function findCollectionWithAncestors(tree: CollectionTreeNodeDTO[], id: number): CollectionTreeNodeDTO[] | null {
    for (const c of tree) {
        if (c.id === id)
            return [c];

        if (c.children.length > 0) {
            const match = findCollectionWithAncestors(c.children, id);
            if (match != null)
                return match.concat([c]);
        }
    }

    return null;
}
