import {Component, Input, OnChanges, SimpleChanges} from "@angular/core";
import {SerialPublicationRestEndpoint, TitleCalendar, VariableAccuracyDate} from "../../../apina-digiweb";
import {TimeTranslationService} from "../../../translations/time-translations";
import {TitleMarkingsService} from "./title-markings.service";
import {variableAccuracyDate} from "../../../utils/date";

@Component({
    selector: 'app-issue-calendar-mark',
    template: `
        <app-progress-spinner [hidden]="!loading"></app-progress-spinner>
        
        <div *ngIf="calendar != null && !loading">
            <h2>{{'title.mark-missing-binding' | translate}} -
                <a (click)="showMissingBindingInfoDialog(issn, year, null, null)"
                   class="{{calendar.cssClasses}}"
                   title='{{calendar.tooltip}}'>
                    {{calendar.year}}
                </a>
            </h2>
    
            <div class="year-calendar row">
                <div *ngFor="let monthCalendar of calendar.months" class="calendar-month col-6 col-md-4 col-lg-3">
                    <table class="month" [ngClass]="{'has-daily-bindings': monthCalendar.hasBindings, 'has-bindings': monthCalendar.bindings[0] != null}">
                        <a (click)="showMissingBindingInfoDialog(issn, year, monthCalendar.monthValue, null)"
                           class="{{monthCalendar.cssClasses}}"
                           title='{{monthCalendar.tooltip}}'>
                            {{monthNames[monthCalendar.monthValue-1]}}
                        </a>
                        <tbody>
                        <tr style="border-color: lightslategray;" class="weekdays">
                            <td *ngFor="let day of weekDays">{{day}}</td>
                        </tr>
    
                        <tr *ngFor="let week of monthCalendar.weeks">
                            <td *ngFor="let day of week.days; trackBy: trackByIndex" class="day" [ngClass]="day?.cssClasses" align="left">
                                <a *ngIf="day != null" (click)="showMissingBindingInfoDialog(issn, year, day.monthOfYear, day.dayOfMonth)"
                                   class="day" [ngClass]="day?.cssClasses"
                                   title="{{'missing-binding.edit.link' | translate}}">
                                    <span title='{{day.tooltip}}'>
                                        {{day.dayOfMonth}}
                                    </span>
                                </a>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    `,
    styleUrls: [
        "./issue-calendar-mark.scss"
    ]
})
export class IssueCalendarMarkComponent implements OnChanges {
    @Input() issn: string;
    @Input() year: number;

    loading = true;
    monthNames: string[];
    weekDays: string[];

    date: VariableAccuracyDate;

    calendar: TitleCalendar;

    constructor(private readonly titleMarkingsService: TitleMarkingsService,
                private readonly serialPublicationEndpoint: SerialPublicationRestEndpoint,
                timeTranslations: TimeTranslationService) {
        this.monthNames = timeTranslations.monthNamesFull;
        this.weekDays = timeTranslations.weekDays;
    }

    private updateCalendar() {
        this.loading = true;
        this.calendar = null;

        this.serialPublicationEndpoint.calendarForYear(this.issn, this.year).subscribe(calendar => {
            this.calendar = calendar;
            this.loading = false;
        });
    }

    showMissingBindingInfoDialog(issn: string, year: number, month: number, day: number) {
        this.year = year;
        this.issn = issn;
        this.date = variableAccuracyDate(year, month, day);
        this.titleMarkingsService.showMissingBindingInfoDialog(issn, this.date).subscribe(() => {
            this.updateCalendar();
        }, () => {
            // usually cancellation
        });
    }

    // trackByFunction<any>
    public trackByIndex(index: number) {
        return index;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateCalendar();
    }
}
