import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CitationInfo, MarcInfo} from "../../apina-digiweb";
import {formatEuropeanDate} from "../../utils/date";
import {BindingService} from "./binding.service";
import {ErrorService} from "../error.service";
import {LoggingService} from "../logging.service";
import {Subscription} from "rxjs";
import {LocalDate} from "@js-joda/core";

@Component({
  selector: 'app-binding-inaccessible',
  template: `
      <section class="container">
          <div class="row">
              <div class="col-12">
                  <div class="alert alert-danger flex-wrap">
                      <div style="display:block" [innerHTML]="'error.not-authorized' | translate"></div>
                  </div>
              </div>
              <hr/>
              <div class="col-12">
                  <table class="table">
                      <tbody>
                      <tr>
                          <td>{{'inaccessible.binding.bindingtitle' | translate}}</td>
                          <td>{{citationInfo.bindingTitle}} <a target="_blank" href="{{'natlibfi.finnasearch' | translate}}%22{{this.shortTitle}}%22"><i class="fa fa-search-plus"></i></a><span class="sr-only">Hae nimekettä Finna.fi-palvelusta.</span></td>
                      </tr>
                      <tr>
                          <td>{{'inaccessible.binding.issn' | translate}}</td>
                          <td>{{citationInfo.issn}}</td>
                      </tr>
                      <tr *ngIf="citationInfo.publisher">
                          <td>{{'inaccessible.binding.publisher' | translate}}</td>
                          <td>{{citationInfo.publisher}}</td>
                      </tr>
                      <tr>
                          <td>{{'inaccessible.binding.publishingdate' | translate}}</td>
                          <td *ngIf="citationInfo.publishingDate">
                              {{formatDate(citationInfo.publishingDate)}}
                          </td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              <hr/>
              <div class="col-12">
                  <app-progress-spinner *ngIf="loading"></app-progress-spinner>

                  <div *ngIf="notFound">
                      <p translate>binding.marc.not-found</p>
                  </div>
                  <div *ngIf="marcRecords?.length > 0">
                      <div class="marc-heading">
                          <span class="title" translate>marc.view.title</span>
                      </div>

                      <div *ngFor="let df of marcRecords[0].dataFieldInfos" class="row">
                          <span class="text-muted col-4 overflow-break-words">
                              {{('marc.' + df.tag) | translate}}:
                          </span>
                          <span class="col-8 overflow-break-words">
                              <span *ngFor="let sf of df.subFields">
                                  {{sf.value}}
                              </span>
                          </span>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  `,
  styleUrls: ['./binding-inaccessible.component.scss']
})
export class BindingInaccessibleComponent implements OnInit, OnDestroy {

    @Input() citationInfo: CitationInfo;

    marcRecords: MarcInfo[];

    loading = true;
    notFound = false;

    showMarc = false;
    shortTitle = "";

    private sub: Subscription;

    constructor(private bindingService: BindingService,
                private errorService: ErrorService,
                private $log: LoggingService) {
    }

    ngOnInit() {
        this.sub = this.bindingService.getMarcRecords().subscribe(marcData => {
            this.$log.debug("Loaded Marc data for inaccessible binding", marcData);
            this.marcRecords = marcData;
            this.loading = false;
            this.notFound = marcData.length === 0;

        }, error => {
            this.loading = false;
            if (error.status === 404) {
                this.notFound = true;
            } else {
                this.errorService.showUnexpectedError(error);
            }
        });
        this.shortTitle = this.citationInfo.bindingTitle.split(" / " , 1)[0];
    }

    formatDate(date: LocalDate): string {
        return formatEuropeanDate(date) || '';
    }

    ngOnDestroy(): void {
        if (this.sub)
            this.sub.unsubscribe();
    }
}
