const js_levenshtein = require('js-levenshtein');

/**
 * Useful for trimming strings from garbage. Returns first index that does NOT match the characters or -1 if not found.
 */
export function firstNonExcludedIndex(input: string, excludedChars: string): number {
    for (let i = 0; i < input.length; i++) {
        if (!excludedChars.includes(input[i]))
            return i;
    }
    return -1;
}

export function lastNonExcludedIndex(input: string, excludedChars: string): number {
    for (let i = input.length - 1; i >= 0; i--) {
        if (!excludedChars.includes(input[i]))
            return i;
    }
    return -1;
}

export function levenshtein(lhs: string, rhs: string): number {
    const a = lhs != null ? lhs.toLocaleLowerCase() : lhs;
    const b = rhs != null ? rhs.toLocaleLowerCase() : rhs;
    return js_levenshtein(a, b);
}

export function levenshteinWordAverage(lhs: string, rhs: string): number {
    if (!lhs || !rhs)
        return levenshtein(lhs, rhs);

    const lWords = lhs.split(/\s+/);
    const rWords = rhs.split(/\s+/);

    const scores = [];

    for (const lWord of lWords) {
        for (const rWord of rWords) {
            scores.push(levenshtein(lWord, rWord));
        }
    }

    let sum = 0;
    for (const score of scores) {
        sum += score;
    }

    return sum / scores.length;
}

export function crop(value: string, length: number, addEllipsis: boolean): string {
    if (length < 1)
        throw Error("Length too short: " + length);

    if (!value)
        return value;

    if (value.length <= length) {
        return value;
    } else {
        if (addEllipsis) {
            return value.substr(0, length - 1) + "…";
        } else {
            return value.substr(0, length - 1);
        }
    }
}