import {Component} from "@angular/core";
import {SettingsService} from "../settings.service";

@Component({
    selector: "app-mediapage",
    template: `
        <p class="container-fluid other-section">
            <h2 align="center" class="sitemap_indent">{{'mediapage.title' | translate}}</h2>
            
            <p class="sitemap_indent" [innerHTML]="'mediapage.text' | translate"></p>
    `
})
export class MediapageComponent {

    constructor(private settingsService: SettingsService) {

    }

    get externalResourcesEnabled() {
        return this.settingsService.commonOptions.includeExternalResources;
    }


}
