<div id="user-consent-banner" *ngIf="isShown | async">
    <h3 id="user-consent-banner-title" translate>cookies.banner.title</h3>
    <p id="user-consent-banner-text" translate>cookies.banner.description</p>
    <div id="user-consent-banner-footer">
        <a [href]="basePaths.cookieConsentPage" id="user-consent-banner-link" class="link-icon link-arrow" translate>cookies.banner.more</a>
        <div id="user-consent-banner-options">
            <button id="user-consent-banner-button-agree" class="btn btn-kk-light" (click)="agreeConsent()" translate>cookies.banner.accept</button>
            <button id="user-consent-banner-button-disagree" class="btn btn-kk-light" (click)="agreeEssentialConsent()" translate>cookies.banner.decline</button>
        </div>
    </div>
</div>