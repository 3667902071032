import {Component, Input, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {NerRestEndpoint} from "../../../apina-digiweb";
import {map} from "rxjs/operators";

@Component({
    selector: "app-ner-lazy-text",
    template: `
        <div *ngIf="text$ | async as text; else loading">
            <div [innerHTML]="text"></div>
        </div>

        <ng-template #loading>
            <app-progress-spinner></app-progress-spinner>
        </ng-template>
    `
})
export class NerLazyTextComponent implements OnInit {
    @Input() nerOccurrenceId: number;

    text$: Observable<string> | null;

    constructor(private nerRestEndpoint: NerRestEndpoint) {
    }

    ngOnInit(): void {
        this.text$ = this.nerRestEndpoint.fetchOcrContext(this.nerOccurrenceId)
            .pipe(map(t => t.text));
    }
}
