import {Component, Input} from "@angular/core";
import {NavigationService} from "../navigation.service";
import {DisplayService} from "../display.service";
import {ISearchDrawerParameters, SearchDrawerService} from "../nav/search-drawer.service";

@Component({
    selector: "app-search-drawer",
    template: `        
        <app-search-tabs [tab]="tab"></app-search-tabs>
    `,
    styleUrls: [
        "./search-drawer.scss"
    ]
})
export class SearchDrawerComponent {
    private params: ISearchDrawerParameters;

    get tab(): string | undefined {
        return this.params && this.params.tab;
    }

    constructor(public displayService: DisplayService,
                private navigationService: NavigationService,
                searchDrawerService: SearchDrawerService) {
        searchDrawerService.parameters.subscribe(params => {
            this.params = params;
        });
    }
}

/**
 * Replaces the old search-drawer component and delegates the parameters to the global component via a service.
 * Intended for legacy templates where it's difficult to use the service directly.
 */
@Component({
    template: "",
    styles: [":host { display: none; }"]
})
export class SearchDrawerServiceDelegateComponent {

    private params: ISearchDrawerParameters = {};

    @Input() set tab(value: string) {
        if (value !== undefined) {
            this.params.tab = value;
            this.updateParams();
        }
    }

    constructor(private service: SearchDrawerService) {
    }

    private updateParams() {
        this.service.setParameters(this.params);
    }
}
