import {Component, Input} from "@angular/core";
import {TitleCalendarMonth} from "../../../apina-digiweb";
import {TimeTranslationService} from "../../../translations/time-translations";

@Component({
    selector: 'app-calendar-month',
    template: `
        <table class="month mb-5" [ngClass]="{'has-daily-bindings': monthCalendar.hasBindings, 'has-bindings': monthCalendar.bindings[0]}">
            <caption>
                <span [class]="monthCalendar.cssClasses" [ngbTooltip]="monthCalendar.tooltip">
                    {{monthNames[monthCalendar.monthValue-1]}}
                </span>
                <!--<span *ngIf="monthCalendar.almanacLink != null">&nbsp;&nbsp;
                    <a [href]="monthCalendar.almanacLink" [ngbTooltip]="'almanacLinkTitle' | translate" class="almanac" target="_blank"><i class="fa fa-moon-o" aria-hidden="true"></i><span class="sr-only">almanacLinkTitle</span></a>
                </span>-->

                <ul class="calendar-month-issues">
                    <li *ngFor="let binding of monthCalendar.bindings">
                        <a [href]="binding.uri" ngbTooltip="No {{binding.issue}}" target="_blank" appStoreSession>No {{binding.issue}}</a>
                    </li>
                </ul>
            </caption>
            <tbody>
            <tr style="border-color: lightslategray;" class="weekdays">
                <td *ngFor="let day of weekDays">{{day}}</td>
            </tr>

            <tr *ngFor="let week of monthCalendar.weeks" class="week-row">
                <td *ngFor="let day of week.days; trackBy: trackByIndex" class="day {{day?.dayOfMonth != null ? 'day-of-month' : ''}}" [ngClass]="day?.cssClasses" align="left">
                    <span *ngIf="day">
                        <span *ngFor="let binding of day.bindings">
                            <a [href]="binding.uri" ngbTooltip="No {{binding.issue}}" target="_blank" appStoreSession><span>
                                    {{day.dayOfMonth}}
                                </span>
                            </a>
                        </span>
    
                        <span *ngIf="!day.bindingDay || day.hasMissingBinding"
                              [ngbTooltip]="day.tooltip">{{day.dayOfMonth}}</span>
                    </span>
                </td>
            </tr>
            </tbody>
        </table>
    `,
    styleUrls: [
        "./calendar-month.scss"
    ]
})
export class CalendarMonthComponent {
    @Input() monthCalendar: TitleCalendarMonth;
    monthNames: string[];
    weekDays: string[];

    constructor(timeTranslations: TimeTranslationService) {
        this.monthNames = timeTranslations.monthNamesFull;
        this.weekDays = timeTranslations.weekDays;
    }

    // trackByFunction<any>
    public trackByIndex(index: number) {
        return index;
    }
}
